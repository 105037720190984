import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import { getUST_SCHLUESSEL } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getItemNet, getItemTax } from "@/pos/logic/order-reactive.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";
import { formatCurrencyDe } from "@/shared/utils";

const Bonpos_USt = {
  Z_KASSE_ID: {
      type: "string", 
      maxLength: 50,
      hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
      type: "datetime",
      hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
      type: "number",
      places: 0,
      hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
      type: "string",
      maxLength: 40,
      hint: "Vorgangs-ID"
  },
  POS_ZEILE: {
      type: "string",
      maxLength: 50,
      hint: "Zeilennummer"
  },
  UST_SCHLUESSEL: {
      type: "number",
      places: 0,
      hint: "ID des USt-Satzes"
  },
  POS_BRUTTO: {
      type: "number",
      places: 5,
      hint: "Bruttoumsatz"
  },
  POS_NETTO: {
      type: "number",
      places: 5,
      hint: "Nettoumsatz"
  },
  POS_UST: {
      type: "number",
      places: 5,
      hint: "USt"
  }
}

export function Bonpos_UStFactory(order: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  return build();
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      POS_BRUTTO: POS_BRUTTO(),
      POS_NETTO: POS_NETTO(),
      POS_UST: POS_UST()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    if (isCancelled) {
      return order.items.indexOf(item) + 1;
    } else {
      return order.cancellationItems!.indexOf(item) + 1;
    }
  }

  function UST_SCHLUESSEL() {
    return getUST_SCHLUESSEL(item?.tax!);
  }

  function POS_BRUTTO() {
    return formatCurrencyDe(item?.vSum || 0);
  }

  function POS_NETTO() {
    return formatCurrencyDe(getItemNet(item) || 0);
  }

  function POS_UST() {
    return formatCurrencyDe(getItemTax(item) || 0);
  }
}