import Queue from 'queue';
import { rnHost } from "@/shared/webview/rnwebview";
import { captureException } from "@sentry/react";
import debug from 'debug';
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import _ from "lodash";
import pTimeout from "p-timeout";
import delay from "delay";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { testNetworkPrinter } from "@/react/Printer/print-network.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import dayjs from "dayjs";

const log = debug('printer:lanManager');

const queueManager: {
  [k: string]: { q: Queue, id: string, opened: boolean, timeoutId?: ReturnType<typeof setTimeout> }
} = {}

const LIMIT = import.meta.env.MODE === 'production' ? 1000 : 1;

const lanLock = new MultiAwaitLock(false);

export const printLan = (task: { data: Buffer, keepConnection: boolean, printerIp: string, ref?: string }) => {

  const notiErr = _.debounce((e: any) => {
    notificationToast(`print to ip: ${task.printerIp} error ${e.message}`, { autoClose: 30 * 1000 });
  }, 30000, { leading: true });

  if (!queueManager[task.printerIp]) {
    console.log(`create new queue for ${task.printerIp}`);
  }

  queueManager[task.printerIp] = queueManager[task.printerIp] || {
    q: new Queue({ autostart: true, concurrency: 1 }),
    id: task.printerIp,
    opened: false,
  }

  queueManager[task.printerIp].q.push(async () => {
    let count = 0;
    while (count < LIMIT) {
      log(`print Lan to ip: ${task.printerIp} with ${task.data.length} bytes, ref: ${task.ref || ''} at ${dayjs().format('HH:mm:ss')}`);
      let openReady = false;
      try {
        const payload = task.data.toString('base64');
        const _uuid = queueManager[task.printerIp].id;
        try {
          await pTimeout(rnHost.add(1, 1), {
            milliseconds: 1000,
          })
        } catch (e) {
          throw new Error('Comlink fail');
        }
        await lanLock.acquireAsync();
        await rnHost.openTcp(_uuid, task.printerIp, true);
        log(`print to ip: ${task.printerIp} open ready`);
        openReady = true;
        // const extra = {chunkSize: allConfig['print_ip_chunk_size']?.asNumber(), chunkDelay: allConfig['print_ip_chunk_delay']?.asNumber()}
        await rnHost.printTcp({ payload, uuid: _uuid, keepConnection: /*task.keepConnection*/ false, useNativeModule: true });
        if (task.keepConnection) {
          clearTimeout(queueManager[task.printerIp].timeoutId);
          queueManager[task.printerIp].timeoutId = setTimeout(async () => {
            lanLock.tryAcquire();
            await rnHost.closeTcp(task.printerIp, true);
            await delay(3000)
            await lanLock.release();
            console.log('close connection after 20s');
          }, 60 * 60 * 1000)
        } else {
          await rnHost.closeTcp(task.printerIp, true);
        }
        log(`print to ip: ${task.printerIp} completed`);
        await delay(500);
        break;
      } catch (e: any) {
        notiErr(e);
        captureException(new Error(`print to ip: ${task.printerIp} error ${e.message}`), { tags: { type: 'print' } });
        log(`print to ip: ${task.printerIp} error ${e.message} openReady: ${openReady} at ${dayjs().format('HH:mm:ss')}`, {alert: 'printer'});
        console.error(`print to ip: ${task.printerIp} error`, e);
        //todo: emit to virtual printer
        if ((e.message === undefined && openReady)/* || e.message?.includes('Comlink fail')*/) {
          log(`print to ip: ${task.printerIp} completed but with error`);
          break;
        } else {
          const printer = groupPrinters0().find(g => g.printers[0].printerType === 'ip' && g.printers[0].ip === task.printerIp);
          if (!generalSetting0()?.preventShowErrorPopUpForPrinter && count > 10 && generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
            await printerErrDialog(LL0().printing.printerError({
              printer: _.upperFirst(printer?.name),
              printerType: _.upperFirst(printer?.printers[0].printerType)
            }), printer?.printers[0].ip, testNetworkPrinter, 'NetworkPrinter')
          }
        }
        log(`print to ip: ${task.printerIp} continue`);
        await delay(1000);
      } finally {
        count++;
      }
    }
  })
}
