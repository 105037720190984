import { context } from '@opentelemetry/api'

import { posSync0 } from '@/data/PosSyncState.ts'
import type { ClientCommand } from '@/lib/client-caller/ClientCommand.types.ts'
import { createSpan } from '@/lib/open-telemetry.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import { getNatsClient, getNatsClientLock } from "@/lib/entries.ts";

class ClientHandler {
  public command$: Record<string, any>
  private isActive: boolean
  private executedIds: Record<any, any>

  constructor() {
    this.command$ = {}
    this.executedIds = {}
    this.isActive = false
  }

  async startHandler() {
    if (this.isActive) return
    this.isActive = true
    await getNatsClientLock.acquireAsync();
    const natsClient = getNatsClient()
    await natsClient.subscribeToSubject(`${posSync0().id}-client`, async (message: ClientCommand) => {
      const clientHandler = getClientHandler()
      const result = await clientHandler.handleCommand(message.method, message.params, message.commandId)
      return result
    })
  }

  async stopHandler() {
    if (!this.isActive) return
    this.isActive = false
    const natsClient = getNatsClient()
    natsClient.unsubscribeToSubject(`${posSync0().id}-client`)
  }

  registerCommand(command: string, fn: (...args: any[]) => any) {
    this.command$[command] = fn
  }

  async handleCommand(command: string, args: any[], commandId?: string) {
    if (commandId) {
      if (this.executedIds[commandId]) return
      this.executedIds[commandId] = 1
    }
    const span = createSpan('Handling client command', undefined, context.active())
    span?.setAttribute('command', command)
    span?.setAttribute('args', JSON.stringify(args))
    span?.setAttribute('storeId', `${posSync0()?.id!}`)
    span?.setAttribute('deviceId', getDeviceId())
    try {
      if (!this.command$[command]) {
        return {
          error: 'not found',
        }
      }
      const result = await this.command$[command](...args)
      if (result) {
        span?.setAttribute(result, JSON.stringify(result))
      }
      span?.end()
      return result
    } catch (err: any) {
      span?.recordException(err)
      span?.end()
      throw err
    }
  }
}

let clientHandler: ClientHandler

export function getClientHandler() {
  if (!clientHandler) {
    clientHandler = new ClientHandler()
  }
  return clientHandler
}
