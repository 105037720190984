import _ from 'lodash'

import type { Order } from '@/data/Order'
import { PaymentType } from '@/data/Payment'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { OrderStatus, type OrderItem, type OrderItemStrip, type OrderStrip } from '@/pos/OrderType'
import { SPECIAL_ITEM_DESCR } from '@/srm/lib/constants'

/**
 * Complete the order, by adding payment method
 *
 * Do not modify input order
 */
export function completeOrder(o: Readonly<Order>, ...paymentMethods: PaymentType[]): Readonly<Order> {
  if (paymentMethods.length === 0) paymentMethods.push(PaymentType.Cash)
  return { ...o, status: OrderStatus.COMPLETED, payments: paymentMethods.map(p => ({ type: p, extraType: p, value: 0 })) }
}

/**
 * Cancel the order, set payment method to "No payment"
 *
 * Do not modify input order
 */
export function cancelOrder<T extends Order | OrderStrip>(o: Readonly<T>, clearItems = false): Readonly<T> {
  return {
    ...o,
    status: OrderStatus.CANCELLED,
    payments: [{ type: 'none', value: 0 }],
    items: [],
    cancellationItems: clearItems ? [] : o.items,
  }
}

/**
 * Reverse the order, make all items price negative
 *
 * Do not modify input order
 */
export function reverseOrder<T extends Order | OrderStrip>(o: Readonly<T>): Readonly<T> {
  return {
    ...o,
    items: o.items?.map(item => ({
      ...item,
      price: item.price * -1,
      modifiers: item.modifiers.map(m => ({ ...m, price: m.price * -1 })),
    })),
  }
}

/**
 * Generate an order with difference payment method
 *
 * Do not modify input order
 */
export function payableOrder<T extends Order | OrderStrip>(o: Readonly<T>, paymentMethod: 'cash' | 'card' | 'none' = 'cash'): Readonly<T> {
  return {
    ...o,
    payments: [{ type: paymentMethod, value: o.payments[0]?.value ?? 0 }],
  }
}

/**
 * Generate an order with target item refunded
 *
 * Do not modify input order
 */
export function refundOrder<T extends Order | OrderStrip>(o: Readonly<T>, predicate: (o: NonNullable<T['items']>[number]) => boolean = () => true): Readonly<T> {
  return {
    ...o,
    status: OrderStatus.REFUNDED,
    items: o.items?.filter(predicate).map(item => ({
      ...item,
      quantity: item.quantity * -1,
      modifiers: item.modifiers.map(m => ({ ...m, quantity: m.quantity * -1 })),
    })),
    refOrder: o._id,
  }
}

/**
 * Generate an order with target item splitted to sub orders
 *
 * Do not modify input order
 */
export async function splitItem(o: Order, predicate: (o: OrderItem) => boolean): Promise<Order> {
  if (!o.seatMap) throw new Error('SeatMap not found')

  const count = o.seatMap.length
  const targetOrder = o.seatMap.find(seat => seat.items.some(predicate))
  const targetItem = targetOrder?.items.find(predicate)
  if (!targetOrder || !targetItem) throw new Error('Item not found')
  if (targetItem.quantity < 1) throw new Error('Quantity exceeds item quantity')

  const latestTransId = await SrmTransactionLog.findOne({ selector: { ref: o._id } })
    .exec()
    .then(a => a?._id)

  const splittedItem: OrderItem = {
    ...targetItem,
    quantity: targetItem.quantity / count,
    originalInfo: { srm_originalTransactionId: latestTransId },
  }
  const newOrder: Order = {
    ...o,
    seatMap: o.seatMap.map(seat => ({
      ...seat,
      items: [
        ...seat.items.filter(i => !predicate(i)), // Remove original item
        splittedItem, // Add splitted item
      ],
    })),
  }
  return newOrder
}

/**
 * Generate an order with target item moved to another seat
 *
 * Do not modify input order
 */
export async function moveItem(o: Order, opt: { selector: (o: OrderItem) => boolean; fromSeat: number; toSeat: number }): Promise<Order> {
  if (!o.seatMap) throw new Error('SeatMap not found')
  const item = o.seatMap[opt.fromSeat].items.find(opt.selector)
  if (!item) throw new Error('Item not found')

  const latestTransId = await SrmTransactionLog.findOne({ selector: { ref: o.seatMap[opt.fromSeat]._id }, sort: [{ date: 'desc' }] })
    .exec()
    .then(a => a?._id)

  const movedItem: OrderItem = { ...item, originalInfo: { srm_originalTransactionId: latestTransId } }

  return {
    ...o,
    seatMap: o.seatMap.map((subOrder, i) => {
      return {
        ...subOrder,
        items: [...subOrder.items.filter(i => !opt.selector(i)), ...(opt.toSeat === i ? [movedItem] : [])],
      }
    }),
  }
}

/**
 * Make the order become package deal
 *
 * Do not modify input order
 *
 * @deprecated not used
 */
export function packageOrder<T extends Order | OrderStrip>(o: Readonly<T>): Readonly<T> {
  return {
    ...o,
    items: [
      // Special
      {
        name: SPECIAL_ITEM_DESCR.package,
        price: -1 * _.sum(o.items?.map(getItemTotal)),
        quantity: 1,
        modifiers: [],
      },
      ...(o.items ?? []),
    ],
  }
}

function getItemTotal(item: OrderItem | OrderItemStrip) {
  return item.price * item.quantity + _.sumBy(item.modifiers, a => a.price * a.quantity)
}

/**
 * Add item to exisiting order
 *
 * Do not modify input order
 */
export function addItemToOrder<T extends Order | OrderStrip>(o: Readonly<T>, item: NonNullable<T['items']>[number]): Readonly<T> {
  return { ...o, items: [...(o.items ?? []), item] }
}

/**
 * Remove item from exisiting order
 *
 * Do not modify input order
 */
export function removeItemFromOrder<T extends Order | OrderStrip>(o: Readonly<T>, predicate: (o: NonNullable<T['items']>[number]) => boolean): Readonly<T> {
  return { ...o, items: o.items?.filter(i => !predicate(i)) }
}
