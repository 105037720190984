import React, { type FunctionComponent, useEffect, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx'
import type { DocDeepSignal } from "@/data/data-utils.ts";
import type { DeviceSetting } from "@/data/DeviceSetting.ts";
import { editDeviceName } from "@/react/MasterMachineView/MasterMachineView.tsx";
import { computed, useSignal } from "@/react/core/reactive.ts";
import {
  createTheme,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  ThemeProvider
} from "@mui/material";
import { TabPanel } from "@/react/Developer/Developer.tsx";
import _ from "lodash"
import { frontendLanguage } from "@/data/language.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import DeveloperOnlyVerifyPopu from "@dashboard/DeveloperOnlyVerifyPopu.tsx";
import { PosFeatures } from "@/srm/features.logic.ts";
import { deepSignal } from 'deepsignal/react'
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { timezoneOptions } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { setOnlineOrdersV } from "@/react/PendingOrder/PendingOrderLogic.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { toggleSystemWebviewState } from "@/data/systemWebview.ts";

export const SRM_PASSWORD_MASTER_MACHINE = "909"
type SetMasterMachineEditNameType = {
  onClose?: () => void;
  deviceSetting?: DocDeepSignal<DeviceSetting>;
};
const muiTheme = createTheme()
const SetMasterMachineEditName: FunctionComponent<
  SetMasterMachineEditNameType
> = ({ onClose, deviceSetting }) => {

  const [name, setName] = useSignal("")
  const [frontendLanguageState, setFrontendLanguageState] = useSignal(deviceSetting?.frontendLanguage || frontendLanguage())
  const [cert, setCert] = useSignal(deviceSetting?.srm?.cert)
  const [certPSI, setCertPSI] = useSignal(deviceSetting?.srm?.certPSI)
  const [privateKey, setPrivateKey] = useSignal(deviceSetting?.srm?.privateKey)
  const [publicKey, setPublicKey] = useSignal(deviceSetting?.srm?.publicKey)
  const [deviceId, setDeviceId] = useSignal(deviceSetting?.srm?.deviceId)
  const [lastTransSig, setLastTransSig] = useSignal(deviceSetting?.srm?.lastTransSig)
  const [isPopupPassword, setIsPopupPassword] = useSignal(true)

  const [currentTab, setCurrentTab] = useState('0')

  const submit = async () => {
    userFLow(`Submit edit name in master machine ${name()}`, {
      username: loginUser()?.name
    })
    if (deviceSetting) {
      await editDeviceName(deviceSetting, name())
      onClose?.()
    }
  }

  const handleChangeTab = (_: unknown, newValue: string) => {
    userFLow(`change tabs in master machine ${newValue}`, {
      username: loginUser()?.name
    })
    setCurrentTab(newValue)
    if (import.meta.env.MODE === 'production' && Number(newValue) === 2) {
      setIsPopupPassword(true)
    } else {
      setIsPopupPassword(false);
    }
  }

  useEffect(() => {
    if (!name() && deviceSetting?.name) {
      setName(deviceSetting.name);
    }
  }, [deviceSetting?.name]);

  return (
    <div
      className="relative bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-between pt-10 px-5 pb-6 box-border min-w-[480px] max-w-full max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
        />
      </div>
      <ThemeProvider theme={muiTheme}>
        <div className={'w-full h-full no-scrollbar p-[10px] overflow-y-auto'}>
          <div className="relative border-b border-b-slate-200 grid grid-cols-[1fr_max-content] gap-2 items-center">
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab
                value="0"
                label="Name Device"
              />
              <Tab
                value="1"
                label="Device Settings"
              />
              {companyInfo0()?.country !== 'de' && (
                <Tab
                  value="2"
                  label="SRM"
                />
              )}
            </Tabs>
          </div>
          <TabPanel
            value={currentTab}
            index="0"
          >
            <div className="self-stretch flex flex-col items-center justify-start gap-[8px] z-[1]">
              <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                {LL0().article.name()}
              </b>
              <TextField
                className="self-stretch font-mulish font-medium text-base text-gray"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                value={name() || ''}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-end pt-4 px-0 pb-0 z-[2] text-center text-sm text-white-solid-white-100-ffffff">
              <div
                onClick={submit}
                className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff w-28 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border">
                <b className="relative">{LL0().ui.submit()}</b>
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value={currentTab}
            index="1"
          >
            <div className='w-full flex flex-col gap-2'>
              <div
                className="self-stretch rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center py-0 px-4 box-border gap-[8px]">
                <Switch color="primary" size="small"
                        checked={deviceSetting?.autoOpenCashDrawer}
                        onChange={(e) => {
                          userFLow(`Auto open cash drawer in master machine ${deviceSetting?.autoOpenCashDrawer} to ${e.target.checked}`, {
                            username: loginUser()?.name
                          })
                          _.assign(deviceSetting, { autoOpenCashDrawer: e.target.checked })
                        }}
                />
                <b className="relative">{LL0().payment.autoOpenCashDrawer()}</b>
              </div>
              <div
                className="self-stretch rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center py-0 px-4 box-border gap-[8px]">
                <Switch color="primary" size="small"
                        checked={!!deviceSetting?.useVirtualKeyboard}
                        onChange={(e, checked) => {
                          userFLow(`use physical keyboard in master machine ${deviceSetting?.useVirtualKeyboard} to ${checked}`, {
                            username: loginUser()?.name
                          })
                          _.assign(deviceSetting, { useVirtualKeyboard: checked })
                        }}
                />
                <b className="relative">{LL0().settings.usePhysicalKeyboard()}</b>
              </div>
              <div
                className="self-stretch rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center py-0 px-4 box-border gap-[8px]">
                <Switch
                  size="small"
                  checked={!deviceSetting?.hidePendingOrders}
                  onChange={(_e, checked) => {
                    _.assign(deviceSetting, { hidePendingOrders: !checked })
                    if (checked) {
                      setOnlineOrdersV(v => v + 1)
                    }
                  }}
                />
                <b className="relative">{LL0().onlineOrder.pendingOrders()}</b>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='text-[14px] text-black'>Use System Webview:</div>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  className='w-full'
                  size="small"
                  value={deviceSetting?.useSystemWebview ?? false}
                  onChange={async (e) => {
                    await toggleSystemWebviewState(deviceSetting, e.target.value)
                  }}
                >
                  <MenuItem value={'false'}>No</MenuItem>
                  <MenuItem value={'true'}>Yes</MenuItem>
                </Select>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='text-[14px] text-black'>{LL0().companyInfo.frontendLanguage()}</div>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size={'small'}
                  value={frontendLanguageState()}
                  onChange={(v) => {
                    userFLow(`Frontend language in master machine ${deviceSetting?.frontendLanguage} to ${v.target.value}`, {
                      username: loginUser()?.name
                    })
                    setFrontendLanguageState(v.target.value)
                    _.assign(deviceSetting, { frontendLanguage: v.target.value || frontendLanguage() })
                  }}
                >
                  <MenuItem value={"en"}>{LL0().login.english()}</MenuItem>
                  <MenuItem value={"de"}>{LL0().login.german()}</MenuItem>
                  <MenuItem value={"cn"}>{LL0().login.china()}</MenuItem>
                  <MenuItem value={"fr"}>{LL0().login.french()}</MenuItem>
                  <MenuItem value={"vn"}>{LL0().login.vietnam()}</MenuItem>
                </Select>
              </div>

              <div className='flex flex-col gap-1'>
                <div className='text-[14px] text-black'>{LL0().settings.quebecSrmSettings.timezone()}</div>
                <Select
                  value={deviceSetting?.timezone}
                  onChange={(e) => {
                    userFLow(`Timezone in master machine ${deviceSetting?.timezone} to to ${e.target.value}`, {
                      username: loginUser()?.name
                    })
                    _.assign(deviceSetting, {
                      forceTimezone: true,
                      timezone: e.target.value
                    })

                  }}
                  size={'small'}
                >
                  {timezoneOptions.map((region) => [
                    <ListSubheader key={region.country}>{region.country}</ListSubheader>,
                    ...region.timezones.map((timezone) => (
                      <MenuItem key={timezone} value={timezone}>
                        {timezone}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </div>
            </div>
          </TabPanel>

          <TabPanel
            value={currentTab}
            index="2"
          >
            {
              isPopupPassword() ? <DeveloperOnlyVerifyPopu
                  // onClose={() => setIsPopupPassword(false)}
                  password={SRM_PASSWORD_MASTER_MACHINE}
                  onComplete={() => {
                    setIsPopupPassword(false);
                    setCurrentTab("2");
                  }}
                />
                :
                <div className='w-full flex flex-col gap-2'>
                  <div
                    className="self-stretch rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center py-0 px-4 box-border gap-[8px]">
                    <Switch color="primary" size="small"
                            checked={deviceSetting?.srm?.trainingMode}
                            onChange={(e, checked) => {
                              userFLow(`srm device in master machine ${deviceSetting?.srm?.trainingMode} to ${checked}`, {
                                username: loginUser()?.name
                              })
                              _.assign(deviceSetting?.srm, { trainingMode: checked })
                            }}
                    />
                    <b className="relative">{LL0().settings.trainingMode()}</b>
                  </div>

                  <div className='flex flex-col gap-1'>
                    <div className='text-[14px] text-black'>{LL0().settings.userSettings.certificate()}</div>
                    <TextField onChange={(v) => {
                      _.debounce(() => userFLow(`certificate srm in master machine ${deviceSetting?.srm?.cert} to ${v.target.value}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      _.assign(deviceSetting?.srm, { cert: v.target.value })
                      setCert(v.target.value)
                    }} value={cert()} />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-[14px] text-black'>{LL0().settings.userSettings.certificatePSI()}</div>
                    <TextField onChange={(v) => {
                      _.debounce(() => userFLow(`certificatePSI srm in master machine ${deviceSetting?.srm?.certPSI} to ${v.target.value}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      _.assign(deviceSetting?.srm, { certPSI: v.target.value })
                      setCertPSI(v.target.value)
                    }} value={certPSI()} />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-[14px] text-black'>{LL0().settings.userSettings.publicKey()}</div>
                    <TextField onChange={(v) => {
                      _.debounce(() => userFLow(`publicKey srm in master machine ${deviceSetting?.srm?.publicKey} to ${v.target.value}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      _.assign(deviceSetting?.srm, { publicKey: v.target.value })
                      setPublicKey(v.target.value)
                    }} value={publicKey()} />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-[14px] text-black'>{LL0().settings.userSettings.deviceID()}</div>
                    <TextField onChange={(v) => {
                      _.debounce(() => userFLow(`deviceId srm in master machine ${deviceSetting?.srm?.deviceId} to ${v.target.value}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      _.assign(deviceSetting?.srm, { deviceId: v.target.value })
                      setDeviceId(v.target.value)
                    }} value={deviceId()} />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-[14px] text-black'>{LL0().settings.userSettings.lastTransSig()}</div>
                    <TextField onChange={(v) => {
                      _.debounce(() => userFLow(`lastTransSig srm in master machine ${deviceSetting?.srm?.lastTransSig} to ${v.target.value}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      _.assign(deviceSetting?.srm, { lastTransSig: v.target.value })
                      setLastTransSig(v.target.value)
                    }} value={lastTransSig()} />
                  </div>
                </div>
            }
          </TabPanel>
        </div>
      </ThemeProvider>

    </div>
  )
    ;
};

export default SetMasterMachineEditName;
