//todo: computed masterDeviceSetting
//todo: computed timezone
import { computed } from "@/react/core/reactive.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import { DeviceSetting } from "@/data/DeviceSetting.ts";
import { deviceSetting0, deviceSettings0 } from "@/data/DeviceSettingSignal.ts";
import { masterId } from "@/lib/master-signal.ts";

export const masterDeviceSetting = computed<DocDeepSignal<DeviceSetting> | undefined>(() => {
  const masterSetting = deviceSettings0().find(d => d._id === masterId())!;
  if (!masterSetting) return deviceSetting0();
  return masterSetting
});