import type { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";
import { formatCurrencyDe } from "@/shared/utils";

export const Stamm_Abschluss = {
  Z_KASSE_ID: {
      type: "string",
      maxLength: 50,
      hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
      type: "datetime",
      hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
      type: "number",
      places: 0,
      hint: "Nr. des Kassenabschlusses"
  },
  Z_BUCHUNGSTAG: {
      type: "string",
      hint: "Vom Erstellungsdatum abweichender Verbuchungstag"
  },
  TAXONOMIE_VERSION: {
      type: "string",
      maxLength: 10,
      hint: "Version der DFKA-Taxonomie-Kasse",
      regex: "^[0-9]+(\\\\.[0-9]{1,2})?$"
  },
  Z_START_ID: {
      type: "string",
      maxLength: 40,
      hint: "Erste BON_ID im Abschluss"
  },
  Z_ENDE_ID: {
      type: "string",
      maxLength: 40,
      hint: "Letzte BON_ID im Abschluss"
  },
  NAME: {
      type: "string",
      maxLength: 60,
      hint: "Name des Unternehmens"
  },
  STRASSE: {
      type: "string",
      maxLength: 60,
      hint: "Straße"
  },
  PLZ: {
      type: "string",
      maxLength: 10,
      hint: "Postleitzahl"
  },
  ORT: {
      type: "string",
      maxLength: 62,
      hint: "Ort"
  },
  LAND: {
      type: "string",
      maxLength: 3,
      hint: "Land"
  },
  STNR: {
      type: "string",
      maxLength: 20,
      hint: "Steuernummer des Unternehmens"
  },
  USTID: {
      type: "string",
      maxLength: 15,
      hint: "USTID"
  },
  Z_SE_ZAHLUNGEN: {
      type: "number",
      places: 2,
      hint: "Summe aller Zahlungen"
  },
  Z_SE_BARZAHLUNGEN: {
      type: "number",
      places: 2,
      hint: "Summe aller Barzahlungen"
  }
}

export function Stamm_AbschlussFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      Z_BUCHUNGSTAG: Z_BUCHUNGSTAG(),
      TAXONOMIE_VERSION: TAXONOMIE_VERSION(),
      Z_START_ID: Z_START_ID(),
      Z_ENDE_ID: Z_ENDE_ID(),
      NAME: NAME(),
      STRASSE: STRASSE(),
      PLZ: PLZ(),
      ORT: ORT(),
      LAND: LAND(),
      STNR: STNR(),
      USTID: USTID(),
      Z_SE_ZAHLUNGEN: Z_SE_ZAHLUNGEN(),
      Z_SE_BARZAHLUNGEN: Z_SE_BARZAHLUNGEN(),
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  function Z_BUCHUNGSTAG() {
    // hint: "Vom Erstellungsdatum abweichender Verbuchungstag",
    return dayjs.unix(eod.date).toISOString();
  }

  function TAXONOMIE_VERSION() {
    // hint: "Version der DFKA-Taxonomie-Kasse",
    return '2.1';
  }

  function Z_START_ID() {
    // luc minh in cai eod -> eod start order.id, end order.id
    // hint: "Erste BON_ID im Abschluss",
    // fixme: add this field to the model
    return eod.startOrderId;
  }

  function Z_ENDE_ID() {
    // hint: "Letzte BON_ID im Abschluss",
    // fixme: add this field to the model
    return eod.endOrderId;
  }

  function NAME() {
    // hint: "Name des Unternehmens",
    return companyInfo0().name;
  }

  function STRASSE() {
    // hint: "Straße",
    return companyInfo0().address;
  }

  function PLZ() {
    // hint: "Postleitzahl",
    return companyInfo0().zipCode;
  }

  function ORT() {
    // hint: "Ort",
    return companyInfo0().city;
  }

  function LAND() {
    // hint: "Land",
    return 'DEU';
  }

  function STNR() {
    // hint: "Steuernummer des Unternehmens",
    return companyInfo0().taxNumber;
  }

  function USTID() {
    // hint: "USTID",
    // return companyInfo0().ustId;
    return "DE5015003899"
  }

  function Z_SE_ZAHLUNGEN() {
    // hint: "Summe aller Zahlungen",
    return formatCurrencyDe(eod.paymentSum ?? 0);
  }

  function Z_SE_BARZAHLUNGEN() {
    // hint: "Summe aller Barzahlungen",
    return formatCurrencyDe(eod.cashSum ?? 0);
  }
}
