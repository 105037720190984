import Emittery from 'emittery'

import type { Order } from '@/data/Order.ts'
import type { TseMethod } from '@/pos/OrderType.ts'

// Emittery.isDebugEnabled = true;

export const paymentHook = new Emittery<{
  preSplitHandler: [Order, TseMethod | undefined]
  paySplitHandler: Order
  payOrderHandler: Order
  postKitchenPrint: Order
}>({ debug: { name: 'payment' } })

export const eodHook = new Emittery<{
  prePrintZ: undefined
  postPrintZ: string
}>({ debug: { name: 'eod' } })

export const liveSurrealHook = new Emittery<{
  request: [action: any, result: Record<string, any>]
}>({ debug: { name: 'request' } })
