import { clearDatabases, removeDatabases } from "@/data/DataSchema.ts";
import delay from "delay";
import { toast } from "react-toastify";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { getDeviceId } from '@/shared/getDeviceId.ts'
import { collectionsMap } from "@/data/CollectionsMap.ts";
import { Order, PaidOrder } from "@/data/Order.ts";
import { convertDocuments } from "@/data/data-utils.ts";
import { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import _ from "lodash";
import { CommitActionString, OrderStatus } from "@/pos/OrderType.ts";
export async function deleteAllData() {
  await clearDatabases();
  await delay(500);
  await removeDatabases();
  await delay(500);
  toast('Data deleted')
}

import './fix/fix1.ts';
import './fix/fix2.ts';
import './fix/fix3.ts';
import './fix/fix4.ts';
import './fix/fix5.ts';
import './fix/fixTaxGroup.ts';
import './fix/fix6.ts';
import './fix/changeTax142.ts';

//@ts-ignore
window.deleteAllData = deleteAllData


export async function saveAllData() {
  for (const item of collectionsMap()) {
    const data = await item.collection.find().exec();
    localStorage.setItem(`data_${item.name}`, JSON.stringify(data));
  }
  toast('Data saved')
}

//@ts-ignore
window.saveAllData = saveAllData

//write loadAllData
export async function loadAllData() {
  for (const item of collectionsMap()) {
    const data = JSON.parse(localStorage.getItem(`data_${item.name}`) || '[]');
    await item.collection.bulkInsert(data);
  }
  toast('Data loaded');
}

//@ts-ignore
window.loadAllData = loadAllData

export async function generateTseConfig() {
  tseConfig0().username = getDeviceId();
  tseConfig0().password = tseConfig0()._id;
  toast('Tse Config generated');
}

//@ts-ignore
window.generateTseConfig = generateTseConfig

export async function fixZ() {
  // const _orders = await PaidOrder.find().exec();
  // const orders = convertDocuments(_orders, true);
  // for (const order of orders) {
  //   order.z = 1;
  //   order.vDate = getVDate(order.date!);
  // }
  // const _eod = await Eod.findOne({}).exec();
  // const eod = convertDocument(_eod, true);
  // eod.date = dayjs('02.04.2024','DD.MM.YYYY').unix();
  // eod.vDate = dayjs('02.04.2024','DD.MM.YYYY').unix();
  // console.log(eod);

  // fix data here
  // const eods = await Eod.find().exec();
  // for (const eod of eods) {
  //   eod.z = eod.z || 0;
  //   await eod.save();
  // }
  // toast('Fixed Z');
}

//@ts-ignore
window.fixZ = fixZ

async function removeEods(_date: number) {
  const _eods = await Eod.find({ selector: { vDate: { $gte: _date } } }).exec();
  let eods = convertDocuments(_eods, true);
  eods = _.sortBy(eods, 'date');
  for (const eod of _eods) {
    await eod.remove();
  }
}

async function removeEodsSelector(query: any) {
  const _eods = await Eod.find({ selector: query }).exec();
  let eods = convertDocuments(_eods, true);
  eods = _.sortBy(eods, 'date');
  for (const eod of _eods) {
    await eod.remove();
  }
}

export async function fixZ2() {
  const _orders = await Order.find({ selector: {} }).exec();
  // const orders = _.sortBy(convertDocuments(_orders, true), 'date');
  let orders = _.sortBy(_orders.map(o => o.toMutableJSON()), 'date');
  orders = orders.map(o => ({
    ...o,
    date: dayjs.unix(o.date).format('DD.MM HH:mm'),
    vDate: dayjs.unix(o.vDate).format('DD.MM HH:mm'),
  }))

  // convert order.items.commits.action to string
  for (const order of orders) {
    for (const commit of order.commits || []) {
      commit.action = CommitActionString[commit.action]
      //action is enum , so i want convert it to string
    }
  }

  const _date = dayjs('20.04.2024', 'DD.MM.YYYY').unix();
  const order2s = await PaidOrder.find({ selector: { vDate: { $gte: _date } } }).exec();
  const order3s = _.sortBy(convertDocuments(order2s, true), 'date');
  const group = _.groupBy(order3s, o => dayjs.unix(o.vDate!).format('DD.MM.YYYY'));

  // let z = 23;

  // for (const key in group) {
  //   const orders = group[key];
  //   const date = dayjs(key, 'DD.MM.YYYY').startOf('d').unix();
  //   await Eod.upsert({ _id: uuid(), vDate: date, z: z, complete: false, date: date });
  //   for (const order of orders) {
  //     order.z = z;
  //   }
  //   z++;
  // }
  // const o0 = order3s.find(o => o.id === 1238);
  // const o1 = order3s.find(o => o.id === 1239);
  // const o2 = order3s.find(o => o.id === 1372);
  // const o3 = order3s.find(o => o.id === 1373);
  // o1.date = dayjs.unix(o0.date).add(5, 'm').unix();
  // o1.vDate = o0.vDate
  // o2.date = dayjs.unix(o0.date).add(10, 'm').unix();
  // o2.vDate = o0.vDate
  // o3.date = dayjs.unix(o0.date).add(15, 'm').unix();
  // o3.vDate = o0.vDate
  // //fix ID 21;
  // const order20 = orders.find(o => o.id === 20);
  // const order21 = orders.find(o => o.id === 21);
  //
  // order21.vDate= order20.vDate;
  // order21.date = dayjs.unix(order20.date).add(5, 'm').unix();


  // await removeEods(_date);
  // await removeEodsSelector({z: {$in : [24, 27]}})

  // await Eod.upsert({ _id: uuid(), vDate: _date, z: 12, complete: false, date: _date });

  // const eod5 = eods.find(e => e.vDate === -90000);
  // await eod5?.doc?.remove();
  //
  // eods = convertDocuments(await Eod.find({}).exec(), true);
  // eods = _.sortBy(eods, 'date');
  //
  // let z = 18;
  // getVDate();
  // for (const eod of eods) {
  //   _.assign(eod, {z});
  //   z++;
  // }
  //
  // for (const order of orders) {
  //   const eod = eods.find(e => e.vDate === order.vDate);
  //   if (eod) {
  //     order.z = eod.z;
  //   } else {
  //     console.log('no eod for order', order.id);
  //   }
  // }
  // await clearEodCache();
  // maxId0().z =_.maxBy(eods, 'z')!.z;
  // console.log('z', _.maxBy(eods, 'z')!.z);

  const _eods = await Eod.find({ selector: {} }).exec();
  let eods = convertDocuments(_eods, true);
  eods = _.sortBy(eods, 'date');

  // const _transactions = await TseTransaction.find({ selector: {z: 25} }).exec();
  // let transactions = convertDocuments(_transactions, true);
  //
  // const e23 = eods.find(e => e.z === 23);
  // e23.date = dayjs('20.04.2024', 'DD.MM.YYYY').unix();
  // e23.vDate = dayjs('20.04.2024', 'DD.MM.YYYY').unix();

  console.log('step1');
}

//@ts-ignore
window.fixZ3 = async function fixZ3() {
  //reassign orders to eod
  const _orders = await PaidOrder.find().exec();
  let orders = _.sortBy(convertDocuments(_orders, true), 'date');
}

export async function fixFastCheckoutOrder() {
  const _orders = await PaidOrder.find().exec();
  let orders = _.sortBy(convertDocuments(_orders, true), 'date');

  orders = orders.filter(o => !o.table);
  console.log('step1');
}

//@ts-ignore
window.fixZ2 = fixZ2

//@ts-ignore
window.fixFastCheckoutOrder = fixFastCheckoutOrder