import React, { type FunctionComponent, useCallback, useState } from 'react'
import EditDiscountLabelOther from '@payment/EditDiscountLabelOther'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {
  order0,
  orderDiscount,
  setOrderDiscount
} from '@/react/PaymentView/PaymentView.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import DiscountForm from "@/react/core/DiscountForm.tsx";
import type { Order } from "@/data/Order.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { handleClearDiscountOrder, handleOrderDiscount } from "@/react/OrderView/OrderView.tsx";

type DiscountPopupType = {
  onClose?: () => void;
  order?: Order;
};

const DiscountPopup: FunctionComponent<DiscountPopupType> = (
  {
    onClose,
    order
  }) => {

  const [isEditButton, setIsEditButton] = useSignal(false)

  const [isEditDiscountLabelOtherPoOpen, setEditDiscountLabelOtherPoOpen] =
    useState(false)

  const openEditDiscountLabelOtherPo = useCallback(() => {
    setEditDiscountLabelOtherPoOpen(true)
  }, [])

  const closeEditDiscountLabelOtherPo = useCallback(() => {
    setEditDiscountLabelOtherPoOpen(false)
  }, [])

  const handleEditDiscount = () => {
    handleClearDiscountOrder()
    setOrderDiscount({ value: '', type: 'percent', label: '' })
    setIsEditButton(!isEditButton())
  }

  return (
    <>
      <div
        className="rounded-md bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-center justify-start min-w-[700px] max-w-full max-h-full text-left text-mini text-royalblue-400 font-mulish height_414:h-full">
        <div
          className="self-stretch bg-basic-color-white flex flex-row items-center justify-between py-2 px-3.5 border-b-[1px] border-solid border-whitesmoke-300">
          <div className="flex-1 flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-row items-center justify-start gap-[4px]">
              <b className="relative">{LL0().payment.total()}:</b>
              <div className="relative text-smi font-semibold text-firebrick">
                {LL3().format.currency(order0()?.vSum || 0)}
              </div>
            </div>
            <div className="self-stretch hidden flex-col items-start justify-center gap-[2px_0px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[0px_4px]">
                <b className="relative">Beef Steak:</b>
                <div className="relative font-semibold text-gray-300">1</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[4px] text-smi text-gray-200">
                <div className="relative [text-decoration:line-through] font-semibold">
                  {LL3().format.currency(40)}
                </div>
                <div className="relative font-semibold text-firebrick">
                  {LL3().format.currency(38.8)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[18px] text-2xs text-basic-color-white">
            <div className="flex flex-row items-center justify-end gap-[12px]">
              {/*<div className="rounded-10xs bg-royalblue-100 hidden flex-row items-center justify-center py-[7px] px-2">*/}
              {/*  <b className="relative">Save Change</b>*/}
              {/*</div>*/}
              <div
                className="rounded-10xs bg-mediumspringgreen flex flex-row items-center justify-center py-[7px] px-2 cursor-pointer"
                onClick={() => {
                  openEditDiscountLabelOtherPo()
                }}
                ref={makeRipple}
              >
                <b className="relative">{LL0().order.addLabel()}</b>
              </div>
              <div className="rounded-10xs bg-mediumspringgreen flex flex-row items-center justify-center py-[7px] px-2"
                   ref={makeRipple}
                   onClick={handleEditDiscount}>
                <b className="relative">{LL0().order.editButton()}</b>
              </div>
            </div>
            <img
              className="relative w-6 h-6 object-cover cursor-pointer"
              alt=""
              src="/payment-iconclose-popup-icon--grey@2x.png"
              onClick={onClose}
            />
          </div>
        </div>
        <DiscountForm
          showDiscountForm={true}
          isEdit={isEditButton()}
          order={order}
          discount={orderDiscount()}
          setDiscount={setOrderDiscount}
          onApply={(d) => handleOrderDiscount(d)}
          onClear={handleClearDiscountOrder}
          onClose={onClose}
        />
      </div>
      {isEditDiscountLabelOtherPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditDiscountLabelOtherPo}
        >
          <EditDiscountLabelOther onClose={closeEditDiscountLabelOtherPo} />
        </PortalPopup>
      )}
    </>
  )
}

export default DiscountPopup
