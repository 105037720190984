import { type FunctionComponent, useCallback, useState } from "react";
import { kitchenGroupPrinters0, labelPrinters0 } from "@/data/GroupPrinterHub.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { setFilter } from "@/data/ReservationHub.ts";
import {
  filterPrinter,
  getNameKitchenPrinter,
  setFilterPrinter
} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import PortalDrawer from "@edit-menu/Popups/PortalDrawer.tsx";
import KitchenMoreMenu from "@kitchen-monitor/KitchenMoreMenu.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

import { isLabelPrinterEnable } from "@/react/Printer/PrinterSettingView.signal.ts";


const MultipleKitchenButton: FunctionComponent = ({}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEditMenuMoreMenuOpen, setEditMenuMoreMenuOpen] = useState(false);

  const openEditMenuMoreMenu = useCallback(() => {
    userFLow(`open edit menu more menu`, {
      username: loginUser()?.name
    });
    setEditMenuMoreMenuOpen(true);
  }, []);

  const closeEditMenuMoreMenu = useCallback(() => {
    userFLow(`close edit menu more menu`, {
      username: loginUser()?.name
    });
    setEditMenuMoreMenuOpen(false);
  }, []);
  return (
    <>
      <div
        className="self-stretch rounded-[18px] bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(255,_255,_255,_0.4)] text-black-solid-black-700-364150 w-[50px] flex flex-col items-center justify-center gap-[4px] cursor-pointer"
        onClick={openEditMenuMoreMenu}
      >
        <img
          className="relative w-[30px] h-[30px] object-cover sm:w-[22px] sm:h-[22px] Tablet_768:flex Tablet_768:w-5 Tablet_768:h-5"
          alt=""
          src="/burger-menu-icon--white@2x.png"
        />
        <b className="!hidden relative inline-block w-9">
          {LL0().ui.more()}
        </b>
      </div>
      {isOpen && <div className="absolute top-0 right-0 bottom-0 left-0" onClick={() => setIsOpen(false)} />}
      <div
        className="flex text-smi flex-col items-start justify-start text-center text-black-solid-black-855-212121 font-mulish"
      >
        <div onClick={() => setIsOpen(!isOpen)}
             className="shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] flex flex-col items-center justify-start relative">
          <div
            className="rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-[116px]  Tablet_600:w-[80px] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-start py-0 px-2 box-border z-[0]">
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconlist-icon@2x.png"
            />
            <div className="relative leading-[139.8%] flex items-center justify-center w-14 h-[19px] shrink-0">
              {getNameKitchenPrinter(filterPrinter()) !== "Cannot find printer" ? getNameKitchenPrinter(filterPrinter()) : LL0().kitchenMonitor.all()}
            </div>
            <img className="relative w-6 h-6 object-cover" alt="" src="/iconarrow-up-icon@2x.png"
                 style={{ transform: isOpen ? '' : 'rotate(180deg)' }} />
          </div>
          {isOpen &&
            <div
              className="mt-2 absolute w-full right-0 top-full left-0 rounded bg-blue-solid-blue-90-d0e5ff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] overflow-hidden flex flex-col items-center justify-start px-0 box-border gap-[2px] z-[1] text-left text-black-solid-black-700-364150">
              <div
                className="w-full relative leading-[139.8%] font-semibold px-2 py-2 rounded-[2px]"
                ref={makeRipple}
                onClick={() => {
                  setFilterPrinter('all')
                }}>
                {LL0().kitchenMonitor.all()}
              </div>
              {kitchenGroupPrinters0()?.map((printer, index) => (
                <div
                  key={index}
                  className="w-full relative leading-[139.8%] font-semibold px-2 py-2 rounded-[2px]"
                  ref={makeRipple}
                  onClick={() => {
                    setFilterPrinter(printer?._id || "unknown")
                  }}
                >
                  {printer.name}
                </div>
              ))}
              {isLabelPrinterEnable() &&
                <div
                  className="w-full relative leading-[139.8%] font-semibold px-2 py-2 rounded-[2px]"
                  ref={makeRipple}
                  onClick={() => {
                    setFilterPrinter(labelPrinters0()?._id || "unknown")
                  }}
                >
                  {labelPrinters0().name}
                </div>
              }
            </div>}
        </div>
      </div>
      {isEditMenuMoreMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Left"
          onOutsideClick={closeEditMenuMoreMenu}
        >
          <KitchenMoreMenu onClose={closeEditMenuMoreMenu}/>
        </PortalDrawer>
      )}
    </>
  );
};

export default MultipleKitchenButton;
