import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import uuid from 'time-uuid'

import { posSync0, posSyncLock } from '@/data/PosSyncState'
import { useAsyncEffect, useSignal } from '@/react/core/reactive.ts'
import type { PairingCode } from '@/react/Developer/DataMenu.logic.ts'
import SurrealClient from '@/shared/SurrealClient.ts'
import axios from "axios";
import { getApiUrl } from "@/shared/utils.ts";

export function InstallationPairingCodeTab() {
  const [pairingCodeV, setPairingCodeV] = useSignal(1)
  const [pairingCodeStore, setPairingCodeStore] = useSignal<PairingCode[]>([])

  async function createPairingCode() {
    if (!posSync0()?.id) {
      return toast.error('Must connect to store!')
    }
    const response = await axios.post(`${getApiUrl()}/api/generatePairingCode`, {
      storeId: posSync0()?.id
    })
    toast.success('Create success!')
    setPairingCodeStore(prev => [...prev, response.data.code])
  }

  useAsyncEffect(async () => {
    await posSyncLock.acquireAsync()
    const db = await SurrealClient.getSurrealClient('cloud')
    if (!db || !posSync0()?.id) {
      return
    }
    const [pairingCodeRaw] = await db.query<[PairingCode[]]>(`SELECT * FROM PairingCode  WHERE storeId = ${posSync0().id} AND used = false AND type = 'pos' AND until > CURRENT_TIMESTAMP;`)
    if (pairingCodeRaw?.length > 0) setPairingCodeStore(pairingCodeRaw)
  }, [pairingCodeV()])

  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-bold text-[16px]">Available Pairing Code:</h1>
      <div className="flex flex-col gap-2">
        {pairingCodeStore().map((p, i) => (
          <div
            key={i}
            className="flex flex-row items-center gap-5"
          >
            <div className="font-bold text-[14px]">{p.code}</div>
            <p>(Valid until: {dayjs(p.until).format('DD-MM-YYYY')})</p>
          </div>
        ))}
      </div>
      <div className="flex flex-row gap-4 items-center">
        <button
          onClick={createPairingCode}
          className="border-[2px] p-2 rounded-[5px] w-[100px] mt-6"
        >
          Create new
        </button>
        <button
          onClick={() => {
            setPairingCodeV(prev => prev + 1)
            toast.success('Success!')
          }}
          className="border-[2px] p-2 rounded-[5px] w-[100px] mt-6"
        >
          Reload
        </button>
      </div>
    </div>
  )
}
