import SurrealClient from "@/shared/SurrealClient.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import { effect, effectOn, signal } from "@/react/core/reactive.ts";
import delay from "delay";
import { firstTimeConnect } from "@/data/ReplicateState.ts";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { ip } from "@/data/IpState.ts";
import { isMaster, masterId, setIsMaster, setMasterId } from "@/lib/master-signal.ts";

effectOn([isMaster], () => {
  if (isMaster()) {
    console.log('isMaster', isMaster());
  }
})

effect(() => {
  if (!posSync0()?._id) return;
  if (firstTimeConnect.v) return;
  if (!posSync0().id && localStorage.getItem('masterId') !== getDeviceId()) {
    localStorage.setItem('masterId', getDeviceId());
    setIsMaster(true);
    setMasterId(getDeviceId());
  }
})

export const [masterIp, setMasterIp] = signal<string>((() => {
  return localStorage.getItem('masterIp') || 'localhost';
})());

effectOn([ip], () => {
  assignIpToMaster()
})

export const upsertMaster = async (deviceId?: string, _id?: string, forceRestart: boolean = false) => {
  await posSyncLock.acquireAsync()
  if (!posSync0().id) return
  const db = await SurrealClient.getSurrealClient(`n${posSync0().id}`);
  await db.query('UPSERT master:one CONTENT $content', {
    content: {
      masterId: deviceId || getDeviceId(),
      masterIp: _id || ip()
    }
  })
  if (forceRestart) {
    await delay(1000);
    location.reload()
  }
}

const changeMasterIp = async () => {
  await posSyncLock.acquireAsync()
  if (!posSync0().id) return
  const db = await SurrealClient.getSurrealClient(`n${posSync0().id}`);
  const [master] = await db.query<[{ masterId: string, masterIp: string }]>('SELECT * FROM ONLY master:one');
  if (master?.masterId !== getDeviceId()) return
  await db.query('UPSERT master:one SET masterIp = $ip', {
    ip: ip()
  })
  setMasterIp(ip())
  console.log('changeMasterIp', ip());
}

export function assignIpToMaster() {
  if (isMaster() && (masterIp() !== ip() || localStorage.getItem('firstTimeUpsertMaster')) && ip() !== 'localhost') {
    changeMasterIp().then();
    localStorage.setItem('firstTimeUpsertMaster', 'true');
  }
}

setTimeout(() => {
  assignIpToMaster();
}, 10000);

export const reloadIfMasterChanged = { v: true }

//get masterId, get masterIp from db and fallback to localStorage
export const fetchMaster = async () => {
  await posSyncLock.acquireAsync()
  if (!posSync0().id) {
    setIsMaster(true);
    setMasterId(getDeviceId);
    setMasterIp('localhost');
    return
  }
  const db = await SurrealClient.getSurrealClient(`n${posSync0().id}`);
  const [master] = await db.query<[{ masterId: string, masterIp: string }]>('SELECT * FROM ONLY master:one');
  if (master) {
    if (masterId() !== master.masterId && reloadIfMasterChanged.v && !firstTimeConnect.v) {
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
    localStorage.setItem('masterId', master.masterId);
    localStorage.setItem('masterIp', !!master.masterIp ? master.masterIp : 'localhost');
    setMasterId(master.masterId);
    setIsMaster(master.masterId === getDeviceId());
    console.log('master', master.masterId);
    console.log('isMaster', master.masterId === getDeviceId());
    if (master.masterIp) {
      setMasterIp(master.masterIp);
    }
  }
}

//use livequery to change masterId, masterIp, and fallback to localStorage
export const assignLiveQuery = async () => {
  await posSyncLock.acquireAsync()
  if (!posSync0().id) return
  const db = await SurrealClient.getSurrealClient(`n${posSync0().id}`);

  fetchMaster().then();
  await db.live('master', (action, result) => {
    fetchMaster();
  })
}

assignLiveQuery().then();

export function isMasterDevice(_id: string) {
  return masterId() === _id;
}

//@ts-ignore
window.isMaster = isMaster;