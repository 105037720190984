import { MessageCategoryType, MessageClassType, MessageType, ResultType, type SaleToPOIRequest } from './Adyen.ts'
import dayjs from 'dayjs'
import uuid from 'time-uuid'
import {rnHost} from "@/shared/webview/rnwebview.ts";

import type { IPayTxPayload, IPayTxResp, IXTerminal } from '@/react/Terminal/IXTerminal.ts'
import { TerminalTypes } from "@/data/Terminal.ts";

export type AdyenLocalTerminalMetadata = {
  host: string
  port: string
  poiID: string
  saleID: string
}

// https://docs.adyen.com/point-of-sale/design-your-integration/terminal-api/#request-message-header
// https://docs.adyen.com/point-of-sale/design-your-integration/choose-your-architecture/local
// https://docs.adyen.com/point-of-sale/basic-tapi-integration/verify-transaction-status
export default class AdyenLocalTerminal implements IXTerminal {
  baseUrl: string
  // The unique ID of the terminal that you send this request to.
  // Format: [device model]-[serial number]. For example, P400‑123456789.
  PoiID: string
  SaleID: string

  constructor(props: AdyenLocalTerminalMetadata) {
    this.baseUrl = `https://${props.host}:${props.port}`
    this.PoiID = props.poiID
    this.SaleID = props.saleID
  }

  async _get(headers: any) {
    const response = await rnHost.adyenRequest('get', `${this.baseUrl}/nexo`, null, headers)
    console.log('post', response)
    return response
  }

  async _post(data: any, headers: any) {
    const response = await rnHost.adyenRequest('post', `${this.baseUrl}/nexo`, data, headers)
    console.log('post', response)
    return response
  }

  async payTx(payload: IPayTxPayload, onProgress?: Function | undefined): Promise<{ error: string } | IPayTxResp> {
    // Step 3: Initialize the API object
    // https://docs.adyen.com/point-of-sale/design-your-integration/terminal-api/#request-message-header
    // service id maximum length = 10
    const serviceID = dayjs().unix().toString()

    // Use a unique transaction for every transaction you perform
    const transactionID = uuid()
    const SaleToPOIRequest: SaleToPOIRequest = {
      MessageHeader: {
        MessageClass: MessageClassType.Service,
        MessageCategory: MessageCategoryType.Payment,
        MessageType: MessageType.Request,
        ProtocolVersion: '3.0',
        ServiceID: serviceID,
        SaleID: this.SaleID,
        POIID: this.PoiID,
      },
      PaymentRequest: {
        SaleData: {
          SaleTransactionID: {
            TransactionID: transactionID,
            TimeStamp: new Date().toISOString(),
          },
        },
        PaymentTransaction: {
          AmountsReq: {
            Currency: 'EUR',
            RequestedAmount: payload.amount + payload.tip,
          }
        }
      }
    }

    // Step 5: Make the request
    try {
      const terminalAPIResponse = await this._post({ SaleToPOIRequest }, {})
      console.log('terminalAPIResponse', terminalAPIResponse)

      if (terminalAPIResponse) {
        const paymentResponse = terminalAPIResponse?.SaleToPOIResponse?.PaymentResponse
        const POIData = paymentResponse?.POIData
        const saleData = paymentResponse?.SaleData
        switch (paymentResponse?.Response?.Result) {
          case ResultType.Success:
            return {
              response: {
                type: TerminalTypes.Adyen_Local,
                payment: {
                  amount: 0,
                  tipAmount: 0,
                  taxAmount: 0,
                  createdTime: dayjs().unix(),
                  externalPaymentId: '',
                  rawResult: paymentResponse,
                },
              },
            }
          case ResultType.Failure:
            return {
              error: `Payment failure - refusalReason: ${'Payment terminal responded with: ' + paymentResponse.Response.ErrorCondition}`,
            }
          default:
            return {
              error: `Could not reach payment terminal with POI ID ${this.PoiID}`,
            }
        }
      } else {
        return {
          error: 'Empty payment response',
        }
      }
    } catch (e: any) {
      return { error: e.message }
    }
  }

  async refundTx(payload: any, onProgress?: Function | undefined): Promise<any> {
    const serviceID = dayjs().unix().toString()
    // Use a unique transaction for every transaction you perform
    const transactionID = uuid()
    const SaleToPOIRequest: SaleToPOIRequest = {
      MessageHeader: {
        MessageClass: MessageClassType.Service,
        MessageCategory: MessageCategoryType.Reversal,
        MessageType: MessageType.Request,
        ProtocolVersion: '3.0',
        ServiceID: serviceID,
        SaleID: this.SaleID,
        POIID: this.PoiID,
      },
      ReversalRequest: {
        OriginalPOITransaction: {
          POITransactionID: {
            TransactionID: payload.poiTransactionId,
            TimeStamp: new Date().toISOString(),
          }
        },
        SaleData: {
          SaleTransactionID: {
            TransactionID: payload.saleTransactionId,
            TimeStamp: new Date().toISOString()
          },
        },
        ReversalReason: 'MerchantCancel'
      },
    }

    // Step 5: Make the request
    try {
      const terminalAPIResponse = await this._post({ SaleToPOIRequest }, {})
      console.log('terminalAPIResponse', terminalAPIResponse)

      if (terminalAPIResponse) {
        const paymentResponse = terminalAPIResponse?.SaleToPOIResponse?.PaymentResponse
        const POIData = paymentResponse?.POIData
        const saleData = paymentResponse?.SaleData
        switch (paymentResponse?.Response?.Result) {
          case ResultType.Success:
            return {
              response: {
                type: TerminalTypes.Adyen_Local,
                payment: {
                  amount: 0,
                  tipAmount: 0,
                  taxAmount: 0,
                  createdTime: dayjs().unix(),
                  externalPaymentId: '',
                  rawResult: paymentResponse,
                },
              },
            }
          case ResultType.Failure:
            return {
              error: `Payment failure - refusalReason: ${'Payment terminal responded with: ' + paymentResponse.Response.ErrorCondition}`,
            }
          case ResultType.Partial:
            return {
              error: `Payment failure - refusalReason: ${'Partial refund not implemented: ' + paymentResponse.Response.ErrorCondition}`,
            }
          default:
            return {
              error: `Could not reach payment terminal with POI ID ${this.PoiID}`,
            }
        }
      } else {
        return {
          error: 'Empty payment response',
        }
      }
    } catch (e: any) {
      return { error: e.message }
    }
  }

  async voidTx(payload: any, onProgress?: Function | undefined): Promise<any> {
    throw new Error('Method not implemented.')
  }

  async cancel(payload: any, onProgress?: Function | undefined): Promise<any> {
    const serviceID = dayjs().unix().toString()
    // Use a unique transaction for every transaction you perform
    const transactionID = uuid()
    const SaleToPOIRequest: SaleToPOIRequest = {
      MessageHeader: {
        MessageClass: MessageClassType.Service,
        MessageCategory: MessageCategoryType.Abort,
        MessageType: MessageType.Request,
        ProtocolVersion: '3.0',
        ServiceID: serviceID,
        SaleID: this.SaleID,
        POIID: this.PoiID,
      },
      AbortRequest: {
        AbortReason: "MerchantAbort",
        MessageReference: {
          MessageCategory: "Payment",
          SaleID: this.SaleID,
          ServiceID: payload.serviceId  // ServiceID of the original transaction.
        }
      },
    }

    // Step 5: Make the request
    try {
      const terminalAPIResponse = await this._post({ SaleToPOIRequest }, {})
      console.log('terminalAPIResponse', terminalAPIResponse)

      if (terminalAPIResponse) {
        const paymentResponse = terminalAPIResponse?.SaleToPOIResponse?.PaymentResponse
        const POIData = paymentResponse?.POIData
        const saleData = paymentResponse?.SaleData
        switch (paymentResponse?.Response?.Result) {
          case ResultType.Success:
            return {
              response: {
                type: TerminalTypes.Adyen_Local,
                payment: {
                  amount: 0,
                  tipAmount: 0,
                  taxAmount: 0,
                  createdTime: dayjs().unix(),
                  externalPaymentId: '',
                  rawResult: paymentResponse,
                },
              },
            }
          case ResultType.Failure:
            return {
              error: `Payment failure - refusalReason: ${'Payment terminal responded with: ' + paymentResponse.Response.ErrorCondition}`,
            }
          case ResultType.Partial:
            return {
              error: `Payment failure - refusalReason: ${'Partial refund not implemented: ' + paymentResponse.Response.ErrorCondition}`,
            }
          default:
            return {
              error: `Could not reach payment terminal with POI ID ${this.PoiID}`,
            }
        }
      } else {
        return {
          error: 'Empty payment response',
        }
      }
    } catch (e: any) {
      return { error: e.message }
    }
  }

  test(onProgress?: Function | undefined): Promise<any> {
    throw new Error('Method not implemented.')
  }

  isOnline(): Promise<boolean> {
    throw new Error('Method not implemented.')
  }

  eod(): void {
    throw new Error('Method not implemented.')
  }
}
