import type { ScriptedRaster } from "@/shared/printer/types.ts";
import _ from "lodash";
import { printDetailFLow } from "@/shared/logger.ts";
import { Buffer } from "buffer";
import assert from "assert";

export function mergeRasters(rasters: (ScriptedRaster | undefined)[]): ScriptedRaster | undefined {
  if (rasters.length === 0) {
    return undefined;
  }
  // assert(rasters.length > 0, 'No raster to merge');
  let index = 0;
  const combined = _.compact(rasters).reduce((accRaster, raster): ScriptedRaster => {
    if (!accRaster) return raster;
    if (!raster || (raster.height === 0 && !raster.esc?.length)) return accRaster;
    const { data, width, height, esc, scripts } = raster;
    const { data: accData, width: accWidth, height: accHeight, esc: accEsc, scripts: accScripts } = accRaster;
    if (!accData || !data)
      printDetailFLow(`mergeRasters: data is null ${index}`);
    index++;
    const getData = () => {
      if (!data) return undefined;
      if (!accData) return undefined;
      return Buffer.concat([accData, data]);
    }
    // @ts-expect-error TODO: fix typings
    return <ScriptedRaster>{
      data: getData(),
      width: width,
      height: height + accHeight,
      esc: Buffer.concat([accEsc, esc]),
      scripts: [...accScripts, ...scripts]
    };
  }, undefined as ScriptedRaster | undefined)
  assert(combined, 'Failed to merge rasters')
  return combined
}