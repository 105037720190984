import type { FunctionComponent } from "react";
import {
  blockMutateOrderButton,
  multiPayment0, order0,
  recordToggleMultiPayment,
  setMultiPayment0
} from "@/react/PaymentView/PaymentView.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import { toast } from "react-toastify";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const MultiPaymentButton: FunctionComponent<{disable: boolean}> = ({disable}) => {

  return (
    <div className={`${!multiPayment0() && '!bg-white !text-black'} ${disable && 'pointer-events-none opacity-25'} ${blockMutateOrderButton() && 'opacity-70'} self-stretch rounded-81xl bg-cyan-solid-cyan-280-80e0ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] text-center text-sm text-black-solid-black-900-1e1e23 font-mulish sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:box-border`}
         onClick={async () => {
           userFLow("multiPayment in Payment", {
             username: loginUser()?.name,
             orderId: order0?.()._id
           })
           if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
             type: 'info',
             autoClose: 500
           });
           await recordToggleMultiPayment(!multiPayment0())
           setMultiPayment0(!multiPayment0());
         }}>
      <img
        className="relative w-[30px] h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
        alt=""
        src="/payment-iconmulti-payment-icon@2x.png"
      />
      <b className="relative leading-[17px] sm:text-smi sm:flex Tablet_768:text-smi Tablet_600:text-xs">
        {LL0().payment.multi()}
      </b>
    </div>
  );
};

export default MultiPaymentButton;
