import dayjs, { type Dayjs } from 'dayjs'
import _ from 'lodash'
import uuid from 'time-uuid'

import { getMaxIdZ } from '@/data/MaxIdHub.ts'
import { type Order, PaidOrder } from '@/data/Order.ts'
import { taxCategories0 } from '@/data/TaxCategoryHub.ts'
import { createOrder, stripPaidOrder } from '@/pos/logic/order-reactive.ts'
import { clearPayment, handleAddPayment } from '@/pos/logic/order-utils.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { CommitAction, OrderStatus, Reason, type OrderStrip, type TOrder } from '@/pos/OrderType.ts'
import { getVDateDayjs } from '@/pos/orderUtils.ts'
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { recordUserActionHistory, UserAction } from "@/data/UserActionHistory.ts";

export async function makeNegativeOrder<T extends Order | OrderStrip>(order: T): Promise<Order> {
  const _order = _.cloneDeep(order)
  delete _order.id
  _order._id = uuid()
  delete _order.qrCode
  _order.refOrder = order._id
  _order.refOrderId = order.id
  _order.status = OrderStatus.CANCELLATION_REFERENCE
  _order.reason = Reason.CHANGE_PAYMENT
  for (const item of _order.items ?? []) {
    item.quantity = -item.quantity
  }
  _order.cancellationItems = []
  _order.date = dayjs(now()).unix()

  await assignZId(getVDateDayjs(dayjs(now())), _order)

  const _order1 = createOrder(_order)
  // clearPayment(_order1);
  if (_order1.payments.length > 1) {
    clearPayment(_order1)
  } else if (_order1.payments.length === 1) {
    _order1.payments[0].value = -_order1.payments[0].value
  }

  //todo: use last payment ??
  // addCashPayment(_order1)
  // await recordOrderCommits(_order1)
  const stripOrder = stripPaidOrder(_order1)
  await PaidOrder.insert(stripOrder)
  return _order1
}

export async function assignZId(vDate: Dayjs, _order: Order | OrderStrip) {
  const { id, z } = await getMaxIdZ(vDate)
  _order.id = id
  _order.z = z
}

export async function cloneNewOrder(order: Order) {
  let _order = _.cloneDeep(order)
  delete _order.id
  _order._id = uuid()
  delete _order.qrCode
  _order.refOrder = order._id
  _order.refOrderId = order.id
  // _order.status = OrderStatus.PAID;
  _order.cancellationItems = []
  _order.date = dayjs(now()).unix()

  _order = createOrder(_order)
  if (_order.payments.length > 1) {
    clearPayment(_order)
  } else if (_order.payments.length === 1) {
    _order.payments[0].value = _order.vSum!
  }

  return _order
}

export async function doCancelOrder(order: Order) {
  const _order = await makeNegativeOrder(order)
  return _order
}

export async function makeRefundOrderRaw(order: Readonly<Order>, fullRefund: boolean = false, mapRefundItems: { [k: string]: number } = {}, initialSum = 0) {
  let draftOrder: Order = _.cloneDeep(order)
  delete draftOrder.id
  draftOrder._id = uuid()
  delete draftOrder.qrCode
  draftOrder.refOrder = order._id
  draftOrder.refOrderId = order.id
  draftOrder.status = OrderStatus.REFUNDED
  draftOrder.reason = Reason.REFUND
  draftOrder.date = dayjs(now()).unix()

  if (!fullRefund) {
    draftOrder.items = draftOrder.cancellationItems ?? []
  }
  draftOrder.cancellationItems = []

  for (const item of draftOrder.items) {
    item.quantity = -item.quantity

    if (!fullRefund && mapRefundItems[item._id!] !== item.price) {
      item.price = mapRefundItems[item._id!]
    }
  }
  draftOrder.refunded = true
  const reactiveOrder = createOrder(draftOrder)

  if (reactiveOrder.payments.length > 1) {
    // clearPayment(reactiveOrder)
    const payments = Array.isArray(reactiveOrder?.payments) ? reactiveOrder?.payments : Object.values(reactiveOrder?.payments || {});
    clearPayment(reactiveOrder)
    if (!isQuebecSrmEnabled()) {
      handleAddPayment(payments, reactiveOrder)
    }
  } else if (reactiveOrder.payments.length === 1) {
    reactiveOrder.payments[0].value = reactiveOrder.vSum!
  }

  // Final check
  if (!reactiveOrder.vSum || reactiveOrder.vSum === 0) throw new Error('Please select something to refund!')
  if ((reactiveOrder.vSum ?? 0) + initialSum < 0) throw new Error('You cannot refund more than initial value!')

  return reactiveOrder
}

export async function makeRefundOrderAmountRaw(order: Order, amount: number = 0) {
  let _order = _.cloneDeep(order)
  const _id = _order.id
  delete _order.id
  _order._id = uuid()
  delete _order.qrCode
  delete _order.discount
  _order.refOrder = order._id
  _order.refOrderId = order.id
  _order.status = OrderStatus.CANCELLATION_REFERENCE
  _order.reason = Reason.REFUND
  _order.date = dayjs(now()).unix()

  _order.items = []
  _order.cancellationItems = []

  _order.refunded = true
  _order = createOrder(_order)
  const taxCategory = taxCategories0()[0]
  _order.commits?.push({
    action: CommitAction.ADD_PRODUCT,
    _id: uuid(),
    quantity: -1,
    productRef: {
      name: `Remboursement des commandes #${_id}`,
      price: Number(amount),
      taxes: [taxCategory.value!, taxCategory.value!],
      taxComponents: taxCategory.components,
    },
  })

  if (_order.payments.length > 1) {
    //handle tse
    const payments = Array.isArray(_order?.payments) ? _order?.payments : Object.values(_order?.payments || {});
    clearPayment(_order)
    if (!isQuebecSrmEnabled()) {
      handleAddPayment(payments, _order)
    }
  } else if (_order.payments.length === 1) {
    _order.payments[0].value = _order.vSum!
  }
  return _order
}

export async function makeRefundOrder(order: Order, fullRefund: boolean = false, mapRefundItems: { [k: string]: number } = {}, initialSum = 0) {
  const _order = await makeRefundOrderRaw(order, fullRefund, mapRefundItems, initialSum)
  return await addZAndInsertRefundOrder(_order, order.vDate!);
}

export async function addZAndInsertRefundOrder(_order: any, date: any) {
  const { id, z } = await getMaxIdZ(dayjs.unix(date))
  _order.id = id
  _order.z = z
  //todo: use last payment ??
  // addCashPayment(_order)
  // await recordOrderCommits(_order)
  const stripOrder = stripPaidOrder(_order)
  await PaidOrder.insert(stripOrder)
  return _order
}

export async function makeRefundOrderAmount(order: Order, amount: number) {
  let _order = await makeRefundOrderAmountRaw(order, amount)
  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!))
  _order.id = id
  _order.z = z
  //todo: use last payment ??
  // addCashPayment(_order)
  await recordUserActionHistory(order, UserAction.REFUND_AMOUNT, { valueTo: amount })
  const stripOrder = stripPaidOrder(_order)
  await PaidOrder.insert(stripOrder)
  return _order
}

Object.assign(window, {
  async dev_makeRefundOrderId(orderId: string) {
    const order = await PaidOrder.findOne({ selector: { _id: orderId } }).exec()
    // ts-expected-error
    if (order) return await makeRefundOrderRaw(createOrder(order.toMutableJSON()), true)
  },
})
