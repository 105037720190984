import { type FunctionComponent, useCallback, useState } from 'react';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import FloorTab from '@printer-setting/FloorTab';
import IsClientRecheckBar from '@printer-setting/IsClientRecheckBar';
import AdditionalPrinterSettings from '@printer-setting/AdditionalPrinterSettings';
import KeyboardWrapper from '@printer-setting/KeyboardWrapper';
import { PrinterDefault } from '@/data/GroupPrinter.ts';
import DeleteConfirmationPopup from '@printer-setting/DeleteConfirmationPopup.tsx';
import LabelPrinterSettings from '@printer-setting/LabelPrinterSettings.tsx';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import {
  currentPrinterIndex, PrinterMode,
  printerSettingMode, selectedGroupPrinter,
  setCurrentPrinterIndex
} from "@/react/Printer/PrinterSettingView.signal.ts";

const PrinterSettingRight: FunctionComponent = () => {
  const [isPrinterTemplatePopupOpen, setPrinterTemplatePopupOpen] =
    useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState<boolean>(false);

  const closeDeleteConfirmationPopup = () => {
    userFLow(`close delete confirmation popup`, {
      username: loginUser()?.name
    });
    setIsDeleteConfirmationPopupOpen(false)
  }

  const openPrinterTemplatePopup = useCallback(() => {
    setPrinterTemplatePopupOpen(true);
  }, []);

  const closePrinterTemplatePopup = useCallback(() => {
    setPrinterTemplatePopupOpen(false);
  }, []);


  return (
    <>
      <div className="text-[#1d1d26] self-stretch flex-1 flex flex-col items-start justify-start py-3 px-6 relative gap-[12px_0px] text-left text-mini text-gray-300 font-mulish Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border">
        {selectedGroupPrinter()?.hasMultiplePositions && printerSettingMode() !== PrinterMode.ADDITIONAL_SETTING &&
        <div className="self-stretch flex flex-row items-center justify-between z-[0]">
          <div className="flex flex-row items-start justify-start gap-[0px_4px]">
            {selectedGroupPrinter()?.printers.map((printer, index) => (
              <FloorTab
                tabsText={printer?.name || `Floor ${index + 1}`}
                isSelected={currentPrinterIndex() === index}
                tabTextColor="#979797"
                onClick={() => {
                  userFLow(`set current Printer Index ${index}`, {
                    username: loginUser()?.name
                  });
                  setCurrentPrinterIndex(index)
                }}
                key={index}
              />
            ))}
          </div>
          <div className="shrink-0 flex flex-row items-center justify-start gap-[0px_12px]">
            {/*<div className="relative w-5 h-5 cursor-pointer flex items-center justify-center">*/}
            {/*  <img*/}
            {/*    className="w-full h-full cursor-pointer object-contain absolute left-[0px] top-[0px] [transform:scale(2)]"*/}
            {/*    alt=""*/}
            {/*    src="/iconquestion-icon2@2x.png"*/}
            {/*    onClick={openPrinterTemplatePopup}*/}
            {/*  />*/}
            {/*</div>*/}
            <img
              className="w-5 relative h-5 object-cover"
              alt=""
              src="/iconplus-icon--black@2x.png"
              onPointerDown={() => {
                selectedGroupPrinter()?.printers.push(PrinterDefault)
              }}
            />
            <img
              className="w-5 relative h-5 object-cover cursor-pointer"
              alt=""
              src="/iconbin-icon--black@2x.png"
              onClick={() => {
                userFLow(`open delete confirmation popup`, {
                  username: loginUser()?.name
                });
                setIsDeleteConfirmationPopupOpen(true)
              }}
            />
          </div>
        </div>
        }
        {[PrinterMode.NORMAL, PrinterMode.RECHECK_PRINTER, PrinterMode.CLIENT_RECEIPT].includes(printerSettingMode()) &&
        <IsClientRecheckBar
          isBar={printerSettingMode() === PrinterMode.NORMAL}
          isRecheckReceipt={printerSettingMode() === PrinterMode.RECHECK_PRINTER}
          isClientReceipt={printerSettingMode() === PrinterMode.CLIENT_RECEIPT}
        />
        }
        {printerSettingMode() === PrinterMode.ADDITIONAL_SETTING &&
        <AdditionalPrinterSettings />
        }
        {printerSettingMode() === PrinterMode.LABEL_PRINTER &&
          <LabelPrinterSettings />
        }
        <KeyboardWrapper />
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup}
                                   onComplete={() => {
                                     userFLow(`confirm deleted confirmation popup`, {
                                       username: loginUser()?.name
                                     });
                                     if (currentPrinterIndex() !== 0) {
                                       selectedGroupPrinter()?.printers.splice(currentPrinterIndex(), 1);
                                       setCurrentPrinterIndex(0);
                                     }
                                   }}/>
        </PortalPopup>
      )}
    </>
  );
};

export default PrinterSettingRight;