import _ from "lodash";
import { computed, signal } from "@/react/core/reactive.ts";
import uuid from "time-uuid";
import { LL0 } from "@/react/core/I18nService.tsx";
import { modifiers0 } from "@/data/ModifierHub.ts";
import { selectedItem } from "@/react/EditMenuView/EditMenuView.tsx";
import { numberFromString } from "@/react/core/utils.ts";
import { customParseFloat } from "@/react/core/Input.tsx";
import type React from "react";
import type { TaxCategory } from "@/data/TaxCategory.ts";
import { OrderLayout } from "@/data/OrderLayout";
import { Product } from "@/data/Product.ts";
import { convertDocument, convertDocuments } from "@/data/data-utils";
import { ProductLayout as ProductLayoutCollection } from "@/data/ProductLayout.ts";
import { generalSetting0, posSettings0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { product0, productLayout0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

export interface TaxData {
  menu: string,
  ref: string | undefined,
  value: number | undefined,
}

export interface MenuTax {
  menu?: string;
  ref?: string;
  value?: number;
}

export const handleSelectMenu = (menu: string) => {
  setMenuSelected(menu)
}

export const [isEditMenuProviderMenuNameOpen, setEditMenuProviderMenuNameOpen] = signal<boolean>(false)

export const openEditMenuProviderMenuName = (() => {
  userFLow(`open edit menu provider menu name`, {
    username: loginUser()?.name
  });
  setEditMenuProviderMenuNameOpen(true);
});
export const closeEditMenuProviderMenuName = (() => {
  userFLow(`close edit menu provider menu name`, {
    username: loginUser()?.name
  });
  setEditMenuProviderMenuNameOpen(false);
});

export const multipleMenusEnableSwitch = () => ({
  checked: !!generalSetting0()?.multipleDineInMenusEnable || false,
  onChange: (e: any) => {
    _.assign(generalSetting0(), { multipleDineInMenusEnable: e.target.checked })
  }
})


export const multipleMenus0 = computed(() => {
  if (!posSettings0() || posSettings0()?.length === 0) return
  if (!generalSetting0()) return [];
  if (!generalSetting0().multipleDineInMenus) {
    const initialMenu = [{ _id: uuid(), name: LL0()?.printing?.dineIn(), isPrintKitchen: true }];
    generalSetting0().multipleDineInMenus = initialMenu
    return generalSetting0().multipleDineInMenus || initialMenu;
  }
  generalSetting0().multipleDineInMenus?.forEach(menu => {
    if (!menu.hasOwnProperty('isPrintKitchen') || menu?.isPrintKitchen == null) {
      menu.isPrintKitchen = true;
    }
  })
  return generalSetting0().multipleDineInMenus;
});

export const printKitchenPricingSwitch = (index: number, pricingLevel: any) => ({
  checked: !!pricingLevel?.isPrintKitchen,
  onChange: (e: any) => {
    const generalSetting = { ...generalSetting0() };
    _.assign(generalSetting?.multipleDineInMenus?.[index], { isPrintKitchen: e.target.checked })
  }
})

export const [menuSelected, setMenuSelected] = signal<string>(multipleMenus0()?.[0]?._id || '');

export const dineIn = computed(() => {
  if (!multipleMenus0() || !multipleMenus0()?.length) {
    return ''
  } else {
    return multipleMenus0()?.[0]?._id
  }
})

export function addNewMenu(menu: string) {
  const _generalSetting = generalSetting0()
  const isMenuExist = _generalSetting.multipleDineInMenus?.find(m => m.name === menu)
  if (menu.trim() === '') return;
  if (!isMenuExist) {
    const newMenus = [...(multipleMenus0() || []), { _id: uuid(), name: menu, isPrintKitchen: true }]
    _.assign(generalSetting0(), { multipleDineInMenus: newMenus })
  } else {
    console.log('this menu is duplicated!!!')
  }
  closeEditMenuProviderMenuName();
}


export function pricingLevelFactory(productX?: Product) {
  return {
    computedPrice,
    getMenusTax,
    computedMenuTaxes,
    updateMenuSelection,
    changeProductPrice,
    setModifiersPriceLevel,
    computedModifierItemPrice,
    handleModifierItemPriceChange,
    checkTakeAwayTax,
    checkDineInTax,
    checkOneTypeTax,
    getInfo,
  }

  function getInfo() {
    return {
      _product: productX ? productX: product0(),
      menu: menuSelected(),
      generalSetting: generalSetting0(),
      product: productX ? productX: product0(),
      multipleEnable: generalSetting0()?.multipleDineInMenusEnable
    }
  }

  function computedPrice() {
    const { product, menu, generalSetting, multipleEnable } = getInfo();

    if (!product) return '';

    const shouldUseProductPrice = !multipleEnable || menu === dineIn() || !menu || !product.menuPrices;
    if (shouldUseProductPrice) return product.price?.toString() || '';

    const menuPrice = product.menuPrices?.find(m => m.menu === menu);
    return menuPrice?.value?.toString() || product.price?.toString() || '';
  }

  function getMenusTax(option: number) {
    const {_product} =  getInfo();
    if (option === 1) {
      const { menuTaxes } = _product || {};
      return menuTaxes;
    } else {
      const { menuTaxes2 } = _product || {};
      return menuTaxes2;
    }
  }

  function computedMenuTaxes(taxData: TaxData, menuTaxes: MenuTax[] | undefined) {
    const {menu} = getInfo();
    let updatedMenuTaxes;
    if (!menuTaxes) {
      updatedMenuTaxes = [taxData];
    } else {
      const index: number = menuTaxes.findIndex((m: any) => m.menu === menu);
      if (index === -1 || index === undefined) {
        updatedMenuTaxes = [...menuTaxes, taxData];
      } else {
        updatedMenuTaxes = [...menuTaxes];
        updatedMenuTaxes[index] = taxData;
      }
    }
    return updatedMenuTaxes;
  }

  function updateMenuSelection(e: (Event & {
    target: { value: unknown; name: string }
  }) | React.ChangeEvent<HTMLInputElement>) {
    const selectMenu = e.target.value as any;
    handleSelectMenu(selectMenu as string);
    const {_product} = getInfo();
    const index = _product?.menuPrices?.findIndex(m => m.menu === selectMenu);
    if (selectMenu !== dineIn() && (!_product?.menuPrices || index === -1)) {
      _.assign(_product, { menuPrices: [..._product?.menuPrices ?? [], { menu: selectMenu, value: _product?.price }] });
    }
    setModifiersPriceLevel();
  }

  function changeProductPrice(value: string) {
    const {_product, multipleEnable} = getInfo();
    const menu = menuSelected();
    const numericValue = numberFromString(value);
    if (_product && numericValue) {
      const isOtherMenu = multipleEnable && menu !== dineIn();
      if (!isOtherMenu) {
        _product.price = numericValue;
      } else {
        let index = _product?.menuPrices?.findIndex(m => m.menu === menu);
        _.assign(_product?.menuPrices?.[index!], { menu: menu, value: numericValue });
      }
    }
  }

  function setModifiersPriceLevel() {
    const { menu, multipleEnable } = getInfo();
    const dineInModifiers = modifiers0().filter(m => !m.menus);
    if (dineInModifiers.length === 0) return;
    for (const dineInModifier of dineInModifiers) {
      const modifierItems = dineInModifier.items;
      for (const modifierItem of modifierItems!) {
        if (multipleEnable && menu !== dineIn()) {
          const index = modifierItem.menuPrices?.findIndex(m => m.menu === menu);
          if (!modifierItem.menuPrices || index === -1) {
            _.assign(modifierItem, {
              menuPrices: [...modifierItem.menuPrices ?? [], {
                menu: menu,
                value: modifierItem.price ?? 0
              }]
            });
          }
        }
      }
    }
  }

  function computedModifierItemPrice() {
    const { menu, multipleEnable } = getInfo();
    if (!multipleEnable || menu === dineIn() || !menu || !selectedItem()?.menuPrices) {
      return selectedItem()?.price?.toString();
    } else {
      const menuPrice = selectedItem().menuPrices!.find(m => m.menu === menu);
      if (!menuPrice) {
        return selectedItem()?.price?.toString();
      } else {
        return menuPrice.value?.toString();
      }
    }
  }

  function handleModifierItemPriceChange(e: string) {
    const { menu, multipleEnable } = getInfo();
    const isOtherMenu = multipleEnable && menu !== dineIn();
    const itemPrice = customParseFloat(e);
    if (!isOtherMenu) {
      _.assign(selectedItem(), { price: itemPrice });
    } else {
      const _selectedItem = selectedItem();
      let index = _selectedItem?.menuPrices?.findIndex(m => m.menu === menu);
      _.assign(_selectedItem?.menuPrices?.[index!], { menu: menu, value: itemPrice });
    }
  }

  function checkTakeAwayTax(taxCategory: TaxCategory) {
    const { taxName2, taxValue2 } = getMenuTaxName();
    const { multipleEnable, menu, product } = getInfo();
    if (!product?.section && !productLayout0()) return false;
    if (menu !== dineIn() && taxName2 === taxCategory.name) {
      if (taxValue2 === taxCategory.value) return true;
      return false
    }

    if (menu === dineIn() || !multipleEnable) {
      if (product?.taxCategory2 === taxCategory.name && product?.tax2 === taxCategory.value) return true;
      return false
    }

    // return (
    //   (productLayout0() && menu !== dineIn() && taxName2 === taxCategory.name) ||
    //   ((menu === dineIn() || !multipleEnable) && product?.taxCategory2 === taxCategory.name)
    // );
  }

  function checkDineInTax(taxCategory: TaxCategory) {
    const { taxName1,  taxValue1} = getMenuTaxName();
    const { multipleEnable, menu, product } = getInfo();
    if (!product?.section && !productLayout0()) return false;
    if (menu !== dineIn() && taxName1 === taxCategory.name) {
      if (taxValue1 === taxCategory.value) return true;
      return false
    }

    if (menu === dineIn() || !multipleEnable) {
      if (product?.taxCategory === taxCategory.name && product?.tax === taxCategory.value) return true;
      return false
    }
    // return (
    //   (productLayout0() && menu !== dineIn() && taxName1 === taxCategory.name) ||
    //   ((menuSelected() === dineIn() || !multipleEnable) && product?.taxCategory === taxCategory.name)
    // );
  }

  function checkOneTypeTax(taxCategory: TaxCategory) {
    const { taxName1, taxValue1 } = getMenuTaxName();
    const { multipleEnable, menu, product } = getInfo();
    if (!product?.section && !productLayout0()) return false;

    if (menuSelected() !== dineIn() && taxName1 === taxCategory.name) {
      if (taxValue1 === taxCategory.value) return true;
      return false
    }

    if (menuSelected() === dineIn() || !multipleEnable) {
      if (product?.taxCategory === taxCategory.name && product?.taxCategory2 === taxCategory.name) {
        if (product.tax === taxCategory.value && product.tax2 === taxCategory.value) return true;
        return false
      }
    }
    // return (
    //   (productLayout0() && menuSelected() !== dineIn() && taxName1 === taxCategory.name) ||
    //   ((menuSelected() === dineIn() || !multipleEnable) && product?.taxCategory === taxCategory.name && product?.taxCategory2 === taxCategory.name)
    // );
  }

  function getMenuTaxName() {
    const { menu, product } = getInfo();
    return {
      taxName1: productLayout0() ? product?.menuTaxes?.find(m => m.menu === menu)?.ref : '',
      taxName2: productLayout0() ? product?.menuTaxes2?.find(m => m.menu === menu)?.ref : '',
      taxValue1: productLayout0() ? product?.menuTaxes?.find(m => m.menu === menu)?.value : '',
      taxValue2: productLayout0() ? product?.menuTaxes2?.find(m => m.menu === menu)?.value : '',
    }
  }
}


export async function getProducts() {
  const orderLayout = await OrderLayout.findOne().exec();
  if (!orderLayout) return;
  const categories = convertDocument<OrderLayout>(orderLayout, true)?.categories;

  const categoryIds = categories?.map(c => c._id);

  if (!categoryIds || !categoryIds.length) return;

  const _productLayouts = await ProductLayoutCollection.find({
    selector: {
      category: { $in: categoryIds },
      type: {$nin: ['Extra', 'Text']}
    }
  }).exec()

  const productLayoutsIds = _productLayouts?.map(p => p.product);

  if (!productLayoutsIds || !productLayoutsIds.length) return;

  const _productInfo = await Product.find({
    selector: {
      _id: { $in: productLayoutsIds },
      name: { $exists: true, $ne: ''}
    }
  }).exec();

  return _productInfo ? convertDocuments(_productInfo, false) : [];
}


export const transformArray = (arr: any) => {
  if (!arr?.length) return [];
  const objectRepresentation = arr.reduce((acc, obj) => {
    const [key, value] = Object.entries(obj)[0];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(value);
    return acc;
  }, {});
  return Object.entries(objectRepresentation).map(([key, value]) => ({ [key]: value }));
};
