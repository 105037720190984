import { CommitAction, type OrderItem, TseMethod } from "@/pos/OrderType.ts";
import _ from "lodash";
import type { Order } from "@/data/Order.ts";

export function getMaxCourse(items: OrderItem[]): number {
  const maxCourse = (_.maxBy(items, i => (i.course ? +i.course : 0)) || { course: 0 }).course!
  return maxCourse
}

export function addTseMethod(order: Order, item: OrderItem, tseMethod: TseMethod, seat?: number) {
  order.commits!.push({
    action: CommitAction.SET_TSE_METHOD,
    tseMethod: tseMethod,
    commitId: item.commitRefs![0],
    ...(seat !== undefined && { seat: seat }),
  })
  item.tseMethod = tseMethod
}

export function mergeSeat(order: Order) {
  order.seatMode = false
  order.seatMap = []
  for (const item of order.items) {
    item.movedQuantity = 0;
    delete item.seat;
  }
}