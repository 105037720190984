import { type FunctionComponent, useState } from "react";
import TipServiceSection from "./TipServiceSection";
import PaymentMethodsSection from "./PaymentMethodsSection";
import TerminalSection from "./TerminalSection";
import { LL0 } from '@/react/core/I18nService.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { router } from '@/pos/PosRouter.ts';
import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { Box, Checkbox, Chip, MenuItem, Select } from '@mui/material';
import { payments0 } from "@/data/PaymentHub.ts";
import { getPaymentIcon } from "@/react/PaymentSettingView/PaymentMap.ts";
import type { Payment } from "@/data/Payment.ts";
import { assign } from 'lodash'
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import ExplainHighPriorityPopup from "@tax-payment/Popups/ExplainHighPriorityPopup.tsx";

export const MAX_HIGH_PRIORITY_PAYMENT = 2

const TaxPaymentRightPath: FunctionComponent = () => {
  const [openExplainHighPriority, setOpenExplainHighPriority] = useState(false);
  const highPriorityPayments = payments0().filter(p => p.highPriority) || []
  return (
    <>
      <div
        className="self-stretch flex-1 flex flex-col items-center justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
        <div
          className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:box-border height_414:h-[42px]">
          <b className="relative sm:text-sm height_414:text-sm">{LL0().order.payment().toUpperCase()}:</b>
          <div
            className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff sm:h-[30px] sm:min-w-[68px] Tablet_600:h-[26px] Tablet_600:pl-2.5 Tablet_600:box-border Tablet_600:min-w-[64px]"
            ref={makeRipple}
            onClick={() => router.screen = mainScreen()}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="w-[45px] relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <div
          className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start pt-4 px-5 pb-0 gap-[16px_0px] text-sm text-gray Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
          <TipServiceSection />
          <PaymentMethodsSection />
          <div className="self-stretch flex flex-col justify-start pt-0 px-0 pb-3 gap-[17px]">
            <div className='w-full flex flex-row justify-between items-center'>
              <b className="relative">{LL0().taxPayment.highPriorityPaymentMethod()} ({highPriorityPayments?.length}/{MAX_HIGH_PRIORITY_PAYMENT}): </b>
              <img
                className="w-[32px] relative h-[32px] object-cover cursor-pointer"
                alt=""
                src="/iconquestion-icon@2x.png"
                onClick={() => setOpenExplainHighPriority(true)}
              />
            </div>
            <Select color="primary" size="small"
                    fullWidth multiple
                    value={highPriorityPayments}
                    renderValue={(selected: Payment[]) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value._id} className='flex flex-row gap-2'
                                label={<div className='w-full flex flex-row gap-2 items-center'>
                                  <img
                                    className="object-contain w-8 relative h-8 shrink-0"
                                    src={getPaymentIcon(value.icon)}
                                    alt={value.name}
                                  />
                                  <span
                                    className={'relative font-medium text-[14px] Tablet_768:text-smi'}>{value.name}</span>
                                </div>}
                          />
                        ))}
                      </Box>
                    )}
            >
              {
                payments0().map(payment => (
                  <MenuItem
                    key={payment._id}
                    value={payment._id}
                    onClick={() => {
                      if (highPriorityPayments?.length < MAX_HIGH_PRIORITY_PAYMENT) {
                        userFLow(`Add payment ${payment.name!} to high priority list payment`, {
                          username: loginUser()?.name
                        })
                        assign(payment, { highPriority: !payment.highPriority })
                      } else {
                        userFLow(`Remove payment ${payment.name!} from high priority list payment`, {
                          username: loginUser()?.name
                        })
                        assign(payment, { highPriority: false })
                      }
                    }}
                  >
                    <div className='w-full flex flex-row gap-2 items-center'>
                      <Checkbox checked={payment.highPriority || false}
                                onChange={(event, checked) => {
                                  if (highPriorityPayments?.length < MAX_HIGH_PRIORITY_PAYMENT) {
                                    userFLow(`Add payment ${payment.name!} to high priority list payment`, {
                                      username: loginUser()?.name
                                    })
                                    assign(payment, { highPriority: checked })
                                  } else {
                                    userFLow(`Remove payment ${payment.name!} from high priority list payment`, {
                                      username: loginUser()?.name
                                    })
                                    assign(payment, { highPriority: false })
                                  }
                                }}
                      />
                      <img
                        className="object-contain w-8 relative h-8 shrink-0"
                        src={getPaymentIcon(payment.icon)}
                        alt={payment.name}
                      />
                      <span className={'relative font-medium text-[14px] Tablet_768:text-smi'}>{payment.name}</span>
                    </div>
                  </MenuItem>
                ))
              }
            </Select>
          </div>
          <TerminalSection />
        </div>
      </div>
      {openExplainHighPriority &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setOpenExplainHighPriority(false)}
        >
          <ExplainHighPriorityPopup onClose={() => setOpenExplainHighPriority(false)} />
        </PortalPopup>
      }
    </>
  );
};

export default TaxPaymentRightPath;
