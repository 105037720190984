import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { createCsvHeader, dsfinvTables, exportDSFINV } from "@/tse/dsfinv/dsfinv-convert.ts";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import dialogService from "@/react/SystemService/dialogService.tsx";
import { tse } from "@/tse/tse-communicate.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { getApiUrl } from "@/shared/utils.ts";
import { posSync0 } from "@/data/PosSyncState.ts";
import { dialogEnterEmail } from "@/components/ui/dialogEnterEmail.tsx";
import JSZip from "jszip";
import { uploadFile } from "@/shared/FileUploader.ts";
import { sendDsfinvS3 } from "@/shared/EmailSender.ts";
import Papa from 'papaparse';
import { TseExport } from "@/data/TseExport.ts";
import uuid from "time-uuid";

const devMode = import.meta.env.MODE === 'development';

export function checkValid(from: number, to: number) {
  if (!from || !to) {
    toast.error('Please fill in both FROM box and TO box!')
    return false
  }
  if (from > to) {
    toast.error('FROM day cannot be after TO day!')
    return false
  }

  if (dayjs().endOf('day').valueOf() < from) {
    toast.error('Cannot export data from the future!')
    return false
  }
  return true
}

export async function handleExportData(from: number, to: number, s3 = false, email?: string) {
  userFLow(`export data`, {
    username: loginUser()?.name
  });
  const isValidDate = checkValid(from, to);
  if (!isValidDate) return;
  const data = await exportDSFINV(from, to)
  await exportToCsv(data, s3, email)
}

export async function handleExportTar(from: number, to: number) {
  userFLow(`export tar`, {
    username: loginUser()?.name
  });
  const isValidDate = checkValid(from, to);
  if (!isValidDate) return;
  const closeRegisterDialog = dialogService.progress({ title: 'Exporting...' })
  try {
    if (tseConfig0()?.type === 'TseLocal') {
      let email = await dialogEnterEmail()
      console.log('email input: ',email)
      if (!email) {
        return toast.warning('Please enter email!')
      }
      // const data = await exportDSFINV(from, to)
      //@ts-ignore
      const filePath = await tse.exportTar({ clientId: 'SWISSBIT',fromTime: from, toTime: to });
      console.log('filePath: ',filePath);
      // const url = await rnHost.exportTseTar('test', posSync0()?.id!, getApiUrl()) // -> fixme: mock test
      // await sendDsfinvS3(email!, `Exported data ${dayjs().format()}`, `You can download the file tar and unzip at: ${url}`)
      // toast.info(`Sending data to your email (${email})...`)
      // console.log('url is: ', url) //todo: add button show links

      // todo: fix me show links
      // await TseExport.insert({
      //   _id: uuid(),
      //   url: url,
      //   type: 'tar',
      //   from: from,
      //   to: to
      // })
    } else {
      const url = await tse.exportTar({ fromTime: from, toTime: to })
      console.log('url ', url)
      if (import.meta.env.MODE === 'production' && getUrlParam('os')) await rnHost.openUrl(url)
      else window.open(url, '_blank')
    }
    toast.success('Export success!')
  } catch (err: any) {
    console.error(err)
    toast.error(`Export failed: ${err.message}`)
  } finally {
    closeRegisterDialog()
  }
}

export const exportToCsv = async (data: any, s3: boolean, email?: string) => {
  const zip = new JSZip();
  const isBrowser = !getUrlParam('os')
  const dataExport: { name: string; data: any; }[] = []
  for (const { name, table, model } of dsfinvTables) {
    const tableData = data[table];

    let csvContent = Papa.unparse(tableData, {
      quotes: true,
      quoteChar: '"',
      delimiter: ";",
      header: true,
      encoding: "UTF-8",
      newline: "\r\n",
      decimalseparator: ","
    });

    if (!tableData || tableData.length === 0) {
      csvContent = createCsvHeader(model)
    }

    if (devMode && !s3) {
      await rnHost.exportCsv(csvContent, name)
      continue
    }

    if (isBrowser || s3) {
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      zip.file(`${name}.csv`, blob);
    } else {
      dataExport.push({
        name: name,
        data: csvContent
      })
    }
  }
  const zipBlob = await zip.generateAsync({ type: 'blob' })

  if (s3) {
    await exportS3()
    return
  }

  await exportZip()

  async function exportZip () {
    if (!devMode && isBrowser) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.setAttribute('download', `dsfinv.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (dataExport.length > 0) {
      await rnHost.exportCsv2(dataExport)
    }
    toast.success(`Export successful!`);
  }

  async function exportS3 () {
    const closeDialog = dialogService.progress({ title: 'Exporting...' })
    const url = await uploadFile('dsfinv', zipBlob, progress => {})
    await sendDsfinvS3(email!, `Exported data ${dayjs().format()}`, `You can download the file and unzip at: ${url}`)
    toast.success(`Export successful, please check your email!`);
    closeDialog()
  }
};