import dialogService from "@/react/SystemService/dialogService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { toast } from "react-toastify";
import { useSignal } from "@/react/core/reactive.ts";
import { validateEmail } from "@/react/Developer/developer.logic.ts";

export const dialogEnterEmail = async (): Promise<string> => {
  return new Promise((resolve) => {
    const showDialog = ({ onClose }) => {
      const [email, setEmail] = useSignal('');

      const handleEnter = () => {
        if (!validateEmail(email())) {
          toast.error('Please enter a valid email address');
          return;
        }
        onClose?.();
        resolve(email());
      };

      const wrappedOnClose = () => {
        resolve('');
        onClose?.();
      };

      return (
        <dialogService.BaseDialog title="" onClose={wrappedOnClose}>
          <div className="w-full relative rounded bg-white overflow-hidden flex flex-col items-start justify-start pt-4 px-4 pb-3 box-border gap-6 min-w-[320px] h-full max-w-full max-h-full text-center text-sm font-mulish">
            <Input
              className="min-h-[40px] self-stretch font-mulish font-medium text-base text-dimgray z-[0]"
              value={email()}
              label="Email"
              onChange={setEmail}
            />
            <TableManualKeyboard inputController={inputController0} onEnter={handleEnter} />
          </div>
        </dialogService.BaseDialog>
      );
    };

    dialogService.show({ component: showDialog });
  });
};
