import { type FunctionComponent, useCallback, useState } from "react";
import { getPaymentIcon } from "@/react/PaymentSettingView/PaymentMap.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import {
  isActivePaymentId,
  onPaymentClick,
  order0,
  paymentContext0,
  scopeOrder0,
  setMultiPayment0
} from "@/react/PaymentView/PaymentView.tsx";
import { payments0 } from "@/data/PaymentHub.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PaymentVerticalAddPayment from "@vertical-payment/PaymentVerticalAddPayment.tsx";

const ButtonGroup: FunctionComponent = () => {
  const { currentSeat, onNext, setSeat } = paymentContext0;
  const [isPaymentVerticalAddPaymentOpen, setPaymentVerticalAddPaymentOpen] =
    useState(false);
  const _order = order0()?.seatMap[currentSeat()]
  const openPaymentVerticalAddPayment = () => {
    if (_order.seat != null) {
      paymentContext0.setSeat(_order.seat);
      setMultiPayment0(false);
    }
    setPaymentVerticalAddPaymentOpen(true);
  }

  const closePaymentVerticalAddPayment = useCallback(() => {
    setPaymentVerticalAddPaymentOpen(false);
  }, []);
  return (
    <>
      <div className="h-12 self-stretch flex flex-row items-end justify-center">
        {payments0().filter(p => p.highPriority).map((payment, index) => (
          <button
            key={index}
            className="relative disabled:opacity-25 box-border odd:bg-[#425ff4] self-stretch flex-1 even:bg-blue-solid-blue-500-0051c1 flex flex-row items-center justify-center py-1 px-4"
            ref={makeRipple}
            disabled={scopeOrder0().items.length === 0}
            onClick={async () => {
              userFLow(`Select payment ${payment.name!} in seat ${currentSeat()}`, {
                username: loginUser()?.name,
                orderId: order0?.()._id,
                currentSeat: currentSeat()
              })
              await onPaymentClick(payment)
              onNext?.()
              setSeat(currentSeat())
            }}
          >
            {isActivePaymentId(payment?.name) && <div className='absolute inset-0 w-full h-full border-[4px] border-red-600' />}
            <img
              className="object-contain w-8 relative h-8 shrink-0"
              alt=""
              src={getPaymentIcon(payment.icon)}
            />
          </button>
        ))}

        <button
          className="disabled:opacity-25 box-border self-stretch flex-1 bg-brown-solid-brown-600-5f4f4b flex flex-row items-center justify-center py-1 px-4"
          ref={makeRipple}
          disabled={scopeOrder0().items.length === 0}
          onClick={openPaymentVerticalAddPayment}
        >
          <img
            className="object-contain w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src='/payment-iconmulti-payment-icon@2x.png'
          />
        </button>
      </div>
      {isPaymentVerticalAddPaymentOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentVerticalAddPayment}
        >
          <PaymentVerticalAddPayment onClose={closePaymentVerticalAddPayment}
                                     onComplete={()=>{
                                       userFLow(`Select other payment in seat ${currentSeat()}`, {
                                         username: loginUser()?.name,
                                         orderId: order0?.()._id,
                                         currentSeat: currentSeat()
                                       })
                                       onNext?.()
                                       setSeat(currentSeat())
                                     }}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ButtonGroup;
