import { selector, signal } from "@/react/core/reactive.ts";
import type { DocObservable } from "@/data/utils/data-utils-2.ts";
import { Product } from "@/data/Product.ts";
import { type CategoryLayout, OrderLayout } from "@/data/OrderLayout.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import { ProductLayout } from "@/data/ProductLayout.ts";

export const PRODUCT_COLORS = ['#FFFFFF', '#8AF1FF', '#87CDFF', '#C0C0FF', '#E1BEE7', '#FFC0FF', '#D4F5D0', '#CCFF85', '#F4FF7C', '#FFF59D', '#FFC86B', '#FF938A']

export enum ITEM_MODE {
  ITEM = "item",
  CATEGORY = "category",
  SECTION = "section",
  KEYBOARD = "keyboard",
  INGREDIENT = "ingredient"
}

export enum CustomDish {
  FOOD = "FOOD",
  DRINK = "DRINK"
}

export type TempItem = {
  active: boolean,
  _id?: string,
  type: ITEM_MODE,
  left?: number,
  top?: number,
  mode: "copy" | "swap"
};
export const [v, setV] = signal<number>(0);
export const [products0, setProducts0] = signal<DocObservable<Product>[]>([]);
export const [orderLayout0, setOrderLayout0] = signal<DocDeepSignal<OrderLayout, {}>>();
export const [productLayouts0, setProductLayouts0] = signal<DocObservable<ProductLayout>[]>([]);
export const [tempItem, setTempItem] = signal<TempItem | undefined>();
export const [tempOnlineProduct, setTempOnlineProduct] = signal(); // TODO: temporary code, improve later
export const [currentCategory, setCurrentCategory] = signal<number>(0);
export const isSelected = selector<number>(currentCategory);
export const [categoryLayout0, setCategoryLayout0] = signal<CategoryLayout>();
export const [editorMode0, setEditorMode0] = signal<ITEM_MODE>(ITEM_MODE.ITEM);
export const [product0, setProduct0] = signal<DocDeepSignal<Product>>();
export const [productLayout0, setProductLayout0] = signal<DocDeepSignal<ProductLayout>>();