import _ from 'lodash'

import type { Order } from '@/data/Order'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { CommitAction } from '@/pos/OrderType'

import { RESENT_ERR_CODE, SPECIAL_ITEM_DESCR } from './constants'
import { ActivitySectors, PaymentMethods, PrintModes, PrintOptions, ServiceTypes, TransactionTypes } from './enum'
import type { CurrentTransactionResponseData, ResponseError, SignedTransactionData, TransactionData, TransactionItem, TransactionItemDetail } from './types'

/**
 * Recursive find and fetch all the referenced transactions.
 *
 * @param t           The original transaction
 * @param ignoreList  Hold list of transaction number to ignore. Mainly for recursive purpose.
 * @param level       Deep level of recursive operation. For debugging purpose
 */
export const fetchRefs = _.memoize(
  async function (t: SignedTransactionData, ignoreList: string[] = [], level = 0): Promise<TransactionNode> {
    console.group(`🪲 Fetching references of transaction [${t.noTrans}]`)
    console.log(`» refs:`, t.refs)
    console.log(`» ignoreTrans:`, ignoreList)
    console.log(`» level:`, level)

    const node = new TransactionNode(t)
    try {
      const targetTrans = (t.refs ?? []).map(a => a.noTrans).filter(a => !ignoreList.includes(a))
      if (!targetTrans.length) return node

      const rows = await SrmTransactionLog.find({ selector: { 'data.noTrans': { $in: targetTrans } } })
        .exec()
        .then(a => a.map(b => b.toMutableJSON()))
      console.log(`» fetched:`, rows)

      for (const { data } of rows) node.refs.push(await fetchRefs(data, [t.noTrans, ...ignoreList], level + 1))

      console.log(`» result:`, node)
      return node
    } finally {
      console.groupEnd()
      console.log(`» Schedule for clearing cache of ${t.noTrans}...`)
      setTimeout(() => {
        console.log(`» Clearing cache for ${t.noTrans}...`, fetchRefs.cache)
        fetchRefs.cache.delete(t.signa.actu)
      }, 60 * 1000 + level * 1000) // Clear cache after 60s, plus 1s for each level
    }
  },
  t => t.signa.actu
)

/**
 * COMMANDE PAR UNE PLATEFORME NUMÉRIQUE (order through a digital platform)
 *
 * typTrans field = "ADDI" or "RFER"
 * AND typServ field = "LVT"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 */
export const isOrderThroughDigitalPlatform = async (t: SignedTransactionData) =>
  [TransactionTypes.temporaryBill, TransactionTypes.closingReceipt].includes(t.typTrans) &&
  t.sectActi.abrvt === ActivitySectors.restaurant &&
  t.sectActi.typServ === ServiceTypes.digitalPlatform &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr)

export const isOccasionalThirdParty = async (t: SignedTransactionData) => t.typTrans === TransactionTypes.occasionalThirdParty && t.modImpr === 'DUP'

/**
 * SOUMISSION (quote)
 *
 * Logic in this block:
 * typTrans field = "SOUM"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be the first transaction of type "SOUM", in the transaction
 * sequence, whose formImpr field <> "NON".
 */
export const isQuote = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.quote &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  t.formImpr !== PrintOptions.notPrinted &&
  refs.filter(a => a.typTrans === TransactionTypes.quote && a.formImpr !== PrintOptions.notPrinted).length === 1

/**
 * SOUMISSION RÉVISÉE (revised quote)
 *
 * typTrans field = "SOUM"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be at least the second transaction of type "SOUM", in the
 * transaction sequence, whose formImpr field <> "NON".
 *
 * > **NOTE**
 * > This notice must always be present above the notice "Remplace N soumissions".
 * > To find out the display conditions, see the notice "Remplace N soumissions".
 */
export const isRevisedQuote = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.quote &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  t.formImpr !== PrintOptions.notPrinted &&
  refs.filter(a => a.typTrans === TransactionTypes.quote && a.formImpr !== PrintOptions.notPrinted).length >= 2

/**
 * ESTIMATION DES RENSEIGNEMENTS (estimate)
 *
 * typTrans field = "ESTM"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be the first transaction of type "ESTM", in the transaction
 * sequence, whose formImpr field <> "NON".
 */
export const isEstimate = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.estimate &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  t.formImpr !== PrintOptions.notPrinted &&
  refs.filter(a => a.typTrans === TransactionTypes.estimate && a.formImpr !== PrintOptions.notPrinted).length === 1

/**
 * ESTIMATION RÉVISÉE (revised estimate)
 *
 * typTrans field = "ESTM"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be at least the second transaction of type "ESTM", in the
 * transaction sequence, whose formImpr field <> "NON".
 *
 * > **NOTE**:
 * This notice must always be present above the notice "Remplace N
 * estimations". To find out the display conditions, see the notice
 * "Remplace N estimations".
 */
export const isRevisedEstimate = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.estimate &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  t.formImpr !== PrintOptions.notPrinted &&
  refs.filter(a => a.typTrans === TransactionTypes.estimate && a.formImpr !== PrintOptions.notPrinted).length >= 2

/**
 * ESTIMATION ANNULÉE (cancelled estimate)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND apresTax field = 0
 * AND modPai field = "AUC"
 * AND a reference to a transaction of type "ESTM"
 * AND "Annulation" item with an "autre" detail:
 * - Reason for cancellation
 */
export const isCancelledEstimate = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +t.mont.apresTax === 0 &&
  t.modPai === 'AUC' &&
  refs.some(a => a.typTrans === TransactionTypes.estimate && a.items.some(b => b.descr === SPECIAL_ITEM_DESCR.cancellation && b.preci?.some(c => c.descr)))

/**
 * FACTURE ORIGINALE (original bill)
 *
 * typTrans field = "ADDI"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be the first transaction of type "ADDI", in the transaction
 * sequence, whose formImpr field <> "NON".
 */
export const isOriginalBill = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.temporaryBill &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  t.formImpr !== PrintOptions.notPrinted &&
  refs.filter(r => r.typTrans === TransactionTypes.temporaryBill && r.formImpr !== PrintOptions.notPrinted).length === 1

/**
 * FACTURE RÉVISÉE (revised bill)
 *
 * typTrans field = "ADDI"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND must be at least the second transaction of type "ADDI", in the
 * transaction sequence, whose formImpr field <> "NON".
 *
 * > NOTE
 * This notice must always be present above the notice "Remplace N factures".
 * To find out the display conditions, see the notice "Remplace N factures".
 */
export const isRevisedBill = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.temporaryBill &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  refs.filter(a => a.typTrans === TransactionTypes.temporaryBill && a.formImpr !== PrintOptions.notPrinted).length >= 2

/**
 * FACTURE ANNULÉE (cancelled bill)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND apresTax field = 0
 * AND modPai field = "AUC"
 * AND a reference to a transaction of type "ADDI"
 * AND "Annulation" item with an "autre" detail:
 * - Reason for cancellation
 */
export const isCancelledBill = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +(t.mont.mtdu ?? t.mont.apresTax) === 0 &&
  t.modPai === PaymentMethods.noPayment &&
  refs.some(a => a.typTrans === TransactionTypes.temporaryBill) &&
  t.items.some(a => a.descr === SPECIAL_ITEM_DESCR.cancellation)

/**
 * PAIEMENT REÇU (payment received)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND mtdu field > 0
 * AND modPai field <> "PAC", "AUC" and "SOB"
 */
export const isPaymentReceived = async (t: SignedTransactionData) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +(t.mont.mtdu ?? t.mont.apresTax) > 0 &&
  ![PaymentMethods.chargeToAccount, PaymentMethods.noPayment, PaymentMethods.notAvailable].includes(t.modPai)

/**
 * PORTÉ AU COMPTE (charge to account)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND mtdu field <> 0
 * AND modPai field = "PAC"
 */
export const isChargeToAccount = async (t: SignedTransactionData) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +(t.mont.mtdu ?? t.mont.apresTax) !== 0 &&
  t.modPai === PaymentMethods.chargeToAccount

/**
 * PAIEMENT NON REÇU (failure to pay)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "PSP"
 * AND mtdu field > 0
 * AND modPai field = "AUC"
 */
export const isFailureToPay = async (t: SignedTransactionData) =>
  t.typTrans === TransactionTypes.closingReceipt && t.modImpr === PrintModes.failureToPay && +(t.mont.mtdu ?? t.mont.apresTax) > 0 && t.modPai === PaymentMethods.noPayment

/**
 * NOTE DE CRÉDIT (credit note)
 *
 * typTrans field = "RFER"
 * AND modImpr field = "FAC", "RPR" or "DUP"
 * AND mtdu field < 0
 */
export const isCreditNote = async (t: SignedTransactionData) =>
  t.typTrans === TransactionTypes.closingReceipt && [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) && +(t.mont.mtdu ?? t.mont.apresTax) < 0

/**
 * TRANSACTION ABANDONNÉE (abandoned transaction)
 *
 * modImpr = "ANN"
 * > **NOTE**
 * This notice must always be accompanied by the notices "*** COPIE DU COMMERÇANT ***" and "NE PAS REMETTRE AU CLIENT".
 */
export const isAbandonedTransaction = async (t: SignedTransactionData) => t.modImpr === 'ANN'

/**
 * Note de crédit corrigée (corrected credit note)
 *
 * typTrans field = "RFER"
 *  AND modImpr field = "FAC", "RPR" or "DUP"
 *  AND mtdu field < 0
 *  AND refers to a "RFER" type transaction.
 *    The "RFER" type transaction in reference must contain:
 *      • mtdu field > 0
 *      • AND formImpr field = "NON"
 *      • AND a reference to a "RFER" transaction with field
 *          mtdu < 0
 *
 * > NOTE
 * The notice "NOTE DE CRÉDIT" must always be present above the notice "Note de crédit corrigée".
 */
export const isCorrectedCreditNote = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +(t.mont.mtdu ?? t.mont.apresTax) < 0 &&
  refs.some(
    a =>
      a.typTrans === TransactionTypes.closingReceipt &&
      +(a.mont.mtdu ?? a.mont.apresTax) > 0 &&
      a.formImpr === PrintOptions.notPrinted &&
      refs.filter(b => a.refs?.some(c => c.noTrans === b.noTrans)).some(b => b.typTrans === TransactionTypes.closingReceipt && +(b.mont.mtdu ?? b.mont.apresTax) < 0)
  )

/**
 * Facture corrigée (corrected bill)
 *
 * typTrans field = "RFER"
 *  AND modImpr field = "FAC", "RPR" or "DUP"
 *  AND mtdu field > 0
 *  AND modPai field <> "AUC" and "SOB"
 *  AND refers to a "RFER" type transaction.
 *    The "RFER" type transaction in reference must contain:
 *      • mtdu field < 0
 *      • AND formImpr field = "NON"
 *      • AND a reference to a "RFER" transaction with field
 *          mtdu < 0
 *
 * > **NOTE**: The notice "PAIEMENT REÇU" or the notice "PORTÉ AU COMPTE",
 * as the case may be, must be present above the notice "Facture corrigée".
 */
export const isCorrectedBill = async (t: SignedTransactionData, refs: TransactionData[]) =>
  t.typTrans === TransactionTypes.closingReceipt &&
  [PrintModes.bill, PrintModes.reproduction, PrintModes.duplicate].includes(t.modImpr) &&
  +(t.mont.mtdu ?? t.mont.apresTax) > 0 &&
  ![PaymentMethods.noPayment, PaymentMethods.notAvailable].includes(t.modPai) &&
  refs.some(
    a =>
      a.typTrans === TransactionTypes.closingReceipt &&
      +(a.mont.mtdu ?? a.mont.apresTax) < 0 &&
      a.formImpr === PrintOptions.notPrinted &&
      refs.filter(b => a.refs?.some(c => c.noTrans === b.noTrans)).some(b => b.typTrans === TransactionTypes.closingReceipt && +(b.mont.mtdu ?? b.mont.apresTax) > 0)
  )

/**
 * *** CERTIFICAT INVALIDE ***
 *
 * NE PAS REMETTRE AU CLIENT
 *
 * (invalid certificate – do not give to customer)
 *
 * Bill produced with an invalid certificate (a certificate that has expired, been
 * revoked [error `JW00B999011E`], or deleted, or that was not issued by us)
 */
export const isInvalidCertificate = (res?: CurrentTransactionResponseData) => res && res.listErr?.some(e => e.codRetour === 'JW00B999011E') // TODO: need to test this case

class TransactionNode {
  constructor(public origin: SignedTransactionData, public refs: TransactionNode[] = []) {}

  /** Get all transactions. Will INCLUDE the origin */
  getAll(level = 0): SignedTransactionData[] {
    const data = [this.origin, ...this.refs.flatMap(a => a.getAll(level + 1))]
    console.log(`» ALL Transactions (lv${level}):`, data)
    return data
  }
}

/**
 * Count printed transactions in the refs. Will look for the refs of the refs too.
 *
 * @warning This function is recursive and will stop at 20 levels deep.
 */
export async function countPrintedRefs(t: SignedTransactionData, refs: TransactionData[], predicate: (a: SignedTransactionData) => boolean, level = 0) {
  console.log(`🪲 Counting printed refs of transaction [${t.noTrans}]`, level)
  if (!t.refs?.length) return 0
  if (level > 20) throw new Error('Too deep recursion!')
  const records = await SrmTransactionLog.find({
    selector: {
      $or: t.refs.map(a => ({
        'data.noTrans': a.noTrans,
        'data.datTrans': a.datTrans,
      })),
    },
  })
    .exec()
    .then(a => a.map(b => b.toMutableJSON()))

  let count = 0
  for (const { data } of records) {
    if (!predicate(data)) continue

    // If not printed, continue looking for the refs
    if (data.formImpr !== PrintOptions.notPrinted) count++
    else count += await countPrintedRefs(data, refs, predicate, level + 1)
  }
  return count
}

/** Check the Error Code returned to see if we need to resend the transaction. Logic extracted from SW-73-V */
export function shouldResendTrans(record: SrmTransactionLog) {
  if (record.sent) return false
  if (!record.response) return true
  if (record.response.listErr?.some(e => RESENT_ERR_CODE.includes(e.codRetour))) return true
  return false
}

/**
 * Check if two temporary bills are unchanged.
 *
 * The following fields are compared:
 *
 * - `mont.pourb` (amount before taxes)
 * - `mont.apresTax` (amount after taxes)
 * - `items` (an array of items)
 *
 * If any of the fields are different, the function returns `false`. Otherwise, it returns `true`.
 *
 * @param a the first temporary bill
 * @param b the second temporary bill
 */
export function isTemporaryBillUnchanged(a: TransactionData, b: TransactionData): boolean {
  if (a.mont.pourb !== b.mont.pourb) return false
  if (a.mont.apresTax !== b.mont.apresTax) return false
  if (a.sectActi.abrvt !== b.sectActi.abrvt) return false
  if (a.sectActi.abrvt === 'RBC' && b.sectActi.abrvt === 'RBC' && a.sectActi.noTabl !== b.sectActi.noTabl) return false
  if (_.differenceWith(a.items, b.items, isItemUnchanged).length) return false
  return true
}
function isItemUnchanged(a: TransactionItem, b: TransactionItem) {
  if (a.qte !== b.qte) return false
  if (a.prix !== b.prix) return false
  if (a.descr !== b.descr) return false
  if (_.differenceWith(a.preci ?? [], b.preci ?? [], isItemDetailsUnchanged).length) return false
  return true
}
function isItemDetailsUnchanged(a: TransactionItemDetail, b: TransactionItemDetail) {
  if (a.qte !== b.qte) return false
  if (a.prix !== b.prix) return false
  if (a.descr !== b.descr) return false
  return true
}

// function isDuplicateTransaction(t: TransactionData) {
//   if (t.modImpr === PrintModes.duplicate) return true
//   // Cancellation & Failure to pay must keep thier status when printing duplicate (SW-73-V 4.4.1.1.14)
//   if ([PrintModes.cancellation, PrintModes.failureToPay].includes(t.modImpr) && t.formImpr !== PrintOptions.notPrinted) return true

//   return false
// }

export function isPrintDuplicateAllowed(_row: SrmTransactionLog) {
  // Don't allow duplicated bill to be duplicate again
  // if (isDuplicateTransaction(row.data)) return false

  // TODO: Check if there is any case that need to be excluded
  return true
}

/** Check in transaction log to see if the order already have transaction(s) sent to WEB-SRM */
export async function isOrderAlreadyTransmitted(order: Order) {
  const record = await SrmTransactionLog.findOne({ selector: { ref: order._id } }).exec()
  console.log(`Checking if order [${order._id}] is transmitted or not:`, !!record)
  return !!record
}

type TransRef = NonNullable<TransactionData['refs']>[number]

export function transToRefs(...rows: TransactionData[]) {
  const data = rows.map<TransRef>(a => ({
    noTrans: a.noTrans,
    datTrans: a.datTrans,
    avantTax: a.mont.avantTax,
  }))
  return data
}

/** Check the Error Code returned to see if we need to resend the transaction. Logic extracted from SW-73-V */
export function hasErrorRequireResent(listErr?: ResponseError[]) {
  return listErr?.some(e => RESENT_ERR_CODE.includes(e.codRetour))
}
