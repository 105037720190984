import { Buffer } from 'buffer'

import EndOfDay from '@eod/EndOfDay.tsx'
import CalendarDate, { type MonthMatrix } from 'calendar-dates'
import dayjs, { type Dayjs } from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import pako from 'pako'
import { memo } from 'react'

import { dataLock } from '@/data/DataUtils.ts'
import { Eod, type EodDocument } from '@/data/Eod.ts'
import { EodCache, type EodCacheDocument } from '@/data/EodCache.ts'
import { maxId0 } from '@/data/MaxIdHub.ts'
import { cashPaymentName0 } from '@/data/PaymentHub.ts'
import { Terminal, TerminalTypes } from '@/data/Terminal.ts'
import { makeTerminalsAvailable } from '@/data/TerminalHub.ts'
import { TseCertificate } from '@/data/TseCertificate.ts'
import { tseConfig0 } from '@/data/TseConfigHub.ts'
import { toISOString } from '@/lib/utils.ts'
import { getEodReportPeriod } from '@/pos/logic/eod-calendar-utils.ts'
import { renderPivotTable } from '@/pos/logic/pivot.js'
import type { DayReport, EodReport, EodReportItem } from '@/pos/logic/ReportType.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { printXReport } from '@/pos/logic/x-report-print.ts'
import { printEod } from '@/pos/logic/z-report-print.ts'
import {
  getBeginDateUnix,
  getBeginHourAsMinutes,
  getEndDateUnix,
  getTodayVDateISO, getVDate,
  getVDateDayjs, getVDateNow
} from '@/pos/orderUtils.ts'
import { onEnter, PosScreen, router } from '@/pos/PosRouter.ts'
import { queryTrainingMode, showTrainingMode } from '@/pos/trainingMode.ts'
import { computed, effect, effectOn, signal } from '@/react/core/reactive.ts'
import { showDateNotComplete } from '@/react/EndOfDayView/eod-dialog.tsx'
import { printInvoiceFromRaster } from '@/react/Printer/print-invoice.ts'
import { endOfDay } from '@/react/Terminal'
import { eodHook } from '@/react/utils/hooks.ts'
import { handleTseEod } from '@/tse/tse-init.ts'
import { mainScreen, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { makeCategoriesAvailable } from "@/data/CategoryHub.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { countReportTotal } from "@/pos/logic/report-utils.ts";
import uuid from "time-uuid";
import { PaidOrder } from "@/data/Order.ts";
import { convertDocsToJson } from "@/data/data-utils.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

const log = debug('view:eod')
type DateState = 'red' | 'green' | 'none'

export const [date0, setDate0] = signal<string>(dayjs().subtract(getBeginHourAsMinutes(), 'minute').format('YYYY-MM-DD'))
export const dateIso0 = computed<string>(() => {
  return toISOString(dayjs(date0(), 'YYYY-MM-DD').unix())
})

function convertToIso(date: string) {
  return toISOString(dayjs(date, 'YYYY-MM-DD').unix())
}

export const [matrix0, setMatrix0] = signal<MonthMatrix>([])

interface ZDateItem {
  [key: string]: Array<EodDocument>
}

export const [group0, setGroup0] = signal<{
  [key: string]: ZDateItem
}>()

export function getZContent(key: string) {
  return key?.split('/')[0]
}

export function getZContentWithMaxZ(key: string) {
  return key?.split('/')[0] === '-1' ? maxZ().toString() : key?.split('/')[0];
}

export const [currentMonth, setCurrentMonth] = signal<Dayjs>(getVDateDayjs(dayjs(now())).startOf('M'))

effectOn([currentMonth], async () => {
  const calendarDate = new CalendarDate()
  const matrix = await calendarDate.getMatrix(dayjs(currentMonth()).toDate())
  //to prevent fetching more than 5 lines (ie first sun is first day of the month)
  setMatrix0(matrix.slice(0, 5))
  //TODO: set to first date in month when changed
})

export const [v, setV] = signal<number>(0)

export const [reports0, setReports0] = signal<EodReport>()
export const reportDates0 = computed<string[]>(() => {
  return Object.keys(reports0() || {}).map(d => d.split('T')[0]);
})

const isDateInReport = (date: string) => reportDates0().includes(date)

export const dayReports0 = computed<DayReport>(() => {
  return reports0()?.[dateIso0()]
})

export const dayReport0 = computed<EodReportItem>(() => {
  return dayReports0()?.[getZContent(selectedZ0())]
})

//list all z in date , tabs at right side
//region currentZs0
export const currentZs0 = computed<string[]>(() => {
  const arr: string[] = [];
  if (reports0()?.[dateIso0()]) {
    arr.push(...Object.keys(reports0()?.[dateIso0()]).filter(k => k === '-1').map(k => `${k}/${false}`));
  }
  if (group0()?.[date0()]) {
    return [...Object.keys(group0()?.[date0()]), ...arr]
  }
  // if (isDateInReport(date0())) {
  //   return [`${-1}/${false}`]
  // }
  return [];
})
//endregion

export function checkIsEmpty() {
  if (group0()?.[date0()]) return false;
  if (isDateInReport(date0())) {
    return false
  }
  return true;
}

export const [selectedZ0, setSelectedZ0] = signal<string>('-1')

export const [showProductSold, setShowProductSold] = signal<boolean>(false)
export const [showMoreInfo, setShowMoreInfo] = signal<boolean>(false)

export const [maxZ, setMaxZ] = signal<number>(-1)

window.dayReport0 = dayReport0
window.dayReports0 = dayReports0
window.selectedZ0 = selectedZ0;
window.date0 = date0;

export const dayReport0Gross = computed(() => {
  return countReportTotal(dayReport0()?.report)?.gross ?? 0
})

// prepare all data for calendar
let reportReadyLock = new MultiAwaitLock(true);
//region reports0-calculate
effectOn([v, queryTrainingMode, matrix0], async () => {
  // aggregate all orders in month...
  if (v() === 0) return
  await dataLock.acquireAsync()

  const isoFirst = _.first(_.first(matrix0()))
  const isoLast = _.last(_.last(matrix0()))

  const from = getBeginDateUnix(dayjs(isoFirst!.iso, 'YYYY-MM-DD').startOf('D').unix())
  const to = getEndDateUnix(dayjs(isoLast!.iso, 'YYYY-MM-DD').endOf('D').unix())

  const eods = await Eod.find({ selector: { vDate: { $gte: from, $lte: to } } }).exec()
  const eodMax = await Eod.findOne({ sort: [{ z: 'desc' }] }).exec();
  setMaxZ(eodMax?.z ? eodMax?.z + 1 : 1);

  const group = renderPivotTable(
    {
      rows: [
        {
          label: 'vDate',
          fn: (eod: EodDocument) => `${dayjs.unix(eod.vDate!).format('YYYY-MM-DD')}`,
        },
        {
          label: 'z',
          fn: (eod: EodDocument) => `${eod.z}/${eod.complete}`,
        },
      ],
      reducers: [
        {
          label: 'a',
          fn(result: EodDocument[], eod: EodDocument) {
            result.push(eod)
            return result
          },
          initValue: [],
        },
      ],
    },
    eods
  )

  setGroup0(group)

  const report = await getEodReportPeriod(from, to)
  setReports0(report)
  reportReadyLock.release().then();
})
//endregion

//region utils
async function removeEodFrom(date: string) {
  const from = dayjs(date).startOf('d').unix();
  const eods = await Eod.find({ selector: { vDate: {$gte: from} } }).exec()
  for (const eod of eods) {
    await eod.incrementalRemove();
    const orders = await PaidOrder.find({ selector: { z: eod.z } }).exec()
    for (const order of orders) {
      await order.incrementalPatch({ z: undefined, eod: undefined });
    }
  }
}

//@ts-ignore
window.removeEodFrom = removeEodFrom;

async function checkReport(date: string = '2025-02-03') {
  const from = dayjs(date).startOf('d').unix();
  const to = dayjs(date).endOf('d').unix();
  const report = await getEodReportPeriod(from, to)
  console.log(report);
}

//@ts-ignore
window.checkReport = checkReport;
//endregion

function isNotCompleted(item: ZDateItem) {
  return !!Object.keys(item).find(key => key.includes('false'))
}

export const dateMap = computed<{
  [key: string]: DateState
}>(() => {
  if (!group0()) return {}
  const reports = reports0();
  if (!reports) return {};

  const result: {
    [key: string]: DateState
  } = {}
  // const reportDates = Object.keys(reports || {}).map(d => d.split('T')[0]);
  // const isDateInReport = (date: string) => reportDates.includes(date)
  for (const row of matrix0()!) {
    for (const { date: _date, iso: date } of row) {
      const keys = Object.keys(reports0()?.[convertToIso(date)] || {}).filter(k => k === '-1').map(k => `${k}/${false}`)
      // if (!(date in group0()!) && isDateInReport(date)) {
      if (keys.length > 0) {
        result[date] = 'green'
      } else if (date in group0()!) {
        result[date] = 'red'
      }
    }
  }
  return result
})

export const [eodCaches0, setEodCaches0] = signal<Array<EodCacheDocument>>([])
const [v2, setV2] = signal<number>(0)
export const zNumber0 = computed<string>(() => {
  const result = Object.keys(dayReports0() || {})[0]
  if (result === '-1') return maxId0()?.eodId!.toString()
  return result
})

// effectOn([dateMap], async () => {
//   if (Object.keys(dateMap()).length === 0 || v2() === 0) return
//   //search all eod cache in month
//   //group by date -> {[date]: report}
//   const result: any = {}
//   for (const [index, item] of Object.entries<DateState>(dateMap())) {
//     if (item === 'none') {
//       continue
//     }
//     const cache = eodCaches0().find(c => dayjs.unix(c.date!).format('DD') === index)
//     if (cache) {
//       result[index] = cache.toMutableJSON()
//     } else {
//       //calculate cache
//       //query orders, items
//       const from = dayjs(currentMonth()).date(parseInt(index)).startOf('d').unix()
//       const orders = unpackOrders(await PaidOrder.find({ selector: { vDate: from } }).exec())
//       //todo: create cache for next use
//       const eodResult = await calculateEod(orders)
//       const eodCache: EodCache = {
//         _id: uuid(),
//         date: from,
//         vSum: eodResult.vSum,
//       }
//       result[index] = eodCache
//       // await EodCache.upsert(eodCache);
//     }
//   }
// })

//@ts-ignore
window.fix7 = async () => {
  const orders = await PaidOrder.find({}).exec()
  for (const order of orders) {
    await order.incrementalPatch({ z: undefined })
  }
}

// need Z key for printing, if this is undefined, then current date can just "Reprint"
export const needZKey = computed(() => {
  const keys = Object.keys(reports0()?.[convertToIso(date0())] || {}).filter(k => k === '-1').map(k => `${k}/${false}`)
  if (keys.length > 0) {
    return keys[0];
  }
  // if (isDateInReport(date0())) {
  //
  // }
  // const date: string = currentMonth().startOf('M').set('D', dayjs(date0()).date()).format('YYYY-MM-DD')
  // const currentDateReport = group0()?.[date]
  // return Object.keys(currentDateReport || {}).find(key => key.includes('false'))
})

export const needReprint = computed(() => {
  const currentDateReport = group0()?.[date0()]
  const keys = Object.keys(reports0()?.[convertToIso(date0())] || {}).filter(k => k === '-1').map(k => `${k}/${false}`)
  if (Object.keys(currentDateReport || {}) && keys.length === 0) {
    return Object.keys(currentDateReport || {}).find(key => key.includes('true'));
  }
})

effect(() => {
  setSelectedZ0(needZKey() ?? currentZs0()[0])
})

export async function getOldestDateNotComplete() {
  //then get order without z base on date asc and z: undefined
  const lastOrderWZ = await PaidOrder.findOne({
    selector: {
      z: { $exists: false },
      trainingMode: showTrainingMode() ? true : { $ne: true }
    },
    sort: [{ date: 'asc' }]
  }).exec();

  if (!lastOrderWZ) return;
  if (lastOrderWZ.vDate === dayjs(date0(), 'YYYY-MM-DD').startOf('d').unix()) return;

  return dayjs.unix(lastOrderWZ?.vDate ?? 0).format('MM-DD-YYYY')
}

async function recalculateReports() {
  log('recalculateReports');
  const eodCache = await EodCache.findOne({selector: {date: dayjs(date0(), 'YYYY-MM-DD').unix()}}).exec();
  await eodCache?.incrementalRemove();

  reportReadyLock.tryAcquire();
  setV(v => v + 1);
  await reportReadyLock.acquireAsync();
}

//region onPrintZ
export async function onPrintZ(onClose?: (() => void) | undefined) {
  // const _needZKey = needZKey()
  // if (!_needZKey) return

  const _date = await getOldestDateNotComplete();
  if (_date) {
    await showDateNotComplete(_date)
    onClose?.()
    return
  }
  const date: string = date0();
  console.log('EndOfDayView::onPrintZ', 'date', date)
  userFLow(`print x report for ${date}`, {
    username: loginUser()?.name
  });

  //todo: insert eod

  if (posSetting0()!.oneEodPerDay && Object.keys(reports0()[dateIso0()]).length > 1) {
    const zs = Object.keys(reports0()[dateIso0()]).filter (k => k !== '-1').map(k => parseInt(k));
    const eods = await Eod.find({ selector: { z: {$in: zs} } }).exec()
    for (const eod of eods) {
      await eod.incrementalRemove();
      const eodCache = await EodCache.findOne({selector: {date: eod.vDate}}).exec();
      await eodCache?.incrementalRemove();
      const orders = await PaidOrder.find({ selector: { z: eod.z } }).exec()
      for (const order of orders) {
        await order.incrementalPatch({ z: undefined })
      }
    }
    reportReadyLock.tryAcquire();
    setV(v => v + 1);
    await reportReadyLock.acquireAsync();
  }

  const report = reports0()[dateIso0()]['-1']
  const { from, to } = report;

  const z = maxZ();
  await handleTseEod({ from, to, z, refresh: recalculateReports })

  // lay tong cac payments cua orders
  // lay tong cac cash-payments cua orders
  // eod.paymentSum, eod.cashSum
  // order.z = eod.z;

  const eod = await Eod.insert(
    {
      _id: uuid(),
      z: z,
      complete: true,
      date: dayjs(now()).unix(),
      vDate: dayjs(date0(), 'YYYY-MM-DD').unix(),
      ...(await calData()),
    } as unknown as EodDocument // Temp fix for type mismatch (the `report` field)
  )

  const orders = convertDocsToJson(await PaidOrder.find({ selector: { date: { $gte: from, $lte: to } } }).exec());
  for (const order of orders) {
    order.z = z;
    order.eod = eod._id;
  }

  await PaidOrder.bulkUpsert(orders);

  // _.assign(maxId0(), { eodId: z, lastEodCompleted: dayReport0().to })
  // for (const orderZ of orderZs) {
  //   //get max z
  //   await orderZ.incrementalPatch({ z });
  // }
  // await Eod.incrementalUpsert({ z: maxId0()?.eodId!, date: dayjs(date).unix(), _id: uuid() });
  // //todo: print here
  //
  await eodHook.emit('postPrintZ', date)
  onClose?.()

  await recalculateReports();

  const raster = await printEod(dayReport0(), z, dayjs(date0()))
  if (!raster) throw new Error('⚠️ Failed to generate Eod Report!')
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'eod' } })

  //region onPrintZ.calData
  async function calData() {
    const startOrderId = dayReport0().report.fromId
    const endOrderId = dayReport0().report.toId
    const cashSum = dayReport0().sumByPayment[cashPaymentName0()!]
    const paymentSum = _.sumBy(_.values(dayReport0().sumByPayment))
    // const taxGroup = dayReport0().taxGroup;
    const report = Buffer.from(pako.deflate(JSON.stringify(dayReport0()))).toString('base64')
    // const report = dayReport0();

    const certificates = (await TseCertificate.findOne().exec())?.certificates || []
    const tseData = {
      username: tseConfig0()?.username,
      serialNumber: tseConfig0()?.serialNumber,
      signatureAlgorithm: tseConfig0()?.signatureAlgorithm,
      tseTimeFormat: tseConfig0()?.tseTimeFormat,
      pdEncoding: tseConfig0()?.pdEncoding,
      tsePublicKey: tseConfig0()?.tsePublicKey,
      certificates: certificates,
    }
    return { startOrderId, endOrderId, cashSum, paymentSum, report, tseData }
  }

  //endregion
}

eodHook.on('postPrintZ', async function () {
  // only run terminal if eod date is today
  if (dateIso0() === getTodayVDateISO()) {
    const zvts = await Terminal.find({ selector: { type: TerminalTypes.Zvt } }).exec()
    for (const zvt of zvts) {
      try {
        console.log('zvt', zvt)
        await endOfDay(zvt._id)
      } catch (e) {
        console.error(e)
      }
    }
  } else {
    console.log('on postPrintZ, skip')
  }
})

export const onReprintZ = async () => {
  const date: string = currentMonth().startOf('M').set('D', dayjs(date0()).date()).format('YYYY-MM-DD')
  userFLow(`print z report for ${date}`, {
    username: loginUser()?.name
  });
  console.log(date)
  const _needReprint = needReprint()
  if (!_needReprint) return
  const eod = group0()![date][selectedZ0() || _needReprint][0]
  const z = eod.z
  const raster = await printEod(dayReport0(), z, dayjs(date0()))
  if (!raster) throw new Error('⚠️ Failed to generate Eod Report!')
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'eod' } })
}
//@ts-expect-error debug only
window.onPrintZ = onPrintZ

export const onPrintX = async () => {
  userFLow(`print x report for ${date0()}`, {
    username: loginUser()?.name
  });
  const raster = await printXReport(dayReport0(), dayjs(date0()))
  if (!raster) return
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'eod' } })
}

//endregion

export const onChangeMonth = (action: 'NEXT' | 'PREV') =>
  setCurrentMonth(prev =>
    prev
      .month(prev.month() + (action === 'NEXT' ? 1 : -1))
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
  )

export const onBack = () => {
  userFLow(`click back`, {
    username: loginUser()?.name
  });
  router.screen = mainScreen()
}

const EndOfDayView = () => {
  makeTerminalsAvailable()
  makeCategoriesAvailable()
  onEnter(PosScreen.EOD, async () => {
    setV(v => v + 1)
    await dataLock.acquireAsync()
    // const eodCaches = await EodCache.find({ selector: { date: {} } }).exec()
    // setEodCaches0(eodCaches)
    // setV2(v => v + 1);
  })
  return <EndOfDay />
}

export default memo(EndOfDayView)