import { type FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  currentItem, handleAddDiscountOrderItem,
  handleClearDiscountOrder,
  handleClearDiscountOrderItem,
  handleOrderDiscount,
  orderDiscount, orderDiscountItem,
  setOrderDiscount, setOrderDiscountItem,
  setTabDiscountPopup,
  showTabSidebar,
  tabDiscountPopup,
  TabsDiscountPopup
} from '@/react/OrderView/OrderView.tsx'
import clsx from "clsx";
import { signal } from "@/react/core/reactive.ts";
import EditItemForm from "@order-view/DiscountPopup/EditItemForm.tsx";
import SplitItemForm from "@order-view/DiscountPopup/SplitItemForm.tsx";
import MoveSeatForm from "@order-view/DiscountPopup/MoveSeatForm.tsx";
import ModifiersForm from "@order-view/DiscountPopup/ModifiersForm.tsx";
import EditDiscountLabelOther from "@order-view/DiscountPopup/EditDiscountLabelOther.tsx";
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import DiscountForm from "@/react/core/DiscountForm.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL3 } from '@/react/core/I18nCurrency';
import { toast } from "react-toastify";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type DiscountPopupItemType = {
  onClose?: () => void;
};

export const [isEditButton, setIsEditButton] = signal<boolean>(false);

const DiscountPopupItem: FunctionComponent<DiscountPopupItemType> = ({
  onClose,
}) => {
  const { order0 } = useContext(ItemFactoryContext);

  const [isEditDiscountLabelOtherPoOpen, setEditDiscountLabelOtherPoOpen] =
    useState(false);

  const openEditDiscountLabelOtherPo = useCallback(() => {
    userFLow(`open edit discount label other`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setEditDiscountLabelOtherPoOpen(true);
  }, []);

  const closeEditDiscountLabelOtherPo = useCallback(() => {
    userFLow(`close edit discount label other`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setEditDiscountLabelOtherPoOpen(false);
  }, []);

  const handleEditDiscount = () => {
    userFLow(`handle edit discount`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    handleClearDiscountOrder()
    currentItem() && handleClearDiscountOrderItem(currentItem())
    setOrderDiscount({ value: '', type: 'percent', label: '' })
    setOrderDiscountItem({ value: '', type: 'percent', label: '' })
    setIsEditButton(!isEditButton())
  }

  useEffect(() => {
    setIsEditButton(false)
  }, [])

  useEffect(() => {
    if (currentItem()?.printed) {
      setTabDiscountPopup(TabsDiscountPopup.DISCOUNT)
    }
  }, [])


  return (
    <>
      <div
        className="w-full h-full relative rounded-md bg-white overflow-hidden flex flex-col items-center justify-start min-w-[540px] max-w-full max-h-full text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish Tablet_600:min-w-[420px] mq1440:min-w-[520px] height_414:h-full">
        <div
          className="self-stretch bg-white flex flex-row items-center justify-between py-2 px-3.5 border-b-[1px] border-solid border-gray-solid-gray-150-eeeeee">
          <div className="flex flex-col items-start justify-start mq320:flex">
            <div className="self-stretch flex flex-row items-center justify-start gap-[0px_4px]">
              <b className="relative">{LL0().common.total()}:</b>
              <div className="relative text-smi font-semibold text-red-solid-red-450-c0272d">
                {showTabSidebar() ? LL3().format.currency(currentItem()?.vSum || 0) : LL3().format.currency(order0?.()?.vSum || 0)}
              </div>
            </div>
            <div className="w-[295px] hidden flex-col items-start justify-center gap-[2px_0px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[0px_4px]">
                <b className="relative">{currentItem()?.name}:</b>
                <div
                  className="relative font-semibold text-gray-300">{LL3().format.currency(currentItem()?.quantity || 0)}</div>
              </div>
              <div
                className="self-stretch flex flex-row items-center justify-start gap-[0px_4px] text-smi text-black-solid-black-880-1d1d26">
                <div className={clsx("relative font-semibold",
                  currentItem()?.vPrice !== currentItem()?.price && "[text-decoration:line-through]"
                )}>
                  {LL3().format.currency(currentItem()?.price)}
                </div>
                {currentItem()?.vPrice !== currentItem()?.price &&
                  <div className="relative font-semibold text-red-solid-red-450-c0272d">
                    {LL3().format.currency(currentItem()?.vPrice || 0)}
                  </div>}
              </div>
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-start gap-[18px] text-2xs text-white Mobile_480:gap-[10px] mq320:gap-[10px]">
            <div className="flex flex-row items-center justify-end gap-[12px]">
              <div
                className="rounded-10xs bg-blue-solid-blue-420-2979ff hidden flex-row items-center justify-center py-[7px] px-2">
                <b className="relative">{LL0().ui.save()}</b>
              </div>
              {tabDiscountPopup() === TabsDiscountPopup.DISCOUNT &&
                <>
                  <div
                    className="rounded-10xs bg-green-solid-green-00c968 flex flex-row items-center justify-center py-[7px] px-2 cursor-pointer"
                    ref={makeRipple}
                    onClick={() => {
                      openEditDiscountLabelOtherPo()
                    }}
                  >
                    <b className="relative">{LL0().order.addLabel()}</b>
                  </div>

                  {/*{isEditDiscountLabel() && <div*/}
                  {/*  className="rounded-10xs bg-green-solid-green-00c968 flex flex-row items-center justify-center py-[7px] px-2"*/}
                  {/*  onClick={() => {*/}
                  {/*    if (currentDiscountLabel() >= 0) {*/}
                  {/*      openEditDiscountLabelOtherPo()*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <b className="relative">{LL0().order.editLabel()}</b>*/}
                  {/*</div>}*/}
                  <div
                    className="min-w-[75px] rounded-10xs bg-green-solid-green-00c968 flex flex-row items-center justify-center py-[7px] px-2"
                    ref={makeRipple}
                    onClick={handleEditDiscount}
                  >
                    <b className="relative">{isEditButton() ? LL0().ui.save() : LL0().order.editButton()}</b>
                  </div>
                </>
              }
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src="/icong-close-popup-icon--grey12@2x.png"
              onClick={onClose}
            />
          </div>
        </div>
        <div
          className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start gap-[0px_8px] text-2xs text-black-solid-black-1000-000000">
          {showTabSidebar() &&
            <div
              className="no-scrollbar self-stretch bg-gray-solid-gray-130-f4f4f4 box-border w-[102px] overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-3 px-0 pb-0 gap-[4px_0px] border-r-[1px] border-solid border-gray-solid-gray-125-f1f1f1 border-l-[1px] Tablet_600:flex Mobile_480:hidden mq320:hidden">
              {!currentItem()?.printed && (<div className={clsx("self-stretch flex flex-col items-start justify-center",
                tabDiscountPopup() === TabsDiscountPopup.MODIFIER && "text-royalblue-200"
              )}
                                                onClick={() => {
                                                  userFLow(`set tab modifier`, {
                                                    orderId: order0?.()?._id,
                                                    username: loginUser()?.name
                                                  });
                                                  setTabDiscountPopup(TabsDiscountPopup.MODIFIER)
                                                }}
              >
                <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                  {tabDiscountPopup() === TabsDiscountPopup.MODIFIER && <div
                    className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-blue-solid-blue-70-cfddfd box-border z-[0] border-l-[1.5px] border-solid border-blue-solid-blue-470-356ff6" />}
                  <div className="relative uppercase font-semibold z-[1]">
                    {LL0().restaurant.modifier().toUpperCase()}
                  </div>
                </div>
              </div>)}
              {<div className={clsx("self-stretch flex flex-col items-start justify-center",
                tabDiscountPopup() === TabsDiscountPopup.DISCOUNT && "text-royalblue-200"
              )}
                    onClick={() => {
                      userFLow(`set tab discount`, {
                        orderId: order0?.()?._id,
                        username: loginUser()?.name
                      });
                      setTabDiscountPopup(TabsDiscountPopup.DISCOUNT)
                    }}
              >
                <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                  {tabDiscountPopup() === TabsDiscountPopup.DISCOUNT &&
                    <div
                      className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}
                  <div className="relative uppercase font-semibold z-[1]">
                    {LL0().order.discount().toUpperCase()}
                  </div>
                </div>
              </div>}
              {<div className={clsx("self-stretch flex flex-col items-start justify-center",
                tabDiscountPopup() === TabsDiscountPopup.EDIT_ITEM && "text-royalblue-200"
              )}
                    onClick={() => {
                      userFLow(`set tab edit item`, {
                        orderId: order0?.()?._id,
                        username: loginUser()?.name
                      });
                      setTabDiscountPopup(TabsDiscountPopup.EDIT_ITEM)
                    }}
              >
                <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                  {tabDiscountPopup() === TabsDiscountPopup.EDIT_ITEM && <div
                    className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}
                  <div className="relative uppercase font-semibold z-[1]">
                    {LL0().inventory.editItem()}
                  </div>
                </div>
              </div>}
              {/*<div className={clsx("self-stretch flex flex-col items-start justify-center",*/}
              {/*  tabDiscountPopup() === TabsDiscountPopup.MOVE_SEAT && "text-royalblue-200"*/}
              {/*)}*/}
              {/*     onClick={() => setTabDiscountPopup(TabsDiscountPopup.MOVE_SEAT)}*/}
              {/*>*/}
              {/*  <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">*/}
              {/*    {tabDiscountPopup() === TabsDiscountPopup.MOVE_SEAT && <div className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}*/}
              {/*    <div className="relative uppercase font-semibold z-[1]">*/}
              {/*      MOVE SEAT*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {<div className={clsx("self-stretch flex flex-col items-start justify-center",
                tabDiscountPopup() === TabsDiscountPopup.SPLIT_ITEM && "text-royalblue-200"
              )}
                    onClick={() => {
                      if (!currentItem().printed && order0?.()?.table) {
                        return toast.error('Please print order first!')
                      }
                      userFLow(`set tab split item`, {
                        orderId: order0?.()?._id,
                        username: loginUser()?.name
                      });
                      setTabDiscountPopup(TabsDiscountPopup.SPLIT_ITEM)
                    }}
              >
                <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">
                  {tabDiscountPopup() === TabsDiscountPopup.SPLIT_ITEM && <div
                    className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}
                  <div className="relative uppercase font-semibold z-[1]">
                    {LL0().order.splitItem()}
                  </div>
                </div>
              </div>}
              {/*<div className={clsx("self-stretch flex flex-col items-start justify-center",*/}
              {/*  tabDiscountPopup() === TabsDiscountPopup.SIZE && "text-royalblue-200"*/}
              {/*)}*/}
              {/*     onClick={() => setTabDiscountPopup(TabsDiscountPopup.SIZE)}*/}
              {/*>*/}
              {/*  <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">*/}
              {/*    {tabDiscountPopup() === TabsDiscountPopup.SIZE && <div className="w-full absolute my-0 mx-[!important] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}*/}
              {/*    <div className="relative uppercase font-semibold z-[1]">*/}
              {/*      SIZE*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className={clsx("self-stretch flex flex-col items-start justify-center",*/}
              {/*  tabDiscountPopup() === TabsDiscountPopup.SAUCE && "text-royalblue-200"*/}
              {/*)}*/}
              {/*     onClick={() => setTabDiscountPopup(TabsDiscountPopup.SAUCE)}*/}
              {/*>*/}
              {/*  <div className="self-stretch flex flex-row items-center justify-start py-[15px] px-2 relative">*/}
              {/*    {tabDiscountPopup() === TabsDiscountPopup.SAUCE && <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-lavender box-border z-[0] border-l-[1.5px] border-solid border-royalblue-200" />}*/}
              {/*    <div className="relative uppercase font-semibold z-[1]">*/}
              {/*      SAUCE*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>}
          <EditItemForm showEditItemForm={tabDiscountPopup() === TabsDiscountPopup.EDIT_ITEM} />
          <DiscountForm
            showDiscountForm={tabDiscountPopup() === TabsDiscountPopup.DISCOUNT && !showTabSidebar()}
            type={"order"}
            isEdit={isEditButton()}
            order={order0?.()}
            discount={orderDiscount()}
            setDiscount={setOrderDiscount}
            onApply={(d) => handleOrderDiscount(d)}
            onClear={handleClearDiscountOrder}
            onClose={onClose}
          />
          <DiscountForm
            showDiscountForm={tabDiscountPopup() === TabsDiscountPopup.DISCOUNT && showTabSidebar()}
            type={"item"}
            isEdit={isEditButton()}
            order={currentItem()}
            discount={orderDiscountItem()}
            setDiscount={setOrderDiscountItem}
            onApply={(d) => handleAddDiscountOrderItem(d, currentItem().commitRefs[0])}
            onClear={() => handleClearDiscountOrderItem(currentItem())}
            onClose={onClose}
          />
          <SplitItemForm showSplitItemForm={tabDiscountPopup() === TabsDiscountPopup.SPLIT_ITEM} />
          <MoveSeatForm showMoveSeatForm={tabDiscountPopup() === TabsDiscountPopup.MOVE_SEAT} />
          {!currentItem()?.printed &&
            <ModifiersForm showModifiersForm={tabDiscountPopup() === TabsDiscountPopup.MODIFIER} />}
        </div>
      </div>
      {isEditDiscountLabelOtherPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditDiscountLabelOtherPo}
        >
          <EditDiscountLabelOther onClose={closeEditDiscountLabelOtherPo} />
        </PortalPopup>
      )}
    </>
  );
};

export default DiscountPopupItem;