import assert from 'assert'

import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'

import { Category } from '@/data/Category'
import { getTransactionId } from '@/data/MaxIdHub'
import type { Order } from '@/data/Order'
import { PaymentType } from '@/data/Payment'
import { companyInfo0, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { defaultQuebecTax0 } from '@/data/TaxCategoryHub'
import { loginUser } from '@/data/UserSignal.ts'
import { createOrder } from '@/pos/logic/order-reactive'
import { getOrderItemOriginalSum, getOrderNet, getOrderOriginalSum, isRefundOrder } from '@/pos/logic/order-utils.ts'
import { OrderStatus, type OrderItem, type OrderPayment, type OrderStrip } from '@/pos/OrderType'
import { LL0 } from '@/react/core/I18nService'
import { roundNumber } from '@/shared/order/order-config.ts'
import { stringifyObj } from '@/shared/utils2.ts'
import { SPECIAL_ITEM_DESCR } from '@/srm/lib/constants'
import { formatCustomerNumber, formatMoney, formatQuantity, formatTableNumber, formatTransactionNumber } from '@/srm/lib/formatters'
import type { TransactionData, TransactionItem, TransactionItemDetail } from '@/srm/lib/types'

import { ActivitySectors, ActivitySubSectors, OperationModes, PaymentMethods, PrintModes, PrintOptions, ServiceTypes, TransactionTypes } from './lib/enum'
import { formatDateForJson, getUtcInfo } from './lib/timezone'
import { ensureValidAsciiStandard, getStreetNumber, getValidZipcode } from './lib/utils'
import { assertTransaction } from './transaction.assert'

const log = debug('data:srm')

interface BaseModifier {
  name: string
  quantity: number
  price?: number
  total?: number
  taxCode?: TransactionItem['tax']
}
interface BaseItem {
  name: string
  quantity: number
  price?: number
  total: number
  taxCode: TransactionItem['tax']
  /** Contain list of Category Names */
  categories?: string[]
  modifiers?: BaseModifier[]
}
interface TransactionAmounts {
  net: number
  qst: number
  gst: number
  gross: number
  tip: number
}

const emptyItem: TransactionItem = {
  qte: formatQuantity(1),
  descr: 'SOB',
  tax: 'SOB',
  acti: ActivitySubSectors.notAvailable,
  prix: formatMoney(0),
}

export interface TransactionConvertingOptions {
  transactionType?: TransactionTypes
  printMode?: PrintModes
  printOption?: PrintOptions
  /** Cancellation/Refund reason */
  cancelReason?: string
  /**
   * Add a cancellation item to make the bill sum to $0.
   *
   * Used in case of cancellation for already transmitted transaction
   */
  addCancellationItem?: boolean
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
  /** Skip generate transaction number. Should be used for temporary transaction */
  skipGenNoTrains?: boolean
}

/** Convert Order to Quebec's SRM Transaction Data */
export async function order2Trans(orderInput: OrderStrip | Order, option: TransactionConvertingOptions = {}): Promise<TransactionData> {
  // Required config
  const { billingNumber, gstNumber, qstNumber, productId, productVersionId, partnerId, certificateCode, version, previousVersion } = posSetting0()?.srm ?? {}

  const genericMsg = ' ' + LL0().srm.messages.checkConfig()
  if (!billingNumber) throw new Error(LL0().srm.errors.missingBillingNumber() + genericMsg)
  if (!gstNumber) throw new Error(LL0().srm.errors.missingGstNumber() + genericMsg)
  if (!qstNumber) throw new Error(LL0().srm.errors.missingQstNumber() + genericMsg)
  if (!productId) throw new Error(LL0().srm.errors.missingProductCode() + genericMsg)
  if (!productVersionId) throw new Error(LL0().srm.errors.missingProductVersionCode() + genericMsg)
  if (!partnerId) throw new Error(LL0().srm.errors.missingPartnerCode() + genericMsg)
  if (!certificateCode) throw new Error(LL0().srm.errors.missingAuthorizationCode() + genericMsg)
  if (!version) throw new Error(LL0().srm.errors.missingProductVersion() + genericMsg)
  if (!previousVersion) throw new Error(LL0().srm.errors.missingPreviousProductVersion() + genericMsg)

  // Following info is optional
  const { name: companyName = '', address: companyAddr = '', zipCode: companyZipcode = '' } = companyInfo0() ?? {}
  const userName = option.impersonate ?? loginUser()?.name ?? ''
  const validZipCode = getValidZipcode(companyZipcode)
  const streetNumber = getStreetNumber(companyAddr)

  const order = sanitizeOrderInput(orderInput, option)

  const amounts = getOrderAmounts(order)
  const validItems = await getTransactionItems({
    items: order.items,
    serviceFee: order.serviceFee,
    isRefunding: isRefundOrder(order),
    discount:
      `${order.discount ?? '0'}` !== '0' && order.vDiscount
        ? {
            percent: `${order.discount}`,
            amount: getOrderOriginalSum(order) - (order.vSubTotal ?? 0),
          }
        : undefined,
  })

  log('ℹ️ Order amounts: ' + stringifyObj(amounts))
  const paymentMethod =
    // If customer fail to pay, the payment method should be "No payment" (AUC)
    option.printMode === PrintModes.failureToPay
      ? PaymentMethods.noPayment
      : // For temporaryBill, estimate, quote or occasionalThirdParty the value of the modPai field must be none ("SOB")
      [TransactionTypes.temporaryBill, TransactionTypes.estimate, TransactionTypes.quote, TransactionTypes.occasionalThirdParty].includes(option.transactionType as TransactionTypes)
      ? PaymentMethods.notAvailable
      : // if `apresTax` field is "$0.00", the value of the `modPai` field must be noPayment ("AUC")
      option.addCancellationItem
      ? PaymentMethods.noPayment
      : // else we take from order's payments field
        getPaymentMethodFromOrderPaymentType(order.payments ?? [])

  const trans: TransactionData = {
    sectActi: {
      //restaurant
      abrvt: ActivitySectors.restaurant,
      typServ: getServiceType(order),
      noTabl: formatTableNumber(order.table),
      nbClint: formatCustomerNumber(Math.max(1, order.seatMap?.length ?? 0)),
    },
    noTrans: option.skipGenNoTrains ? '-' : formatTransactionNumber((await getTransactionId(dayjs())).transactionId),
    nomMandt: companyName.trim(),
    nomUtil: userName.trim(),
    docAdr: { docNoCiviq: streetNumber, docCp: validZipCode },
    relaCommer: 'B2C',
    datTrans: formatDateForJson(new Date()),
    utc: getUtcInfo() as TransactionData['utc'],
    items: validItems.length ? baseItems2TransactionItems(validItems, order, option) : [emptyItem],
    mont: {
      avantTax: formatMoney(amounts.net), // ?
      TPS: formatMoney(amounts.gst),
      TVQ: formatMoney(amounts.qst),
      apresTax: formatMoney(amounts.gross),

      // 3 fields below is skipped because we not support installments
      // versActu: formatMoney(0.0),
      // versAnt: formatMoney(0.0),
      // sold: formatMoney(0.0),

      // 2 field below is skipped because we do not support feature "Determine the amount due"
      // ajus: amounts.discount !== 0 ? formatMoney(amounts.discount) : undefined,
      // mtdu: amounts.discount !== 0 ? formatMoney(amounts.gross - amounts.discount) : undefined,

      // Only sent tip if there is value
      pourb: amounts.tip ? formatMoney(amounts.tip) : undefined,
    },
    noDossFO: billingNumber,
    noTax: { noTPS: gstNumber, noTVQ: qstNumber },
    commerElectr: 'N',
    typTrans: option.transactionType ?? TransactionTypes.notAvailable,
    modPai: paymentMethod,
    modImpr: option.printMode ?? PrintModes.bill,
    formImpr: option.printOption ?? PrintOptions.notPrinted,
    modTrans: order.trainingMode ? OperationModes.training : OperationModes.operating,
    refs: undefined, // This will be calculated separately
    SEV: {
      idSEV: productId,
      idVersi: productVersionId,
      codCertif: certificateCode,
      idPartn: partnerId,
      versi: version,
      versiParn: previousVersion,
    },
  }

  assertTransaction(trans, order.externalId?.toString() ?? order._id)

  log('✅ Converted Order to Transaction successfully', { orderId: order._id })
  return trans
}

/**
 * Sanitize order input before converting to SRM transaction
 * The reason we need to sanitize the order input is because we need to handle
 * the case where the order is cancelled, and we need to add a "cancellation item"
 * to the order to make the sum of the bill = 0.
 *
 * @param orderInput - The order input to be sanitized
 * @param option - The options for converting the order to SRM transaction
 * @returns The sanitized order
 */
function sanitizeOrderInput(orderInput: Order | OrderStrip, option: TransactionConvertingOptions) {
  const clonedOrder = _.cloneDeep(orderInput)
  delete clonedOrder.commits

  log('🔐 Generating Transaction for Order', clonedOrder)

  const isCancelling = option.printMode === PrintModes.cancellation || clonedOrder.status === OrderStatus.CANCELLED_BEFORE_PAID

  // FIXME: This is a workaround for shipping data, we need to handle it properly in `order-reactive.ts` later
  const { shippingData } = clonedOrder
  if (shippingData) {
    // Append tip to main order
    if (shippingData.tip) clonedOrder.tip = (clonedOrder.tip ?? 0) + shippingData.tip
    // Append shipping fee & shipping service fee to main order's service fee
    if (shippingData.fee) clonedOrder.serviceFee = (clonedOrder.serviceFee ?? 0) + shippingData.fee
    if (shippingData.serviceFee) clonedOrder.serviceFee = (clonedOrder.serviceFee ?? 0) + shippingData.serviceFee
    delete clonedOrder.shippingData
  }

  const order = createOrder({
    ...clonedOrder,
    ...(isCancelling
      ? {
          // If order is cancelled, we must clear all the discount
          // This is because we don't want to send the discount to SRM
          // when the order is cancelled
          discount: '0',
          discountLabel: '',
          items: [
            ...(clonedOrder.items ?? []),
            ...(option.addCancellationItem
              ? [
                  // Must included origin cancelled items, also we need to add
                  // a "cancellation item", that cancel out all items and make the sum of the bill = 0
                  // As requirement in 4.4.1.5.2, document SW-73-V
                  ...(clonedOrder.cancellationItems ?? []),
                  ...(clonedOrder.directCancellationItems ?? []),
                  ...genCancellationItems(clonedOrder, true),
                ]
              : []),
          ],
        }
      : {}),
  })
  return order
}

function baseItems2TransactionItems(items: BaseItem[], order: Order, option: TransactionConvertingOptions): TransactionItem[] {
  const result: TransactionItem[] = []
  for (const item of items) {
    const subSector = getActivitySubSector(item, order)

    // This is a cancellation item. We must also add reason for cancel, in the `preci` field
    if (item.name === SPECIAL_ITEM_DESCR.cancellation) {
      result.push({
        qte: formatQuantity(1),
        descr: item.name,
        tax: item.taxCode,
        acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
        prix: formatMoney(item.total),
        preci: [
          {
            descr: ensureValidAsciiStandard(option.cancelReason ?? 'commande annulée'),
            acti: ActivitySubSectors.notAvailable,
          },
        ],
      })
      continue
    }

    // Special item: Service fees / Package
    if (item.name === SPECIAL_ITEM_DESCR.serviceFees || item.name === SPECIAL_ITEM_DESCR.package) {
      result.push({
        qte: formatQuantity(1),
        descr: item.name,
        tax: item.taxCode,
        acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
        prix: formatMoney(item.total),
      })
      continue
    }
    if (item.name === SPECIAL_ITEM_DESCR.additionalTax) {
      result.push({
        qte: formatQuantity(0),
        descr: item.name,
        tax: 'SOB',
        acti: ActivitySubSectors.notAvailable,
        prix: formatMoney(0),
        preci: item.modifiers?.map(
          m =>
            <TransactionItemDetail>{
              descr: m.name,
              qte: formatQuantity(m.quantity),
              unitr: typeof m.price !== 'undefined' ? formatMoney(m.price) : undefined,
              prix: typeof m.total !== 'undefined' ? formatMoney(m.total) : undefined,
              tax: m.taxCode,
              acti: m.quantity === 0 ? ActivitySubSectors.notAvailable : subSector,
            }
        ),
      })
      continue
    }

    result.push({
      descr: ensureValidAsciiStandard(item.name ?? ''),
      qte: formatQuantity(item.quantity),
      unitr: typeof item.price !== 'undefined' ? formatMoney(item.price) : undefined,
      prix: formatMoney(item.total),
      tax: item.taxCode,
      acti: item.total === 0 ? ActivitySubSectors.notAvailable : subSector,
      preci: item.modifiers?.length
        ? item.modifiers.map(
            (m): TransactionItemDetail => ({
              descr: ensureValidAsciiStandard(m.name),
              qte: formatQuantity(m.quantity),
              unitr: typeof m.price !== 'undefined' ? formatMoney(m.price) : undefined,
              prix: typeof m.total !== 'undefined' ? formatMoney(m.total) : undefined,
              tax: item.taxCode,
              acti: typeof m.total === 'undefined' || m.total === 0 ? ActivitySubSectors.notAvailable : subSector,
            })
          )
        : undefined,
    })
  }
  return result
}

type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>

interface GetItemsOpts {
  items: OrderItem[]
  serviceFee: number | undefined
  discount?: { percent: string; amount: number } | undefined
  isRefunding?: boolean
}

async function getTransactionItems({ items, serviceFee, isRefunding, discount }: GetItemsOpts): Promise<BaseItem[]> {
  if (!items) return []
  const result: BaseItem[] = []
  const categories = await Category.find({ selector: { _id: { $in: _.uniq(_.flatMap(items, a => a.categories)) } } })
    .exec()
    .then(a => a.map(a => a.toMutableJSON()))
  const categoriesMap = _.keyBy(categories, '_id')

  const validItems = items.filter((i): i is WithRequired<OrderItem, 'name' | 'quantity'> => !!i.name && i.quantity !== 0)
  const baseItems = validItems.map(
    i =>
      <BaseItem>{
        name: (i?.id ? i?.id + '. ' : '') + i.name,
        quantity: i.quantity,
        price: i.price,
        total: i.price * i.quantity,
        categories: i.categories?.map(a => categoriesMap[a]?.name).filter(Boolean),
        taxCode: getTaxCode(i),
        modifiers: [
          ...i.modifiers.map(
            (m): BaseModifier => ({
              name: m.name,
              price: m.price,
              quantity: i.quantity * m.quantity,
              total: i.quantity * m.price * m.quantity,
            })
          ),
          // 1. When the order is refunding, we must clear the discount
          // 2. If the order already has discount, we must use it instead of the item's discount
          ...(!isRefunding && !discount && +(i.discount ?? '0') !== 0
            ? [
                {
                  name: SPECIAL_ITEM_DESCR.discount + getDiscountInfo(i.discount),
                  quantity: 1,
                  total: (i.vSubTotal ?? 0) - getOrderItemOriginalSum(i),
                },
              ]
            : []),
        ],
      }
  )
  result.push(...baseItems)

  if (serviceFee) {
    const item: BaseItem = { name: SPECIAL_ITEM_DESCR.serviceFees, quantity: 1, total: serviceFee, taxCode: 'FP' }
    result.push(item)
  }
  if (!isRefunding && discount) {
    const item: BaseItem = {
      name: SPECIAL_ITEM_DESCR.discount + getDiscountInfo(discount.percent),
      quantity: 1,
      total: -+discount.amount,
      taxCode: 'FP',
    }
    result.push(item)
  }
  const itemsWithAdditionalTax = validItems.filter(a => !!a.taxComponents?.some(a => a.printLabel === 'ADDI'))
  if (itemsWithAdditionalTax.length) {
    const modifiers = _.entries(
      _.groupBy(itemsWithAdditionalTax, a =>
        a.taxComponents
          ?.filter(a => a.printLabel === 'ADDI')
          .map(a => a.name)
          .pop()
      )
    ).map(([taxName, items]) => {
      const taxTotal = _.sumBy(items, a => a.vTaxComponents?.ADDI ?? 0)
      const taxInfo = items[0].taxComponents?.find(a => a.name === taxName)
      const isTaxIncluded = taxTotal === 0
      return <BaseModifier>{
        name: `${taxName}@${taxInfo?.value}%`,
        quantity: isTaxIncluded ? 0 : items.length,
        total: taxTotal === 0 ? undefined : taxTotal,
        taxCode: isTaxIncluded ? 'SOB' : 'FP',
      }
    })
    result.push(<BaseItem>{
      name: SPECIAL_ITEM_DESCR.additionalTax,
      quantity: 1,
      total: 0,
      taxCode: 'SOB',
      modifiers,
    })
  }
  return result
}

function getDiscountInfo(discount: OrderItem['discount']) {
  if (typeof discount !== 'string') return ''
  if (discount.indexOf('%') === -1) return ''

  return `@${discount}`
}

function getOrderAmounts(o: Order | OrderStrip): TransactionAmounts {
  const net = roundNumber(o.vSubTotal ?? o.getNet?.() ?? getOrderNet(o))
  if (net === 0) return { net, qst: 0, gst: 0, gross: 0, tip: 0 }
  const gst = roundNumber(o.vTaxComponents?.TPS || 0)
  const qst = roundNumber(o.vTaxComponents?.TVQ || 0)
  const gross = roundNumber(o.vSum ?? 0)
  const tip = roundNumber((o.tip ?? 0) + (o.shippingData?.tip ?? 0))
  const result: TransactionAmounts = { net, qst, gst, gross, tip }

  verifyAnFixAmounts(result, o._id)

  return result
}

/** Generate cancellation items, one for each item's tax code */
function genCancellationItems(order: Order, includeCancelled = false): OrderItem[] {
  const items = [...order.items, ...(includeCancelled ? [...(order.cancellationItems ?? []), ...(order.directCancellationItems ?? [])] : [])]
  if (!items.length) return []
  const grouped = _.groupBy(items, item => getTaxCode(item))
  return Object.entries(grouped).map(([, items]) => {
    const total = _.sumBy(items, getOrderItemOriginalSum)
    return {
      name: SPECIAL_ITEM_DESCR.cancellation,
      quantity: 1,
      price: -total,
      modifiers: [],
      taxComponents: items[0].taxComponents,
    }
  })
}

/** Get item activity sub sector, based on item's category */
function getActivitySubSector(item: BaseItem, order: Order): ActivitySubSectors {
  // For wine, we must set the activity sub sector to "bar"
  // TODO: check if this is correct
  if (item.categories?.some(a => /^wine/i.test(a))) return ActivitySubSectors.bar
  return order.table ? ActivitySubSectors.restaurant : ActivitySubSectors.bar
}
function getServiceType(order: Order): ServiceTypes {
  if (order.externalId !== undefined || order.externalStoreId !== undefined || order.provider !== undefined) return ServiceTypes.digitalPlatform
  if (order.table) return ServiceTypes.tableService
  return ServiceTypes.counterService
}

function getPaymentMethodFromOrderPaymentType(orderPayments: OrderPayment[]): PaymentMethods {
  if (orderPayments.length > 1) return PaymentMethods.mixed
  const [orderPayment] = orderPayments
  if (!orderPayment) return PaymentMethods.noPayment

  if (orderPayment.extraType === PaymentType.Cash || orderPayment.type.match(/cash/i)) return PaymentMethods.cash
  if (orderPayment.extraType === PaymentType.Credit || orderPayment.type.match(/credit/i)) return PaymentMethods.creditCard
  if (orderPayment.extraType === PaymentType.Debit || orderPayment.type.match(/card/i)) return PaymentMethods.debitCard
  // TODO: support more payment methods
  return PaymentMethods.other
}

function recalculateAmounts(amounts: TransactionAmounts): TransactionAmounts {
  const newAmounts: TransactionAmounts = {
    net: amounts.net,
    gst: roundNumber((amounts.net * defaultQuebecTax0().TPS) / 100),
    qst: roundNumber((amounts.net * defaultQuebecTax0().TVQ) / 100),
    get gross() {
      return roundNumber(this.gst + this.qst)
    },
    tip: amounts.tip,
  }
  return newAmounts
}
function calDiff(amounts: TransactionAmounts): number {
  return Math.abs(roundNumber(amounts.net + amounts.gst + amounts.qst) - roundNumber(amounts.gross))
}

/**
 * Verify and fix the amounts of the transaction. Will update the amounts if the total of the order differ by 0.01 or less
 */
function verifyAnFixAmounts(amounts: TransactionAmounts, ref: string): void {
  if (amounts.net === 0) assert(amounts.gst === 0 && amounts.qst === 0 && amounts.gross === 0, 'Something wrong when calculating order amount: NET = 0 but GST, QST or GROSS are not 0')

  const delta = calDiff(amounts)
  if (delta > 0 && delta <= 0.01) {
    log('⚡️ Total of the order differ by 0.01 or less, trying to fix by add it to the largest tax...' + stringifyObj(amounts), { orderId: ref })
    if (amounts.gst > amounts.qst) amounts.gst += delta
    else amounts.qst += delta
  }

  const delta2 = calDiff(amounts)
  if (delta2 > 0) {
    log(`⚡️ Total of the order still differ by ${delta2}, trying to recalculate all amount...`, { orderId: ref })
    const newAmounts = recalculateAmounts(amounts)
    const delta3 = calDiff(amounts)
    if (delta3 <= 0.01) {
      log('⚡️ Recalculated success, applying the new amounts...', { orderId: ref })
      amounts.gst = newAmounts.gst
      amounts.qst = newAmounts.qst
      amounts.gross = newAmounts.gross
    }
    // If the fix is not working, log it with alert, but still allow to proceed
    if (roundNumber(amounts.net + amounts.gst + amounts.qst) !== amounts.gross) {
      log('⚠️ Failed to calculate amounts ' + stringifyObj(amounts), { orderId: ref, alert: true })
    }
  }

  log('💰 Verified Amounts for order ' + stringifyObj(amounts), { orderId: ref })
}

function getTaxCodeFromPrintLabel(label: string | undefined): string {
  if (!label) return ''
  if (/^tps$/i.test(label)) return 'F'
  if (/^tvq$/i.test(label)) return 'P'
  if (/^addi$/i.test(label)) return 'S'
  return ''
}
function getTaxCode(item: OrderItem): TransactionItem['tax'] {
  const codes = ['F', 'P', 'S']

  const result = codes.filter(c => item.taxComponents?.map(a => getTaxCodeFromPrintLabel(a.printLabel)).includes(c)).join('')
  if (!result) {
    log('⚠️ Tax code not found for item', { item })
    return 'NON'
  }
  return result as 'F' | 'P' | 'S' | 'FP' | 'FS' | 'PS' | 'FPS'
}

Object.assign(window, { order2Trans }) // For debugging purpose
