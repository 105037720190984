import type { FunctionComponent } from 'react';
import EndOfDayTabs from '@eod/EndOfDayTabs';
import EndOfDayReportContainer from '@eod/EndOfDayReportContainer';
import {
  currentZs0,
  dayReports0,
  getZContent,
  getZContentWithMaxZ,
  selectedZ0,
  setSelectedZ0
} from '@/react/EndOfDayView/EndOfDayView.tsx';
import clsx from 'clsx';
import dayjs from "dayjs";
import { maxId0 } from "@/data/MaxIdHub.ts";

const EndOfDayRight: FunctionComponent = () => {
  // currentZs0 dang object, cac tab zNumber
  return (
    <div className="h-full self-stretch bg-basic-color-white shadow-[0px_0px_4px_rgba(0,_0,_0,_0.4)_inset] w-[342px] flex flex-col items-start justify-start gap-[24px] sm:w-[260px]">
      <div className={clsx((currentZs0()?.length < 1) && 'opacity-0', "h-[60px] self-stretch bg-lightslategray overflow-x-auto flex flex-row items-start justify-start")}>
        {currentZs0()?.map((key) => (
          <EndOfDayTabs id={`Z: ${getZContentWithMaxZ(key)}`}
                        key={key}
                        startTime={dayReports0() ? dayjs.unix(dayReports0()?.[getZContent(key)]?.from).format('HH:mm') : ""}
                        endTime={dayReports0() ? dayjs.unix(dayReports0()?.[getZContent(key)]?.to).format('HH:mm'): ""}
                        showSelected={selectedZ0() === key}
                        onClick={() => {
                          setSelectedZ0(key);
                        }} />
        ))}
      </div>
      <EndOfDayReportContainer isEmpty={false} />
    </div>
  );
};

export default EndOfDayRight;
