import TextField from "@/react/core/TextField.tsx";
import {setTerminal0, terminal0} from "@/react/PaymentSettingView/PaymentSettingView.tsx";

export default function CCVA920TerminalConfiguration() {
  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">SaleId</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.saleID || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            if (!prev.metadata) prev.metadata = {}
            prev.metadata.saleID = e.target.value;
            return prev;
          })}
        />
      </div>
      <p>Your unique ID for the system where you send this request from.</p>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">POIID</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.poiID || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            if (!prev.metadata) prev.metadata = {}
            prev.metadata.poiID = e.target.value;
            return prev;
          })}
        />
      </div>
      <p>The unique ID of the terminal that you send this request to. Format: [device model]-[serial number]. For
        example, P400‑123456789.</p>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">Host</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.host || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            if (!prev.metadata) prev.metadata = {}
            prev.metadata.host = e.target.value;
            return prev;
          })}
        />
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
        <div className="relative">Port</div>
        <TextField
          className="self-stretch"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          value={terminal0()?.metadata?.port || ""}
          onChange={e => setTerminal0(prev => {
            if (!prev) return prev;
            if (!prev.metadata) prev.metadata = {}
            prev.metadata.port = e.target.value;
            return prev;
          })}
        />
      </div>
    </>
  )
}