import { posSync0, posSyncLock } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { allConfig, remoteConfigLock } from '@/extensions/firebase/useFirebase.ts'
import { servers } from '@/lib/servers.ts'
import { effectOn } from '@/react/core/reactive.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'

let offlineLogEnabled = localStorage.getItem('enable_offline_log') !== 'disable'

effectOn([], async () => {
  await remoteConfigLock.acquireAsync()
  if (!allConfig['enable_offline_log']?.asBoolean()) {
    localStorage.setItem('enable_offline_log', 'disable')
    offlineLogEnabled = false
  } else {
    localStorage.setItem('enable_offline_log', 'enable')
    offlineLogEnabled = true
  }
})

effectOn([server, posSync0], async () => {
  await posSyncLock.acquireAsync()
  if (!offlineLogEnabled) return
  if (server() && !!posSync0()) {
    const serverName = server()
    const storeName = `n${posSync0().id}`
    await rnHost.configLog(
      servers[serverName!].surrealDbLog.url,
      getDeviceId(),
      storeName,
      servers[serverName!].surrealDbLog.username!,
      servers[serverName!].surrealDbLog.password!,
      serverName!,
      servers[serverName!].s3Log.region!,
      servers[serverName!].s3Log.accessKeyId!,
      servers[serverName!].s3Log.secretAccessKey,
      servers[serverName!].s3Log.bucketName
    )
  }
})

export const rnLog = (message: string) => {
  if (!offlineLogEnabled) return
  rnHost.rnLog(message)
}
