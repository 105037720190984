import { useEffect } from 'react'
import { map, Subscription, tap } from 'rxjs'
import { dataLock } from '@/data/DataUtils.ts'
import { signal } from '@/react/core/reactive'
import { TseExport } from "@/data/TseExport.ts";

export const [tseLinks0, setTseLinks0] = signal<Array<TseExport>>([])

export const makeTseExportLinksAvailable = () => {
  useEffect(() => {
    let sub = new Subscription()

    dataLock.acquireAsync().then(() => {
      sub.add(
        TseExport.find()
          .$.pipe(
          map(a => a.map(doc => doc.toMutableJSON())),
          tap(a => setTseLinks0(a))
        )
          .subscribe()
      )
    })

    return () => sub.unsubscribe()
  }, [])
}

// @ts-ignore
window.tseLinks0 = tseLinks0
