import dialogService from "@/react/SystemService/dialogService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { toast } from "react-toastify";
import { useSignal } from "@/react/core/reactive.ts";
import { validateEmail } from "@/react/Developer/developer.logic.ts";
import { tseLinks0 } from "@/data/TseExportHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { useState } from "react";
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import dayjs from "dayjs";

export const dialogShowLinks = async (): Promise<string> => {
  return new Promise((resolve) => {
    const showDialog = ({ onClose }) => {
      const wrappedOnClose = () => {
        resolve('');
        onClose?.();
      };

      const handleOpenUrl = async (url: string | undefined) => {
        if (!url) return;
        if (import.meta.env.MODE === "production" && getUrlParam("os")) {
          await rnHost.openUrl(url);
        } else {
          window.open(url, "_blank");
        }
      };

      return (
        <dialogService.BaseDialog title="" onClose={wrappedOnClose}>
          <div
            className="w-full relative rounded bg-white overflow-hidden flex flex-col items-start justify-start px-4 pb-3 box-border text-center text-sm font-mulish">
            <p>{LL0().dashboard.listUrl()}</p>
            <div className="max-h-80 overflow-y-auto space-y-2">
              {tseLinks0().length > 0 ? (
                tseLinks0().map((data, index) => (
                  <div key={index} className="p-2 border-b flex items-center justify-between gap-2">
                    <p className="text-black whitespace-nowrap">
                      ({dayjs.unix(data.from as number).format('DD-MM-YYYY')} → {dayjs.unix(data.to as number).format('DD-MM-YYYY')})
                    </p>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenUrl(data.url);
                      }}
                      href={data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline truncate"
                    >
                       {data.url}
                    </a>
                  </div>

                  // <div
                  //   key={index} className="p-2 border-b">
                  //   <a onClick={(e) => {
                  //     e.preventDefault();
                  //     handleOpenUrl(data.url);
                  //   }}
                  //      href={data.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                  //     •  {data.url}
                  //   </a>
                  // </div>
                ))
              ) : (
                <p className="text-gray-500">{LL0().dashboard.noUrl()}</p>
              )}
            </div>
          </div>
        </dialogService.BaseDialog>
      );
    };

    dialogService.show({ component: showDialog });
  });
};
