import { convertDocuments } from "@/data/data-utils";
import { SyncMode } from './data-enum'
import { DeviceSetting, type LayoutConfig, type PrinterMappingItem, type ScreenConfig } from '@/data/DeviceSetting';
import { getDeviceId, getUniqueDeviceId } from '@/shared/getDeviceId'
import { computed, effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import { now } from "@/pos/logic/time-provider.ts";
import dayjs from "dayjs";
import _ from "lodash";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { map, type Subscription, tap } from "rxjs";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { setUseNativeInput } from "@/react/core/NativeInput.ts";
import pTimeout from "p-timeout";
import { screenConfig } from "@/data/screenConfig.ts";
import { ip } from "@/data/IpState.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import { deviceSetting0, deviceSettingV, setDeviceSettings0, setDeviceSettingV } from "@/data/DeviceSettingSignal.ts";
import { isGermany } from "@/react/CompanyInformationView/companyInfomation.logic.ts";
import { frontendLanguage } from "@/data/language.ts";
import { MenuDirection } from "@/data/MenuDirection.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";
import { lockFlow } from "@/shared/logger.ts";
import { masterId } from "@/lib/master-signal.ts";
import { initSystemWebviewState } from "@/data/systemWebview.ts";

export const [syncMode0, setSyncMode0] = signal(/*deviceSetting0()?.syncMode ||*/ (() => {
  return localStorage.getItem('syncMode') as SyncMode || (!getUrlParam('os') ? 'online': 'mixed')
})());

effectOn([deviceSetting0], () => {
  if (deviceSetting0()?.syncMode && deviceSetting0()?.syncMode !== localStorage.getItem('syncMode')) {
    localStorage.setItem('syncMode', deviceSetting0()?.syncMode || SyncMode.mixed);
    if (deviceSetting0()?.syncMode !== syncMode0()) {
      setSyncMode0(deviceSetting0()?.syncMode || SyncMode.mixed);
    }
  }
})


// @ts-ignore
window.deviceSetting0 = deviceSetting0;

// @ts-ignore
window.masterDeviceSetting = masterDeviceSetting;

export const deviceSettingLock = new MultiAwaitLock(true)

// async function reloadIfChangeMaster() {
//   await posSettingLock.acquireAsync();
//   await deviceSettingLock.acquireAsync();
//   await delay(100);
//   let masterDevice = masterDeviceSetting();
//   effectOn([masterDeviceSetting, posSetting0], async () => {
//     if (!posSync0().id) return;
//     if (masterDeviceSetting()?._id && masterDevice?._id && masterDeviceSetting()?._id !== masterDevice?._id) {
//       await delay(1000);
//       // location.reload();
//     }
//   })
// }

// reloadIfChangeMaster().then();

export const getTimezoneMasterDevice = computed<string | undefined>(() => {
  if (masterDeviceSetting()?.timezone)
    return masterDeviceSetting()?.timezone;
  return dayjs.tz.guess();
});

// @ts-ignore
window.getTimezoneMasterDevice = getTimezoneMasterDevice;

export const callSystemLock = new MultiAwaitLock(true)

const isShowToolbar = computed(() => {
  return window.innerHeight > 410
})

export const isSmallSidebar = computed(()=> {
  return deviceSetting0()?.screenConfig?.smallSidebar
})

export const isVerticalMenu = computed(()=> {
  return deviceSetting0()?.screenConfig?.menuDirection === MenuDirection.VERTICAL
})

export const printerMapping0 = computed<PrinterMappingItem[]>(() => deviceSetting0()?.printerMapping ?? [])

let deviceSettings0Sub: Subscription | null = null

export const screenConfigDataBase: ScreenConfig = {
  itemCardHeight: 76,
  productFontSize: 14,
  textLineHeight: 17,
  oneLine: false,
  oneLineShorten: false,
  categoryHeight: 42,
  categoryWidth: 96,
  categoryFontSize: 14,
  categoryLineHeight: 18,
  hideTitleName: false,
  scrollableLayout: false,
  smallSidebar: false,
  menuDirection: MenuDirection.VERTICAL,
  kitchenFontSize: 14
}
export const layoutConfigDataBase: LayoutConfig = {
  enableScroll: false,
  enableZoom: false,
  partitionColumns: 4,
  partitionTableHeight: false,
}

effectOn([deviceSettingV, ip], async () => {
  await dataLock.acquireAsync();
  lockFlow('deviceSetting effectOn start');
  if (!localStorage.getItem('init-device-setting')) {
    let _id = getDeviceId()
    // if (Boolean(getUrlParam('os'))) {
    //   _id = await getUniqueDeviceId()
    // }
    localStorage.setItem('init-device-setting', 'true');
    await DeviceSetting.upsert({
      _id,
      isMaster: true,
      date: dayjs(now()).unix(),
      force: false,
      show: Boolean(getUrlParam('os')),
      isSupportDevice: !Boolean(getUrlParam('os')),
      //todo: create prop timezone use dayjs tz
      timezone: dayjs.tz.guess(),
      useVirtualKeyboard: !Boolean(getUrlParam('os')),
      showToolbar: isShowToolbar(),
      callSystem: {
        mode: 'none',
      },
      syncMode: SyncMode.mixed,
      screenConfig: screenConfigDataBase,
    });
  }

  deviceSettings0Sub?.unsubscribe()
  deviceSettings0Sub = DeviceSetting.find().$.pipe(
    map(a => convertDocuments(a, true, ['date'])),
    tap(async a => {
      try {
        lockFlow('deviceSetting tap');
        setDeviceSettings0(a);

        if (['56k-modem', 'artech'].includes(deviceSetting0()?.callSystem?.mode || '')) {
          callSystemLock.release().then();
        }

        if (deviceSetting0()?.useVirtualKeyboard !== undefined) {
          setUseNativeInput(!!deviceSetting0()?.useVirtualKeyboard);
        }

        if (!deviceSetting0()?.publicIp) {
          fetch('https://ipinfo.io/json')
            .then(response => response.json())
            .then(data => {
              _.assign(deviceSetting0(), { publicIp: data.ip })
            })
        }


        if (!deviceSetting0()?.ip && ip() !== 'localhost') {
          _.assign(deviceSetting0(), { ip: ip() })
        }

        if (!deviceSetting0()?.name) {
          try {
            const deviceName = await pTimeout(rnHost.sendDeviceName(), { milliseconds: 1000 });
            _.assign(deviceSetting0(), { name: deviceName })
          } catch (e) {
            _.assign(deviceSetting0(), { name: 'Device' })
          }
        }

        if (deviceSetting0()?._id === masterId() && deviceSetting0()?.timezone !== dayjs.tz.guess() && !deviceSetting0()?.forceTimezone) {
          _.assign(deviceSetting0(), { timezone: dayjs.tz.guess() })
        }

        if (deviceSetting0()?.autoOpenCashDrawer === undefined) {
          _.assign(deviceSetting0(), { autoOpenCashDrawer: true })
        }
        if (!deviceSetting0()?.screenConfig) {
          _.assign(deviceSetting0(), { screenConfig: screenConfigDataBase })
        } else if (!deviceSetting0()?.screenConfig?.kitchenFontSize) {
          _.assign(deviceSetting0()?.screenConfig, { kitchenFontSize: screenConfigDataBase.kitchenFontSize });
        }
          // init data for vertical partition mode
        if (!deviceSetting0()?.layoutConfig) {
          _.assign(deviceSetting0(), {layoutConfig: layoutConfigDataBase})
        }
        if (isGermany()) {
          if (frontendLanguage() === 'de') return;
          _.assign(deviceSetting0(), { frontendLanguage: 'de' })
        }

        if (deviceSetting0()?.updatedAt?.toString() !== localStorage.getItem('init-device-setting')) {
          localStorage.setItem('send-device-id', deviceSetting0()?.updatedAt?.toString()!)
          rnHost.sendDeviceId(deviceSetting0()?._id!);
        }

        if (deviceSetting0()?.secondDisplayType) {
          const previous = localStorage.getItem('open-second-display');
          const updatedAt = deviceSetting0()!.updatedAt?.toString();
          const appVersion = import.meta.env.VITE_APP_VERSION;

          const [previousUpdatedAt = '', previousAppVersion = ''] = _.split(previous, '/');

          if (deviceSetting0()!.updatedAt?.toString() !== previousUpdatedAt || appVersion !== previousAppVersion) {
            localStorage.setItem('open-second-display', updatedAt + '/' + appVersion);
            rnHost.openSecondDisplay(deviceSetting0()!.secondDisplayType!);
          }
        }
        if (deviceSetting0()?.useSystemWebview == null){
          initSystemWebviewState(deviceSetting0()).then()
        }

        lockFlow('deviceSetting tap finish');
      } catch (e) {
        console.error(e);
      } finally {
        lockFlow('deviceSettingLock release');
        deviceSettingLock.release().then();
      }
    }),
  ).subscribe()

}/*, {defer: true}*/);

effectOn([deviceSettingV],()=> {
  screenConfig()
})

export const makeDeviceSettingsAvailable = async () => {
  if (deviceSettingV() === 0) {
    await dataLock.acquireAsync();
    DeviceSetting.$.subscribe(_.throttle((change) => {
      setDeviceSettingV(v => v + 1);
    }, 100, { leading: true, trailing: true }))
    setDeviceSettingV(1);
    const ds = DeviceSetting.find().$$;

    //@ts-ignore
    window.ds = ds;
  }
};

makeDeviceSettingsAvailable().then();
