import type {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxCollection,
  RxConflictHandler,
  RxConflictHandlerInput,
  RxConflictHandlerOutput,
  RxDatabase,
  RxDocument,
  RxJsonSchema
} from 'rxdb'
import { deepEqual } from 'rxdb/plugins/utils'
import { createCollection } from "@/data/utils/migration.ts";

export const MAX_ID_COLLECTION_NAME = 'max_ids'

export const VERSION = 11
const maxIdSchema = {
  title: 'maxId schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    eodId: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    z: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    orderId: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    transactionId: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    lastEodDate: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    inventoryId: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    customerId: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    lastEodCompleted: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 },
    ticketNumber: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ^ 10 }
  },
  indexes: [],
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export interface MaxId extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof maxIdSchema> {}
export type MaxIdDocument = RxDocument<MaxId>
export type MaxIdCollection = RxCollection<MaxId>

export const MaxIdCollection: MaxIdCollection = {} as MaxIdCollection
export const MaxId = MaxIdCollection

//@ts-ignore
window.MaxId = MaxId

const customConflictHandler: RxConflictHandler<any> = function (i: RxConflictHandlerInput<MaxId>): Promise<RxConflictHandlerOutput<any>> {
  //prevent overwrite to cloud if exists
  if (i.newDocumentState?.z === 0 && i.realMasterState?.z !== 0) {
    console.log('prevent overwrite MaxId to cloud if exists')
    return Promise.resolve({ isEqual: false, documentData: i.realMasterState })
  }

  if (deepEqual(i.newDocumentState, i.realMasterState)) {
    return Promise.resolve({ isEqual: true })
  }

  return Promise.resolve({ isEqual: false, documentData: i.realMasterState })
}

export const createMaxIdCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: MaxId,
    collectionName: MAX_ID_COLLECTION_NAME,
    version: VERSION,
    schema: maxIdSchema,
  });
}
