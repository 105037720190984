import dayjs from "dayjs";
import { formatCurrencyDe } from "@/shared/utils.ts";
import { getUST_SCHLUESSEL } from "@/tse/dsfinv/dsfinvModel.ts";
import type { Order } from "@/data/Order.ts";
import type { ItemModifier, OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { calNet, calTax } from "@/pos/orderUtils.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bonpos_Zusatzinfo = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  POS_ZEILE: {
    type: "string",
    maxLength: 50,
    hint: "Zeilennummer"
  },
  ZI_ART_NR: {
    type: "string",
    maxLength: 50,
    hint: "Artikelnummer"
  },
  ZI_GTIN: {
    type: "string",
    maxLength: 50,
    hint: "GTIN"
  },
  ZI_NAME: {
    type: "string",
    maxLength: 50,
    hint: "Artikelbezeichnung"
  },
  ZI_WARENGR_ID: {
    type: "string",
    maxLength: 40,
    hint: "Warengruppen-ID"
  },
  ZI_WARENGR: {
    type: "string",
    maxLength: 50,
    hint: "Bezeichnung Warengruppe"
  },
  ZI_MENGE: {
    type: "number",
    places: 3,
    hint: "Menge"
  },
  ZI_FAKTOR: {
    type: "number",
    places: 3,
    hint: "Faktor, z. B. Gebindegrößen"
  },
  ZI_EINHEIT: {
    type: "string",
    maxLength: 50,
    hint: "Maßeinheit, z. B. kg, Liter oder Stück"
  },
  ZI_UST_SCHLUESSEL: {
    type: "number",
    places: 0,
    hint: "ID USt-Satz des Basispreises"
  },
  ZI_BASISPREIS_BRUTTO: {
    type: "number",
    places: 5,
    hint: "Basispreis brutto"
  },
  ZI_BASISPREIS_NETTO: {
    type: "number",
    places: 5,
    hint: "Basispreis netto"
  },
  ZI_BASISPREIS_UST: {
    type: "number",
    places: 5,
    hint: "Basispreis USt"
  }
}

export function Bonpos_ZusatzinfoFactory(order: Order, item: OrderItem, modifier: ItemModifier, eod: Eod) {
  return build();
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      ZI_ART_NR: ZI_ART_NR(),
      ZI_GTIN: ZI_GTIN(),
      ZI_NAME: ZI_NAME(),
      ZI_WARENGR_ID: ZI_WARENGR_ID(),
      ZI_WARENGR: ZI_WARENGR(),
      ZI_MENGE: ZI_MENGE(),
      ZI_FAKTOR: ZI_FAKTOR(),
      ZI_EINHEIT: ZI_EINHEIT(),
      ZI_UST_SCHLUESSEL: ZI_UST_SCHLUESSEL(),
      ZI_BASISPREIS_BRUTTO: ZI_BASISPREIS_BRUTTO(),
      ZI_BASISPREIS_NETTO: ZI_BASISPREIS_NETTO(),
      ZI_BASISPREIS_UST: ZI_BASISPREIS_UST(),
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    // hint: "Zeilennummer"
    return item.modifiers.indexOf(modifier) + 1;
  }

  function ZI_ART_NR() {
    // hint: "Artikelnummer"
    return '';
  }

  function ZI_GTIN() {
    // hint: "GTIN"
    return '';
  }

  function ZI_NAME() {
    // hint: "Artikelbezeichnung"
    return modifier?.name;
  }

  function ZI_WARENGR_ID() {
    // hint: "Warengruppen-ID"
    return '';
  }

  function ZI_WARENGR() {
    // hint: "Bezeichnung Warengruppe"
    return '';
  }

  function ZI_MENGE() {
    // hint: "Menge"
    return modifier?.quantity * item?.quantity;
  }

  function ZI_FAKTOR() {
    // hint: "Faktor, z. B. Gebindegrößen"
    return '';
  }

  function ZI_EINHEIT() {
    // hint: "Maßeinheit, z. B. kg, Liter oder Stück"
    return '';
  }

  function ZI_UST_SCHLUESSEL() {
    // hint: "ID USt-Satz des Basispreises"
    return getUST_SCHLUESSEL(item?.tax);
  }

  function ZI_BASISPREIS_BRUTTO() {
    // hint: "Basispreis brutto"
    return formatCurrencyDe(modifier?.vPrice!);
  }

  function ZI_BASISPREIS_NETTO() {
    // hint: "Basispreis netto"
    return formatCurrencyDe(calNet(modifier?.price, item?.tax!));
  }

  function ZI_BASISPREIS_UST() {
    // hint: "Basispreis USt"
    return formatCurrencyDe(calTax(modifier?.price, item?.tax!));
  }

}
