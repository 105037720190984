// This is just a temporary file, will be removed

import { captureException } from '@sentry/react'
import axios from 'axios'
import debug from 'debug'

import { posSync0, posSyncLockReady } from '@/data/PosSyncState.ts'
import { effectOn } from '@/react/core/reactive.ts'
import { setReplicateTokenForDb } from '@/react/Developer/developer.logic.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import { surrealLock } from '@/shared/SurrealClient.ts'
import { getApiUrl } from '@/shared/utils.ts'

const log = debug('data:migrateSurreal')

let startedMigration = !!localStorage.getItem('noNeedToRunSurrealMigration')

effectOn([posSync0], async () => {
  await posSyncLockReady.acquireAsync()
  if (!!localStorage.getItem('noNeedToRunSurrealMigration')) {
    surrealLock.release().then()
    return;
  }
  if (startedMigration) return
  startedMigration = true
  if (!posSync0()?.id) {
    localStorage.setItem('noNeedToRunSurrealMigration', '1')
    return
  }
  log('Migrating to token');
  try {
    const response = await axios.post(`${getApiUrl()}/api/authDevice`, {
      mode: 'migration',
      storeId: posSync0()?.id,
      deviceId: getDeviceId(),
    })
    await setReplicateTokenForDb('cloud', response.data.token)
    await setReplicateTokenForDb('refreshToken', response.data.refreshToken)
    const dbTokenRes = await axios.post(`${getApiUrl()}/api/getDbToken`, {
      refreshToken: response.data.refreshToken,
      storeId: posSync0()?.id,
      deviceId: getDeviceId(),
    })
    const token = dbTokenRes.data?.token
    await setReplicateTokenForDb(`n${posSync0()?.id}`, token)
    localStorage.setItem('noNeedToRunSurrealMigration', '1')
    surrealLock.release().then()
  } catch (e) {
    log(`Error updating surreal ${JSON.stringify(e)}`)
    captureException(e)
  }
})
