import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import _ from "lodash";
import { TseType } from "@/tse/tse-utils.ts";
import TextField from "@/react/core/TextField.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { initializeFirstTimeTse } from "@/tse/tse-communicate.ts";
import uuid from "time-uuid";
import { tseCertificates0 } from "@/data/TseCertificateHub.ts";
import React from "react";
import { deregisterTSE } from "@/react/Developer/Developer.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

const DeveloperTSETab = ()=> {
  return (
    <div className="w-full flex flex-col gap-2 p-4">
      <FormControl
        className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
        variant="outlined"
      >
        <InputLabel color="primary" />
        <Select
          color="primary"
          size="small"
          value={tseConfig0()?.type || ''}
          onChange={e => {
            _.assign(tseConfig0(), { type: e.target.value })
          }}
        >
          {Object.values(TseType).map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              { [TseType.TseServer, TseType.TseLocal].includes(value) ? value : `${value} ${LL0().settings.onlyForTest()}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText />
      </FormControl>
      {[TseType.TseServer, TseType.TseServerTestGermany, TseType.TseServerMock, TseType.TseServerTest].includes(tseConfig0()?.type as any) && (
        <>
          <div className={'flex flex-col gap-1'}>
            <p className="text-black text-[14px]">User name</p>
            <TextField
              type={'string'}
              value={tseConfig0()?.username}
            />
          </div>
          <div className={'flex flex-col gap-1'}>
            <p className="text-black text-[14px]">Password</p>
            <TextField
              type={'string'}
              value={tseConfig0()?.password}
            />
          </div>
        </>
      )}
      {!tseConfig0()?.initialized && (
        <div className={'flex flex-row gap-2'}>
          <button
            ref={makeRipple}
            className="w-[120px] h-10 rounded bg-blue-500 text-white px-2 py-1 text-sm"
            onClick={() => initializeFirstTimeTse()}
          >
            Initialize
          </button>
          {[TseType.TseServer, TseType.TseServerTestGermany, TseType.TseServerMock, TseType.TseServerTest].includes(tseConfig0()?.type as any) && (
            <button
              className="h-10 rounded bg-blue-500 text-white px-2 py-1 text-sm"
              onClick={() => {
                const _uuid = uuid()
                _.assign(tseConfig0(), { username: _uuid, password: uuid() })
              }}
            >
              Generate new tse client
            </button>
          )}
        </div>
      )}

      {tseConfig0()?.initialized && (
        <>
          <div className={'space-x-[10px]'}>
            <span className={'text-red-500 text-[18px] mt-2'}>Initialized</span>
            <button
              className="w-[120px] h-10 rounded bg-blue-500 text-white px-2 py-1 text-sm"
              onClick={deregisterTSE}
            >
              Deregister
            </button>
          </div>
          <div className={'w-full break-words'}>TsePublicKey: {tseConfig0()?.tsePublicKey}</div>

          <div className={'w-full break-words'}>signatureAlgorithm: {tseConfig0()?.signatureAlgorithm}</div>

          <div className={'w-full break-words'}>serialNumber: {tseConfig0()?.serialNumber}</div>

          <div className={'w-full break-words'}>certificateExpirationDate: {tseConfig0()?.certificateExpirationDate}</div>

          <div className={'w-full break-words'}>tseTimeFormat: {tseConfig0()?.tseTimeFormat}</div>

          <div className={'w-full break-words'}>pdEncoding: {tseConfig0()?.pdEncoding}</div>

          <div className={'h-[200px] overflow-scroll'}>
            Certificate:
            {tseCertificates0()?.[0]?.certificates?.map((certificate, index) => (
              <div
                key={index}
                className={'w-full break-words'}
              >
                {certificate}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default DeveloperTSETab