import dialogService from "@/react/SystemService/dialogService.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export const dialogNotiMatching = async (title: string) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className="w-full relative rounded bg-white overflow-hidden flex flex-col items-center justify-center box-border gap-[24px] min-w-[280px] pb-4 h-full max-w-full max-h-full text-center text-xl text-black font-mulish"
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">
            {title}
          </b>
        </div>
        <div className="w-full flex flex-row items-end justify-end text-sm text-white">
          <div
            className="flex-1 rounded-md bg-blue-500 box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-500"
            onClick={() => {
              onClose();
            }}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>

    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
