import type { Order } from '@/data/Order';
import _ from 'lodash';
import dayjs from 'dayjs';
import { CommitAction, InvoiceTypes } from '@/pos/OrderType';
import { getInvoicePrinter } from '@/react/Printer/print-utils.ts';
import uuid from 'time-uuid';
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { onPrintInvoiceComplete } from "@/react/PaymentView/PaymentView.tsx";
import { extractQr } from "@/tse/makeProcessDataLib.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { isValidSrmUser } from '@/data/UserSignal.computed';
import { toast } from 'react-toastify';
import { openCashDrawer } from "@/pos/cashdrawer.ts";
import { captureException } from "@sentry/react";
import { srmTransactionLogic } from '@/srm/transaction.logic';
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { isQuebecSrmEnabled, printOverMaster0 } from "@/data/PosSettingsSignal.ts";
import { setCanGoBack } from '../OrderView/OrderView'
import { PrintScripts } from '@/data/PrintScripts'
import type { PrinterAddress, ScriptedRaster } from '@/shared/printer/types'
import { invoiceFLow, userFLow } from "@/shared/logger.ts";
import { deviceSetting0 } from '@/data/DeviceSettingSignal'
import { loginUser } from '@/data/UserSignal';
import { LL0 } from "@/react/core/I18nService.tsx";
import { printInvoice_new } from '@/react/PaymentView/PrintStack.ts';
import {
  checkMasterAvailable,
  checkMasterAvailableInternal,
  MasterAvailableHandler
} from "@/data/utils/master-available.ts";


export async function handlePrintInvoice(order: Order, invoiceType: InvoiceTypes = InvoiceTypes.INVOICE) {
  if (checkMasterAvailable()) return;
  if(isQuebecSrmEnabled() && !isValidSrmUser())
    return toast.error(LL0().srm.errors.unauthorized())

  if (printOverMaster0()) {
    await onPrintInvoiceComplete({ invoiceType })
  } else {
    await printInvoice(order, invoiceType)
  }
}

export async function printInvoice(order: Order, invoiceType: InvoiceTypes = InvoiceTypes.INVOICE, parentOrder?: Order) {
  userFLow("printInvoice red bill Order History", {
    username: loginUser()?.name
  })
  invoiceFLow('print Invoice', {orderId: order._id});
  if (isQuebecSrmEnabled() && !isValidSrmUser()) return toast.error(LL0().srm.errors.unauthorized())

    //todo: save in virtual printer
  if (!order._id) return;
  // order.items = order.items.filter(item => item?.quantity !== 0);

  if (isQuebecSrmEnabled()) {
    switch (invoiceType) {
      case InvoiceTypes.GUEST_CHECK:
        order.commits?.push({ action: CommitAction.PRINT_UNPAID_BILL })
        if (await srmTransactionLogic.shouldPrintReproduce(order)) srmTransactionLogic.reproduceBill(order).then()
        else srmTransactionLogic.recordTemporaryBill(order, { print: true, parentOrder }).then()
        setCanGoBack(false) // Prevent user to cancel, so the srm_transaction can be saved
        return
      case InvoiceTypes.INVOICE:
        srmTransactionLogic.recordClosingReceipt(order, { print: true }).then()
        order.printInvoice = true
        return
    }
  }

  await printInvoice_new(order, invoiceType);
}

interface PrintInvoiceOptions {
  /** Skip open cash drawer */
  skipOpenCashDrawer?: boolean
  printer?: PrinterAddress
  metadata?: {
    orderId?: string
    invoiceType?: InvoiceTypes
    /** Other options will be saved as metadata */
    [key:string]: unknown
  }
}

/**
 * Print an invoice  save data to PrintImage using raster.
 * 
 * Will also open cash drawer, unless `skipOpenCashDrawer` is set to true.
 */
export const printInvoiceFromRaster = async (raster: ScriptedRaster, opt: PrintInvoiceOptions = {}): Promise<void> => {
  const { skipOpenCashDrawer, printer, metadata } = opt
  const invoicePrinter = printer || getInvoicePrinter();
  if (!invoicePrinter) return;
  if (!raster) return;
  const imagePrinter = new VPrinter(invoicePrinter);
  await imagePrinter.printRaster(raster);

  if (!skipOpenCashDrawer && deviceSetting0()?.autoOpenCashDrawer) {
    try {
      if (invoicePrinter.printerType !== 'integrate') {
        await imagePrinter.openCashDrawer();
      } else {
        await openCashDrawer();
      }
    } catch (e) {
      captureException(e);
    }
  }
  await imagePrinter.print({ cut: true, printRaster: false });

    // Save printed image as scripts
    setTimeout(async () => {
      await PrintScripts.insert({
        _id: uuid(),
        date: dayjs().unix(),
        scripts: _.cloneDeep(raster.scripts),
        metadata: _.cloneDeep(metadata)
      })
    }, 500)
}

//them dau vao -> if else -> in theo dau vao
//cat ra thanh 3 ham, truyen dau vao

//kien truc phan mem
//class
//factory

interface PrintQrCodeFactory {
  printTseHeader: () => Promise<void>
  printTseQr: () => Promise<void>
  printSignature: () => Promise<void>
  printSerialNumber: (printer: VPrinter) => Promise<void>
  printTimeFormat: (printer: VPrinter) => Promise<void>
  printSignatureAlgorithm: (printer: VPrinter) => Promise<void>
  printPublicKey: (printer: VPrinter) => Promise<void>
  printUserName: (printer: VPrinter) => Promise<void>
  getRaster: () => Promise<ScriptedRaster>
  printFull: () => Promise<void>
}

//todo: move to tse folder
export function printQrCode(printer: VPrinter, order: Order): PrintQrCodeFactory {
  const qrData = order && order.qrCode ? extractQr(order.qrCode!): undefined;
  // await printTseHeader();
  // await printSerialNumber(printer);
  // await printSignature();
  // await printTimeFormat(printer);
  // await printSignatureAlgorithm(printer);
  // await printPublicKey(printer);
  // await printUserName(printer);
  // await printTseQr();

  return {
    printTseHeader,
    printTseQr,
    printSignature,
    printSerialNumber,
    printTimeFormat,
    printSignatureAlgorithm,
    printPublicKey,
    printUserName,
    getRaster,
    printFull
  };

  async function printFull() {
    await printTseHeader();
    await printSerialNumber(printer);
    await printSignature();
    await printTimeFormat(printer);
    await printSignatureAlgorithm(printer);
    await printPublicKey(printer);
    await printUserName(printer);
    await printTseQr();
  }

  async function getRaster() {
    const raster = await printer.getRaster();
    return raster
  }

  async function printTseQr() {
    if (tseConfig0().hideTseQrCode) return;
    if (!qrData) return;
    await printer.newLine(8);
    await printer.alignCenter();
    await printer.printQrCode(order.qrCode!, 0.5);
  }

  async function printSignature() {
    if (!qrData) return;
    await print2Cols(printer, 'TSE-Signature:', qrData.signature);
  }

  async function printTseHeader() {
    if (!qrData) return;
    const firstOrderItemDate = _.orderBy(order.items, ['date'])[0].date;
    await print2Cols(printer, 'TSE-Transaktion:', qrData.transactionNumber);
    await print2Cols(printer, 'Erstbestellung:', dayjs.unix(firstOrderItemDate!).format(LL2().dates.fullTime()));
    await print2Cols(printer, 'TSE-Start:', qrData.startTime);
    await print2Cols(printer, 'TSE-Stop:', qrData.logTime);
  }
}



async function print2Cols(printer: VPrinter, text: string, text2: string) {
  await printer.tableCustom([
    { text, align: 'LEFT', width: 0.45 },
    { text: text2, align: 'LEFT', width: 0.55 },
  ]);
}

async function printSerialNumber(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Seriennummer:', tseConfig0().serialNumber!);
}

async function printTimeFormat(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Zeitformat:', tseConfig0().tseTimeFormat!);
}

async function printSignatureAlgorithm(printer: VPrinter) {
  await print2Cols(printer, 'TSE-Hashalgorithmus:', tseConfig0().signatureAlgorithm!);
}

async function printPublicKey(printer: VPrinter) {
  await print2Cols(printer, 'TSE-PublicKey:', tseConfig0().tsePublicKey!);
}

async function printUserName(printer: VPrinter) {
  await print2Cols(printer, 'KassenID:', tseConfig0().username!);
}

export function printQrCodeFactory() {
  const printer = new VPrinter(getInvoicePrinter());

  return {
    printSerialNumber: async () => {
      await printSerialNumber(printer);
      return await printer.getRaster();
    },
    printTimeFormat: async () => {
      await printTimeFormat(printer);
      return await printer.getRaster();
    },
    printSignatureAlgorithm: async () => {
      await printSignatureAlgorithm(printer);
      return await printer.getRaster();
    },
    printPublicKey: async () => {
      await printPublicKey(printer);
      return await printer.getRaster();
    },
    printUserName: async () => {
      await printUserName(printer);
      return await printer.getRaster();
    },
  }
}