import { renderPivotTable } from "@/pos/logic/pivot.js";
import _ from "lodash";

import {
  fromIdReducer,
  fromReducer,
  originalPriceReducer,
  quantityReducer,
  soldOrderCntReducer, toIdReducer,
  toReducer,
  vTaxSumReducer,
  vTaxSumGroupReducer, totalExcludeCashbackReducer, totalOtherFeeReducer
} from './report-shared.ts';
import type { Order } from "@/data/Order.ts";
import { categories0 } from "@/data/CategoryHub.ts";
import { type OrderItem, type OrderPayment, OrderStatus, Reason, type VTaxComponents } from '@/pos/OrderType.ts'
import dayjs from "dayjs";
import { cashPaymentName0, debitorPayment0 } from "@/data/PaymentHub.ts";
import { taxCategories0 } from "@/data/TaxCategoryHub.ts";
import { PaymentType } from "@/data/Payment.ts"
import type { TaxReport } from "@/pos/logic/ReportType.ts";
import { orderConfig } from "@/shared/order/order-config.ts";


interface PaymentReport extends OrderPayment {
  otherFee: number;
  excludeTipValue?: number,
  tip?: number,
  user: string,
  cashback?: number,
  excludeCashback?: number,
}

function getExcludeTipAndCashbackValue(order: Order, p: OrderPayment, index: number) {
  const paymentLength = order.payments?.length;
  const otherFees = (order.donation || 0) + (order.shippingData?.tip || 0) + (order.shippingData?.serviceFee || 0) + (order.shippingData?.fee || 0) + (order.vExtraTax || 0)
  if (paymentLength === 1) return {
    tip: order.tip || 0,
    excludeValue: p.value - (order.tip || 0) - (order.cashback || 0),
    cashback: order.cashback || 0,
    otherFee: otherFees,
  };

  const isCashPayment = (p: OrderPayment) => p.type === cashPaymentName0() || p.extraType === PaymentType.Cash || ['cash', 'bar'].includes(_.lowerCase(p.type)) || _.lowerCase(p.type).includes('cash') || _.lowerCase(p.type).includes('bar');
  const isFirstCashPayment = order.payments.findIndex(p => isCashPayment(p));
  const isFirstNonCashPayment = order.payments.findIndex(p => !isCashPayment(p));

  if (isCashPayment(p) && index === isFirstCashPayment) return {
    tip: order.cashTip ? order.cashTip : 0,
    excludeValue: p.value - (order.cashTip || 0) - (order.cashback || 0),
    cashback: order.cashback || 0,
    otherFee: index === 0 ? otherFees : 0,
  };

  if (!order.cashTip && !isCashPayment(p) && index === isFirstNonCashPayment) {
    const cashback  = isFirstCashPayment === -1 ? (order.cashback || 0) : 0;
    return {
      tip: order.tip || 0,
      excludeValue: p.value - (order.tip || 0) - cashback,
      cashback: cashback,
      otherFee: index === 0 ? otherFees : 0,
    }
  }
  return {
    tip: 0,
    excludeValue: p.value,
    cashback: 0,
    otherFee: 0
  };
}

async function getCommonReportData(orders: Order[]) {
  const paidOrders = orders.filter(o => {
    //chi lay order da thanh toan
    if ([OrderStatus.PAID, OrderStatus.REFUNDED, OrderStatus.DEBITOR].includes(o.status)) return true;
    if ([OrderStatus.CANCELLATION_REFERENCE].includes(o.status) && o.reason === Reason.REFUND) return true;
    return false;
    // return [OrderStatus.PAID, OrderStatus.REFUNDED, OrderStatus.CANCELLATION_REFERENCE, OrderStatus.CANCELLED].includes(o.status);
  });
  const refundedOrders = orders.filter(o => o.status === OrderStatus.CANCELLATION_REFERENCE && o.reason === Reason.REFUND || o.status === OrderStatus.REFUNDED && o.reason);
  //todo: cache category
  const items = _.reduce<Order, OrderItem[]>(
    paidOrders,
    (items, order) => {
      return items.concat(
        order.items.map(i => Object.assign(i, { orderDate: order.date }))
      );
    },
    []
  );
  const itemsQuantity = items.length;
  
  const separatedItemsByCategory = _.reduce<OrderItem, OrderItem[]>(items, (items, item) => {
    if (!item.categories || item.categories.length === 0) return items.concat([{
      ..._.omit(item, 'categories'),
      category: 'Other',
      name: item.name?.replace(/\./g, '?')
    }])
    return items.concat((item.categories || []).map(categoryId => ({
      ..._.omit(item, 'categories'),
      // category: (_.find(categories0(), c => c._id!.toString() === categoryId.toString()) || { name: 'Other' }).name
      category: categories0().find(c=> c._id.toString() === categoryId.toString())?.name ?? 'Other',
      name: item.name?.replace(/\./g, '?')
    })))
  }, [])
  let groupItemsByCategory = renderPivotTable(
    {
      rows: ['name'],
      columns: ['category'],
      reducers: ['@sum[2]:vSum', '@sum:quantity', originalPriceReducer]
    },
    separatedItemsByCategory
  );
  let sumByCategory = renderPivotTable(
    {
      rows: ['category'],
      //columns: ["@date[DD.MM.YYYY]:orderDate"],
      reducers: ['@sum[2]:vSum']
    },
    separatedItemsByCategory
  );

  const payments = paidOrders.reduce((r: Array<PaymentReport>, order) => {
    return r.concat(...order.payments?.map((p, index) => {
      const value = getExcludeTipAndCashbackValue(order, p, index);
      return {
        ...p,
        excludeTipValue: value.excludeValue,
        tip: value.tip || 0, //todo: consider case multi payment
        user: _.get(order, 'users.0', ''),
        cashback: value.cashback || 0,
        excludeCashback: value.excludeValue + (value.tip || 0),
        otherFee: value.otherFee || 0
      }
    }));
  }, [])
  const sumByPayment = renderPivotTable(
    {
      rows: ['type'],
      reducers: ['@sum[2]:value']
    },
    payments
  )

  const otherFeeByPayment = renderPivotTable(
    {
      rows: ['type'],
      reducers: ['@sum[2]:otherFee']
    },
    payments
  )
  //todo: remove sumByPayment (breaking change, need more tests)
  const paymentReport = renderPivotTable(
    {
      rows: ['type'],
      reducers: [
        {
          fn: (total: number, i: PaymentReport) => total + i.value,
          label: 'total',
          initValue: 0
        },
        {
          fn: (total: number, i: PaymentReport) => total + (i.excludeTipValue || 0),
          label: 'excludeTipValue',
          initValue: 0
        },
        {
          fn: (cnt: number, i: PaymentReport) => cnt + 1,
          label: 'orderCnt',
          initValue: 0,
        },
        {
          fn: (totalTip: number, i: PaymentReport) => totalTip + (i.tip || 0),
          label: 'tip',
          initValue: 0
        },
        {
          fn: (totalCashback: number, i: PaymentReport) => totalCashback + (i.cashback || 0),
          label: 'cashback',
          initValue: 0
        },
        {
          fn: (total: number, i: PaymentReport) => total + (i.excludeCashback || 0),
          label: 'excludeCashback',
          initValue: 0
        },
        {
          fn: (totalOtherFee: number, i: PaymentReport) => totalOtherFee + (i.otherFee || 0),
          label: 'otherFee',
          initValue: 0
        }
      ]
    },
    payments
  )
  const totalDiscount = renderPivotTable({
    reducers: ['@sum[2]:vDiscount']
  }, paidOrders)

  const discountedOrders = orders.filter(i => (i.vDiscount || 0) > 0).map(i => ({
    ..._.pick(i, ['id', 'table', 'vDiscount', 'date']),
    originalVSum: _.sumBy(i.items, item => ((item.price! || item.vPrice!) + _.sumBy(item.modifiers, m => (m.price! || m.vPrice!) * m.quantity)) * item.quantity),
    user: _.get(i, 'users.0'),
  }))

  const totalDiscountByLabel = items.filter(i => (i.vDiscount || 0) > 0).reduce<{ [p: string]: number }>((rs, item) => {
    const discountLabel = _.get(item, 'discountLabel', 'Other') || 'Other';
    if (!rs[discountLabel]) rs[discountLabel] = 0
    rs[discountLabel] += item.vDiscount!
    return rs
  }, {})

  const totalServiceFee = renderPivotTable({ reducers: ['@sum[2]:serviceFee'] }, paidOrders) || 0
  const totalTip = renderPivotTable(
    {
      reducers: ['@sum[2]:tip']
    },
    paidOrders) || 0
  const cashTip = renderPivotTable(
    {
      reducers: ['@sum[2]:cashTip']
    },
    paidOrders) || 0
  const totalCashback = renderPivotTable({
    reducers: ['@sum[2]:cashback']
  }, paidOrders) || 0
  const totalRefund = renderPivotTable({
    reducers: ['@sum[2]:vSum']
  }, refundedOrders) || 0

  const totalShipping = renderPivotTable({
    reducers: ['@objSum[2]:shippingData']
  }, paidOrders) || 0

  const totalDonation = renderPivotTable({
    reducers: ['@sum[2]:donation']
  }, paidOrders) || 0

  const totalExtraTax = renderPivotTable({
    reducers: ['@sum[2]:vExtraTax']
  }, paidOrders) || 0


  const cashKeys = Object.keys(sumByPayment || {})?.filter(p => p === cashPaymentName0() || ['cash', 'bar', 'tiền mặt'].includes(p.toLowerCase()) || p.toLowerCase().includes('cash') || p.toLowerCase().includes('bar'));


  // const totalCashSalesExcludingCashback = (_.sum(_.map(sumByPayment || {}, cashKeys)) || 0) - totalCashback - cashTip - (_.sum(_.map(otherFeeByPayment || {}, cashKeys)) || 0);
  const totalCashSalesExcludingCashback = _.sum(
    cashKeys.map(key =>
      (sumByPayment?.[key] || 0) - (otherFeeByPayment?.[key] || 0)
    )
  ) - totalCashback - cashTip || 0;
  const totalCashlessSales = _.sum(_.map(_.omit(sumByPayment, [...cashKeys, debitorPayment0()]))) || 0
  const totalOtherCashlessFee = _.sum(_.map(_.omit(otherFeeByPayment, [...cashKeys, debitorPayment0()]))) || 0
  const totalDebitorAmount = sumByPayment[debitorPayment0()] || 0
  // fixme: add total debitor
  const cardTip = totalTip - cashTip
  const totalCashlessSalesExcludingTip = totalCashlessSales - cardTip - totalOtherCashlessFee
  const report = renderPivotTable({
    reducers: [
      '@sum[2]:vSum',
      '@sum[2]:vDiscount',
      vTaxSumReducer,
      quantityReducer,
      // fromReducer,
      // toReducer,
      fromIdReducer,
      toIdReducer,
      '@objSum[2]:vTaxComponents',
      '@sum[2]:vSubTotal'
    ]
  }, paidOrders) || {
    vSum: 0,
    vDiscount: 0,
    vTaxSum: {}
  };

  const reportFromTo = renderPivotTable({ reducers: [fromReducer, toReducer] }, orders);
  _.assign(report, reportFromTo);

  const voucherItems = paidOrders.reduce<OrderItem[]>((list, order) => {
    list.push(...order.items.filter(i => i.isVoucher));
    return list;
  }, []);

  const soldVouchers = voucherItems.filter(i => i.price >= 0)
  const redeemedVouchers = voucherItems.filter(i => i.price < 0)

  const voucherReport = {
    soldVouchers: soldVouchers,
    redeemedVouchers: redeemedVouchers,
    soldTotal: _.sumBy(soldVouchers, 'price'),
    redeemedTotal: Math.abs(_.sumBy(redeemedVouchers, 'price'))
  }

  const dineInReport = renderPivotTable({
    reducers: [vTaxSumReducer, '@objSum[2]:vTaxComponents', '@sum[2]:vSubTotal']
  }, items.filter(i => !i.vTakeAway));


  const takeAwayReport = renderPivotTable({
    reducers: [vTaxSumReducer, '@objSum[2]:vTaxComponents', '@sum[2]:vSubTotal']
  }, items.filter(i => i.vTakeAway));

  const dineInQuantity = paidOrders.filter(o => !o.takeAway).length;
  const takeAwayQuantity = paidOrders.filter(o => o.takeAway).length;

  const cancelledItems = orders.reduce<OrderItem & {status: OrderStatus}[]>((list, order) => {
    if (order.status === OrderStatus.CANCELLED) {
      list.push(...mapItem(order.items! || []), ...mapItem(order.cancellationItems! || []));
      return list;
    }
    if (order.status === OrderStatus.CANCELLED_BEFORE_PAID) {
      list.push(...mapItem(order.items! || []), ...mapItem(order.cancellationItems! || []), ...mapItem(order.directCancellationItems! || []));
      return list;
    }
    list.push(...mapItem(order.directCancellationItems! || []));
    list.push(...mapItem(order.cancellationItems?.filter(i => i.quantity > 0) || []));
    return list;

    function mapItem(items: OrderItem[]) {
      return items?.map(i => ({ ...i, status: order.status }))
    }
  }, [] as any).map(i => {
    return _.pick(i, ['id', 'name', 'vPrice', 'quantity', 'modifiers', 'vSum', 'status']);
  });

  const cancelledReport = renderPivotTable({
    columns: ['status'],
    reducers: ['@sum[2]:vSum']
  }, cancelledItems);

  const reportByHours = paidOrders.reduce<{[p: string]: {
      orderCnt: number,
      total: number
    }}>((rs, order) => {
    const hour = `${dayjs.unix(order.date!).hour()}:00 - ${dayjs.unix(order.date!).hour()}:59`
    if (!rs[hour]) rs[hour] = {
      orderCnt: 0,
      total: 0
    }
    rs[hour].orderCnt ++
    rs[hour].total += order.vSum!
    return rs
  }, {})

  const cancelledItemReport = renderPivotTable({
    reducers: ['@sum[2]:vSum']
  }, cancelledItems) || 0;

  const refundItems = orders?.filter(o => o.status === OrderStatus.REFUNDED && o.reason).reduce<OrderItem[]>((list, order) => {
    list.push(...order.items!);
    return list;
  }, [] as any).map(i => {
    return _.pick(i, ['id', 'name', 'vPrice', 'quantity', 'modifiers', 'vSum']);
  });

  const refundItemReport = renderPivotTable({
    reducers: ['@sum[2]:vSum']
  }, refundItems) || 0;


  const {net} = countReportTotal(report)

  const avgItemPrice = itemsQuantity === 0 ? 0 : _.round(report.vSum / itemsQuantity, 2)
  const avgItemInOrder = report.quantity === 0 ? 0 : _.round(itemsQuantity / report.quantity, 2)
  const avgOrderPrice = report.quantity ? _.round(report.vSum / report.quantity, 2) : 0;
  const avgOrderNetPrice = report.quantity ? _.round(net / report.quantity, 2) : 0;

  const {gross: dineInGross} = countReportTotal(dineInReport);
  const {gross: takeAwayGross} = countReportTotal(takeAwayReport)

  const avgDineInSale = dineInQuantity ? _.round(dineInGross / dineInQuantity, 2) : 0;
  const avgTakeAwaySale = takeAwayQuantity ? _.round(takeAwayGross / takeAwayQuantity, 2) : 0;

  const taxGroup = renderPivotTable({
    reducers: [vTaxSumGroupReducer]
  }, paidOrders);


  return {
    groupItemsByCategory,
    sumByCategory,
    totalServiceFee,
    totalTip,
    totalCashback,
    sumByPayment,
    paymentReport,
    totalCashSalesExcludingCashback,
    totalCashlessSales,
    totalCashlessSalesExcludingTip,
    totalDiscount,
    report,
    voucherReport,
    dineInReport,
    takeAwayReport,
    discountedOrders,
    itemsQuantity,
    dineInQuantity,
    takeAwayQuantity,
    totalDiscountByLabel,
    cancelledReport,
    reportByHours,
    cancelledItemReport,
    avgItemPrice,
    avgItemInOrder,
    avgOrderPrice,
    avgOrderNetPrice,
    avgDineInSale,
    avgTakeAwaySale,
    cancelledItems,
    totalRefund,
    taxGroup,
    totalDebitorAmount,
    refundItems,
    refundItemReport,
    totalShipping,
    totalDonation,
    totalExtraTax
  }
}

function getStaffReport(orders: Order[]) {
  const paidOrders = orders.filter(i => [OrderStatus.DEBITOR, OrderStatus.PAID, OrderStatus.ACCEPTED/*, OrderStatus.CANCELLED_BEFORE_PAID*/, OrderStatus.REFUNDED].includes(i.status) || i.status === OrderStatus.CANCELLATION_REFERENCE && i?.reason === Reason.REFUND)
  const payment = paidOrders.reduce<PaymentReport[]>((r, order) => r.concat(...order.payments.map((p, index) => {
    const value = getExcludeTipAndCashbackValue(order, p, index);
    return {
      ...p,
      user: _.get(order, 'users.0', ''),
      excludeCashback: value.excludeValue + (value.tip || 0),
      otherFee: value.otherFee || 0,
    }
  })), []);

  let groupByPayment = renderPivotTable(
    {
      rows: [],
      columns: ['user', 'type'],
      reducers: ['@sum[2]:value', totalExcludeCashbackReducer, totalOtherFeeReducer]
    },
    payment
  );

  //sales
  let userSales = renderPivotTable(
    {
      rows: [],
      columns: ['users.0'],
      reducers: [vTaxSumReducer, '@sum[2]:vDiscount', soldOrderCntReducer, '@objSum[2]:vTaxComponents', '@sum[2]:vSubTotal']
    },
    paidOrders
  );


  let groupByStatus = renderPivotTable(
    {
      rows: ['status'],
      columns: ['users.0'],
      reducers: ['@sum[2]:vSum']
    },
    orders
  );

  let totalTipByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@sum[2]:tip']
  }, paidOrders)

  const cashTipByUser = renderPivotTable(
    {
      columns: ['users.0'],
      reducers: ['@sum[2]:cashTip']
    }, paidOrders)

  let totalCashbackByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@sum[2]:cashback']
  }, paidOrders)

  const totalDiscountByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@sum[2]:vDiscount']
  }, paidOrders)

  const totalShippingByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@objSum[2]:shippingData']
  }, paidOrders)

  const totalDonationByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@sum[2]:donation']
  }, paidOrders)

  const totalExtraTaxByUser = renderPivotTable({
    columns: ['users.0'],
    reducers: ['@sum[2]:vExtraTax']
  }, paidOrders)

  let cashSalesByUserExcludingCashBack: { [p: string]: number } = {}
  for (const user of Object.keys(groupByPayment)) {
    cashSalesByUserExcludingCashBack[user] = (groupByPayment[user][cashPaymentName0()]?.value || 0) - (totalCashbackByUser[user] || 0) - (cashTipByUser[user] || 0) - (groupByPayment[user][cashPaymentName0()]?.totalOtherFee || 0)
  }
  let cashlessSalesByUser: { [p: string]: number } = {}
  for (const user of Object.keys(groupByPayment)) {
    cashlessSalesByUser[user] = _.sum(_.map(_.omit(groupByPayment[user], [cashPaymentName0(), debitorPayment0()]), 'value'))
  }
  let cashlessSalesByUserExcludingTip: { [p: string]: number } = {}
  for (const user of Object.keys(groupByPayment)) {
    const totalOtherFeeCashless = _.sum(_.map(_.omit(groupByPayment[user], [cashPaymentName0(), debitorPayment0()]), 'totalOtherFee'))
    cashlessSalesByUserExcludingTip[user] = cashlessSalesByUser[user] - (totalTipByUser[user] - cashTipByUser[user]) - (totalOtherFeeCashless || 0)
  }
//fixme: count debitor
  let debitorByUser: { [p: string]: number } = {}
  for (const user of Object.keys(groupByPayment)) {
    debitorByUser[user] = groupByPayment[user][debitorPayment0()]?.value || 0
  }

  interface OrderItemReport extends OrderItem {
    user?: string,
    table?: string,
    orderId?: number,
    date?: number
  }

  const cancellationItems = orders.reduce<Array<OrderItemReport>>((items, o) => {
    const assignUser = (items: OrderItem[]): OrderItemReport[] => items.map(i => ({
      ...i,
      user: o.users![0],
      table: o.table,
      orderId: o.id,
      date: o.date
    }))

    if (o.status === OrderStatus.PAID || o.status === OrderStatus.IN_PROGRESS || o.status === OrderStatus.ACCEPTED || o.status === OrderStatus.CANCELLED_BEFORE_PAID || o.status === OrderStatus.DEBITOR) {
      items.push(...assignUser(o.directCancellationItems! || []));
      items.push(...assignUser(o.cancellationItems! || []));
      if (o.status === OrderStatus.CANCELLED_BEFORE_PAID) items.push(...assignUser(o.items! || []))
    } else if (o.status === 'cancelled') {
      items.push(...assignUser(o.cancellationItems! || []))
      items.push(...assignUser(o.items || []))
    }
    return items
  }, [])

  const refundItems = orders?.filter(o => o.status === OrderStatus.REFUNDED && o.reason).reduce<Array<OrderItemReport>>((items, o) => {
    const assignUser = (items: OrderItem[]): OrderItemReport[] => items.map(i => ({
      ...i,
      user: o.users![0],
      table: o.table,
      orderId: o.id,
      date: o.date
    }))
    items.push(...assignUser(o.items!))
    items.push(...assignUser(o.cancellationItems!))
    return items
  }, [])

  const totalCancelled = renderPivotTable({
    columns: ['user'],
    reducers: ['@sum[2]:vSum']
  }, cancellationItems)

  const cancellationItemsGroups = cancellationItems.reduce<{
    [p: string]: { [k: string]: OrderItem[] }
  }>((groups, i) => {
    const user = i.user
    if (!groups[user!]) groups[user!] = {}
    const key = `${i.table}/${i.date}`
    if (!groups[user!][key]) groups[user!][key] = []
    groups[user!][key].push(i)
    return groups
  }, {})

  const soldItemsCnt = renderPivotTable({
    columns: ['users.0'],
    reducers: [{ fn: (soldItemsCnt: number, order: Order) => soldItemsCnt + order.items.length, initValue: 0 }]
  }, paidOrders)

  const voucherReportByUser = paidOrders.reduce<{
    [p: string]: {
      soldVouchers: OrderItem[],
      redeemedVouchers: OrderItem[],
      soldTotal: number,
      redeemedTotal: number,
    }
  }>((rs, order) => {
    let user = _.get(order, 'users.0')
    for (const item of order.items) {
      if (item.isVoucher) {
        // if (item.user) user = item.user
        if (!user) continue;
        if (!rs[user]) {
          rs[user] = {
            soldVouchers: [],
            redeemedVouchers: [],
            soldTotal: 0,
            redeemedTotal: 0,
          }
        }
        if (item.price > 0) {
          rs[user].soldVouchers.push(item)
          rs[user].soldTotal += item.price
        } else {
          rs[user].redeemedVouchers.push(item)
          rs[user].redeemedTotal += Math.abs(item.price)
        }
      }
    }
    return rs
  }, {})
  return {
    groupByPayment,
    userSales,
    cashSalesByUserExcludingCashBack,
    cashlessSalesByUser,
    cashlessSalesByUserExcludingTip,
    groupByStatus,
    totalTipByUser,
    cashTipByUser,
    totalCashbackByUser,
    totalCancelled,
    cancellationItems,
    cancellationItemsGroups,
    voucherReportByUser,
    soldItemsCnt,
    totalDiscountByUser,
    debitorByUser,
    refundItems,
    totalShippingByUser,
    totalDonationByUser,
    totalExtraTaxByUser
  }
}


function isTaxCombo(vTaxComponents: VTaxComponents, taxValue: string) {
  if (_.isEmpty(vTaxComponents)) return false;
  if (taxValue == '0' || taxValue == null) return false;
  const taxType = taxCategories0()?.find(t => t.value === Number(taxValue) || t.value === Number(taxValue.replace(',', '.')))?.type
  return taxType === 'combo';
}

export function countReportTotal(report: Partial<TaxReport>) {
  if (!report) return {
    tax: 0,
    net: 0,
    gross: 0
  }
  const tax = orderConfig.useTaxComponents ? _.round(_.sum(_.values(report.vTaxComponents ?? {})), 2): (report!.vTaxSum?.tax || 0);
  const net = orderConfig.useTaxComponents ? (report?.vSubTotal || 0) : (report!.vTaxSum?.net || 0);
  const gross = orderConfig.useTaxComponents ? _.round(tax + net, 2): (report!.vTaxSum?.gross || 0);
  return {
    tax: tax,
    net: net,
    gross: gross
  }
}

export {
  getCommonReportData,
  getStaffReport,
  isTaxCombo
}
