import React, { type FunctionComponent, useCallback } from "react";
import VerticalEditMenuFormDrawer from "./VerticalEditMenuFormDrawer.tsx";
import PortalDrawer from "./PortalDrawer";
import {
  addProduct,
  isEditMenuEditFormOpen,
  menu0,
  section0,
  sections0,
  setEditMenuEditFormOpen,
  setSection0,
  setTempSwitchItem,
  tempSwitchItem
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { clsx } from "clsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { SectionGrid } from "@/react/EditMenuView/Vertical/RenderSections.tsx";
import { Section } from "@/data/Section.ts";
import { batch } from "@/react/core/reactive.ts";
import { peek } from "deepsignal/react";
import { toast } from "react-toastify";
import type { DocObservable } from "@/data/utils/data-utils-2.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { ITEM_MODE, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

export type CategorySectionWrapperType = {
  section: DocObservable<Section>;
  className?: string;
};

const CategorySectionWrapper: FunctionComponent<CategorySectionWrapperType> = ({
  className = "",
  section
}) => {

  const openEditMenuEditForm = useCallback(() => {
    setEditMenuEditFormOpen(true);
  }, []);

  const closeEditMenuEditForm = useCallback(() => {
    setEditMenuEditFormOpen(false);
  }, []);

  const [section1, setSection1] = section?.signal
  const isSelected = section0()?._id === section?._id;
  return (
    <>
      <div
        className={`self-stretch flex flex-col items-start justify-start gap-2 text-left text-sm text-white-solid-white-100-ffffff font-mulish ${className}`}
      >
        <div
          className={clsx("self-stretch rounded border-gray-solid-gray-320-d2d2d2 border-[1px] border-dashed flex flex-col items-start justify-start pt-1 px-1 pb-2 gap-1.5 cursor-pointer",
            isSelected && '!border-[#ee0b0b] !border-solid'
          )}
        >
          <div className='flex justify-between w-full px-1 pt-1 pb-2'>
            <div className="rounded flex flex-row items-center justify-start p-1 gap-1"
                 ref={makeRipple}
                 onClick={async (e) => {
                   const switchItem = tempSwitchItem();
                   if (switchItem?.active && switchItem?.type === "section") {
                     if (switchItem.mode === "swap") {
                       const switchSection = sections0().find(s => s._id === switchItem._id)
                       const currentSection = sections0().find(s => s._id === section._id)
                       if (switchSection && currentSection) {
                         batch(() => {
                           switchSection.get().order = peek(currentSection.get(), 'order')
                           currentSection.get().order = peek(switchItem, 'order')
                           setTempSwitchItem()
                           toast.success('Switch success')
                         })
                       } else {
                         console.error('switch error')
                       }
                     } else {
                       //todo: copy
                     }
                     return
                   }
                   batch(() => {
                     openEditMenuEditForm()
                     setEditorMode0(ITEM_MODE.SECTION);
                     setSection0(section1())
                     setTempSwitchItem()
                   });

                 }}
            >
              {section1()?.icon && <img
                className="w-6 relative h-6 object-cover sm:w-9 sm:h-9 mq600:w-8 mq600:h-8"
                alt=""
                src={section1()?.icon}
              />}
              <b className="relative leading-[20px]">{section1()?.name}</b>
            </div>
            <div className='flex justify-center items-center rounded-full bg-[#1e96f5] text-white px-2 py-1 text-[12px] font-bold'
                 ref={makeRipple}
                 onClick={async () => {
                   await addProduct(section.get())
                 }}
            >
              + {LL0().inventory.addItem()}
            </div>
          </div>
          <SectionGrid
            sectionId={section?._id!}
            rows={1}
            columns={menu0()?.maxColumn}
            rowGap={4}
            colGap={4}
            style={{ height: deviceSetting0()?.screenConfig?.itemCardHeight }}
          />
        </div>


      </div>
      {(isEditMenuEditFormOpen() && !generalSetting0()?.useMenuV2)&& (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeEditMenuEditForm}
        >
          <VerticalEditMenuFormDrawer onClose={closeEditMenuEditForm} />
        </PortalDrawer>
      )}
    </>
  );
};

export default CategorySectionWrapper;
