import GridLayout from '@/react/core/GridLayout';
import { memo, useCallback, useEffect, useMemo } from 'react';
import {
  calculateInitTax,
  findProduct,
  onClickSwitch,
  onDelete0
} from '@/react/EditMenuView/EditMenuView.tsx';
import type { CategoryLayout } from '@/data/OrderLayout';
import GridElement from '@/react/core/GridElement';
import { batch, makeActiveFactory, signal, useAsyncEffect, useSignal } from '@/react/core/reactive';
import { ProductLayout } from '@/data/ProductLayout';
import { Product } from '@/data/Product';
import uuid from 'time-uuid';
import _ from 'lodash';
import JsonFn, { clone } from '@atc-group/json-fn';
import { convertDocument, type DocDeepSignal } from '@/data/data-utils';
import { type DeepSignal, useDeepSignal } from 'deepsignal/react';
import clsx from 'clsx';
import EditMenuNumberKeyboard from '@edit-menu/EditMenuNumberKeyboard.tsx';
import { kitchenGroupPrinters0 } from '@/data/GroupPrinterHub.ts';
import type { Setter } from "solid-js";
import delay from "delay";
import { untracked } from "@preact/signals-react";
import LazyRender from "@/react/core/LazyRender.tsx";
import { getDeviceId } from '@/shared/getDeviceId'
import { keyboardConfig0, menuScrollbar } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import {
  setProductMatching,
  setTempProductMatching,
  tempProductMatching
} from "@/react/PendingOrder/ProductMatchingLogic.ts";
import {
  isSelected, ITEM_MODE, productLayouts0, products0,
  setEditorMode0,
  setProduct0,
  setProductLayout0, setTempItem, tempItem, tempOnlineProduct
} from "@/react/EditMenuView/EditMenuView.signal.ts";

const makeActive = makeActiveFactory()

const itemsHub: any = {};

const RenderLayoutItem = memo((
  { top, left, category, setVer, ver, active: initActive }: {
    category: CategoryLayout;
    top: number;
    left: number;
    setVer: Setter<number>;
    ver: number;
    active: boolean;
  }
) => {
  const [active, setActive] = useSignal<boolean>(initActive);
  useEffect(() => {
    if (initActive) {
      onClick().then();
    }
  }, []);
  const _productLayout = useMemo(() => untracked(() => productLayouts0())!.find(
    (p) => p.get().top === top && p.get().left === left && p.get().category === category._id
  ), []);

  // console.log('[RenderLayoutItem] _productLayout', _productLayout)
  const [created, setCreated] = useSignal<boolean>(!!_productLayout);
  const [useCreated, setUseCreated] = useSignal<boolean>(!!_productLayout);
  const productId = useMemo<string>(() => _productLayout || uuid(), []);
  const initProductLayout = useDeepSignal<ProductLayout>({
    top,
    left,
    _id: uuid(),
    type: "Article",
    category: category._id,
    text: '',
    product: productId
  })
  const [productLayout, setProductLayout] = _productLayout?.signal || signal<ProductLayout>(initProductLayout);


  const initGroupPrinter = useMemo(() => kitchenGroupPrinters0()[0], []);
  const taxInit = calculateInitTax(initGroupPrinter)

  const initProduct = useDeepSignal<Product>({
    name: '', _id: productId, id: '', price: 0, groupPrinter: initGroupPrinter?._id,
    ...taxInit
  })


  // if (!itemsHub[productLayout()._id]) {
    itemsHub[productLayout()._id] = {
      setProductLayout,
      setVer,
      setActive: () => {
        batch(() => {
          makeActive(setActive);
          setProduct0(product());
          setProductLayout0(productLayout() as ProductLayout);
          setEditorMode0(ITEM_MODE.ITEM);
        });
      }
    }
  // }
  const foundProduct = useMemo(() => findProduct(productLayout()!), [])
  const [product, setProduct] = foundProduct?.signal || useSignal<DocDeepSignal<Product>>(initProduct);
  // console.log('render RenderLayoutItem : ', created());

  if (ver > 0) {
    useAsyncEffect(async () => {
      const _productLayout = await ProductLayout.findOne({
        selector: {
          top, left, category: category._id
        }
      }).exec();
      if (!_productLayout) {
        batch(() => {
          setProductLayout(initProductLayout);
          setProduct(initProduct);
          setCreated(false);
        })
        console.log('load from database');
        return;
      }
      setProductLayout(convertDocument<ProductLayout>(_productLayout, true));
      setCreated(true);
      setUseCreated(true);
      const _product = await Product.findOne({
        selector: {
          _id: _productLayout.product
        }
      }).exec();
      if (!_product) return;
      setProduct(convertDocument<Product>(_product, true));
      console.log('load from database');
    }, []);
  }

  useAsyncEffect(async () => {
    if (useCreated()) return;
    if (!_.isEmpty(product().name) || !_.isEmpty(productLayout().text)) {
      console.log('insert product');
      if (!productLayout().color) productLayout().color = "#FFFFFF";
      console.log(product());
      const _product = await Product.incrementalUpsert(JsonFn.clone(product()));
      const _productLayout = await ProductLayout.incrementalUpsert(clone(productLayout() as ProductLayout));
      setCreated(true);

    }
  }, [JSON.stringify(product()), JSON.stringify(productLayout())], { defer: true })

  let text: string | undefined;
  if (productLayout()?.type === "Text") {
    text = productLayout()?.text || '';
  } else {
    text = `${product().id ? product().id + "." : ""}${product().name}`
  }

  const bgColor = () => {
    if (!created()) return 'rgba(236, 236, 236, 0.3)';
    return productLayout().type !== "Text" ? productLayout().color || "#000000" : "transparent";
  }

  const textColor = () => {
    if (!created()) return '#FFFFFF';
    return productLayout()?.type !== "Text" ? "#000000" : "#FFFFFF";
  }

  const border = () => {
    if ((!created() || productLayout().type === "Text") && !active()) return "dashed 1.5px #BDBDBD";
    return active() ? "solid 2px #FF0022" : "1px solid transparent";
  }

  const onClick = useCallback(async () => {
    console.log('[RenderLayoutItem] onClick')

    const switchItem = tempItem();
    console.log('switchItem', switchItem)

    if (switchItem?.active && switchItem.type === "item") {
      const _srcProductLayout = await ProductLayout.findOne({selector: {_id: switchItem._id}}).exec()
      if (!_srcProductLayout) {
        setTempItem()
        return
      }

      const srcProductLayout = convertDocument(_srcProductLayout, true);
      if (switchItem.mode === "swap") {
        // same product layout -> skip
        if (switchItem?._id === productLayout()?._id) {
          setTempItem();
          return
        }

        // TODO: why do this extra step, query these variables just for logging?
        const _product = await Product.findOne({selector: {_id: productLayout()?.product}}).exec();
        const _productAreSwap = await Product.findOne({selector: {_id: srcProductLayout?.product}}).exec();
        if (_product && _productAreSwap) {
          const item = convertDocument<Product>(_product, true)
          const itemAreSwap = convertDocument<Product>(_productAreSwap, true)
          userFLow(`swap item ${itemAreSwap?.name} with item ${item?.name}`, {
            itemId: itemAreSwap?._id,
            username: loginUser()?.name
          });
        }

        await _srcProductLayout.incrementalPatch({
          left: productLayout()?.left,
          top: productLayout()?.top,
          category: productLayout()?.category
        })

        batch(() => {
          productLayout()!.left = switchItem.left!;
          productLayout()!.top = switchItem.top!;
          productLayout().category = category._id;
        })

        setTimeout(async () => {
          itemsHub[srcProductLayout._id].setVer((v: number) => v + 1);
          setVer(v => v + 1);
          await delay(50);
          itemsHub[srcProductLayout._id].setActive();
        }, 300)
      } else {
        const srcProduct = await Product.findOne({selector: {_id: srcProductLayout.product}}).exec();
        batch(() => {
          // TODO: pick fields instead
          const omitProductFields = [
            "_id", "doc", "id",
            "updatedAt", "createdAt",
            "layouts", "attributes", "choices",
            "isNoPrint", "option", "type", "isDivArticle"
          ]
          const copyProductInfo =  {
            ..._.omit(clone(srcProduct || {}), omitProductFields), // TODO: // clone necessary?
            id: (Number(srcProduct?.id || "0") + 1).toString()
          }
          _.assign(product(), copyProductInfo) // TODO: assign ? Why not use setProduct?

          // TODO: pick fields instead
          const omitProductLayoutFields = ["_id", "doc", "product", "top", "left"]
          _.assign(productLayout(), {
            ..._.omit(clone(srcProductLayout), omitProductLayoutFields),
            category: category._id
          })
        })
      }
      setTempItem();
      return;
    }

    onDelete0.value = async () => {
      console.log("onDelete");
      userFLow(`delete item ${product()?.name}`, {
        username: loginUser()?.name
      });
      const _product = products0().find((p) => p._id === productLayout()?.product);
      if (_product) {
        await _product.doc?.incrementalRemove();
      }
      // const p = productLayouts0().find((p) => p._id === productLayout()?._id);

      const deleteProductLayout = await ProductLayout.findOne({ selector: { _id: productLayout()?._id } }).exec();
      if (deleteProductLayout) {
        await deleteProductLayout.remove()
      }


      // if (p) await p?.doc?.incrementalRemove();
      setTimeout(() => {
        setVer(v => v + 1);
      }, 50);

      // batch(() => {
      //   setProductLayout(deepSignal<ProductLayout>({
      //     top,
      //     left,
      //     _id: uuid(),
      //     type: "Article",
      //     category: category._id
      //   }));
      //   setProduct(deepSignal<Product>({ name: '', _id: uuid(), id: '', price: 0 }));
      //   setCreated(false);
      //   setProduct0(product());
      //   setProductLayout0(productLayout() as ProductLayout);
      // })
    };

    const _tempProductMatching = tempProductMatching()
    if (!_.isEmpty(_tempProductMatching)) {
      if (_.isEmpty(product().name) && _.isEmpty(productLayout().text)) {
        setTempProductMatching(undefined)
        if (!productLayout().color) productLayout().color = "#FFFFFF";
        product().name = _tempProductMatching.name
        product().price = _tempProductMatching.price
        await Product.incrementalUpsert(JsonFn.clone(product()));
        await ProductLayout.incrementalUpsert(clone(productLayout() as ProductLayout));
        setCreated(true);
        setProductMatching(product()._id)
      }
    }

    batch(() => {
      makeActive(setActive);
      setProduct0(product());
      setProductLayout0(productLayout() as ProductLayout);
      setEditorMode0(ITEM_MODE.ITEM);
    });
  }, [created()])

  return (
    <GridElement x={left} y={top} cols={1} rows={1}
                 onClick={(e) => {
                   if (e.detail === 1) onClick().then();
                   if (e.detail === 2) onClickSwitch("swap")
                 }}
                 className={clsx("center px-[4px] rounded-[2px] font-semibold text-[13px] min-h-[36px]",
                   productLayout()?.type === "Extra" && "italic")}
                 style={{
                   color: textColor(),
                   background: bgColor(),
                   border: border()
                 }}>
        {text}
    </GridElement>
  );
});

const EmptyProduct = memo((props: {x: number, y: number, onClick: () => void}) => {
  return <GridElement x={props.x} y={props.y} cols={1} rows={1}
                      onClick={() => props.onClick()}
                      className={"center px-[4px] rounded-[2px] font-semibold text-[13px] min-h-[36px] text-white"}
                      style={{
                        background: 'rgba(236, 236, 236, 0.3)',
                        border: "dashed 1.5px #BDBDBD"
                      }}>
  </GridElement>
})

const RenderLayoutItemWrapper =
  ({ top, left, category }: { category: CategoryLayout; top: number; left: number }) => {
    const [ver, setVer] = useSignal<number>(0);
    useEffect(() => {
      if (ver() % 2 === 1) setVer(ver() + 1);
    }, [ver()]);
    //check exists

    const _productLayout = useMemo(() => untracked(() => productLayouts0())!.find(
      (p) => p.get().top === top && p.get().left === left && p.get().category === category._id
    ), [ver()]);

    useEffect(() => {
      ProductLayout.$.subscribe(change => {
        //handle case switch from another device
        if (!(change.documentData.top === top && change.documentData.left === left && change.documentData.category === category._id)) {
          if (!(change.previousDocumentData?.top === top && change.previousDocumentData?.left === left && change.previousDocumentData?.category === category._id)) return;
        }
        if ((change.documentData as any).updatedOn === getDeviceId()) return;
        if (change.operation === 'UPDATE' &&
          change.documentData.top === change.previousDocumentData?.top &&
          change.documentData.left === change.previousDocumentData?.left
        ) return;
        setTimeout(() => {
          setVer(ver => ver + 1);
        }, 200)
      })
    }, [])

    const [active, setActive] = useSignal<boolean>(false);

    const onClick = useCallback(() => {
      console.log('[RenderLayoutItemWrapper] onClick')
      setActive(true)
    }, [])

    if (!_productLayout && !active()) {
      return <EmptyProduct x={left} y={top} onClick={onClick}/>
    }

    return ver() % 2 === 0 && <RenderLayoutItem
      ver={ver()}
      setVer={setVer}
      active={active()}
      category={category} top={top} left={left}
    />
  }

const RenderProducts = memo(({ category, index }: { category: DeepSignal<CategoryLayout>, index: number }) => {
  //observe length for re-render
  category.products?.$length?.value;
  //todo: get rows, columns
  // console.log(`RenderProducts: `);
  const rowsArr = useMemo(() => new Array(category.rows).fill(0), [category.rows]);
  const columnsArr = useMemo(() => new Array(category.columns).fill(0), [category.columns]);
  const rows = category.rows;
  const columns = category.columns;
  const selected = isSelected(index);

  const renderContent = useMemo(() => {
    return <>
      {rowsArr.map((item, top) => (
        columnsArr.map((_item, left) => (
          <RenderLayoutItemWrapper category={category} top={top} left={left} key={`${top}/${left}`} />
        ))
      ))}
      {keyboardConfig0() && (index === 0 || !keyboardConfig0()?.onlyShowInFirstPage)
        && keyboardConfig0().active &&
        <GridElement
          x={keyboardConfig0().left!}
          y={keyboardConfig0().top!}
          cols={keyboardConfig0().width!}
          rows={keyboardConfig0().height!}
          className="self-start h-full"
        >
          <EditMenuNumberKeyboard editable />
        </GridElement>
      }
    </>
  }, [category.rows, category.columns])

  return (
    <LazyRender show={selected}>
      <GridLayout
        rows={rows}
        cols={columns}
        colGap={5}
        rowGap={5}
        style={{
          display: selected ? "grid" : "none"
        }}
        className={`w-full ${menuScrollbar() ? 'auto-rows-min': 'h-full'}`}
        scrollable={menuScrollbar()}
      >
        {renderContent}
      </GridLayout>
    </LazyRender>
  );
}, (oldProps, newProps) => {
  if (JSON.stringify(oldProps.category) !== JSON.stringify(newProps.category)) return false;
  if (oldProps.index !== newProps.index) return false;
  return true;
})

export default RenderProducts;
