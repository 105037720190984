import type React from 'react';
import { Fragment, type FunctionComponent } from 'react';
import {
  inputControllers,
  setCurrenKeyboardKey,
  setIsPopupOpen
} from '@/react/EditMenuView/EditMenuView.tsx';
import _ from 'lodash';
import { effect, useComputed, useSignal } from '@/react/core/reactive.ts';
import PlusIcon from '@/assets/plus-1.png';
import {
  findProduct,
  onProductClick,
  productLayouts,
  setKeyboardMatchProducts,
  setKeyboardProductQuantity
} from '@/react/OrderView/OrderView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { clsx } from 'clsx';
import { companyInfo0, keyboardConfig0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { ITEM_MODE, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

const defaultKeyboard = [
  [
    {
      "value": "7",
      "size": 1,
    },
    {
      "value": "8",
      "size": 1,
    },
    {
      "value": "9",
      "size": 1,
    }
  ],
  [
    {
      "value": "4",
      "size": 1,
    },
    {
      "value": "5",
      "size": 1,
    },
    {
      "value": "6",
      "size": 1,
    }
  ],
  [
    {
      "value": "1",
      "size": 1,
    },
    {
      "value": "2",
      "size": 1,
    },
    {
      "value": "3",
      "size": 1,
    }
  ],
  [
    {
      "value": "0",
      "size": 1,
    },
    {
      "value": "enter",
      "size": 2,
      "type": "enter",
    }
  ]
]
const defaultKeyboardWithX = [
  [
    {
      "value": "7",
      "size": 1,
    },
    {
      "value": "8",
      "size": 1,
    },
    {
      "value": "9",
      "size": 1,
    }
  ],
  [
    {
      "value": "4",
      "size": 1,
    },
    {
      "value": "5",
      "size": 1,
    },
    {
      "value": "6",
      "size": 1,
    }
  ],
  [
    {
      "value": "1",
      "size": 1,
    },
    {
      "value": "2",
      "size": 1,
    },
    {
      "value": "3",
      "size": 1,
    }
  ],
  [
    {
      "value": "0",
      "size": 1,
    },
    {
      "value": " x ",
      "size": 1,
    },
    {
      "value": "enter",
      "size": 1,
      "type": "enter",
    }
  ]
]

type KeyboardCellValue = {
  value: string;
  size: number;
  type?: "enter";
  top: number;
  left: number
  icon: string;
  editable: boolean
}

type EditMenuNumberKeyboardProps = {
  editable?: boolean;
}

const EditMenuNumberKeyboard: FunctionComponent<EditMenuNumberKeyboardProps> = ({ editable }: EditMenuNumberKeyboardProps) => {
  const [kbdText, setKbdText] = useSignal<string>("");

  const processKbdInput = () => {
    if (!kbdText().trim()) return;

    const [searchId, quantity] = kbdText().trim().split(" x ");

    const searchResults = productLayouts().filter(productLayout => {
      const product = findProduct(productLayout);
      return product?.id === searchId;
    })
    const quantityNumber = Number(quantity) ?? 1
    // for the case quantity is 0, just ignore it
    if (searchResults.length === 0 || quantityNumber === 0) return;

    if (searchResults.length === 1) {
      onProductClick(searchResults[0], quantityNumber)
    } else {
      setKeyboardMatchProducts(searchResults)
    }
    setKeyboardProductQuantity(quantityNumber);
    setKbdText("")
    return;
  }

  const keyboardLayout = useComputed(() => {
    let rs: KeyboardCellValue[][] = (keyboardConfig0()?.x ? _.cloneDeep(defaultKeyboardWithX) : _.cloneDeep(defaultKeyboard)) as KeyboardCellValue[][];

    const extraLayout = keyboardConfig0()?.rows || [];
    for (let i = 0; i < extraLayout.length; i++) {
      const row = extraLayout?.[i] || [];
      rs[i] = _.concat(row.map(x => ({
        value: x,
        size: 1,
        icon: (editable && x === ' ') ? 'icon-add' : '',
        editable: editable
      })), rs[i]) as KeyboardCellValue[];
    }
    for (let i = 0; i < rs.length; i++) {
      for (let j = 0; j < rs[i].length; j++) {
        rs[i][j].top = i + 1
        rs[i][j].left = j === 0 ? 1 : rs[i][j - 1].left + rs[i][j - 1].size
      }
    }
    return rs;
  })

  const handleKeyClickInUseMode = (item: KeyboardCellValue) => {
    if (item.type === 'enter') {
      processKbdInput();
    } else {
      setKbdText(prev => prev + item.value);
    }
  }

  const handleClickKey = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: KeyboardCellValue) => {
    // item.editable default key(0-9,x,Enter) is undefined
    // item.editable is true if key is Expansion Column
    if (!item.editable && !editable) {
      handleKeyClickInUseMode(item)
      return;
    }
    e.stopPropagation();
    if (item.editable) {
      setIsPopupOpen(true)
      const dispose = effect(() => {
        if (inputControllers()[0]) {
          inputControllers()[0]?.setKeys(item.value?.split("")!);
          setTimeout(() => {
            inputControllers()[0]?.setFocus(true);
            inputControllers()[0]?.moveCaretToEnd();
            dispose();
          }, 200);
        }
      })
      setCurrenKeyboardKey({ top: item.top, left: item.left })
    }
    if (editable) {
      setEditorMode0(ITEM_MODE.KEYBOARD)
    }
  }

  return (
    <div
      className={clsx("z-[1] w-full h-full flex flex-col items-center justify-center gap-[4px] text-center text-xl text-black-solid-black-860-1c1c1c font-mulish",
        editable && 'bg-gray-solid-gray-130-f4f4f4'
      )}
      onClick={() => setEditorMode0(ITEM_MODE.KEYBOARD)}>
      <div
        className="self-stretch h-full relative grid auto-cols-fr grid-rows-5 [grid-row-gap:4px] [grid-column-gap:4px] text-lg text-black-solid-black-900-1e1e23">
        <div
          className="flex-1 self-stretch rounded-10xs bg-gray-solid-gray-150-eeeeee box-border flex flex-row items-center justify-end py-1 px-[5px] border-[1px] border-solid border-gray-solid-gray-600-979797"
          style={{
            gridColumn: `1/${(keyboardConfig0()?.rows?.[0]?.length || 0) + 4}`
          }}
        >
          <div className="relative flex-1 flex flex-row items-center justify-between">
            <b className={clsx("relative", kbdText() === '' && 'text-[#ccc]')}>{kbdText() || LL0().order.itemNumber()}</b>
            {kbdText() !== '' &&
              <img
                className="absolute top-[0%] right-0 bottom-[0%] aspect-[1] h-full max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/icondelete-icon-2@2x.png"
                onClick={() => setKbdText("")}
              />
            }
          </div>
        </div>
        {keyboardLayout().map(row => (
          <Fragment key={row.map(item => item.value).join()}>
            {row.map(item => (
              <div key={`${item.top}-${item.left}`}
                   className="rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative border-[1px] border-solid border-gray-solid-gray-600-979797"
                   style={{ gridArea: `${item.top + 1} / ${item.left} / auto / span ${item.size}` }}
                   onClick={e => handleClickKey(e, item)}
                   ref={makeRipple}
              >
                {item.value === 'enter' ?
                  <div
                    className="w-full h-full rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative">
                    <img
                      className="relative h-1/2 aspect-[2/1] max-h-[20px]"
                      alt=""
                      src="back-arrow1.svg"
                    />
                  </div>
                  : item.icon ?
                    <div
                      className="w-full h-full rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative">
                      <img
                        className="relative h-[17px] aspect-square max-h-[40px]"
                        alt="item icon"
                        src={item.icon === "icon-add" ? PlusIcon : ""}
                      />
                    </div>
                    :
                    <b className="relative leading-[17px]">{item.value}</b>
                }
              </div>
            ))}
          </Fragment>))}
      </div>
    </div>
  )
}

export default EditMenuNumberKeyboard