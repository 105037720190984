import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify'

import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { toggleSystemWebviewState } from '@/data/systemWebview.ts'
import { isStop, setIsStop } from '@/pos/logic/rrweb-share.ts'
import { onRecord } from '@/pos/logic/rrweb-utils.ts'
import { startSyncit, stopSyncit } from '@/pos/logic/syncit-utils.ts'
import { useSignal } from '@/react/core/reactive.ts'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import TextField from '@/react/core/TextField.tsx'
import { demoPrintBase64 } from '@/react/Printer/print-label.ts'





const DeveloperOtherTab = () => {
  const [maxWorkers, setMaxWorkers] = useSignal<string>(localStorage.getItem('MAX_WORKERS') || '4')

  return (
    <>
      <div className="flex flex-col gap-4 mt-3">
        <div className="flex flex-row items-center">
          <div className='w-[100px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
               ref={makeRipple}
               onClick={async () => await demoPrintBase64()}
          >
            Print demo
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-3">
        <div className="flex flex-row items-center">
          <div
            className='w-[100px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
            ref={makeRipple}
            onClick={() => {
              if (!isStop()) {
                localStorage.removeItem('enable-record')
                toast.success('stop recording')
                setIsStop(true)
              } else {
                localStorage.setItem('enable-record', 'true')
                toast.success('start recording')
                onRecord()
                setIsStop(false)
              }
            }}
          >
            {isStop() ? 'Start record' : 'Stop record'}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-3">
        <div className="flex flex-row items-center">
          <div
            className='w-[100px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
            ref={makeRipple}
            onClick={() => startSyncit()}
          >
            Start
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-3">
        <div className="flex flex-row items-center">
          <div className='w-[100px] text-white flex p-2 justify-center items-center bg-[#ff8787] rounded-md max-w-[300px]'
               ref={makeRipple}
               onClick={stopSyncit}
          >
            Stop
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start pt-4 gap-2 mq480:flex-col gap-2">
        <TextField
          value={maxWorkers()}
          label={'Max worker'}
          className={'w-full self-stretch max-w-[300px]'}
          onChange={e => {
            setMaxWorkers(e.target.value);
          }}
        />
        <div
          className='w-[100px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
          ref={makeRipple}
          onClick={() => {
            toast.success('Success!')
            localStorage.setItem('MAX_WORKERS', maxWorkers())
            window.location.reload()
          }}
        >
          Save
        </div>
      </div>
      <div className="max-w-[220px] flex flex-col gap-1">
        <p>Use System Webview:</p>
        <Select
          className='w-full'
          size="small"
          value={deviceSetting0()?.useSystemWebview ?? false}
          onChange={async (e) => {
            await toggleSystemWebviewState(deviceSetting0(), e.target.value)
          }}
        >
          <MenuItem value={'false'}>No</MenuItem>
          <MenuItem value={'true'}>Yes</MenuItem>
        </Select>
      </div>
    </>
  )
}

export default DeveloperOtherTab