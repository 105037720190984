import Queue from 'queue';
import { rnHost } from "@/shared/webview/rnwebview";
import uuid from "time-uuid";
import { captureException } from "@sentry/react";
import debug from 'debug';
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { testUSBPrinter } from "@/react/Printer/print-usb.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { testNetworkPrinter } from "@/react/Printer/print-network.ts";
import dayjs from "dayjs";

const log = debug('printer:usbManager');

const queue = new Queue({ autostart: true, concurrency: 1 });

const LIMIT = import.meta.env.MODE === 'production' ? 1000 : 1;

export const printUsb = (task: { data: Buffer, path?: string, ref?: string }) => {
  log(`print to usb ${task.data.length}`);
  queue.push(async () => {
    let count = 0;
    while (count < LIMIT) {
      try {
        log(`print Usb with ${task.data.length} bytes, ref: ${task.ref || ''} at ${dayjs().format('HH:mm:ss')}`);
        const payload = task.data.toString('base64');
        await rnHost.printUsb({ payload, path: task.path });
        break;
      } catch (e: any) {
        captureException(new Error(`print to usb: error ${e.message}`), { tags: { type: 'print' } });
        log(`print to usb: error ${e.message}`);
        console.error(`print to usb: error`, e);
        //todo: emit to virtual printer
        if (e.message?.includes('queueing USB request') || e.message?.includes('claim USB interface')) {
          break;
        } else {
          const printer = groupPrinters0().find(g => g.printers[0].printerType === 'usb' && g?.printers[0].usb === task.path);
          if (!generalSetting0()?.preventShowErrorPopUpForPrinter && generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
            await printerErrDialog(LL0().printing.printerError({
              printer: _.upperFirst(printer?.name),
              printerType: _.upperFirst(printer?.printers[0].printerType)
            }), printer?.printers[0].usb, testUSBPrinter)
          }
        }
      } finally {
        count++;
      }
    }
  })
}
