import { type Order, PaidOrder } from "@/data/Order.ts";
import { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import type { TseTransaction } from "@/data/TseTransaction.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

//transactions_tse.csv
export const TSE_Transaktionen = {
  Z_KASSE_ID: {
      type: "string",
      maxLength: 50,
      hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
      type: "datetime",
      hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
      type: "number",
      places: 0,
      hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
      type: "string",
      maxLength: 40,
      hint: "Vorgangs-ID"
  },
  TSE_ID: {
      type: "number",
      places: 0,
      hint: "ID der für die Transaktion verwendeten TSE"
  },
  TSE_TANR: {
      type: "number",
      places: 0,
      hint: "Transaktionsnummer der Transaktion"
  },
  TSE_TA_START: {
      type: "string",
      hint: "Log-Time der StartTransaction-Operation"
  },
  TSE_TA_ENDE: {
      type: "string",
      hint: "Log-Time der FinishTransaction-Operation"
  },
  TSE_TA_VORGANGSART: {
      type: "string",
      maxLength: 30,
      hint: "processType der FinishTransaction-Operation"
  },
  TSE_TA_SIGZ: {
      type: "number",
      places: 0,
      hint: "Signaturzähler der FinishTransaction-Operation"
  },
  TSE_TA_SIG: {
      type: "string",
      maxLength: 512,
      hint: "Signatur der FinishTransaction-Operation"
  },
  TSE_TA_FEHLER: {
      type: "string",
      maxLength: 200,
      hint: "Ggf. Hinweise auf Fehler der TSE"
  },
  TSE_TA_VORGANGSDATEN: {
      type: "string",
      hint: "Daten des Vorgangs (optional)"
  }
}

export function TSE_TransaktionenFactory(tseTransaction: TseTransaction) {
  let eod: Eod
  let order: Order;
  return build();
  async function build() {
    order = await PaidOrder.findOne({ selector: { _id: tseTransaction.order } }).exec() as Order;
    eod = await Eod.findOne({ selector: {z: order?.z}}).exec() as Eod;
    if (!order || !eod) return []
    return buildSingle();
  }
  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      TSE_ID: TSE_ID(),
      TSE_TANR: TSE_TANR(),
      TSE_TA_START: TSE_TA_START(),
      TSE_TA_ENDE: TSE_TA_ENDE(),
      TSE_TA_VORGANGSART: TSE_TA_VORGANGSART(),
      TSE_TA_SIGZ: TSE_TA_SIGZ(),
      TSE_TA_SIG: TSE_TA_SIG(),
      TSE_TA_FEHLER: TSE_TA_FEHLER(),
      TSE_TA_VORGANGSDATEN: TSE_TA_VORGANGSDATEN()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return order.z;
  }

  function BON_ID() {
    // hint: "Vorgangs-ID"
    return order.id;
  }

  function TSE_ID() {
    // hint: "ID der für die Transaktion verwendeten TSE"
    //fixme : xem lai sau khi xem cai bang tse
    return 1;
  }

  function TSE_TANR() {
    // hint: "Transaktionsnummer der Transaktion"
    return tseTransaction.TSE_TANR;
  }

  function TSE_TA_START() {
    // hint: "Log-Time der StartTransaction-Operation"
    return dayjs.unix(tseTransaction.TSE_TA_START!).toISOString();
  }

  function TSE_TA_ENDE() {
    // hint: "Log-Time der FinishTransaction-Operation"
    return dayjs.unix(tseTransaction.TSE_TA_ENDE!).toISOString();
  }

  function TSE_TA_VORGANGSART() {
    // hint: "processType der FinishTransaction-Operation"
    return tseTransaction.TSE_TA_VORGANGSART;
  }

  function TSE_TA_SIGZ() {
    // hint: "Signaturzähler der FinishTransaction-Operation"
    return tseTransaction.TSE_TA_SIGZ;
  }

  function TSE_TA_SIG() {
    // hint: "Signatur der FinishTransaction-Operation"
    return tseTransaction.TSE_TA_SIG;
  }

  function TSE_TA_FEHLER() {
    // hint: "Ggf. Hinweise auf Fehler der TSE"
    return tseTransaction.TSE_TA_FEHLER;
  }

  function TSE_TA_VORGANGSDATEN() {
    // hint: "Daten des Vorgangs (optional)"
    return tseTransaction.TSE_TA_VORGANGSDATEN;
  }
}
