import type { FunctionComponent } from 'react';
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { payment0, setInputControllers } from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import _ from 'lodash';
import { terminals0 } from '@/data/TerminalHub.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx';
import Input, { inputController0 } from '@/react/core/Input.tsx';
import TaxPaymentPaymentIconItem from '@tax-payment/Popups/NewEditPaymentMethodPopup/TaxPaymentPaymentIconItem.tsx';
import { PaymentType } from '@/data/Payment'
import { PaymentMap } from '@/react/PaymentSettingView/PaymentMap'

export type NewEditPaymentMethodPopuType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const NewEditPaymentMethodPopu: FunctionComponent<
  NewEditPaymentMethodPopuType
> = ({ onClose, onComplete }) => {
  return (
    <div
      className="new-payment-popup bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-start justify-between pt-3.5 px-3 pb-0 box-border min-w-[580px] max-w-full max-h-full text-left text-mini text-royalblue-300 font-mulish height_414:h-auto">
      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-3 gap-[17px]">
        <b className="relative">{LL0().taxPayment.selectTerminal()}:</b>
        <FormControl className="flex-1" variant="outlined">
          <InputLabel color="primary" />
          <Select color="primary" size="small" multiple value={payment0()?.terminalIds || []}
                  onChange={e => {
                    const value = e.target.value;
                    const terminalIds = typeof value === 'string' ? value.split(',') : value;
                    _.assign(payment0(), { terminalIds });
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={terminals0().find(terminal => terminal._id === value)?.name} />
                      ))}
                    </Box>
                  )}
          >
            {terminals0().map(terminal =>
              <MenuItem value={terminal?._id} key={terminal?._id}>{terminal?.name}</MenuItem>)}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[0px_16px] pb-3">
        <b className="relative">{LL0().settings.paymentName()}:</b>
        <Input
          className={'self-stretch w-full flex-1'}
          refInputController={i => {
            return setInputControllers?.([i]);
          }}
          value={payment0()?.name ?? ''}
          onChange={value => _.assign(payment0(), { name: value })}
        />
        <FormControl
          className="!w-full flex-1 font-mulish font-semibold text-sm text-blue-solid-blue-500-0051c1"
          variant="outlined"
          size='small'
          sx={{
            borderRadius: "0px 0px 0px 0px",
            width: "318px",
            height: "37px",
            m: 0,
            p: 0,
            "& .MuiInputBase-root": {
              m: 0,
              p: '14px',
              minHeight: "37px",
              justifyContent: "center",
              display: "inline-flex",
            },
            "& .MuiInputLabel-root": {
              m: 0,
              p: 0,
              minHeight: "37px",
              display: "inline-flex",
            },
            "& .MuiMenuItem-root": {
              m: 0,
              p: 0,
              height: "37px",
              display: "inline-flex",
            },
            "& .MuiSelect-select": {
              m: 0,
              p: 0,
              height: "37px",
              alignItems: "center",
              display: "inline-flex",
            },
            "& .MuiInput-input": { m: 0, p: 0 },
            "& .MuiInputBase-input": { textAlign: "left", p: "0 !important" },
          }}
        >
          <InputLabel color="primary">{LL0().taxPayment.paymentType()}</InputLabel>
          <Select
            color="primary"
            size="small"
            label={LL0().taxPayment.paymentType()}
            displayEmpty
            value={payment0()?.type || PaymentType.None}
            onChange={(e) => _.assign(payment0(), { type: e.target.value })}
          >
            <MenuItem value="none">{LL0().printerSetting.none()}</MenuItem>
            <MenuItem value="debit">{LL0().taxPayment.debit()}</MenuItem>
            <MenuItem value="credit">{LL0().taxPayment.credit()}</MenuItem>
            <MenuItem value="cash">{LL0().payment.cash()}</MenuItem>
            <MenuItem value="debitor">{LL0().payment.debitor()}</MenuItem>
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div
        className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start pt-4 px-0 pb-0 gap-[0px_29px]">
        <b className="relative inline-block min-w-[97px]">{LL0().taxPayment.paymentIcon()}:</b>
        <div
          className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-0 pr-2 pl-0 gap-[4px_0px] text-lg text-black height_414:max-h-[176px]">
          <label className="self-stretch h-9 shrink-0 flex flex-row items-center justify-start gap-[0px_12px]">
            <input
              className="cursor-pointer relative w-5 h-5"
              type="radio"
              name="icon"
              checked={!payment0()?.icon}
              onChange={e => e.target.checked && _.assign(payment0(), { icon: "" })}
            />
            <i className="relative">{LL0().taxPayment.noIcon()}</i>
          </label>
          {PaymentMap.map((item) =>
            <TaxPaymentPaymentIconItem key={item.id} model={item} />)}
        </div>
      </div>
      <TableManualKeyboard inputController={inputController0} onEnter={onComplete} />
    </div>
  );
};

export default NewEditPaymentMethodPopu;