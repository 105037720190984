import type { Raster } from '@/shared/printer/pure-image-printer-parallel'

import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { convertToBase64Png } from "@/shared/utils3.ts";
import type { ScriptedRaster } from "@/shared/printer/types.ts";

/** Print image to browser's console.  */
export async function printImageToConsole(name: string, rasterOrBase64Url?: string | Raster, size?: { w?: number; h?: number }, download = false) {
  if (import.meta.env.MODE === 'production') return
  //@ts-ignore
  if (!rasterOrBase64Url.data) return;
  if (!rasterOrBase64Url) return
  if (generalSetting0()?.virtualPrinter) return
  console.groupCollapsed('🖼️', name)
  try {
    let { w, h } = size || {}
    if (typeof rasterOrBase64Url !== 'string') {
      w = rasterOrBase64Url?.width
      h = rasterOrBase64Url?.height
    }
    const base64Url = typeof rasterOrBase64Url === 'string' ? rasterOrBase64Url : await convertToBase64Png(rasterOrBase64Url)

    if (download) {
      console.log(`⬇️ Downloading ${name}...`)
      const a = document.createElement('a')
      a.href = base64Url
      a.download = `${name}.png`
      a.click()
    }

    console.log('🔗 %O', { base64Url, w, h, download })
    console.log(
      '%c ',
      [
        // CSS
        'border: 1px solid',
        `padding-top: ${h ?? 400}px`,
        `padding-left: ${w ?? 600}px`,
        `background: black url('${base64Url}') top center/contain no-repeat`,
      ].join(';')
    )
  } catch (e) {
    console.log('Failed to print image', rasterOrBase64Url, e)
  } finally {
    console.groupEnd()
  }
}
