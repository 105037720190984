/**
 * https://mdbootstrap.com/docs/angular/css/colors/
 * https://docs.microsoft.com/en-us/dotnet/api/system.windows.forms.messagebox.show
 */

import {signal} from "@/react/core/reactive.ts";
import {Icon} from "@mui/material";
import {getIndex} from "@/react/SystemService/layer.ts";
import {LL0} from "@/react/core/I18nService.tsx";
import _ from "lodash"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HelpIcon from '@mui/icons-material/Help';

export enum Buttons {
  OKCancel = 1,
  AbortRetryIgnore = 2,
  YesNoCancel = 3,
  YesNo = 4,
  RetryCancel = 5,
  OK = 6,
  Dismiss = 7,
}

export enum Icons {
  Information = 1,
  Warning = 2,
  Error = 3,
  Question = 4,
  Success = 5,
  None = 6,
}

export enum Results {
  abort = 'abort',
  cancel = 'cancel',
  ignore = 'ignore',
  no = 'no',
  ok = 'ok',
  retry = 'retry',
  yes = 'yes',
  dismiss = 'dismiss'
}

const [msgData, setMsgData] = signal({
  show: false,
  btn: Buttons.OKCancel,
  icon: Icons.None,
  title: '',
  content: '',
  imgUrl: undefined as string | undefined,
  zIndex: 0,
  resultHandlerFn: (result: Results) => console.log(result)
});

const renderBtn = (uiLabel: string, t: (value: string) => string, onClick: () => void, isDefaultBtn?: boolean) => {
  const style = {
    backgroundColor: isDefaultBtn ? '#1271ff' : '#e0e0e0',
    color: isDefaultBtn ? '#fff' : '#1f1f1f',
    minWidth: '100px'
  }
  return <div
    className="cursor-pointer self-stretch rounded-md bg-blue-solid-blue-420-2979ff w-28 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border text-white-solid-white-100-ffffff"
    key={uiLabel}
    style={style}
    data-testid={`msgBox.${uiLabel}Btn`}
    onClick={onClick}>
    {t(`ui.${uiLabel}`)}
  </div>
}

const btnRenders = {
  [Buttons.OK]: (t: any, on: any) => renderBtn('ok', t, () => on(Results.ok)),
  [Buttons.OKCancel]: (t: any, on: any) => [
    renderBtn('cancel', t, () => on(Results.cancel)),
    renderBtn('ok', t, () => on(Results.ok), true)
  ],
  [Buttons.AbortRetryIgnore]: (t: any, on: any) => [
    renderBtn('abort', t, () => on(Results.abort)),
    renderBtn('retry', t, () => on(Results.retry)),
    renderBtn('ignore', t, () => on(Results.ignore))
  ],
  [Buttons.YesNoCancel]: (t: any, on: any) => [
    renderBtn('cancel', t, () => on(Results.cancel)),
    renderBtn('no', t, () => on(Results.no)),
    renderBtn('yes', t, () => on(Results.yes), true)
  ],
  [Buttons.YesNo]: (t: any, on: any) => [
    renderBtn('no', t, () => on(Results.no)),
    renderBtn('yes', t, () => on(Results.yes), true)
  ],
  [Buttons.RetryCancel]: (t: any, on: any) => [
    renderBtn('cancel', t, () => on(Results.cancel)),
    renderBtn('retry', t, () => on(Results.retry), true)
  ],
  [Buttons.Dismiss]: (t: any, on: any) => [
    renderBtn('dismiss', t, () => on(Results.dismiss)),
  ]
}
const iconRenders = {
  [Icons.None]: () => null,
  [Icons.Success]: () => <CheckCircleIcon className="mr-3" style={{color: "#00C851"}}/>,
  [Icons.Information]: () => <InfoIcon className="mr-3" style={{color: "#33b5e5"}}/>,
  [Icons.Warning]: () => <WarningIcon className="mr-3" style={{color: "#ffbb33"}}/>,
  [Icons.Error]: () => <DangerousIcon className="mr-3" style={{color: "#ff4444"}}/>,
  [Icons.Question]: () => <HelpIcon className="mr-3"/>
}

const render = () => {
  const LL = LL0()
  const t = (path: string) => _.get(LL, path)()

  return msgData().show && <div data-component="msg-box-container" className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-[#000000af]"
              style={{zIndex: msgData().zIndex}}>
    <div className="message-box rounded bg-white-solid-white-100-ffffff w-[500px] py-6 px-8 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish flex flex-col bg-white">
      <div className="font-bold">{msgData().title}</div>
      <div className="row-flex items-start whitespace-pre-wrap break-words">
        {iconRenders[msgData().icon]()}
        {msgData().content}
        {msgData().imgUrl && <img className="w-full" src={msgData().imgUrl}/>}
      </div>
      <div className="flex flex-row gap-4 items-center justify-end">
        {btnRenders[msgData().btn](t, msgData().resultHandlerFn)}
      </div>
    </div>
  </div>
};

function show(title: string, content: string, btn?: Buttons, icon?: Icons, imgUrl?: string): Promise<Results> {
  return new Promise(resolve => {
    setMsgData({
      title,
      content,
      imgUrl,
      btn: btn || Buttons.YesNo,
      icon: icon || Icons.Question,
      resultHandlerFn: (result: Results) => {
        setMsgData((prev) => ({...prev, show: false}))
        resolve(result)
      },
      show: true,
      zIndex: getIndex()
    })
  })
}

export default {
  Buttons,
  Icons,
  Results,
  show,
  render
}
