import debug from 'debug'
import _ from 'lodash'

import type { DeviceSrmSetting } from '@/data/DeviceSetting'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import defaultTaxesByCity from '@/react/PaymentSettingView/defaultTaxesByCity.json'
import dialogService from '@/react/SystemService/dialogService'
import { bypassConfirmation } from '@/shared/decorators'
import { CONSTANT_VALUES } from '@/srm/lib/constants'

import { srmTransactionLogic } from '../transaction.logic'
import { TESTCASE_VARS } from './constants'
import { setLastSucceededTestcase, setNextTestcase, setTestDevices, testDevices } from './state'

const log = debug('data:srm')

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export async function advanceToTestcase(testcaseNumber: string) {
  await posSetting0()?.doc?.incrementalUpdate({
    $set: { 'srm.testcaseNumber': testcaseNumber },
  })
}
const colors = {
  blue: ['color:DodgerBlue', 'color:initial'],
  fuchsia: ['color:Fuchsia', 'color:initial'],
}

export function getCaTaxInfo() {
  const tax = defaultTaxesByCity['Canada-Quebec'][0]
  const taxVal = tax.components.reduce((acc, curr) => acc + curr.value, 0)
  return {
    tax: taxVal,
    taxCategory: tax.name,
    taxComponents: tax.components,
  }
}

export function getCaAdditionalTaxInfo() {
  const tax = defaultTaxesByCity['Canada-Quebec (with additional taxes)'][2]
  const taxVal = tax.components.reduce((acc, curr) => acc + curr.value, 0)
  return {
    tax: taxVal,
    taxCategory: tax.name,
    taxComponents: tax.components,
  }
}

export function clearTestDevices() {
  setTestDevices(null)
  setNextTestcase('001.001')
}

export async function useTestDevice(name: 'A' | 'B' | 'C') {
  const config = testDevices()?.[name]

  await deviceSetting0()?.doc?.incrementalUpdate({
    $set: {
      'srm.deviceId': config?.deviceId,
      'srm.cert': config?.cert,
      'srm.certPSI': config?.certPSI,
      'srm.privateKey': config?.privateKey,
      'srm.publicKey': config?.publicKey,
      'srm.lastTransSig': config?.lastTransSig,
    },
  })
}
export async function saveTestDevice(name: 'A' | 'B' | 'C') {
  const config = deviceSetting0()?.srm
  const val = config ? _.cloneDeep(config) : undefined
  setTestDevices(pre => ({ ...pre, [name]: val } as Record<'A' | 'B' | 'C', DeviceSrmSetting>))
}

interface ExecTestcaseOptions {
  num: number
  step: number
  device: 'A' | 'B' | 'C'
  impersonate?: string
  run: () => Promise<void>
}

export async function execTestcase({ num, device, run, step, impersonate }: ExecTestcaseOptions): Promise<boolean> {
  const testcase = [num, step].map(i => i.toString().padStart(3, '0')).join('.')
  const closeDialog = dialogService.progress({ title: `Running ${testcase}...` })
  console.groupCollapsed(`[SRM] 🧪 ${testcase}`)
  bypassConfirmation('always')
  srmTransactionLogic.options.impersonate = impersonate ?? TESTCASE_VARS.userName
  try {
    await advanceToTestcase(testcase)
    await useTestDevice(device)
    await sleep(200) // Take a breath
    log(`ℹ️ [%c${testcase}%c] starting...`, ...colors.blue)
    await run()
    setLastSucceededTestcase(testcase)
    await saveTestDevice(device)
    return true
  } catch (e) {
    console.warn(e)
    return false
  } finally {
    srmTransactionLogic.options.impersonate = undefined
    await advanceToTestcase(CONSTANT_VALUES.CASESSAI_EMPTY)
    bypassConfirmation(false)
    console.groupEnd()
    closeDialog()
  }
}
