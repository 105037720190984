//@ts-ignore
import Papa from 'papaparse';
import { collectionsMap } from "@/data/CollectionsMap.ts";
import { toast } from "react-toastify";
import uuid from 'time-uuid';
import { VoucherStatus } from "@/data/Voucher.ts";

export function importDataFromCsv() {
  let data: any
  return {
    data,
    readFile,
    importData,
  }

  async function readFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";

    const fileReadPromise = new Promise<void>((resolve, reject) => {
      input.addEventListener("change", (event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          Papa.parse(file, {
            header: true,
            complete: (results: any) => {
              data = results.data;
              resolve();
            },
            error: (error: any) => {
              console.error("Error when read csv file:", error);
              reject(error);
            },
          });
        } else {
          console.error('File not found');
          reject(new Error('File not found'));
        }
      });
    });

    input.click();
    await fileReadPromise;
    console.log('Done reading');
  }

  async function importData(collectionName: string) {
    let t
    try {
      if (!data) await readFile()
      t = toast.info(`Importing data ${collectionName}...`, { autoClose: false })
      const collectionItem = collectionsMap().find((collection) => collection.name === collectionName)
      if (!collectionItem) {
        toast.warning(`Cannot find collection ${collectionName}`)
        return
      }
      const data2 = data
        .map((item: any) => {
          item = {
            ...item,
            status: item?.usedValue ? item.usedValue : VoucherStatus.CREATED,
            usedValue: item?.usedValue ? item.usedValue : 0,
            activated: item?.activated ? item.activated : true
          }
          if (typeof item === 'object' && !item.hasOwnProperty('_id')) {
            return {
              ...item,
              _id: uuid(),
            };
          }
          return item
        })
        .filter((item: any) => {
          if (collectionName === 'voucher') {
            if (!item.hasOwnProperty('code') || !item.hasOwnProperty('totalValue') || !item?.code || !item.totalValue) {
              console.log('Removing item:', item);
              return false;
            }
          }
          return true;
        });

      console.log('import: ', data2)
      await collectionItem.collection.bulkInsert(data2)
      toast.done(t)
      toast.success(`Import ${collectionName} success!`)
    } catch (e) {
      if (t) toast.done(t)
    }
  }

  async function checkCodeVoucher() {
    // todo: check voucher code exists
  }
}