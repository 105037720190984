//@ts-ignore
import { Eod } from "@/data/Eod.ts";
import { PaidOrder } from "@/data/Order.ts";
import dayjs from "dayjs";

export const migrateEodV2 = async () => {
  //tim eod chua complete
  const eods = await Eod.find({ selector: { complete: false } }).exec()
  for (const eod of eods) {
    await eod.incrementalRemove();
    const orders = await PaidOrder.find({ selector: { z: eod.z } }).exec()
    for (const order of orders) {
      await order.incrementalPatch({ z: undefined })
    }
  }
}

//@ts-ignore
window.migrateEodV2 = migrateEodV2;

export const clearEodFrom = async (from: string = '30.01.2025') => {
  //tim eod chua complete
  const eods = await Eod.find({ selector: { vDate: { $gt: dayjs(from, 'DD.MM.YYYY').unix() } } }).exec()
  for (const eod of eods) {
    await eod.incrementalRemove();
  }

  const orders = await PaidOrder.find({ selector: { date: { $gt: dayjs(from, 'DD.MM.YYYY').unix() } } }).exec()
  for (const order of orders) {
    await order.incrementalPatch({ z: undefined })
  }

}

//@ts-ignore
window.clearEodFrom = clearEodFrom;