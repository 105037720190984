import { type FunctionComponent, memo, useCallback, useContext, useState } from "react";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PaymentProcessingPopUp from "@payment/PaymentProcessingPopUp";
import PaymentScreenPopup from "@payment/PaymentScreenPopup";
import PaymentSelectItems from "@payment/PaymentSelectItems";
import PaymentTipFee from "@payment/PaymentTipFee";
import PaymentInformation from "@payment/PaymentInformation";
import PaymentKeyboard from "@payment/PaymentKeyboard";
import PaymentSplitPopup from "@payment/PaymentSplitPopup";
import PaymentRightPathTopNav from "@payment/PaymentRightPathTopNav";
import MultiPaymentButton from "@payment/MultiPaymentButton";
import {
  blockMutateOrderButton,
  canSwitchMultiPayment,
  dialogProcessTerminalPaymentState,
  onPaymentComplete,
  openPaymentProcessingPopUp,
  paymentContext0,
  PaymentScreenMode,
  setPaymentScreenMode,
  order0
} from '@/react/PaymentView/PaymentView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import SmallHeightKeyboard from "@payment/SmallHeightKeyboard.tsx";
import { signal } from '@/react/core/reactive.ts';
import PaymentScreenTurnOnMasterDev from '@payment/PaymentScreenTurnOnMasterDev.tsx';
import OrderScreenRightContainer from '@order-view/OrderScreenRightContainer.tsx';
import { openCashDrawer } from '@/pos/cashdrawer.ts'
import clsx from "clsx";
import { toast } from "react-toastify";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import { isInSrmTrainingMode } from "@/data/DeviceSettingSignal";
import { PosFeatures, isFeatureEnabled } from "@/srm/features.logic";
import { removeDiscountBeforeSplit } from "@/react/OrderView/order-splitbill-utils.ts";
import { isAllowMultiplePayments, printerGeneralSetting0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { AccessPort, getAccessibility } from "@/react/DecentralizationPort.ts";
import VoucherPopup from "@/react/VoucherView/VoucherPopup.tsx";
import DiscountPopupItem from "@payment/DiscountPopupItem.tsx";
export const [isPaymentInsufficientPopupOpen, setIsPaymentInsufficientPopupOpen] = signal(false);
export const [isMasterMachineNotTurnedOn, setIsMasterMachineNotTurnedOn] = signal(false)

const PaymentScreenPlugin: FunctionComponent = () => {
  const { order0, setSeat } = useContext(ItemFactoryContext)
  const isSplitEnable = paymentContext0.splitEnable()

  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false);
  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false);

  const openVoucherPopup = useCallback(() => {
    userFLow(`openVoucherPopup in Payment`, {
      username: loginUser()?.name,
      orderId: order0?.()._id
    })
    if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
      type: 'info',
      autoClose: 500
    });
    setVoucherPopupOpen(true);
    // setIsExpanded(false);
  }, []);

  const closeVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(false);
  }, []);

  const openDiscountPopup = useCallback(() => {
    userFLow(`openDiscountPopup in Payment`, {
      username: loginUser()?.name,
      orderId: order0?.()._id
    })
    if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
      type: 'info',
      autoClose: 500
    });
    setDiscountPopupOpen(true);
    // setIsExpanded(false);
  }, []);

  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className="payment-screen bg-gray-solid-gray-140-f4f3f3 w-full h-full flex flex-col items-start justify-start text-center text-sm text-black-solid-black-900-1e1e23 font-mulish">
        <div className="overflow-y-auto self-stretch flex-1 flex flex-row items-start justify-between">
          <div
            className="self-stretch flex-1 flex flex-col items-start justify-start relative gap-[8px] h-full Tablet_600:gap-[8px]">
            <div
              className="self-stretch flex-1 flex flex-row items-start justify-start p-6 box-border gap-[24px] !bg-[url('/payment-left-content@3x.png')] bg-cover bg-no-repeat bg-[top] h-full z-[0] sm:p-4 sm:box-border Tablet_768:gap-[16px] Tablet_768:p-2 Tablet_768:box-border Tablet_600:gap-[12px]">
              <div className="self-stretch flex-1 flex flex-col items-center justify-between">
                <div
                  className="no-scrollbar overflow-y-auto self-stretch flex-1 flex flex-col items-center justify-start pt-0 px-0 pb-2">
                  <PaymentSelectItems />
                </div>
                <PaymentTipFee />
              </div>
              <div className="self-stretch flex-1 flex flex-col items-start justify-between">
                <PaymentInformation />
                <div className="self-stretch relative bg-white-opacity-white-opacity-0-ffffff h-1" />
                <PaymentKeyboard />
                <SmallHeightKeyboard />
              </div>
            </div>
            <PaymentSplitPopup />
          </div>
          <div
            className="!pt-3 self-stretch w-[280px] bg-white-solid-white-100-ffffff shrink-0 flex flex-col items-center justify-start pt-3 px-0 pb-0 box-border sm:w-[250px] sm:pt-2 sm:box-border Tablet_768:w-[230px] Tablet_768:pt-2 Tablet_768:box-border Tablet_600:w-[230px] Tablet_600:pt-2 Tablet_600:box-border mq1440:w-[400px] height_414:w-[230px] height_414:pt-2 height_414:box-border">
            <PaymentRightPathTopNav
              showCancel={false}
              showSplitViewBill
              paymentRightPathTopNavWidth="100%"
            />
            {isInSrmTrainingMode() &&
              <div className="self-stretch flex flex-col items-center justify-center pt-2.5 px-1 pb-0 z-[1]">
                <div
                  className="self-stretch rounded-10xs bg-cyan-solid-cyan-250-8aeaff flex flex-row items-center justify-start py-1 pr-1 pl-1.5 gap-[5px]">
                  <img
                    className="w-[22px] relative h-[22px] object-cover"
                    alt=""
                    src="/icontraining-mode-icon@2x.png"
                  />
                  <i
                    className="!text-left flex-1 relative [display:-webkit-inline-box] text-[10px] font-medium overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
                    {LL0().settings.inTrainingMode()}
                  </i>
                </div>
              </div>
            }
            <OrderScreenRightContainer />
          </div>
        </div>
        <div
          className="self-stretch [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] h-[50px] flex flex-row items-start justify-between py-2 px-4 box-border sm:pl-1 sm:pr-1 sm:box-border Tablet_768:h-[46px] mq1440:h-[58px]">
          <div
            className="self-stretch flex flex-row items-start justify-start gap-[12px] md:gap-[8px] sm:gap-[6px] Tablet_768:gap-[4px]">
            {!isSplitEnable &&
              <>
                <button
                  className=" self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-cyan-solid-cyan-400-4ad4cd overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border"
                  ref={makeRipple}
                  onClick={() => openCashDrawer()}
                >
                  <img
                    className="relative w-[30px] h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
                    alt=""
                    src="/payment-icondrawer-icon@2x.png"
                  />
                  <b className="relative leading-[17px] md:flex sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
                    {LL0().payment.drawer()}
                  </b>
                </button>
                {isFeatureEnabled(PosFeatures.RedBill) &&
                  <div
                    className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-pink-solid-pink-150-ffa8ff overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border"
                    ref={makeRipple}
                    onClick={() => setPaymentScreenMode(PaymentScreenMode.BILL)}
                  >
                    <img
                      className="relative w-[30px] h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
                      alt=""
                      src="/payment-ordervoucher-icon1@2x.png"
                    />
                    <b className="relative leading-[17px] sm:text-smi sm:flex Tablet_600:text-xs">
                      {LL0().order.viewBill()}
                    </b>
                  </div>
                }
                {(isFeatureEnabled(PosFeatures.MultiplePayment) && isAllowMultiplePayments()) &&
                  <MultiPaymentButton disable={!canSwitchMultiPayment()} />
                }
                <div
                  className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-yellow-solid-yellow-280-f6ca79 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[0px_3px] cursor-pointer sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:box-border"
                  onClick={openPaymentProcessingPopUp}
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
                    alt=""
                    src="/payment-iconmulti-payment-icon1@2x.png"
                  />
                  <b className="relative leading-[17px] sm:text-smi sm:flex Tablet_768:text-smi Tablet_600:text-xs">
                    {LL0().payment.terminal()}
                  </b>
                </div>
              </>
            }
            {isSplitEnable && <>
              <div
                className="flex shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] overflow-hidden flex-row items-center justify-center py-0.5 px-2 box-border gap-[3px] cursor-pointer"
                onClick={openVoucherPopup}
                ref={makeRipple}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
                  alt=""
                  src="/ordervoucher-icon2@2x.png"
                />
                <b className="relative leading-[17px] Tablet_768:text-xs">
                  {LL0().order.voucher()}
                </b>
              </div>
              {getAccessibility(AccessPort.DISCOUNT) &&
                <div
                  className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
                  onClick={openDiscountPopup}
                  ref={makeRipple}
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
                    alt=""
                    src="/orderdiscount-icon@2x.png"
                  />
                  <b className="relative leading-[17px] Tablet_768:text-xs">
                    {LL0().order.discount()}
                  </b>
                </div>
              }
            </>

            }
            <div className="self-stretch relative bg-white-opacity-white-opacity-0-ffffff w-1" />
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-end gap-[12px] md:gap-[8px] sm:gap-[6px] Tablet_768:gap-[4px]">
            {isFeatureEnabled(PosFeatures.SplitBill) &&
              <button
                disabled={isSplitEnable}
                className={clsx(
                  "disabled:opacity-25 self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-yellow-solid-yellow-250-ffdb5a overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border",
                  {
                    'opacity-70': blockMutateOrderButton()
                  }
                )}
                ref={makeRipple}
                onPointerDown={() => {
                  userFLow("onSeatToggle in Payment", {
                    username: loginUser()?.name,
                    orderId: order0?.()._id
                  })
                  if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
                    type: 'info',
                    autoClose: 500
                  });
                  removeDiscountBeforeSplit(order0?.()!);
                  paymentContext0.onSeatToggle();
                  setSeat?.(0)
                }}
              >
                <img
                  className="iconsplit-icon-payment--%123 w-[30px] relative h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
                  alt=""
                  src="/payment-iconsplit-icon@2x.png"
                />
                <b className="relative leading-[17px] sm:text-smi Tablet_600:text-xs">
                  {order0?.().seatMode ? LL0().order.reDivision() : LL0().fnBtn.paymentFunctions.splitCheck()}
                </b>
              </button>
            }

            <button
              className="disabled:opacity-25 self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-blue-solid-blue-350-2196f3 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 gap-[3px] cursor-pointer text-white-solid-white-100-ffffff sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border"
              disabled={isSplitEnable}
              ref={makeRipple}
              onClick={() => onPaymentComplete({
                needPrintInvoice: printerGeneralSetting0()?.autoPrintInvoiceBeforePaying
              })}
            >
              <img
                className="iconpay-icon--%123 w-[30px] relative h-[30px] object-cover md:flex sm:w-[26px] sm:h-[26px] Tablet_768:flex Tablet_768:w-[26px] Tablet_768:h-[26px]"
                alt=""
                src="/payment-iconpay-icon@2x.png"
              />
              <b className="relative leading-[17px] sm:text-smi Tablet_768:text-smi Tablet_600:text-xs">
                {LL0().payment.complete()}
              </b>
            </button>
          </div>
        </div>
      </div>

      {dialogProcessTerminalPaymentState().show && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <PaymentProcessingPopUp />
        </PortalPopup>
      )}
      {isPaymentInsufficientPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsPaymentInsufficientPopupOpen(false)}
        >
          <PaymentScreenPopup onClose={() => setIsPaymentInsufficientPopupOpen(false)} />
        </PortalPopup>
      )}
      {isMasterMachineNotTurnedOn() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsMasterMachineNotTurnedOn(false)}
        >
          <PaymentScreenTurnOnMasterDev onClose={() => setIsMasterMachineNotTurnedOn(false)} />
        </PortalPopup>
      )}
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup} order={order0?.()} />
        </PortalPopup>
      )}
      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopupItem onClose={closeDiscountPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(PaymentScreenPlugin);