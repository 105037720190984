import dayjs from "dayjs";
import { formatCurrencyDe } from "@/shared/utils";
import type { Order } from "@/data/Order.ts";
import type { Tax } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getUST_SCHLUESSEL } from "@/tse/dsfinv/dsfinvModel.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bonkopf_USt = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  UST_SCHLUESSEL: {
    type: "number",
    places: 0,
    hint: "ID des USt-Satzes"
    //todo: check again
  },
  BON_BRUTTO: {
    type: "number",
    places: 5,
    hint: "Bruttoumsatz"
    //todo: check again
  },
  BON_NETTO: {
    type: "number",
    places: 5,
    hint: "Nettoumsatz"
    //todo: check again
  },
  BON_UST: {
    type: "number",
    places: 5,
    hint: "USt"
    //todo: check again
  }
}

export function Bonkopf_UStFactory(order: Order, eod: Eod) {
  let taxDetail: Tax;
  let tax: number;

  return build();
  function build() {
    const rows = [];
    for (let _tax in order.vTaxSum) {
      taxDetail = order.vTaxSum[_tax];
      tax = parseInt(_tax);
      rows.push(buildSingle());
    }
    return rows;
  }

  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      BON_BRUTTO: BON_BRUTTO(),
      BON_NETTO: BON_NETTO(),
      BON_UST: BON_UST()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function UST_SCHLUESSEL() {
    // hint: "ID des USt-Satzes"
    //todo: not sure , must base on tax table
    return getUST_SCHLUESSEL(tax);
  }

  function BON_BRUTTO() {
    // hint: "Bruttoumsatz"
    return formatCurrencyDe(taxDetail.gross);
  }

  function BON_NETTO() {
    // hint: "Nettoumsatz"
    return formatCurrencyDe(taxDetail.net)
  }

  function BON_UST() {
    // hint: "USt"
    return formatCurrencyDe(taxDetail.tax)
  }
}