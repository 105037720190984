import _ from "lodash";
import dayjs from "dayjs";
import { formatCurrencyDe } from "@/shared/utils";
import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos, getUST_SCHLUESSEL, GV_TYP_ENUM } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getItemNet, getItemTax } from "@/pos/logic/order-reactive.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Z_GV_Typ = {
  Z_KASSE_ID: {
    type: "string", 
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  GV_TYP: {
    type: "string",
    maxLength: 30,
    hint: "Typ der Geschäftsvorfall-Art"
  },
  GV_NAME: {
    type: "string",
    maxLength: 40,
    hint: "Name der Geschäftsvorfall-Art"
  },
  AGENTUR_ID: {
    type: "number",
    places: 0,
    hint: "ID der Agentur"
  },
  UST_SCHLUESSEL: {
    type: "number",
    places: 0,
    hint: "ID des Umsatzsteuersatzes"
  },
  Z_UMS_BRUTTO: {
    type: "number",
    places: 5,
    hint: "Bruttoumsatz"
  },
  Z_UMS_NETTO: {
    type: "number",
    places: 5,
    hint: "Nettoumsatz"
  },
  Z_UST: {
    type: "number",
    places: 5,
    hint: "USt"
  }
}

enum Z_GV_TypMode {
  ORDER_DISCOUNT = 'ORDER_DISCOUNT',
  ITEM = 'ITEM',
  ITEM_DISCOUNT = 'ITEM_DISCOUNT'
}

function hasDiscount(discount: string | number | undefined) {
  //fixme: check case like N/A, ...
  return !!discount;
}

export function Z_GV_TypFactory(order: Order, eod: Eod) {
  let item: OrderItem;
  let mode: Z_GV_TypMode;
  return build();
  function build() {
    const rows = [];
    for (item of order.items) {
      mode = Z_GV_TypMode.ITEM;
      rows.push(buildSingle());
      if (hasDiscount(item?.discount) && !hasDiscount(order.discount)) {
        mode = Z_GV_TypMode.ITEM_DISCOUNT;
        rows.push(buildSingle());
      }
    }

    if (hasDiscount(order.discount)) {
      mode = Z_GV_TypMode.ORDER_DISCOUNT;
      rows.push(buildSingle());
    }

    return rows;
  }

  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      GV_TYP: GV_TYP(),
      GV_NAME: GV_NAME(),
      AGENTUR_ID: AGENTUR_ID(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      Z_UMS_BRUTTO: Z_UMS_BRUTTO(),
      Z_UMS_NETTO: Z_UMS_NETTO(),
      Z_UST: Z_UST()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses"
    return order.z;
  }

  function GV_TYP() {
    if (mode === Z_GV_TypMode.ITEM) {
      if (item?.isVoucher) {
        if (item?.price > 0) {
          return GV_TYP_ENUM.MehrzweckgutscheinKauf;
        } else {
          return GV_TYP_ENUM.MehrzweckgutscheinEinloesung;
        }
      }
      if (item?.name === 'Pfand Verkauf') {
        return GV_TYP_ENUM.Pfand;
      } else if (item?.name === 'Pfand Kauf') {
        return GV_TYP_ENUM.PfandRueckzahlung
      }
    } else if (mode === Z_GV_TypMode.ITEM_DISCOUNT) {
      return GV_TYP_ENUM.Rabatt;
    } else if (mode === Z_GV_TypMode.ORDER_DISCOUNT) {
      return GV_TYP_ENUM.Rabatt;
    }

    // hint: "Typ der Geschäftsvorfall-Art"
    return GV_TYP_ENUM.Umsatz;
  }

  function GV_NAME() {
    // hint: "Name der Geschäftsvorfall-Art"
    if (mode === Z_GV_TypMode.ITEM_DISCOUNT) {
      return `Einzelne Artikel: ${item?.name}`;
    }

    return '';
  }

  function AGENTUR_ID() {
    // hint: "ID der Agentur"
    return '';
  }

  function UST_SCHLUESSEL() {
    // hint: "ID des Umsatzsteuersatzes"
    return getUST_SCHLUESSEL(item?.tax!);
  }

  function Z_UMS_BRUTTO() {
    if (mode === Z_GV_TypMode.ITEM_DISCOUNT) {
      return formatCurrencyDe(item?.vDiscount!);
    }
    // hint: "Bruttoumsatz"
    return formatCurrencyDe(item?.vSum!);
  }

  function Z_UMS_NETTO() {
    if (mode === Z_GV_TypMode.ITEM_DISCOUNT) {
      return formatCurrencyDe(getItemNet(item) ?? 0);
    }
    // hint: "Nettoumsatz"
    return formatCurrencyDe(getItemNet(item) ?? 0);
  }

  function Z_UST() {
    if (mode === Z_GV_TypMode.ITEM_DISCOUNT) {
      return formatCurrencyDe(getItemTax(item) ?? 0);
    }
    // hint: "USt"
    return formatCurrencyDe(getItemTax(item) ?? 0);
  }
}