import { Button } from "@mui/material";
import { LL0 } from '@/react/core/I18nService.tsx';
import type { FunctionComponent } from "react";

export type ExplainHighPriorityPopupType = {
  onClose?: () => void
}
const ExplainHighPriorityPopup: FunctionComponent<ExplainHighPriorityPopupType> = ({onClose}) => {
  return (
    <div
      className="w-[532px] rounded bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] flex flex-col items-start justify-start pt-[27px] px-[30px] pb-6 box-border relative gap-[8px] max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <b className="relative z-[0]">
        {LL0().taxPayment.highPriorityPaymentMethod()}:
      </b>
      <div className="self-stretch relative text-black-solid-black-880-1d1d26 z-[1] whitespace-pre-line">
        {LL0().taxPayment.highPriorityPaymentMethodExplain()}
      </div>
      <div className="self-stretch flex flex-row items-center justify-end gap-[0px_12px] z-[2]">
        <Button
          className="cursor-pointer"
          disableElevation={true}
          color="primary"
          variant="contained"
          sx={{ borderRadius: "0px 0px 0px 0px" }}
          onClick={onClose}
        >
          {LL0().ui.ok()}
        </Button>
      </div>
      <img
        className="w-[26px] absolute !m-[0] top-[9px] right-[13px] h-[26px] overflow-hidden shrink-0 object-cover cursor-pointer z-[3]"
        alt=""
        src="/close-popup-icon--grey@2x.png"
        onClick={onClose}
      />
    </div>
  )
}

export default ExplainHighPriorityPopup