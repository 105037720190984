import dayjs from "dayjs";
import type { Eod } from "@/data/Eod.ts";
import { getUST_SCHLUESSEL, UST_SCHLUESSEL_MAP } from "@/tse/dsfinv/dsfinvModel.ts";
import { getInflateReport, getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Stamm_USt = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  UST_SCHLUESSEL: {
    type: "number",
    places: 0,
    hint: "ID des Umsatzsteuersatzes"
  },
  UST_SATZ: {
    type: "number",
    places: 2,
    hint: "Prozentsatz"
  },
  UST_BESCHR: {
    type: "string",
    maxLength: 55,
    hint: "Beschreibung"
  }
}

export function Stamm_UStFactory(eod: Eod) {
  let tax: number;

  return build();

  function build() {
    const rows = [];
    const report = getInflateReport(eod);

    const taxGroup = report?.taxGroup;
    if (!taxGroup) return []
    for (let _tax in taxGroup) {
      tax = parseInt(_tax);
      rows.push(buildSingle());
    }
    return rows;
  }

  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      UST_SATZ: UST_SATZ(),
      UST_BESCHR: UST_BESCHR(),
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses"
    return eod.z;
  }

  function UST_SCHLUESSEL() {
    // hint: "ID des Umsatzsteuersatzes"
    return getUST_SCHLUESSEL(tax);
  }

  function UST_SATZ() {
    // hint: "Prozentsatz"
    return tax;
  }

  function UST_BESCHR() {
    // hint: "Beschreibung"
    const taxId = UST_SCHLUESSEL()
    if (taxId === 0) return ''; //TODO: add later
    return UST_SCHLUESSEL_MAP[taxId];
  }
}