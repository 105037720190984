import debug from 'debug'
import { toast } from 'react-toastify'

import { User } from '@/data/User'
import { LL0 } from '@/react/core/I18nService'
import { signal } from '@/react/core/reactive'
import { handleError, progressToast } from '@/shared/decorators'

import { WebSrmUserError } from './errors'
import { getSignableSdkWithHeadersAndTaxNum } from './getSdkWithHeadersAndTaxNum'
import { CONSTANT_VALUES } from './lib/constants'
import { handleSrmError } from './lib/decorators'
import type { UserRequestBody } from './lib/types'
import { setNextTestcase } from './testcase/state'

const log = debug('data:srm-user')

export interface SrmUserModel {
  name?: string
  passcode?: string
  /** GST number */
  noTPS?: string
  /** QST number */
  noTVQ?: string
}

const [loading, setLoading] = signal(false)

export class SrmUserLogic {
  @progressToast(u => LL0().srm.messages.creatingUser({ name: u.name ?? '' }), undefined, setLoading)
  @handleSrmError()
  @handleError()
  async createUser(model: SrmUserModel) {
    const { sdk, headers } = getSignableSdkWithHeadersAndTaxNum()
    if (!model.name) throw new Error(LL0().srm.errors.invalidUserName({ name: '<empty>' }))
    if (!model.passcode) throw new Error(LL0().srm.errors.invalidPasscode())
    const existingUsers = await User.find({
      selector: {
        $or: [{ name: model.name }, { passcode: model.passcode }],
      },
    }).exec()

    if (existingUsers?.length > 1) throw new Error(LL0().srm.errors.userAlreadyExists())
    const user = await User.findOne({ selector: { name: model.name } }).exec()
    if (!user) throw new Error(LL0().srm.errors.invalidUserName({ name: model.name }))

    const body: UserRequestBody = {
      reqUtil: {
        modif: 'AJO',
        nomUtil: model.name,
        noTax: { noTPS: model.noTPS ?? '', noTVQ: model.noTVQ ?? '' },
      },
    }
    const res = await sdk.user(headers, body)

    const errors: string[] = []
    if (res?.retourUtil.noTax.noTPS === 'INV') errors.push(LL0().srm.errors.invalidGstNumber())
    if (res?.retourUtil.noTax.noTVQ === 'INV') errors.push(LL0().srm.errors.invalidQstNumber())
    if (res.retourUtil.casEssai && res.retourUtil.casEssai !== CONSTANT_VALUES.CASESSAI_EMPTY) {
      log('🧪 Next testcase is', res.retourUtil.casEssai)
      toast.info(`🧪 Next testcase is ${res.retourUtil.casEssai}`)
      setNextTestcase(res.retourUtil.casEssai)
    }
    await user.incrementalUpdate({
      $set: {
        srmSynced: !errors.length,
        srmSyncingError: errors.join(', '),
        srmSyncedName: model.name,
      },
    })
  }

  @progressToast(u => LL0().srm.messages.validateUser({ name: u.name ?? '' }), undefined, setLoading)
  @handleSrmError()
  @handleError()
  async validateUser(model: SrmUserModel) {
    const { sdk, headers } = getSignableSdkWithHeadersAndTaxNum()
    if (!model.name) throw new Error(LL0().srm.errors.invalidUserName({ name: '<empty>' }))
    if (!model.passcode) throw new Error(LL0().srm.errors.invalidPasscode())
    const user = await User.findOne({ selector: { name: model.name } }).exec()
    if (!user) throw new Error(LL0().srm.errors.invalidUserName({ name: model.name }))

    const body: UserRequestBody = {
      reqUtil: {
        modif: 'VAL',
        noTax: { noTPS: model.noTPS ?? '', noTVQ: model.noTVQ ?? '' },
      },
    }
    const res = await sdk.user(headers, body)

    const errors: string[] = []
    if (res?.retourUtil.noTax.noTPS === 'INV') errors.push(LL0().srm.errors.invalidGstNumber())
    if (res?.retourUtil.noTax.noTVQ === 'INV') errors.push(LL0().srm.errors.invalidQstNumber())

    if (res.retourUtil.casEssai && res.retourUtil.casEssai !== CONSTANT_VALUES.CASESSAI_EMPTY) {
      log('🧪 Next testcase is', res.retourUtil.casEssai)
      toast.info(`🧪 Next testcase is ${res.retourUtil.casEssai}`)
      setNextTestcase(res.retourUtil.casEssai)
    }
  }

  @progressToast(u => LL0().srm.messages.deletingUser({ name: u }), undefined, setLoading)
  @handleSrmError()
  @handleError()
  async deleteUser(userName: string) {
    const { sdk, headers, gstNumber, qstNumber } = getSignableSdkWithHeadersAndTaxNum()
    const body: UserRequestBody = {
      reqUtil: {
        modif: 'SUP',
        nomUtil: userName,
        noTax: { noTPS: gstNumber, noTVQ: qstNumber },
      },
    }
    const res = await sdk.user(headers, body)
    if (res.retourUtil.listErr?.length) throw new WebSrmUserError(res)
    if (res.retourUtil.casEssai && res.retourUtil.casEssai !== CONSTANT_VALUES.CASESSAI_EMPTY) {
      log('🧪 Next testcase is', res.retourUtil.casEssai)
      toast.info(`🧪 Next testcase is ${res.retourUtil.casEssai}`)
      setNextTestcase(res.retourUtil.casEssai)
    }
  }
}

export const srmUserLogic = new SrmUserLogic()
export const srmUserLogicLoading = loading
