import React, { CSSProperties, memo } from 'react';
import {
  menu0,
  product0,
  products0,
  section0,
  sections0,
  setEditMenuEditFormOpen,
  setProduct0,
  setSection0,
  setTempSwitchItem,
  tempSwitchItem
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import clsx from "clsx";
import { Product } from "@/data/Product.ts";
import { Section } from "@/data/Section.ts";
import { batch } from "@/react/core/reactive.ts";
import { peek } from "deepsignal/react";
import { toast } from "react-toastify";
import type { DocObservable } from "@/data/utils/data-utils-2.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { ITEM_MODE, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

const RenderProduct = memo(({ product, style }: { product: Product, style?: CSSProperties }) => {

  return (
    <div
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setEditorMode0(ITEM_MODE.ITEM);
        setProduct0(product)
        const switchItem = tempSwitchItem();
        if (switchItem && switchItem?.active && switchItem.type === "item") {
          if (switchItem.mode === "swap") {
            const currentProduct  = products0().find(p => p._id === product._id)
            const switchProduct = products0().find(p => p._id === switchItem._id)
            if (switchProduct && currentProduct) {
              batch(() => {
                switchProduct.get().layout!.order = peek(currentProduct.get().layout!, 'order')
                currentProduct.get().layout!.order = peek(switchItem, 'order')
                switchProduct.get().section = peek(currentProduct.get(), 'section')
                currentProduct.get().section = peek(switchItem, 'section')
                setProduct0(switchProduct)
                setTempSwitchItem()
              })
              toast.success('Switch success')
              return
            }
          }
        }
        setEditMenuEditFormOpen(true)
        setTempSwitchItem()
      }}
      style={{
        ...style,
        background: product?.layout?.color ?? '#FFFFFF',
        fontSize: (deviceSetting0()?.screenConfig?.productFontSize || 14),
        lineHeight: (`${deviceSetting0()?.screenConfig?.textLineHeight}px` || '14px')
      }}
      className={clsx(`bg-white border p-1 rounded-[4px] text-black overflow-hidden text-ellipsis`, { "border-2 border-[#FF0022]": product0()?._id === product?._id,  "italic skew-25 text-[#333333] border-black font-normal": product?.layout?.type === 'Extra'})}
    >
      {product?.id ? product?.id + '. ' : ''}{product?.name}
    </div>
  )
})

export const SectionGrid = memo(({ rows, columns = 3, rowGap, colGap, sectionId, style }: {
  rows: number,
  columns?: number,
  sectionId: string,
  rowGap?: number,
  colGap?: number,
  style?: CSSProperties
}) => {

  const products = products0()?.filter(product => product.get()?.section === sectionId)?.sort((a, b) => (a.get()?.layout?.order || 0) - (b.get()?.layout?.order || 0)) || []

  const calculatedColumns = Math.min(Math.ceil(products?.length / rows), columns);
  const getColumns = generalSetting0()?.useMenuV2 ? 6 : (menu0()?.maxColumn || 3)
  //todo: calculatedMaxWidth

  //todo: CHECK

  const style0 = {
    gridTemplateRows: `repeat(${rows}, auto)`,
    gridTemplateColumns: `repeat(${getColumns}, minmax(0, 1fr))`,
    // gridAutoRows: 'minmax(82px, auto)',
    alignItems: 'stretch',
    gridRowGap: `${rowGap}px`,
    gridColumnGap: `${colGap}px`,
    // maxWidth: products?.length < columns? calculatedColumns : '100%',
  }

  return (
    <>
      <div
        className="grid w-full"
        style={{...style0}}
      >
        {products.map((product, idx) => (
          <RenderProduct product={product.get()} key={idx} style={style}/>
        ))}
      </div>
    </>
  );
});
const RenderSectionWrapper = memo(({ section }: {
  section: DocObservable<Section>,
}) => {

  const [section1, setSection1] = section.signal
  const isSelected = section0()?._id === section?._id;

  return (
    <div
      className={clsx('p-2', { 'border-2': isSelected })}
    >
      <p
        onClick={async (e) => {
          const switchItem = tempSwitchItem();
          if (switchItem?.active && switchItem?.type === "section") {
            if (switchItem.mode === "swap") {
              const switchSection = sections0().find(s => s._id === switchItem._id)
              const currentSection = sections0().find(s => s._id === section._id)
              if (switchSection && currentSection) {
                batch(() => {
                  switchSection.get().order = peek(currentSection.get(), 'order')
                  currentSection.get().order = peek(switchItem, 'order')
                  setTempSwitchItem()
                  toast.success('Switch success')
                })
              } else {
                console.error('switch error')
              }
            } else {
              //todo: copy
            }
            return
          }
          batch(() => {
            setEditorMode0(ITEM_MODE.SECTION);
            setSection0(section1())
            setTempSwitchItem()
          });

        }}>{section.get()?.name}</p>
      <SectionGrid sectionId={section?._id!} rows={1} columns={3} />
    </div>
  )
});
export default RenderSectionWrapper;
