import { SrmTransactionLog } from '@/data/SrmTransactionLog'

import { PrintModes, PrintOptions, TransactionTypes } from './lib/enum'

export async function getLatestTransactionThatCanBeDuplicate(orderId: string): Promise<SrmTransactionLog | undefined> {
  return await SrmTransactionLog.findOne({
    selector: {
      ref: orderId,
      $or: [
        {
          'data.modImpr': PrintModes.bill,
          'data.formImpr': { $not: PrintOptions.notPrinted },
        },
        { 'data.modImpr': { $in: [PrintModes.cancellation, PrintModes.failureToPay] } },
      ],
    },
    sort: [{ date: 'desc' }],
  })
    .exec()
    .then(a => a?.toMutableJSON())
}

export async function getLatestOrderTransaction(...refs: (string | undefined)[]): Promise<SrmTransactionLog | undefined> {
  const ids = refs.filter(Boolean)
  if (!ids.length) return

  return await SrmTransactionLog.find({
    selector: { ref: { $in: ids } },
    sort: [{ date: 'desc' }],
    limit: 1,
  })
    .exec()
    .then(a => a.map(b => b.toMutableJSON()))
    .then(a => a[0])
}

export async function getLatestClosingReceipt(ref: string | undefined): Promise<SrmTransactionLog | undefined> {
  if (!ref) return

  return await SrmTransactionLog.find({
    selector: {
      ref,
      'data.typTrans': TransactionTypes.closingReceipt,
      'data.modImpr': { $nin: [PrintModes.reproduction, PrintModes.duplicate] },
    },
    sort: [{ date: 'desc' }, { 'data.datTrans': 'desc' }, { 'data.noTrans': 'desc' }],
    limit: 1,
  })
    .exec()
    .then(a => a.map(b => b.toMutableJSON()))
    .then(a => a[0])
}
