import type { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { createCollection } from "@/data/utils/migration.ts";

export const TSE_EXPORT_COLLECTION_NAME = 'tse_exports'

export const VERSION = 1;
const tseExportSchema = {
  title: 'tseExport schema',
  version: VERSION,
  primaryKey: "_id",
  type: "object",
  properties: {
    _id: { type: "string", maxLength: 24 },
    url: { type: "string" },
    type: { type: 'string', maxLength: 24 },
    from: { type: 'number'},
    to: { type: 'number' },
  },
  indexes: [],
}

export interface TseExport {
  _id?: string,
  url?: string,
  type?:  's3'|'tar',
  from?: number,
  to?: number,
}

export type TseExportCollection = RxCollection<TseExport>;
export type TseExportDocument = RxDocument<TseExport>

export const TseExportCollection: TseExportCollection = {} as TseExportCollection;

export const TseExport = TseExportCollection;

// @ts-ignore
window.TseExport = TseExport;

export const createTseExportCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: TseExport,
    collectionName: TSE_EXPORT_COLLECTION_NAME,
    version: VERSION,
    schema: tseExportSchema,
  });
};