import axios from 'axios'
import dayjs from 'dayjs'
import debug from 'debug'
import { awaitRxStorageReplicationInSync, getPreviousVersions, getPrimaryKeyOfInternalDocument, INTERNAL_CONTEXT_COLLECTION, writeSingle, type RxCollection } from 'rxdb'
// @ts-ignore
import type { RxReplicationState } from 'rxdb/src/plugins/replication'

import { dbReady, dbsList, masterAvailable, onlineMasterAvailable, setMasterAvailable } from '@/data/data-utils.ts'
import { replicationIdentifierPrefix } from '@/data/PosSyncHub.ts'
import { getRxdbClient, type RxdbClient, type RxReplicationStateClient } from '@/lib/rxdb-client.ts'
import { connectMasterToSocketServer } from '@/lib/rxdb-master.ts'
import { computed, effectOn, signal } from '@/react/core/reactive.ts'
import { clearNotificationToast, notificationToast } from '@/react/FloorPlanView/Noti.ts'
import { getApiUrl, getServer, getUrlSurrealDbCloud } from '@/shared/utils.ts'

import { SyncMode } from './data-enum'

import '@/data/data-commands.ts'

import { captureException } from '@sentry/react'
import Queue from 'queue'
import semver from 'semver'

import { collectionsMap } from '@/data/CollectionsMap.ts'
import { deviceSettingLock, syncMode0 } from '@/data/DeviceSettingHub.ts'
import { posSettingLock } from '@/data/PosSettingsSignal.ts'
import { posSync0, posSyncLock, reSyncDb } from '@/data/PosSyncState.ts'
import { firstTimeConnect, needWaitedForMaster, setNeedWaitedForMaster } from '@/data/ReplicateState.ts'
import { migrationPromise } from '@/data/utils/migration.ts'
import { allConfig, remoteConfigLock } from '@/extensions/firebase/useFirebase.ts'
import { getClientHandler } from '@/lib/client-caller/client-handler.ts'
import { masterIp } from '@/lib/fetch-master.ts'
import ClientCaller from '@/lib/master-caller/client-caller.ts'
import { getMasterHandler } from '@/lib/master-caller/master-handler.ts'
import { isMaster } from '@/lib/master-signal.ts'
import { rxdbSurrealClient } from '@/lib/surrealdb/rxdb-surreal-sync-client.ts'
import { rxdbSurrealMaster } from '@/lib/surrealdb/rxdb-surreal-sync-master.ts'
import type { RxdbSyncSurreal, RxSurrealDBReplicationState } from '@/lib/surrealdb/rxdb-surreal-sync.ts'
import { getSyncLock } from '@/lib/surrealdb/sync-state-lock.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { handleClickUpdate } from '@/react/Developer/UpdateNowPopup.logic.ts'
import { liveSurrealHook } from '@/react/utils/hooks.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import SurrealClient from '@/shared/SurrealClient.ts'
import { getUrlParam } from '@/shared/utils2.ts'

const log = debug('data:replicate')

const USE_SURREAL_REPLICATION = false
const IS_MASTER = !!getUrlParam('m') || false
const SYNC_ONLINE = !!getUrlParam('o') || false

export const offlineReplicate: { [k: string]: RxReplicationStateClient<any, any> } = {}
export const surrealReplicate: { [k: string]: RxSurrealDBReplicationState<any> } = {}

//@ts-ignore
window.setMasterAvailable = setMasterAvailable

setTimeout(() => setNeedWaitedForMaster(false), 5000)

//only for first time connect to cloud
export const [forceOnlineSync, setForceOnlineSync] = signal<boolean>(false)

const cancelLock = new MultiAwaitLock()

let [firstClientOfflineSync, setFirstClientOfflineSync] = signal<boolean>(true)

export const offlineSync = computed<boolean>(() => {
  if (!dbReady()) return false
  if (!posSync0()) return false
  if (!posSync0().id) return false
  if (!masterIp()) return false
  if (isMaster()) return true
  if (syncMode0() === SyncMode.offline) return true
  if (forceOnlineSync()) return false
  masterAvailable()
  if (firstClientOfflineSync() && syncMode0() === SyncMode.mixed) return true
  if (masterAvailable() && syncMode0() === SyncMode.mixed) return true
  return false
})

export const onlineSync = computed<boolean>(() => {
  forceOnlineSync()
  if (!posSync0()) return false
  if (!posSync0().id) return false
  if (isMaster()) return true
  if (syncMode0() === SyncMode.none || syncMode0() === SyncMode.offline) return false
  if (syncMode0() === SyncMode.online) return true
  if (!needWaitedForMaster() && !masterAvailable() && syncMode0() === SyncMode.mixed) return true
  if (offlineSync()) return false
  if (forceOnlineSync()) return true
  return false
})

let offlineReady = false
let offlineHoldingLock = false
let onlineHoldingLock = false
// offlineSyncState -> notConnected, masterReady, synced
export const offlineSyncReady = computed<boolean>(() => {
  if (!offlineSync()) return false
  if (isMaster()) return true
  if (masterAvailable()) return true
  return false
})

export const shouldWarnBecauseOffline = computed<boolean>(() => {
  if (isMaster()) return false
  if (!isMaster() && offlineSync() && !masterAvailable()) return true
  if (!isMaster() && onlineSync() && !onlineMasterAvailable()) return true
  return false
})

const [dataSynced, setDataSynced] = signal(false)

let notiId: string
effectOn([offlineSyncReady], async () => {
  if (offlineSyncReady() && !isMaster()) {
    notiId = notificationToast(`Client offline sync: ${masterIp()}`, { autoClose: 10000 * 3600 })
    await offlineReplicate['order']?.awaitInSync()
    setDataSynced(true)
  } else {
    clearNotificationToast(notiId)
    setDataSynced(false)
  }
})

const offlineQueue = new Queue({ autostart: true, concurrency: 1 })
effectOn([offlineSync, isMaster], async () => {
  log('offlineSync state', offlineSync())
  log('offline ready', offlineReady)
  offlineQueue.push(async () => {
    log('posSettingLock.acquireAsync')
    await posSettingLock.acquireAsync()
    log('deviceSettingLock.acquireAsync')
    await deviceSettingLock.acquireAsync()
    log('deviceSettingLock.release')
    await Promise.all(Object.keys(migrationPromise).map(key => migrationPromise[key]))
    if (isMaster() && offlineSync()) {
      if (!offlineReady) {
        offlineReady = true
        notificationToast(`Master offline sync ${masterIp()}`, { autoClose: 10000 * 3600 })
        await connectMasterToSocketServer({
          databases: dbsList.map(db => db.v),
          url: `ws://${getUrlParam('os') === 'android' ? '127.0.0.1' : 'localhost'}:8080`,
        })
        console.log('Connected all master')
      }
    } else {
      if (offlineSync()) {
        if (!offlineReady) {
          offlineReady = true
          await cancelLock.acquireAsync()
          if (!offlineSync()) return
          const collectionNames = collectionsMap().map(({ collection }) => collection.name)
          const rxdbClient = await getRxdbClient(collectionNames, `ws://${masterIp()}:8080`)
          const collections = collectionsMap('sync')
          const promises = collections.map(({ collection, name }) => {
            return createOfflineReplicate(rxdbClient!, collection, name)
          })
          await Promise.all(promises)
          console.log('offline acquired lock')
          offlineHoldingLock = true
          cancelLock.tryAcquire()
          setFirstClientOfflineSync(false)
        }
      } else {
        console.log('clear offline sync')
        const promises = []
        for (const { collection, name } of collectionsMap('sync')) {
          promises.push(
            new Promise(async resolve => {
              const syncLock = getSyncLock(name)
              await syncLock.acquireAsync()
              syncLock.release()
              await offlineReplicate[name]?.cancel()
              try {
                if (offlineReplicate[name]) await awaitRxStorageReplicationInSync(offlineReplicate[name] as any)
              } catch (e) {}
              delete offlineReplicate[name]
              resolve(null)
            })
          )
        }
        await Promise.all(promises)
        offlineReady = false
        if (offlineHoldingLock) {
          cancelLock.release().then()
        }
      }
    }
  })
})

let onlineReady = false
const onlineQueue = new Queue({ autostart: true, concurrency: 1 })

let onlineNotiId: string
effectOn([onlineSync], async () => {
  console.log('onlineSync state', onlineSync())
  console.log('online ready', onlineReady)
  onlineQueue.push(async () => {
    await posSettingLock.acquireAsync()
    await posSyncLock.acquireAsync()
    await Promise.all(Object.keys(migrationPromise).map(key => migrationPromise[key]))
    if (onlineSync()) {
      if (onlineReady) return
      onlineReady = true
      await cancelLock.acquireAsync()
      if (!onlineSync()) return
      console.log('creating online sync')
      onlineReady = true
      onlineNotiId = notificationToast('Online sync', { autoClose: 10000 * 3600 })
      const syncSurrealState: RxSurrealDBReplicationState<any>[] = []
      const rxdbSurreal: RxdbSyncSurreal = isMaster() && !firstTimeConnect.v ? rxdbSurrealMaster : rxdbSurrealClient
      await rxdbSurreal.initSurrealSync(
        `n${posSync0()?.id?.toString()}`,
        collectionsMap('all').map(({ collection, name }) => name),
        [
          'online_order',
          'menu',
          'online_provider',
          'product',
          'image',
          'category',
          'modifier',
          'order_layout',
          'product_layout',
          'print_image',
          'pos_setting',
          'eod_cache',
          'open_hour',
          'online_order_product_mapping',
        ],
        ['order']
      )
      await rxdbSurreal.initialize()
      await Promise.all(
        collectionsMap('sync').map(async ({ collection, name }) => {
          const syncState = await createSurrealReplicate(collection, name, rxdbSurreal)
          //@ts-ignore
          syncSurrealState.push(syncState)
        })
      )
      await Promise.all(syncSurrealState.map(state => state.start()))
      console.log('online acquired lock')
      onlineHoldingLock = true
      cancelLock.tryAcquire()
    } else {
      onlineReady = false
      if (Object.keys(surrealReplicate).length > 0) {
        clearNotificationToast(onlineNotiId)
        // notificationToast('clean up online sync', { autoClose: 5000 * 10 })
      }
      console.log('clear online sync')
      const promises = []
      for (const { collection, name } of collectionsMap('sync')) {
        promises.push(
          new Promise(async resolve => {
            const syncLock = getSyncLock(name)
            await syncLock.acquireAsync()
            syncLock.release()
            surrealReplicate[name]?.cancel()
            try {
              if (surrealReplicate[name]) await awaitRxStorageReplicationInSync(surrealReplicate[name] as any)
            } catch (e) {}
            delete surrealReplicate[name]
            resolve(null)
          })
        )
      }
      await Promise.all(promises)
      if (onlineHoldingLock) {
        cancelLock.release().then()
      }
    }
  })
})

export async function rePullCollection(collectionNames: string[]) {
  log(`Re-pulling ${collectionNames}`)
  if (reSyncDb.length > 0) {
    log(`Failed to re-pull ${collectionNames}`)
    console.log('Can not re-pull now')
    return false
  }
  await Promise.all(
    collectionsMap('sync')
      .filter(({ name }) => collectionNames.includes(name))
      .map(async ({ collection }) => {
        const collectionDocKeys = getPreviousVersions(collection.schema.jsonSchema).map(version => collection.name + '-' + version)
        const found = await collection.database.internalStore.findDocumentsById(
          collectionDocKeys.map(key => getPrimaryKeyOfInternalDocument(key, INTERNAL_CONTEXT_COLLECTION)),
          false
        )
        await Promise.all(
          found.map(async oldCollectionMeta => {
            const oldStorageInstance = await collection.database.storage.createStorageInstance({
              databaseName: collection.database.name,
              collectionName: collection.name,
              databaseInstanceToken: collection.database.token,
              multiInstance: collection.database.multiInstance,
              options: {},
              schema: oldCollectionMeta.data.schema,
              password: collection.database.password,
              devMode: false,
            })
            await writeSingle(
              collection.database.internalStore,
              {
                previous: oldCollectionMeta,
                document: Object.assign({}, oldCollectionMeta, {
                  _deleted: true,
                }),
              },
              'rx-migration-remove-collection-meta'
            )
            await oldStorageInstance.remove()
          })
        )
        await collection.destroy()
        await collection.remove()
      })
  )
  log(`Done re-pulling ${collectionNames}`)

  localStorage.setItem('RE_SYNC_DB', JSON.stringify(collectionNames))
  return true
}

effectOn([posSync0, isMaster], async () => {
  if (posSync0()?.id) {
    checkSyncUpdate()
    const masterHandler = getMasterHandler()
    const clientHandler = getClientHandler()
    if (isMaster()) {
      await masterHandler.startHandler()
      await clientHandler.stopHandler()
      ClientCaller.stopClientPingInterval()
    } else {
      await masterHandler.stopHandler()
      await clientHandler.startHandler()
      ClientCaller.startClientPingInterval()
    }
  }
})

export const getReplicates = () => {
  if (posSync0().syncProtocol === 'v2') {
    return surrealReplicate
  }
}

const storeData: any = {}

function pullFactory(collectionName: string) {
  return (data: any) => {
    console.log(`🔻 pull ${collectionName} : ` /*, data*/)
    // if (!storeData[collectionName]) storeData[collectionName] = []
    // storeData[collectionName].push(data)
    return data
  }
}

function pushFactory(collectionName: string) {
  return (data: any) => {
    console.log(`🔺 push ${collectionName} : `, data)
    if (data.doc) {
      console.log('delete data.doc')
      delete data.doc
    }
    return data
  }
}

async function createOfflineReplicate<
  T extends {
    updatedAt: number
    _id: string
  },
  V
>(rxdbClient: RxdbClient, collection: RxCollection<T, V>, collectionName: string) {
  await remoteConfigLock.acquireAsync(1000)
  offlineReplicate[collectionName] = await rxdbClient.replicateWithWebsocketServer({
    collection: collection,
    replicationIdentifier: 's_' + replicationIdentifierPrefix() + 'i_' + collectionName,
    validation: allConfig['offline_sync_validation']?.asBoolean(),
    dbName: collectionName,
  })

  rxdbClient.revalidateError$.subscribe(async ({ document, assumedMasterState }) => {
    captureException(
      'offline replicate error: ' +
        JSON.stringify({
          document,
          assumedMasterState,
        }),
      { tags: { type: 'sync' } }
    )
    console.error('offline replicate error', document, assumedMasterState)
    let doc = await collection.findOne({ selector: { _id: document._id } as any }).exec()
    await doc?.incrementalPatch({ updatedAt: dayjs(now()).unix() } as any)
  })
}

async function createSurrealReplicate<T, V>(collection: RxCollection<T, V>, collectionName: string, rxdbSurreal: RxdbSyncSurreal) {
  await posSyncLock.acquireAsync()
  let id = posSync0()?.id?.toString()
  const _collectionName = `n${id}_${collectionName}`

  const replicateState = await rxdbSurreal.replicateSurrealDB<T>({
    url: getUrlSurrealDbCloud(),
    dbName: `n${id}`,
    collection,
    collectionName,
    username: 'cloudUser',
    password: 'po0xuuGj08OY65z',
    replicationIdentifier: 's_' + replicationIdentifierPrefix() + 'i_' + collectionName,
    push: {
      batchSize: 200,
      modifier: pushFactory(_collectionName),
    },
    pull: {
      batchSize: 200,
      modifier: pullFactory(_collectionName),
    },
  })
  surrealReplicate[collectionName] = replicateState
  return replicateState
}

async function checkSyncUpdate(needReload: boolean = false) {
  if (!posSync0()?.id) return
  if (import.meta.env.VITE_APP_VERSION?.includes('local')) return
  const db = await SurrealClient.getSurrealClient('cloud')
  const versionQuery: Array<any> = await db.query(`SELECT * FROM StoreVersion:${posSync0().id}`)
  const versionCheck = versionQuery?.[0]?.[0]?.version
  const currentVersion = import.meta.env.VITE_APP_VERSION?.split('-')[0]
  const version = versionCheck?.split('-')[0]
  if (!!version && semver.gt(version, currentVersion)) {
    log('Need update new version', version, currentVersion)
    //todo: add more command (can add different command type to request) to handle update type (store or s3 or pop-up)
    if (needReload) {
      location.reload()
    } else {
      if (getUrlParam('os') === 'android') handleClickUpdate('store').then()
      else handleClickUpdate('s3').then()
    }
  } else if (currentVersion !== 'local' && isMaster() && getUrlParam('os') && (!version || semver.lt(version, currentVersion))) {
    log('Force updating new ver', version, currentVersion)
    await db.query(`UPSERT StoreVersion:${posSync0().id} SET version = "${currentVersion}"`)
    await db.query(`INSERT INTO Request { "storeId": ${posSync0().id}, "command": "update" }`)
  }
}

liveSurrealHook.on('request', async ([action, result]) => {
  if (result?.storeId !== posSync0()?.id) return
  if (action === 'CREATE') {
    if (result?.command === 'update') {
      checkSyncUpdate(true)
    }
  }
})
