import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { PaymentType } from '@/data/Payment'
import { OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaAdditionalTaxInfo, getCaTaxInfo } from './libs'
import { getLatestTransactionThatCanBeDuplicate } from '../transaction-history.service'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_01', null)
const [order04, setOrder04] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_04', null)
const [order07, setOrder07] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_07', null)
const [order09, setOrder09] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_09', null)
const [order11, setOrder11] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_11', null)
const [order14, setOrder14] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_14', null)
const [order17, setOrder17] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_17', null)
const [order19, setOrder19] = signalSyncedWithLocalStorage<O | null>('TESTCASE_003_ORDER_19', null)

const getBaseOrder = (): O => {
  const taxInfo = getCaTaxInfo()
  const order: O = {
    _id: uuid(),
    table: '11',
    status: OrderStatus.IN_PROGRESS,
    payments: [],
    tip: 0,
    discount: 0,
    multiplePriceMenu: '',
    discountSeat: [],
    items: [
      { name: 'Samosa', price: 20, quantity: 2, modifiers: [], ...taxInfo },
      { name: 'Garlic Bread', price: 5, quantity: 3, modifiers: [], ...taxInfo },
    ],
  }
  return order
}

export const testRunner003: Record<string, () => Promise<boolean>> = {
  '003.001': () =>
    execTestcase({
      num: 3,
      step: 1,
      device: 'A',
      run: async () => {
        const order: O = getBaseOrder()
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder01(order)
      },
    }),
  '003.002': () =>
    execTestcase({
      num: 3,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        await srmTransactionLogic.reproduceBill(o)
      },
    }),
  '003.003': () =>
    execTestcase({
      num: 3,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
  '003.004': () =>
    execTestcase({
      num: 3,
      step: 4,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '12' }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder04(order)
      },
    }),
  '003.005': () =>
    execTestcase({
      num: 3,
      step: 5,
      device: 'A',
      run: async () => {
        const o = order04()
        if (!o) throw new Error('Please run step 4 first')
        const t = await getLatestTransactionThatCanBeDuplicate(o._id)
        if (!t) throw new Error('Fail to get transaction!')
        await srmTransactionLogic.printDuplicate(t._id)
      },
    }),
  '003.006': () =>
    execTestcase({
      num: 3,
      step: 6,
      device: 'A',
      run: async () => {
        const o = order04()
        if (!o) throw new Error('Please run step 4 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o), { print: true })
      },
    }),
  '003.007': () =>
    execTestcase({
      num: 3,
      step: 7,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '13' }
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true })
        setOrder07(order)
      },
    }),
  '003.008': () =>
    execTestcase({
      num: 3,
      step: 8,
      device: 'A',
      run: async () => {
        const o = order07()
        if (!o) throw new Error('Please run step 7 first')
        await srmTransactionLogic.reproduceBill(o)
      },
    }),
  '003.009': () =>
    execTestcase({
      num: 3,
      step: 9,
      device: 'A',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: '14' }
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true })
        setOrder09(order)
      },
    }),
  '003.010': () =>
    execTestcase({
      num: 3,
      step: 10,
      device: 'A',
      run: async () => {
        const o = order09()
        if (!o) throw new Error('Please run step 9 first')
        const t = await getLatestTransactionThatCanBeDuplicate(o._id)
        if (!t) throw new Error('Fail to get transaction!')
        await srmTransactionLogic.printDuplicate(t._id)
      },
    }),
  '003.011': () =>
    execTestcase({
      num: 3,
      step: 11,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder11(order)
      },
    }),
  '003.012': () =>
    execTestcase({
      num: 3,
      step: 12,
      device: 'C',
      run: async () => {
        const o = order11()
        if (!o) throw new Error('Please run step 11 first')
        await srmTransactionLogic.reproduceBill(o)
      },
    }),
  '003.013': () =>
    execTestcase({
      num: 3,
      step: 13,
      device: 'C',
      run: async () => {
        const o = order11()
        if (!o) throw new Error('Please run step 11 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o, PaymentType.Credit), { print: true })
      },
    }),
  '003.014': () =>
    execTestcase({
      num: 3,
      step: 14,
      device: 'C',
      run: async () => {
        const order: O = {
          ...getBaseOrder(),
          _id: uuid(),
          table: undefined,
          items: [
            ...getBaseOrder().items,
            {
              name: 'Whiskey Chivas 8',
              price: 100,
              quantity: 1,
              modifiers: [],
              categories: ['5df6f5dd7854273fa4fd989e'], // Wine category
              ...getCaAdditionalTaxInfo(),
            },
          ],
        }
        await srmTransactionLogic.recordTemporaryBill(order, { print: true })
        setOrder14(order)
      },
    }),
  '003.015': () =>
    execTestcase({
      num: 3,
      step: 15,
      device: 'C',
      run: async () => {
        const o = order14()
        if (!o) throw new Error('Please run step 14 first')
        const t = await getLatestTransactionThatCanBeDuplicate(o._id)
        if (!t) throw new Error('Fail to get transaction!')
        await srmTransactionLogic.printDuplicate(t._id)
      },
    }),
  '003.016': () =>
    execTestcase({
      num: 3,
      step: 16,
      device: 'C',
      run: async () => {
        const o = order14()
        if (!o) throw new Error('Please run step 14 first')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(o, PaymentType.Cash, PaymentType.Debit), { print: true })
      },
    }),
  '003.017': () =>
    execTestcase({
      num: 3,
      step: 17,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order), { print: true })
        setOrder17(order)
      },
    }),
  '003.018': () =>
    execTestcase({
      num: 3,
      step: 18,
      device: 'C',
      run: async () => {
        const o = order17()
        if (!o) throw new Error('Please run step 17 first')
        await srmTransactionLogic.reproduceBill(o)
      },
    }),
  '003.019': () =>
    execTestcase({
      num: 3,
      step: 19,
      device: 'C',
      run: async () => {
        const order: O = { ...getBaseOrder(), _id: uuid(), table: undefined }
        await srmTransactionLogic.recordClosingReceipt(completeOrder(order, PaymentType.Debit), { print: true })
        setOrder19(order)
      },
    }),
  '003.020': () =>
    execTestcase({
      num: 3,
      step: 20,
      device: 'C',
      run: async () => {
        const o = order19()
        if (!o) throw new Error('Please run step 19 first')
        const t = await getLatestTransactionThatCanBeDuplicate(o._id)
        if (!t) throw new Error('Fail to get transaction!')
        await srmTransactionLogic.printDuplicate(t._id)
      },
    }),
}
