import { Menu, MenuTypes } from "@/data/Menu.ts";
import uuid from "time-uuid";
import { Category } from "@/data/Category.ts";
import { Section } from "@/data/Section.ts";
import { type Accessor, computed, selector, signal, useAsyncEffect } from "@/react/core/reactive.ts";
import { type DocObservable } from "@/data/utils/data-utils-2.ts";
import { Product } from "@/data/Product.ts";
import _ from "lodash";
import TextField from "@/react/core/TextField.tsx";
import { Button } from "@/components/ui/button.tsx";
import { convertDocument, type DocDeepSignal } from "@/data/data-utils.ts";
import { Fragment } from "react";
import GridLayout from "@/react/core/GridLayout.tsx";
import {
  calculateInitTax,
  onClickSwitch
} from "@/react/EditMenuView/EditMenuView.tsx";
import RenderCategory from "@/react/EditMenuView/Vertical/RenderCategoryVertical.tsx";
import RenderSectionWrapper from "@/react/EditMenuView/Vertical/RenderSections.tsx";
import { toast } from "react-toastify";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { GroupPrinter } from "@/data/GroupPrinter.ts";
import type { InputController } from "@/react/core/Input.tsx";
import { menuSelected, multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { dataLock } from "@/data/DataUtils.ts";
import { setOpenEditMenuForm } from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import { kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { clone } from '@atc-group/json-fn';
import { importProductLayouts } from "@/react/SyncMenuView/SyncMenuView.tsx";
import {
  setProductMatching,
  tempProductMatching
} from "@/react/PendingOrder/ProductMatchingLogic.ts";
import {
  editorMode0,
  ITEM_MODE,
  setEditorMode0,
  setTempItem,
  tempItem
} from "@/react/EditMenuView/EditMenuView.signal.ts";

//join
export const [menu0, setMenu0] = signal<DocDeepSignal<Menu, {}>>();
export const [categories0, setCategories0] = signal<DocObservable<Category>[]>();
export const [sections0, setSections0] = signal<DocObservable<Section>[]>();
export const [products0, setProducts0] = signal<DocObservable<Product>[]>();
export const [isEditMenuEditFormOpen, setEditMenuEditFormOpen] = signal<boolean>(false);
export const [category0, setCategory0] = signal<Category>();
export const [tempSwitchItem, setTempSwitchItem] = signal<any>();
export const [section0, setSection0] = signal<DocDeepSignal<Section>>();
export const [product0, setProduct0] = signal<Product>();
export const [currentCategory, setCurrentCategory] = signal<number>(0);
export const isSelected = selector<number>(currentCategory);
export const isSelectedSection = selector<number>(section0);
export const [isOpenPopupImport, setOpenPopupImport] = signal(false)

//@ts-ignore
window.section0 = section0
//@ts-ignore
window.product1 = product0
//@ts-ignore
window.setOpenPopupImport = setOpenPopupImport
//@ts-ignore
window.categories0 = categories0

export const categories2 = computed(() => {
  if (!menu0()) return [];
  const arr = [];
  //Duyệt theo left trước -> Sau đó là top
  for (let j = 0; j < (menu0()?.columns || 0); j++) {
    for (let i = 0; i < (menu0()?.rows || 0); i++) {
      const category = categories0()?.find(
        p => p.get()?.layout?.left === j && p.get()?.layout?.top === i
      );
      if (category) {
        arr.push(category.get());
      }
    }
  }
  return arr;
});

//@ts-ignore
window.categories2 = categories2
export const onImportProduct = async () => {
  const filteredImportProducts = importProductLayouts()
    .filter(productLayout => productLayout.type !== 'Extra')
    .map(productLayout => productLayout.product)

  const productOrder = await Product.findOne({
    selector: { section: section0()._id },
    sort: [
      { 'layout.order': 'desc' },
    ],
  }).exec()
  let orderNew = Number(productOrder?.layout?.order || 0)
  for (const product of filteredImportProducts) {
    const productLayout = importProductLayouts().find(p => p.product._id === product._id)
    if (!productLayout) continue
    const newLayout = {
      type: productLayout!.type,
      color: productLayout!.color,
      order: orderNew + 1
    }
    orderNew += 1
    _.assign(product, { section: section0()._id, layout: newLayout })
  }

  await Product.bulkUpsert(filteredImportProducts)
  setOpenPopupImport(false)
  setEditMenuEditFormOpen(false)
  setOpenEditMenuForm(false)
  toast.success('Import success!')
}

export async function deleteAllCategory() {
  const categories = await Category.find().exec()
  for (const category of categories) await category.remove()
  menu0().columns = 1
}

export const getPrinterClassname = (groupPrinter: GroupPrinter) => {
  if (product0()?.groupPrinter === groupPrinter._id) {
    return 'border-[2px] !border-[#8AEAFF] !bg-[#CEFFFF]';
  } else if (product0()?.groupPrinter2 === groupPrinter._id) {
    return 'border-[2px] !border-[#e556a3] !bg-[#FF938A]';
  } else if (product0()?.labelPrinter === groupPrinter._id) {
    return 'border-[2px] !border-[#8AEAFF] !bg-[#F4FF7C]';
  } else {
    return 'border-[0.5px] border-[#B1B1B1]';
  }
};
let i = 1

export const demoMenu = [{
  _id: '9fe08454-11f9-8888-9999',
  name: 'Vertical Menu',
  type: MenuTypes.LOCAL,
  position: 1,
  rows: 2,
  columns: 3,
  maxColumn: 3
}]

export async function getMenuCreateIfNotExist() {
  if (menu0()) return
  const menuCheck = await Menu.findOne({ selector: { type: MenuTypes.LOCAL } }).exec()
  if (!menuCheck) {
    const menu = await Menu.bulkInsert(demoMenu)
    setMenu0(convertDocument(menu.success[0], true))
  } else {
    const _menu = convertDocument(menuCheck, true)
    setMenu0(_menu)
  }
}

export async function addCategory() {
  await getMenuCreateIfNotExist()
  const category = await Category.findOne({
    selector: {
      'layout.top': { $exists: true },
      'layout.left': { $exists: true }
    },
    sort: [
      { 'layout.left': 'desc' },
      { 'layout.top': 'desc' }
    ],
  }).exec()
  let top = category?.layout?.top
  let left = category?.layout?.left
  if (top === undefined || left === undefined) {
    top = 0;
    left = 0;
  } else if (top === 0 && left === 0) {
    top = 1
    left = 0
  } else if (left > 0 && left > top && top < 1) {
    top += 1
  } else {
    top = 0
    left += 1
    if (left >= menu0()?.columns!) {
      _.assign(menu0(), { columns: left + 1 })
    }
  }
  console.log('insert: ', { left, top })
  const newCategory = await Category.insert({
    _id: uuid(),
    menus: [menu0()._id],
    type: 'vertical',
    layout: {
      top: top,
      left: left,
      color: '#FFFFFF'
    },
    name: 'C' + i,
  })
  i++
  if (!category) setCategory0(newCategory?.toMutableJSON())

  // open drawer when create new category
  setTimeout(() => {
    setCurrentCategory(categories0().length - 1)
    setCategory0(convertDocument(newCategory, true))
    setEditorMode0(ITEM_MODE.CATEGORY);
    setOpenEditMenuForm(true)
  }, 350)
}

export const onClickDelete = async () => {
  if (!editorMode0()) {
    return
  }
  if (editorMode0() === ITEM_MODE.ITEM) {
    const product = await Product.findOne({ selector: { _id: product0()._id } }).exec()
    product?.remove()
  } else if (editorMode0() === ITEM_MODE.SECTION) {
    const section = await Section.findOne({ selector: { _id: section0()._id } }).exec()
    section?.remove()
  } else if (editorMode0() === ITEM_MODE.CATEGORY) {
    const index = categories2().findIndex(category => category._id === category0()?._id || '');
    console.log('index is: ', index)
    const categoryDelete = await Category.findOne({ selector: { _id: category0()._id } }).exec()
    await categoryDelete?.remove()
    if (index === -1) return;
    for (let i = index; i < categories2().length; i++) {
      const c = _.cloneDeep(categories2()[i])
      if (!c?.layout) {
        console.log('not found layout , return')
        continue
      }
      setTimeout(async () => {
        const newLayout = { ...c.layout }
        if (c.layout!.top === 0) {
          newLayout.left! -= 1;
          newLayout.top = 1
        } else {
          newLayout.top = 0;
        }
        console.log(newLayout)
        _.assign(c, { layout: newLayout })
        await Category.upsert(c)
      }, 700)
    }
  }
  setEditorMode0()
  setEditMenuEditFormOpen(false)
  setOpenEditMenuForm(false)
}

export const onClickCopy = async () => {
  if (!editorMode0() || tempItem()?.active) {
    return
  }
  if (editorMode0() === ITEM_MODE.ITEM) {
    const product = _.cloneDeep(product0());
    const lastProduct = products0()?.reduce((maxProduct: null | DocObservable<Product>, s) =>
      s?.get().section === product?.section && (!maxProduct || s?.get().layout?.order > maxProduct?.get().layout?.order)
        ? s : maxProduct, null);

    _.assign(product.layout, { order: lastProduct ? lastProduct?.get()?.layout?.order + 1 : 0 });

    product._id = uuid();
    if (product.id) product!.id = (Number(product.id) + 1).toString();
    await Product.insert(product)
  }
  setEditMenuEditFormOpen(false)
  setOpenEditMenuForm(false)
}

export const onClickSwitchVertical = (mode: "copy" | "swap") => {
  setEditMenuEditFormOpen(false)
  setOpenEditMenuForm(false)
  if (!editorMode0() || tempItem()?.active) {
    setTempItem()
    return
  }
  if (editorMode0() === ITEM_MODE.ITEM) {
    setTempSwitchItem({
      active: true,
      type: editorMode0(),
      mode,
      _id: product0()._id,
      order: product0()?.layout?.order,
      section: product0()?.section
    })
  } else if (editorMode0() === ITEM_MODE.SECTION) {
    setTempSwitchItem({
      active: true,
      type: editorMode0(),
      mode,
      _id: section0()._id,
      order: section0()?.order,
    })
  } else if (editorMode0() === ITEM_MODE.CATEGORY) {
    setTempSwitchItem({
      active: true,
      type: editorMode0(),
      mode,
      _id: category0()._id,
      top: category0()?.layout?.top,
      left: category0()?.layout?.left,
    })
  }
  //todo: swap item & category
}

export const rowsCategoryArr = computed(() => new Array(menu0()?.rows).fill(0));
export const columnsCategoryArr = computed(() => new Array(menu0()?.columns).fill(0));

export const addSection = async (category: Category) => {
  if (!category) {
    toast.warning('Choose a category first!')
    return
  }
  const section = await Section.findOne({
    sort: [
      { 'order': 'desc' },
    ],
  }).exec()
  const newOrder = (section?.order || 0) + 1
  console.log(section?.order)
  const sectionId = await Section.insert({
    _id: uuid(),
    categories: [category._id],
    name: 'Section' + newOrder,
    order: newOrder,
  });
  // open drawer when create new section
  setSection0(convertDocument(sectionId, true))
  setEditorMode0(ITEM_MODE.SECTION);
  setEditMenuEditFormOpen(true)
}

function random2Number() {
  return Math.floor(Math.random() * 90) + 10;
}

export const addProduct = async (section: Section) => {
  if (!section) {
    toast.warning('Choose a section first!')
    return
  }
  const product = await Product.findOne({
    selector: { section: section._id },
    sort: [
      { 'layout.order': 'desc' },
    ],
  }).exec()
  const newOrder = (product?.layout?.order || 0) + 1
  const initGroupPrinter = kitchenGroupPrinters0()[0]
  const taxInit = calculateInitTax(initGroupPrinter)
  const price = random2Number()
  const newProduct = {
    _id: uuid(),
    section: section._id,
    layout: { order: newOrder, type: 'Article', color: '#FFFFFF' },
    price: price,
    name: 'Product' + price,
    groupPrinter: initGroupPrinter?._id,
    ...taxInit
  }
  const tempP = tempProductMatching()
  if (tempP) {
    _.assign(newProduct, { name: tempP.name, price: tempP.price })
    setProductMatching(newProduct._id)
  }
  // open drawer when create product
  const addProduct = await Product.insert(clone(newProduct));
  setProduct0(convertDocument(addProduct, true))
  setEditorMode0(ITEM_MODE.ITEM);
  setEditMenuEditFormOpen(true)
}

export const onToggleHappyHourVertical = (happyHourId: string) => {
  const happyHourIndex = product0().happyHours?.indexOf(happyHourId);
  const existingHappyHours = product0().happyHours || [];
  if (happyHourIndex === undefined || happyHourIndex === -1) {
    _.assign(product0(), { happyHours: [...existingHappyHours, happyHourId] });
    return;
  }
  existingHappyHours.splice(happyHourIndex, 1);
  _.assign(product0(), { happyHours: existingHappyHours });
}
export const onToggleCategoryVertical = (categoryId: string) => {
  const categoryIndex = product0().categories?.indexOf(categoryId);
  const existingCategory = product0().categories || [];
  if (categoryIndex === undefined || categoryIndex === -1) {
    _.assign(product0(), { categories: [...existingCategory, categoryId] });
    return;
  }
  existingCategory.splice(categoryIndex, 1);
  _.assign(product0(), { categories: existingCategory });
}

export const pushDataVertical = (inputControllers: Accessor<InputController[]>) => {
  inputControllers()[0].setKeys(product0()?.name?.split('') || []);
  inputControllers()[1].setKeys(product0()?.id?.split('') || []);
  if (generalSetting0().multipleDineInMenus && menuSelected() !== multipleMenus0()?.[0]) {
    let index = product0().menuPrices?.findIndex(m => m.menu === menuSelected());
    if (index !== -1) {
      inputControllers()[2].setKeys(product0()?.menuPrices?.[index!]?.value?.toString().split('') || []);
    }
  } else {
    inputControllers()[2].setKeys(product0()?.price?.toString()?.split('') || []);
  }
  inputControllers()[0].moveCaretToEnd();
  inputControllers()[1].moveCaretToEnd();
  inputControllers()[2].moveCaretToEnd();
};

const EditMenuVertical = () => {
  useAsyncEffect(async () => {
    await dataLock.acquireAsync();
    const _menu = await Menu.findOne({ selector: { type: MenuTypes.LOCAL } }).exec()
    if (_menu) {
      console.log('_menu is: ', _menu)
      setMenu0(convertDocument(_menu, true))
    }
    console.log('---------Edit Menu Vertical----------')
    //
    // await MakeObservableList(
    //   Category.find({ selector: { menus: { $exists: true }, type: { $exists: true } } }),
    //   (c) => (c.menus?.length || 0) > 0,
    //   doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
    //     .makeAutoSave().makeDebounce(100).exec()
    // )
    //   .addListSignal(categories0, setCategories0)
    //   .makeAutoPull()
    //   .pull();
    //
    // await MakeObservableList(
    //   Section.find(),
    //   (c) => true,
    //   doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
    //     .makeAutoSave().makeDebounce(100).exec()
    // )
    //   .addListSignal(sections0, setSections0)
    //   .makeAutoPull()
    //   .pull();
    //
    // await MakeObservableList(
    //   Product.find({
    //     selector: {
    //       'layout.order': { $exists: true },
    //       section: { $exists: true }
    //     }
    //   }),
    //   (c) => !!c?.section,
    //   doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
    //     .makeAutoSave().makeDebounce(100).exec()
    // )
    //   .addListSignal(products0, setProducts0)
    //   .makeAutoPull()
    //   .pull();
  })

  return <div>
    <div>------EDIT MENU------</div>
    <Button onClick={() => router.screen = PosScreen.VERTICAL_EDIT_MENU as PosScreen}>edit menu -demo</Button>
    <Button onClick={() => router.screen = PosScreen.VERTICAL_ORDER_VIEW as PosScreen}>order -demo</Button>
    <Button onClick={() => router.screen = PosScreen.VERTICAL_ORDER as PosScreen}>order -vertical</Button>
    <div className={'flex flex-row gap-5'}>
      <button onClick={async () => {
        await Menu.insert({
          _id: uuid(),
          type: MenuTypes.LOCAL,
          rows: 2,
          columns: 3,
          maxColumn: 3
        })
      }}>Add menu (in demo data)
      </button>
      <button onClick={addCategory}>Add Category
      </button>
      <button onClick={async () => {
        addSection(category0())
      }}>Add Section
      </button>
      <button onClick={async () => {
        addProduct(section0())
      }}>Add Product
      </button>
    </div>
    <div>List Categories / selectable</div>
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 px-[5px] pb-[5px] box-border bg-blue-300">
      <GridLayout rows={2} cols={menu0()?.maxColumn || 0} colGap={5} rowGap={5}
                  className="h-full flex-grow self-center"
                  style={{ height: (2) * 38 - 5 }}
      >
        {rowsCategoryArr().map((item: number, top: number) => (<Fragment key={top}>
          {columnsCategoryArr().map((_item: number, left: number) => (
            <RenderCategory top={top} left={left} key={`${top}/${left}`} />
          ))}
        </Fragment>))}
      </GridLayout>
    </div>
    <div className="flex flex-row w-full gap-10 mt-3">
      <div>
        <div>List Section + Product</div>
        <div className='p-2 bg-blue-300'>
          {sections0()?.filter(s => s.get()?.categories?.includes(category0()?._id))?.sort((a, b) => (a.get()?.order || 0) - (b.get()?.order || 0))?.map((section, index) => (
            <RenderSectionWrapper key={index} section={section} />
          ))}
        </div>
      </div>
      <div className="flex flex-col pt-2 gap-3">
        <Button onClick={onClickDelete}>Delete</Button>
        <Button onClick={() => onClickSwitch('swap')}>Switch</Button>
        <div className="flex flex-row">
          <TextField
            value={category0()?.name || ''}
            label="Category"
            onChange={(e) => _.assign(category0(), { name: e.target.value })}
          />
          <Button onClick={deleteAllCategory}>Delete all</Button>
        </div>
        <div className="flex flex-row">
          <TextField
            value={section0()?.name || ''}
            label="Section"
            onChange={(e) => _.assign(section0(), { name: e.target.value })}
          />
          <TextField
            type={'number'}
            value={section0()?.order || '0'}
            label="Order Section"
            onChange={(e) => _.assign(section0(), { order: e.target.value })}
          />
          <Button onClick={async () => {
            const sections = await Section.find().exec()
            for (const s of sections) await s.remove()
          }}>Delete all</Button>
          <Button onClick={async () => {
            await onClickCopy()
          }}>Copy</Button>
        </div>
        <div className="flex flex-row">
          <TextField
            value={product0()?.name || ''}
            label="Product"
            onChange={(e) => _.assign(product0(), { name: e.target.value })}
          />
          <TextField
            type={'number'}
            value={product0()?.layout?.order || '0'}
            label="Order Product"
            onChange={(e) => _.assign(product0(), { name: e.target.value })}
          />
        </div>
      </div>
    </div>
  </div>
}

export default EditMenuVertical