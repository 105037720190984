import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import dayjs from "dayjs";
import { Bonpos } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bon_Referenzen = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  POS_ZEILE: {
    type: "string",
    hint: "Zeilennummer des referenzierenden Vorgangs (nicht bei Verweis aus einem Bonkopf heraus)"
  },
  REF_TYP: {
    type: "string",
    maxLength: 20,
    hint: "Art der Referenz"
  },
  REF_NAME: {
    type: "string",
    maxLength: 40,
    hint: "Beschreibung bei Art \"ExterneSonstige"
  },
  REF_DATUM: {
    type: "datetime",
    hint: "Zeitstempel des Vorgangs, auf den referenziert wird "
  },
  REF_Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  REF_Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  REF_BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  }
}

export function Bon_ReferenzenFactory(order: Order, refOrder: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  return build();
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      REF_TYP: REF_TYP(),
      REF_NAME: REF_NAME(),
      REF_DATUM: REF_DATUM(),
      REF_Z_KASSE_ID: REF_Z_KASSE_ID(),
      REF_Z_NR: REF_Z_NR(),
      REF_BON_ID: REF_BON_ID()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return order.z;
  }

  function BON_ID() {
    // hint: "Vorgangs-ID",
    return order.id;
  }

  function POS_ZEILE() {
    // hint: "Zeilennummer des referenzierenden Vorgangs (nicht bei Verweis aus einem Bonkopf heraus)",
    if (isCancelled) {
      return order.items.indexOf(item) + 1;
    } else {
      return order.cancellationItems!.indexOf(item) + 1;
    }
  }

  function REF_TYP() {
    // hint: "Art der Referenz",
    return 'Transaktion';
  }

  function REF_NAME() {
    // hint: "Beschreibung bei Art “ExterneSonstige”",
    return '';
  }

  function REF_DATUM() {
    // hint: "Zeitstempel des Vorgangs, auf den referenziert wird ",
    return dayjs.unix(refOrder.date!).toISOString();
  }

  function REF_Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id;
  }

  function REF_Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return refOrder.z;
  }

  function REF_BON_ID() {
    // hint: "Vorgangs-ID",
    return refOrder.id;
  }
}
