import { CommitAction, type ItemCommit, type OrderItem, type TOrder } from "@/pos/OrderType.ts";
import _ from "lodash";
import uuid from "time-uuid";
import { Discount } from "@/data/Discount.ts";

export function addItemFactory() {
  return {
    addItem,
    addModifier,
    addItemDiscount,
    addItemCourse
  }

  function addItem(order: TOrder, item: OrderItem, seat: number | undefined, newId: string, isSplitItem?: boolean, splitInfo?: any) {
    const commitInfo = {
      action: CommitAction.ADD_PRODUCT,
      ref: item.originalProductId,
      _id: newId,
      productRef: {
        id: item.id,
        name: item?.name ?? '',
        price: item.price,
        taxes: item.taxes,
        ingredients: item.ingredients,
        ...(item?.categories && item.categories!.length > 0 && { categories: item.categories }),
        taxComponents: item.taxComponents,
        taxComponents2: item.taxComponents2,
      },
      groupPrinter: item.groupPrinter,
      groupPrinter2: item.groupPrinter2,
      labelPrinter: item.labelPrinter,
      quantity: 1,
      ...order.seatMode && { seat: seat }
    }

    //add split item
    if (isSplitItem) {
      const { _id: orgId, item: currentItem, originalInfo, srm_originalTransactionId } = splitInfo;
      _.assign(commitInfo, {
        action: CommitAction.SPLIT_ITEM,
        quantity: item.quantity, ref: orgId,
        commitId: orgId,
        printed: item.printed,
        printedRound: item.printedRound,
        srm_originalTransactionId: srm_originalTransactionId
      })

      if (item?.splitId) {
        const { originalInfo: itemInfo } = item
        _.assign(commitInfo, {
          oldId: itemInfo?.oldId,
          oldQuantity: itemInfo?.oldQuantity,
          oldPrice: itemInfo?.oldPrice
        })
      } else {
        _.assign(commitInfo, {
          oldId: originalInfo?._id,
          oldQuantity: originalInfo?.quantity,
          oldPrice: originalInfo?.price
        })
      }

      if (currentItem && currentItem?.seat != null) {
        // add originalSeat and originalQuantity to handle same _id items in different seat
        _.assign(commitInfo, { originalSeat: currentItem?.seat, originalQuantity: currentItem?.quantity })
      }
    }

    order.commits?.push(commitInfo as ItemCommit)
  }

  function addModifier(order: TOrder, modifiers: any[], seat: number | undefined, newId: string) {
    if (modifiers?.length === 0) return
    for (const modifier of modifiers) {
      order.commits?.push({
        action: CommitAction.ADD_MODIFIER,
        quantity: 1,
        ref: newId,
        _id: uuid(),
        productRef: { name: modifier.name, price: modifier.price },
        commitId: newId ?? '',
        ...order.seatMode && { seat: seat }
      })
    }
  }

  function addItemDiscount(order: TOrder, newId: string, item: OrderItem) {
    const hasDiscountItem = item.discount && !order?.discount;
    if (!hasDiscountItem) return
    const isPercent = String(item.discount!).includes('%')
    const discount: Discount = {
      value: isPercent ? Number(String(item?.discount).replace('%', '')) : Number(item?.discount),
      type: isPercent ? 'percent' : 'amount'
    }
    order.commits?.push({
      action: CommitAction.ADD_DISCOUNT_ORDER_ITEM,
      commitId: newId,
      type: discount.type ?? 'percent',
      value: discount.value ?? 0,
      label: '',
    })
  }

  function addItemCourse(order: TOrder, item: OrderItem, newId: string) {
    if (item.course === 1) return
    order?.commits?.push({
      action: CommitAction.SET_COURSE,
      course: Number(item.course || 0),
      commitId: newId
    })
  }
}