import type Printer from "@/shared/printer/pure-image-printer-parallel";
import { ImagePrinter, ImagePrinterLock } from "@/lib/entries.ts";

export async function createPrinter(): Promise<Printer> {
  await ImagePrinterLock.acquireAsync();
  return new ImagePrinter(560, {
    printFunctions: {
      printRaster: async ({ data, width, height }) => {
        return { data, width, height };
      }
    },
    defaultPrintTarget: 'raster'/* : 'raster'*/
  });
}

export function staticCreatePrinter(Printer2: typeof Printer): Printer {
  return new Printer2(560, {
    printFunctions: {
      printRaster: async ({ data, width, height }) => {
        return { data, width, height };
      }
    },
    defaultPrintTarget: 'raster'/* : 'raster'*/
  });
}