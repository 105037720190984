import { getDeviceId } from '@/shared/getDeviceId'
import { deviceSettingLock } from '@/data/DeviceSettingHub.ts'
import SurrealClient from "@/shared/SurrealClient.ts";
import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { posSettingLock } from "@/data/PosSettingsSignal.ts";
import { exportMeta } from "@/data/fix/backup-indexeddb.ts";
import { toast } from "react-toastify";
import { deviceSetting0, deviceSettings0 } from "@/data/DeviceSettingSignal.ts";
import { masterId } from "@/lib/master-signal.ts";
import dayjs from "dayjs";
import type Surreal from "surrealdb.js";
import { idleHandler } from "@/lib/idle-share.ts";
import { captureException } from '@sentry/react';
import _ from 'lodash';

export const sendDeviceInfo = async () => {
  const id = getDeviceId()
  if (!deviceSetting0()?.cloudRegister || !id || !posSync0()?.id) return
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    await db.query(`UPSERT Device:${id} SET onlineDate = '${new Date()}';`)
  } catch (e: any) {
    console.log('Send device info failed: ', e.message)
  }
}


export const sendDeviceName = async () => {
  await deviceSettingLock.acquireAsync()
  await posSyncLock.acquireAsync()
  await posSettingLock.acquireAsync()
  const id = getDeviceId()
  if (!id || !posSync0()?.id) return
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    const ip = deviceSetting0()?.publicIp
    const name = deviceSetting0()?.name
    const show = deviceSetting0()?.show
    const devicePos = deviceSetting0()?.devicePos
    await db.query(`UPSERT Device:${id} SET ip = "${ip}" , name = "${name}" , show = ${show} , devicePos = ${devicePos}`)
    await db.query(`UPDATE PosUser:${posSync0()!.id} SET masterDevice = '${masterId()}';`)
  } catch (e: any) {
    console.log('Send device name failed: ', e.message)
  }
}

async function fetchOnlineDate(db: Surreal) {
  const [devices]: any = await db.query(`SELECT * FROM Device WHERE store = ${posSync0().id}  AND show = true;`)
  if (!devices?.length) return
  for (const device of devices) {
    const ds = deviceSettings0().find(d => d._id === device.id?.id?.toString())
    if (ds) {
      _.assign(ds, { date: dayjs(device.onlineDate as string).unix() })
    }
  }
}

export const syncDeviceOnlineDate = async () => {
  await posSyncLock.acquireAsync()
  await deviceSettingLock.acquireAsync()
  if (!posSync0()?.id) return
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    fetchOnlineDate(db).then()
    const [deviceLiveId] = await db.query(`LIVE SELECT * FROM Device WHERE store = ${posSync0().id} AND show = true;`)
    await db.subscribeLive(deviceLiveId as any, (action, result) => {
      if (result?.store !== posSync0()?.id || !result.onlineDate) return
      if (action === "UPDATE") {
        const ds = deviceSettings0().find(d => d._id === result.id?.id?.toString())
        if (ds) {
          _.assign(ds, { date: dayjs(result.onlineDate as string).unix() })
        }
      }
    })
  } catch (e: any) {
    captureException(new Error('syncDeviceOnlineDate failed: ', e.message))
  }
}
