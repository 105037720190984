import { convertDocuments } from '@/data/data-utils'
import { DATA_INITED } from './data-const'
import { dataLock } from '@/data/DataUtils.ts'
import { initDemoData } from '@/data/dev.ts'
import { PosSetting } from '@/data/PosSetting'
import {
  companyInfo0,
  generalSetting0,
  isQuebecSrmEnabled,
  posSetting0,
  posSettingLock,
  printerGeneralSetting0,
  setPosSettings0
} from '@/data/PosSettingsSignal.ts'
import { effect, effectOn, signal } from '@/react/core/reactive'
import { wsSendInfo, wsSendOrderConfig } from '@/react/websocket/ws-send'
import { secondDisplayLock } from '@/react/websocket/ws-state'
import { orderConfig } from '@/shared/order/order-config.ts'
import { posSync0 } from "@/data/PosSyncState.ts";
import _ from "lodash";
import { isGermany, } from "@/react/CompanyInformationView/companyInfomation.logic.ts";
import debug from "debug";
import { rnHost } from "@/shared/webview/rnwebview.ts";

const log = debug('data:pos-setting-hub');

export const [posSettingV, setPosSettingV] = signal(1)

export const setServer = async (server: string) => {
  posSync0().server = server
}

export const [ignoreSetMasterDevice, setIgnoreSetMasterDevice] = signal(false)

effectOn(
  [posSettingV],
  async () => {
    await dataLock.acquireAsync()
    try {
      const needInitStoreStep = localStorage.getItem("needInitStoreStep");
      let _posSettings = await PosSetting.find().exec()
      if (_posSettings.length === 0 && initDemoData() && !localStorage.getItem(DATA_INITED) && !posSync0()?.id && !needInitStoreStep) {
        localStorage.setItem(DATA_INITED, 'true')
        const { initNewDatabase } = await import('@/data/ImportData.ts')
        await initNewDatabase()
        _posSettings = await PosSetting.find().exec()
      }

      const posSettings = convertDocuments<PosSetting>(_posSettings, true, [])
      setPosSettings0(posSettings)
      if (!orderConfig.force) {
        orderConfig.beginHour = posSettings[0]?.generalSetting?.beginHour || '06:00'
        orderConfig.isNetPriceBase = !(posSettings[0]?.generalSetting?.isProductPriceIncludingTax || false)
        if (isQuebecSrmEnabled()) {
          orderConfig.sumPrecision = 2
        } else if (posSettings[0]?.companyInfo?.country === 'de') {
          orderConfig.sumPrecision = 4
        } else {
          orderConfig.sumPrecision = posSettings[0]?.generalSetting?.decimalPointExpressed || 4
        }
        orderConfig.trackCancelBeforePrint = posSettings[0]?.generalSetting?.quebecSrmEnable || false
      }

      // if (posSetting0() && !posSetting0()?.cashbook) {
      //   addDemoCashbookData().then()
      // }

      // if (posSettings[0]?.companyInfo?.logo) {
      //   delete posSettings[0].companyInfo.logo
      // }
      if (posSettings[0]?.generalSetting?.secondDisplayLanguage) {
        const check = localStorage.getItem('set-sd-language')
        //@ts-ignore
        const updatedAt = posSettings[0]?.updatedAt?.toString()
        if (check !== updatedAt) {
          rnHost.sendSDLanguage(posSettings[0]?.generalSetting?.secondDisplayLanguage).catch(e => {
            console.error(e)
          })
          localStorage.setItem('set-sd-language', updatedAt)
        }
      }
      await posSettingLock.release();
    } catch (e) {
      console.error(e);
    }
  } /*, {defer: true}*/
)
;(async () => {
  await dataLock.acquireAsync()
  PosSetting.$.subscribe(() => setPosSettingV(posSettingV() + 1))
})()

//@ts-ignore
window.posSetting0 = posSetting0
//@ts-ignore
window.companyInfo0 = companyInfo0
//@ts-ignore
window.printerGeneralSetting0 = printerGeneralSetting0

//for rear display screen
effectOn([companyInfo0, generalSetting0], async () => {
  await dataLock.acquireAsync()
  const companyInfo = companyInfo0()
  if (isGermany()) {
    if (companyInfo0()?.country === 'de') return;
    _.assign(companyInfo0(), { country: 'de' })
  }
  if (companyInfo) {
    rnHost.sendCompanyInfo(JSON.stringify(companyInfo)).catch(e => {})
    await secondDisplayLock.acquireAsync()
    wsSendInfo(companyInfo)
  }
  //TODO: Make this required props inPosSetting
  const generalSetting = generalSetting0()
  if (generalSetting) {
    await secondDisplayLock.acquireAsync()
    const { beginHour, isProductPriceIncludingTax } = generalSetting
    wsSendOrderConfig({ beginHour, isProductPriceIncludingTax })
  }
})
// @ts-ignore
window.generalSetting0 = generalSetting0

effectOn([isGermany],()=>{
  if (isGermany()) {
    _.assign(generalSetting0(), { allowMultiplePayments: false })
  }
})