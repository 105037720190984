import { posSync0, posSyncLock } from "@/data/PosSyncState.ts";
import { idleHandler } from "@/lib/idle-share.ts";
import SurrealClient from "@/shared/SurrealClient.ts";
import _ from "lodash";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { handleClickUpdate } from "@/react/Developer/UpdateNowPopup.logic.ts";
import { deviceSettingLock } from "@/data/DeviceSettingHub.ts";
import { getDeviceId } from "@/shared/getDeviceId.ts";
import { captureException } from "@sentry/react";
import { posSettingLock } from "@/data/PosSettingsSignal.ts";
import { liveSurrealHook } from "@/react/utils/hooks.ts";
import { exportMeta } from "@/data/fix/backup-indexeddb.ts";
import { toast } from "react-toastify";
import { effectOn } from "@/react/core/reactive.ts";
import { updateFlow } from "@/shared/logger.ts";
import { getNatsClient, getNatsClientLock } from "@/lib/entries";

let latestRequest = ''
const modeUpdate = ['store', 's3', 'electron']
const handleUpdate = _.debounce(async (result: any) => {
  notificationToast(`Update device now`, { autoClose: 5000 })
  await handleClickUpdate(result.mode, result.version, result.downloadUrl)
}, 300)

export const listenRequestFromNats = async () => {
  await posSyncLock.acquireAsync()
  await getNatsClientLock.acquireAsync();
  const natsClient = getNatsClient()
  await natsClient.subscribeToSubject(`${posSync0().id}-request`, async (request: any) => {
    if (request.storeId !== posSync0().id || request.deviceId !== getDeviceId() || latestRequest === request._id) return
    if (!modeUpdate.includes(request.mode)) return
    const timeout = parseInt(request.timeout || 0)
    updateFlow(`Receiver request with mode ${request.mode}, version: ${request.version}, timeout ${timeout}ms..`)
    latestRequest = request._id
    if (!timeout) {
      const db = await SurrealClient.getSurrealClient('cloud')
      if (db) {
        updateFlow(`SET response = 'ok'`)
        await db.query(`UPDATE Request SET response = 'ok' where _id = '${request._id}' AND mode = '${request.mode}';`)
      }
      handleUpdate(request)
    } else {
      idleHandler(timeout, 'update', async () => {
        const db = await SurrealClient.getSurrealClient('cloud')
        if (db) {
          await db.query(`UPDATE Request SET response = 'ok' where _id = '${request._id}' AND mode = '${request.mode}';`)
        }
        handleUpdate(request)
      }, { interval: timeout * 0.3 })
    }
  })
}


export const checkRequestUpdate = async () => {
  await deviceSettingLock.acquireAsync()
  await posSyncLock.acquireAsync()
  try {
    const db = await SurrealClient.getSurrealClient('cloud')
    if (!db) return
    const id = getDeviceId()
    // @ts-ignore
    const [[request]] = await db.query(`SELECT * FROM Request WHERE deviceId = '${id}' ORDER BY date DESC LIMIT 1;`)
    if (!request || !request?.id || request?.response) return
    if (!modeUpdate.includes(request.mode)) return
    const timeout = parseInt(request.timeout || 0)
    updateFlow(`receiver request with mode ${request.mode}, version: ${request.version}, timeout ${timeout}ms`)
    if (!timeout) {
      updateFlow(`SET response = 'ok'`)
      await db.query(`UPDATE ${request.id} SET response = 'ok';`)
      handleUpdate(request)
      return
    }
    idleHandler(timeout, 'update', async () => {
      updateFlow(`SET response = 'ok'`)
      await db.query(`UPDATE Request SET response = 'ok' WHERE deviceId = '${getDeviceId()}' AND mode = '${request.mode}';`)
      handleUpdate(request)
    }, { interval: timeout * 0.3 })
  } catch (err: any) {
    captureException(new Error('Cannot check request update ' + err?.message))
  }
}

export const updateDeviceVersion = async () => {
  await posSyncLock.acquireAsync()
  await posSettingLock.acquireAsync()
  await deviceSettingLock.acquireAsync()
  const id = getDeviceId()
  try {
    const db = await SurrealClient.getSurrealClient('cloud');
    if (!db) return
    liveSurrealHook.on('request', async ([action, result]) => {
      if (id !== result.deviceId || result?.storeId !== posSync0()?.id || latestRequest === result._id) return
      latestRequest = result._id
      if (action === "CREATE") {
        if (result.mode === 'exportMeta') {
          await exportMeta()
          toast.success('Export success')
          return
        }
        if (!modeUpdate.includes(result.mode)) return
        const timeout = parseInt(result.timeout || 0)
        updateFlow(`receiver request with mode ${result.mode}, version: ${result.version}, timeout ${timeout}ms`)
        if (!timeout) {
          updateFlow(`SET response = 'ok'`)
          await db.query(`UPDATE Request SET response = 'ok' WHERE deviceId = '${getDeviceId()}' AND mode = '${result.mode}';`)
          handleUpdate(result)
        } else {
          idleHandler(timeout, 'update', async () => {
            updateFlow(`SET response = 'ok'`)
            await db.query(`UPDATE ${result.id} SET response = 'ok';`)
            handleUpdate(result)
          }, { interval: timeout * 0.3 })
        }
      }
    })
  } catch (e: any) {
    captureException(new Error('Update device version failed: ', e.message))
  }
}

effectOn([posSync0], async () => {
  console.log('updateDeviceVersion');
  await updateDeviceVersion()
}, { defer: true })
