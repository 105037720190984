import _, { assign } from 'lodash'
import { wsLock } from '@/react/websocket/ws-state.ts'
import { getUrlParam } from '@/shared/utils2.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'
import { toast } from "react-toastify";

export const initSystemWebviewState = async (deviceSetting: any) => {
  if (!getUrlParam('os')) {
    assign(deviceSetting, { useSystemWebview: false })
    return
  }
  await wsLock.acquireAsync()
  const systemWebviewEnable = await rnHost.getUseSystemWebviewState()
  assign(deviceSetting, { useSystemWebview: systemWebviewEnable })
}

export const toggleSystemWebviewState = async (deviceSetting: any, e:any) => {
  try {
    await rnHost.toggleSystemWebview()
    _.assign(deviceSetting, { useSystemWebview: e })
  }
  catch (error: any) {
    toast.error(`Toggle fail: ${error.message}`)
  }
}
