import { captureException, captureMessage } from '@sentry/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import { TimeoutError } from 'p-timeout'
import { toast } from 'react-toastify'
import type { RxDocument } from 'rxdb'
import uuid from 'time-uuid'

import { Order, PaidOrder } from '@/data/Order'
import { updateOrder } from '@/data/OrderHub'
import { isRunningTestcase, mainScreen, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { SrmEvents } from '@/data/SrmEventLog'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { updateTransNoForUserAction } from '@/data/UserActionHistory.ts'
import { loginUser } from '@/data/UserSignal.ts'
import { YearlyReports, type YearlyReportData } from '@/data/YearlyReports'
import { stripPaidOrder } from '@/pos/logic/order-reactive'
import { now } from '@/pos/logic/time-provider.ts'
import { CommitAction, OrderStatus, type ItemCommitRecordSrmTransaction, type OrderItem, type TOrder } from '@/pos/OrderType'
import { router } from '@/pos/PosRouter'
import { LL0 } from '@/react/core/I18nService'
import { notificationToast } from '@/react/FloorPlanView/Noti'
import { makeNegativeOrder } from '@/react/PaymentView/payment-utils'
import { getTicketNumber } from '@/react/Printer/print-label.ts'
import { getInvoicePrinter } from '@/react/Printer/print-utils'
import { VPrinter } from '@/react/Printer/VPrinter'
import { bound, clonedArgs, consoleGroup, ensureExclusive, handleError, performanceMark, progressDialog, progressToast, queue, requireConfirmation, skipIf } from '@/shared/decorators'
import { downloadPrintedImage, ensureHasValue } from '@/shared/utils'
import { rnHost } from '@/shared/webview/rnwebview'

import { WebSrmTransactionError } from './errors'
import { getLastTransactionSignature, saveLastTransactionSignature } from './getLastTransactionSignature'
import { getSignableSdkWithHeadersAndTaxNum } from './getSdkWithHeadersAndTaxNum'
import { getSignableSdk } from './getSignableSdk'
import { srmInvoiceLogic } from './invoice.logic'
import { CONSTANT_VALUES } from './lib/constants'
import { ensureSrmConfigured, handleSrmError } from './lib/decorators'
import { OperationModes, PrintModes, PrintOptions, TransactionTypes } from './lib/enum'
import { formatMoney, formatQuantity } from './lib/formatters'
import { logAndNotifySrmError } from './lib/logAndNotifySrmError'
import type { CurrentTransactionResponseData, SignedTransactionData, TransactionData } from './lib/types'
import { ensureValidAsciiStandard, shouldDownloadReceipt } from './lib/utils'
import { hasErrorRequireResent, isOrderAlreadyTransmitted, isPrintDuplicateAllowed, isTemporaryBillUnchanged, transToRefs } from './lib/utils.transaction'
import { order2Trans } from './order2Trans'
import { isCurrentlyOffline, recordSrmEvent } from './recordSrmEvent'
import { recordTransaction } from './recordTransaction'
import { pendingSrmTransactions } from './signals'
import { setNextTestcase } from './testcase/state'
import { getLatestClosingReceipt, getLatestOrderTransaction } from './transaction-history.service'

const log = debug('data:srm-transaction')

interface Options {
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
  /** Batch size when sending transaction in offline mode */
  batchSize?: number
}

/**
 * Options for recording transaction
 */
interface RecordTransactionOptions {
  /**
   * Should print the transaction. Default to false.
   */
  print?: boolean
  /**
   * Parent order of the seat order. Should be passed when `order` is a splitted order
   *
   * Note. This order's `_id` must be the same as `order.splitId`
   */
  parentOrder?: Order
  /**
   * Extra transaction refs, will be append directly to transaction data.
   * This is useful when you want to include the reference to other transactions
   * in the printed bill.
   */
  extraRefs?: TransactionData[]
}

const generalOrderInfo = (o: Order) => [o._id, o.table ? `table ${o.table}` : undefined, o.vSum].filter(ensureHasValue).join(' | ')
const generalTransInfo = (t?: TransactionData, ref?: string) => (t ? [ref, t.noTrans, t.typTrans, t.modImpr, t.formImpr, t.mont.apresTax].filter(ensureHasValue).join(' | ') : '')

/** SRM Transaction Logic. Used decorators pattern */
export class SrmTransactionLogic {
  constructor(public options: Options = {}) {}

  @ensureExclusive()
  async signTransaction(data: TransactionData) {
    const sdk = getSignableSdk()
    const lastSignature = await getLastTransactionSignature()
    log('ℹ️ lastSignature', lastSignature)
    const signed = await sdk.signTransaction(data, lastSignature)
    log('ℹ️ nextSignature', signed.signa.actu)
    await saveLastTransactionSignature(signed.signa.actu)
    return signed
  }

  @consoleGroup(([t, r]) => generalTransInfo(t, r))
  @ensureExclusive()
  @performanceMark()
  private async _sendTransactions(main?: SignedTransactionData, ref?: string): Promise<[CurrentTransactionResponseData | undefined, RxDocument<SrmTransactionLog> | undefined]> {
    const { sdk, headers } = getSignableSdkWithHeadersAndTaxNum()

    const userName = this.options?.impersonate ?? loginUser()?.name
    if (!userName) throw new Error(LL0().srm.errors.loginRequired())

    let record: RxDocument<SrmTransactionLog> | undefined
    let transRes: CurrentTransactionResponseData | undefined
    const pending = [...pendingSrmTransactions()]

    if (main) {
      if (!ref) throw new Error(LL0().srm.errors.missingOrderId())
      record = await recordTransaction(main, ref, userName)
    }

    if (pending.length) log(`ℹ️ You have ${pending.length} pending transaction(s)`)
    do {
      const slice = pending.splice(0, this.options?.batchSize ?? 3)
      const batch = slice.map(a => a.toMutableJSON()).map(a => a.data)

      log(`ℹ️ Sending ${(main ? 1 : 0) + batch.length} transaction to WEB-SRM...`, headers, main, batch)

      // Take 3 most recent transactions and send it together with main transaction
      const response = await sdk.transactions(headers, main, batch).catch(async e => {
        // If it's network error, set response to null so we can handle offline mode
        if (e instanceof TimeoutError) return null
        if (e instanceof AxiosError && e.code && [AxiosError.ECONNABORTED, AxiosError.ERR_NETWORK].includes(e.code)) return null
        if (e instanceof Error && e.message === 'Network error') return null
        log('⚠️ Unknown error when communicating with WEB-SRM:', e)
        captureException(e) // Unknown error, track it with Sentry
        return null
      })

      if (!response) {
        captureMessage('ℹ️ Offline mode detected', { level: 'info', tags: { srm: 'online' } })
        notificationToast('ℹ️ Offline Mode detected!', { type: 'info' })
        log('ℹ️ Offline mode detected...')
        await recordSrmEvent(SrmEvents.ENTER_OFFLINE, userName)
        return [undefined, record]
      }

      if (response.retourTrans.casEssai && response.retourTrans.casEssai !== CONSTANT_VALUES.CASESSAI_EMPTY) {
        log('🧪 Next testcase is', response.retourTrans.casEssai)
        toast.info(`🧪 Next testcase is ${response.retourTrans.casEssai}`)
        setNextTestcase(response.retourTrans.casEssai)
      }

      const { retourTransActu, retourTransLot } = response.retourTrans
      const mainErrors = retourTransActu?.listErr ?? []
      const batchErrors = retourTransLot?.listErr ?? []

      // Record the response if it's the main transaction
      if (retourTransActu) {
        // Record the main response
        transRes = retourTransActu
        // Save it to database
        if (record)
          await record.incrementalUpdate({
            $set: {
              sent: !hasErrorRequireResent(mainErrors),
              response: retourTransActu,
            },
          })
      }
      // If there is a batch transaction, record their responses
      if (retourTransLot) {
        for (const item of slice) {
          const itemErrors = batchErrors.filter(e => e.noTrans === item.data.noTrans)
          await item.incrementalUpdate({
            $set: {
              sent: !hasErrorRequireResent(itemErrors),
              'response.noTrans': item.data.noTrans,
              'response.listErr': itemErrors,
            },
          })
        }
      }

      // If preciously offline, notify user that it now backed online
      if (await isCurrentlyOffline()) {
        captureMessage('🛜 Device is back online', { level: 'info', tags: { srm: 'online' } })
        notificationToast('🛜 Device is back online', { type: 'info', autoClose: 10 * 60 * 1000 })
        await recordSrmEvent(SrmEvents.BACK_ONLINE, userName)
      }

      if (mainErrors.length || batchErrors.length) logAndNotifySrmError(new WebSrmTransactionError(response))

      log('✅ Send transaction succeeded', response)

      // Clear out the main transaction, so if there is more pending, it got sent without the main one
      main = undefined
    } while (pending.length > 0)

    return [transRes, record] as const
  }

  @progressToast(signed => LL0().srm.messages.printingBill({ total: Number(signed.mont.apresTax) }))
  @performanceMark()
  private async _printTransaction(signed: SignedTransactionData, transRes?: CurrentTransactionResponseData, logRecord?: SrmTransactionLog, order?: TOrder): Promise<void> {
    const { sdk, deviceId } = getSignableSdkWithHeadersAndTaxNum()
    const { testcaseNumber } = posSetting0()?.srm || {}

    log(`⚡️ Printing [${signed.noTrans}]...`)
    const printer = getInvoicePrinter()
    if (!printer) throw new Error(LL0().srm.errors.printerNotConfigured())

    const relatedTransactions = await SrmTransactionLog.find({
      selector: {
        ref: logRecord?.ref ?? order?._id,
        _id: { $ne: logRecord?._id },
      },
    })
      .exec()
      .then(a => a.map(a => a.toMutableJSON()).map(a => a.data))

    log('ℹ️ Related transactions:', relatedTransactions)

    const scripts = await srmInvoiceLogic.generateInvoicePrintingScripts(signed, {
      qrcodeData: await sdk.generateQRCode(signed),
      deviceId,
      transRes,
      order,
      relatedTransactions,
    })

    const metadata = {
      orderId: logRecord?.ref,
      srm_transactionNumber: signed.noTrans,
      srm_testcaseNumber: testcaseNumber,
      srm_logRecordId: logRecord?._id,
    }

    const vPrinter = new VPrinter(getInvoicePrinter())
    await vPrinter.getRasterFromSavedScript(scripts);
    await vPrinter.print({ viaMaster: true, createVirtualPrinter: true, virtualPrinterMetadata: metadata })

    // Only use for testing & Quebec submission
    if (shouldDownloadReceipt(testcaseNumber)) {
      const raster = await new VPrinter({ escPOS: false }).getRasterFromSavedScript(_.cloneDeep(scripts))
      await downloadPrintedImage(raster, `[${testcaseNumber}] ${signed.noTrans.slice(-4)}`)
    }
  }

  /**
   * Record temporary bill.
   */
  @consoleGroup(([o]) => generalOrderInfo(o))
  @progressToast(genTemporaryBillToastMsg)
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  @clonedArgs()
  async recordTemporaryBill(order: Order, opt: RecordTransactionOptions = {}) {
    const trans = await order2Trans(order, {
      transactionType: TransactionTypes.temporaryBill,
      printMode: opt.print ? PrintModes.bill : undefined,
      printOption: opt.print ? PrintOptions.paper : undefined,
      impersonate: this.options?.impersonate,
    })
    trans.refs = await getTransRefs(order, opt)
    const signed = await this.signTransaction(trans)
    const [response, logRecord] = await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order._id, signed?.noTrans)
    if (opt.print) await this._printTransaction(signed, response, logRecord, order)
  }

  @consoleGroup(([o]) => generalOrderInfo(o))
  @progressToast(o =>
    typeof o.seat === 'undefined'
      ? LL0().srm.messages.recordingClosingReceipt({ total: o.vSum ?? 0 })
      : LL0().srm.messages.recordingClosingReceiptForSeat({
          seat: o.seat + 1,
          total: o.vSum ?? 0,
        })
  )
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  @clonedArgs()
  async recordClosingReceipt(order: Order, opt: RecordTransactionOptions = {}) {
    const trans = await order2Trans(order, {
      transactionType: TransactionTypes.closingReceipt,
      printMode: opt.print ? PrintModes.bill : undefined,
      printOption: opt.print ? PrintOptions.paper : undefined,
      impersonate: this.options?.impersonate,
    })
    trans.refs = await getTransRefs(order, opt)
    const signed = await this.signTransaction(trans)
    const [response, logRecord] = await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order._id, signed.noTrans)
    if (opt.print) {
      getTicketNumber(order)
      await this._printTransaction(signed, response, logRecord, order)
    }
    if (logRecord) this.incrementalSaveYearlyReport(logRecord).then() // Don't wait for this
  }

  /**
   * Record refund transaction. Will take the original Closing Receipt as reference.
   */
  @consoleGroup(([o]) => generalOrderInfo(o))
  @progressToast(() => LL0().srm.messages.recordingRefund())
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async recordRefund(order: Order, opt: RecordTransactionOptions = {}) {
    if (!order.refOrder) throw new Error(LL0().srm.errors.missingOriginalOrder())
    const trans = await order2Trans(order, {
      transactionType: TransactionTypes.closingReceipt,
      printMode: opt.print ? PrintModes.bill : undefined,
      printOption: opt.print ? PrintOptions.paper : undefined,
      impersonate: this.options?.impersonate,
    })
    const row = await getLatestClosingReceipt(order.refOrder)
    if (!row) throw new Error(LL0().srm.errors.originBillNotFound())
    trans.refs = transToRefs(row.data)
    const signed = await this.signTransaction(trans)
    const [response, logRecord] = await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order.refOrder, signed.noTrans)
    if (opt.print) await this._printTransaction(signed, response, logRecord)
    if (logRecord) this.incrementalSaveYearlyReport(logRecord).then() // Don't wait for this
  }

  @consoleGroup(([o]) => generalOrderInfo(o))
  @requireConfirmation(o =>
    [OrderStatus.PAID, OrderStatus.REFUNDED, OrderStatus.CANCELLATION_REFERENCE].includes(o.status)
      ? { title: LL0().srm.caption.reprint(), content: LL0().srm.messages.reprintOrder({ id: o.id ?? 0 }) }
      : undefined
  )
  @progressToast(genReproduceToastMsg)
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async reproduceBill(order: Order) {
    // Gest latest printed bill
    const [record] = await SrmTransactionLog.find({
      selector: {
        ref: order._id,
        'data.formImpr': { $in: [PrintOptions.paper, PrintOptions.notPrinted] },
        'data.modImpr': PrintModes.bill,
      },
      sort: [{ date: 'desc' }],
    }).exec()
    if (!record) throw new Error(LL0().srm.errors.originBillNotFound())

    const signed = await this.signTransaction({
      ...record.data,
      modImpr: PrintModes.reproduction,
      formImpr: PrintOptions.paper,
    })
    const [response, logRecord] = await this._sendTransactions(signed, record.ref)
    await updateTransNoForUserAction(record.ref, signed.noTrans)
    if (logRecord) this.incrementalSaveYearlyReport(logRecord).then() // Don't wait for this
    await this._printTransaction(signed, response, logRecord)
  }

  /** Print a duplicate of printed bill. Not for customer - should only be used for internal */
  @consoleGroup(([o]) => o)
  @requireConfirmation(() => ({
    title: LL0().srm.caption.printDuplicate(),
    content: LL0().srm.messages.printDuplicate(),
  }))
  @progressToast(() => LL0().srm.messages.printingDuplicate())
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async printDuplicate(transactionLogId: string) {
    const record = await SrmTransactionLog.findOne({ selector: { _id: transactionLogId } }).exec()
    if (!record) throw new Error(LL0().srm.errors.originBillNotFound())
    if (!isPrintDuplicateAllowed(record)) throw new Error(LL0().srm.errors.printDuplicateNotAllowed())

    const signed = await this.signTransaction({
      ...record.data,
      modImpr: [PrintModes.cancellation, PrintModes.failureToPay].includes(record.data.modImpr) ? record.data.modImpr : PrintModes.duplicate,
      formImpr: PrintOptions.paper,
    })
    const [response, logRecord] = await this._sendTransactions(signed, record.ref)
    await updateTransNoForUserAction(record.ref, signed.noTrans)
    await this._printTransaction(signed, response, logRecord)
  }

  /**
   * Record cancellation for order that is being entering.
   *
   * User creating the order, add some item, but discard it in the end
   */
  @consoleGroup(([o]) => generalOrderInfo(o))
  @progressToast(async (o, opt) =>
    (await isOrderAlreadyTransmitted(o))
      ? LL0().srm.messages.recordingCancellation()
      : opt?.makingPayment
      ? LL0().srm.messages.recordingClosingReceiptEntryCancellation()
      : LL0().srm.messages.recordingTemporaryBillEntryCancellation()
  )
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async recordCancellation(order: Order, opt: { makingPayment?: boolean } = {}) {
    const lastTrans = await getLatestOrderTransaction(order._id)
    const isTransmitted = !!lastTrans
    const shouldPrint = isTransmitted
    const isClosingReceipt = opt.makingPayment || isTransmitted

    const trans = await order2Trans(order, {
      transactionType: isClosingReceipt ? TransactionTypes.closingReceipt : TransactionTypes.temporaryBill,
      printOption: isTransmitted ? PrintOptions.paper : undefined,
      printMode: isTransmitted ? PrintModes.bill : PrintModes.cancellation,
      addCancellationItem: isTransmitted,
      impersonate: this.options?.impersonate,
    })
    if (lastTrans) trans.refs = transToRefs(lastTrans.data)
    const signed = await this.signTransaction(trans)
    const [response, logRecord] = await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order._id, signed.noTrans)
    if (shouldPrint) await this._printTransaction(signed, response, logRecord)
  }

  /**
   * Change the payment method of already closed bill.
   *
   * Will create a negative bill to cancel the closed bill first,
   * then after that create another bill with changed payment method
   */
  @consoleGroup(([o, m]) => generalOrderInfo(o) + ` | [${m}]`)
  @progressToast((o, m) => LL0().srm.messages.changingPaymentMethod({ id: o.id ?? 0, method: typeof m === 'string' ? m : m.payments[0].type }))
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async changePaymentMethod(
    order: Order,
    nextPaymentMethodOrChangedOrder: 'cash' | 'card' | 'none' | Order,
    onCreatedNegativeOrder?: (negativeOrder: Order, signed: SignedTransactionData, response?: CurrentTransactionResponseData) => Promise<void> | void
  ) {
    const changedOrder: Order =
      typeof nextPaymentMethodOrChangedOrder === 'string'
        ? {
            ...order,
            _id: uuid(),
            payments: [
              {
                type: nextPaymentMethodOrChangedOrder,
                value: order.payments[0]?.value ?? 0,
              },
            ],
          }
        : nextPaymentMethodOrChangedOrder

    if (order.payments[0].type === changedOrder.payments[0].type) throw new Error(LL0().srm.errors.samePaymentMethod())

    //update original order status
    await PaidOrder.findOne({ selector: { _id: order._id } }).incrementalPatch({ status: OrderStatus.CANCELLED })

    const negativeOrder = await makeNegativeOrder(order)
    const trans1 = await order2Trans(negativeOrder, {
      transactionType: TransactionTypes.closingReceipt,
      impersonate: this.options?.impersonate,
    })
    const row = await getLatestClosingReceipt(order._id)
    if (row) trans1.refs = transToRefs(row.data)
    const signed1 = await this.signTransaction(trans1)
    const [response1, logRecord1] = await this._sendTransactions(signed1, negativeOrder._id)
    await updateTransNoForUserAction(negativeOrder._id, signed1.noTrans)
    if (onCreatedNegativeOrder) await onCreatedNegativeOrder(negativeOrder, signed1, response1)
    if (logRecord1) this.incrementalSaveYearlyReport(logRecord1).then() // Don't wait for this

    const trans2 = await order2Trans(changedOrder, {
      transactionType: TransactionTypes.closingReceipt,
      printMode: PrintModes.bill,
      printOption: PrintOptions.paper,
      impersonate: this.options?.impersonate,
    })
    trans2.refs = transToRefs(trans1)
    const signed2 = await this.signTransaction(trans2)
    const [response2, logRecord2] = await this._sendTransactions(signed2, changedOrder._id)
    await updateTransNoForUserAction(changedOrder._id, signed2.noTrans)
    changedOrder.date = dayjs(now()).unix() // update order timestamp
    await PaidOrder.insert(stripPaidOrder(changedOrder)) // Save changed order
    await this._printTransaction(signed2, response2, logRecord2)
    if (logRecord2) this.incrementalSaveYearlyReport(logRecord2).then() // Don't wait for this
  }

  /** Record a closing receipt for a case where customer walk away without paying */
  @consoleGroup(([o]) => generalOrderInfo(o))
  @requireConfirmation(() => ({
    title: LL0().srm.caption.failureToPay(),
    content: LL0().srm.messages.markFailureToPayConfirm(),
  }))
  @progressDialog(() => ({ title: LL0().srm.messages.markingFailureToPay() }))
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async handleFailureToPay(order: Order) {
    const trans = await order2Trans(order, {
      transactionType: TransactionTypes.closingReceipt,
      printMode: PrintModes.failureToPay,
      impersonate: this.options?.impersonate,
    })
    const row = await getLatestOrderTransaction(order._id)
    if (row) trans.refs = transToRefs(row.data)
    const signed = await this.signTransaction(trans)
    await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order._id, signed.noTrans)
    // We must NOT print the bill in this case

    // Remove order & go back
    order.commits?.push({ action: CommitAction.CANCEL_BEFORE_PRINT })
    const canceledOrder = await updateOrder(order)
    log('» Cancelled order:', canceledOrder)
    if (!isRunningTestcase()) router.screen = mainScreen()
  }

  /**
   * Record action when user trying to refund a bill but discard it in the end
   *
   * @deprecated When cancelling refund, we does not need to send anything, but the action still need to be recorded
   */
  @consoleGroup(([o]) => generalOrderInfo(o))
  @progressToast(() => LL0().srm.messages.cancelingRefund())
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  async recordRefundCancellation(order: Order) {
    const trans = await order2Trans(
      {
        _id: order._id,
        status: OrderStatus.CANCELLED,
        payments: [{ type: 'none', value: 0 }],
      } as Order,
      {
        transactionType: TransactionTypes.closingReceipt,
        printMode: PrintModes.cancellation,
        impersonate: this.options?.impersonate,
      }
    )
    const signed = await this.signTransaction(trans)
    await this._sendTransactions(signed, order._id)
    await updateTransNoForUserAction(order._id, signed.noTrans)
  }

  @skipIf(() => pendingSrmTransactions().length === 0)
  @requireConfirmation(() => ({
    title: LL0().srm.caption.sendOfflineTransactions(),
    content: LL0().srm.messages.sendOfflineTransactionsConfirm({ count: pendingSrmTransactions().length }),
  }))
  @progressToast(() => LL0().srm.messages.sendingOfflineTransactions())
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  @bound()
  async sendOfflineTransactions() {
    await this._sendTransactions()
  }

  @skipIf(() => pendingSrmTransactions().length === 0)
  @progressToast(() => LL0().srm.messages.sendingOfflineTransactions())
  @handleError()
  @handleSrmError()
  @ensureSrmConfigured()
  @bound()
  async sendOfflineTransactionsUnattended() {
    await this._sendTransactions()
  }

  /**
   * Check if the order is unchanged by comparing the last temporary bill with the current order.
   *
   * This is used to check if the order needs to be resent to the SRM.
   *
   * @param order The order to check.
   * @returns `true` if the order is unchanged, `false` otherwise.
   */
  isOrderUnchanged = isOrderUnchanged

  /**
   * Check if the order should print reproduce.
   *
   * The order should print reproduce if:
   * - The last temporary bill exists.
   * - The last temporary bill is printed on paper.
   * - The last temporary bill is printed in bill mode.
   * - The order is unchanged.
   *
   * @param order The order to check.
   * @returns `true` if the order should print reproduce, `false` otherwise.
   */
  shouldPrintReproduce = shouldPrintReproduce

  @queue(3000)
  private async incrementalSaveYearlyReport(record: SrmTransactionLog) {
    log('ℹ️ Incremental saving yearly report...')
    const year = dayjs.unix(record.date).year()
    const user = record.data.nomUtil ?? ''
    const isPaymentRecord = (t: TransactionData) => t.typTrans === TransactionTypes.closingReceipt && t.modImpr === PrintModes.bill && +t.mont.apresTax !== 0
    const isPositivePaymentRecord = (t: TransactionData) => isPaymentRecord(t) && +t.mont.apresTax > 0
    const isPrintedBill = (t: TransactionData) => t.modImpr === PrintModes.bill && t.formImpr !== PrintOptions.notPrinted
    const training = record.data.modTrans === OperationModes.training
    const reportData: YearlyReportData = {
      year,
      totalRecords: 1,
      paymentRecords: isPositivePaymentRecord(record.data) ? 1 : 0,
      net: isPaymentRecord(record.data) ? Number(record.data.mont.avantTax) : 0,
      gst: isPaymentRecord(record.data) ? Number(record.data.mont.TPS) : 0,
      qst: isPaymentRecord(record.data) ? Number(record.data.mont.TVQ) : 0,
      gross: isPaymentRecord(record.data) ? Number(record.data.mont.apresTax) : 0,
      adj: isPaymentRecord(record.data) ? Number(record.data.mont.ajus ?? '0') : 0,
      due: isPaymentRecord(record.data) ? Number(record.data.mont.mtdu ?? record.data.mont.apresTax) : 0,
      latestTrans: isPrintedBill(record.data)
        ? {
            apresTax: record.data.mont.apresTax,
            datTrans: record.data.datTrans,
            noTrans: record.data.noTrans,
            psiDatTrans: record.response?.psiDatTrans,
            psiNoTrans: record.response?.psiNoTrans,
          }
        : undefined,
    }
    log('ℹ️ Report data:', reportData)
    const report = await YearlyReports.findOne({ selector: { year, user, training } }).exec()
    if (report) {
      log('ℹ️ Incremental updating yearly report...')
      await report.incrementalUpdate({
        $inc: {
          'reportData.totalRecords': 1,
          'reportData.paymentRecords': reportData.paymentRecords,
          'reportData.net': reportData.net,
          'reportData.gst': reportData.gst,
          'reportData.qst': reportData.qst,
          'reportData.gross': reportData.gross,
          'reportData.adj': reportData.adj,
          'reportData.due': reportData.due,
        },
      })
      if (reportData.latestTrans) {
        await report.incrementalUpdate({
          $set: {
            'reportData.latestTrans': reportData.latestTrans,
          },
        })
      }
    } else {
      log('ℹ️ Inserting yearly report...')
      await YearlyReports.insert({ _id: uuid(), year, user, training, reportData })
    }
  }
}

/** SRM Transaction Logic */
export const srmTransactionLogic = new SrmTransactionLogic()

async function genTemporaryBillToastMsg(o: Order, opt: RecordTransactionOptions = {}) {
  const seatMode = typeof o.seat !== 'undefined'
  const seat = (o.seat ?? 0) + 1
  const total = o.vSum ?? 0

  if (opt?.print)
    return !seatMode
      ? LL0().srm.messages.printingUnpaidBill({ total })
      : LL0().srm.messages.printingUnpaidBillForSeat({
          seat,
          total,
        })
  return !seatMode
    ? LL0().srm.messages.recordingTemporaryBill({ total })
    : LL0().srm.messages.recordingTemporaryBillForSeat({
        seat,
        total,
      })
}

async function genReproduceToastMsg(o: Order) {
  const seatMode = typeof o.seat !== 'undefined'
  const seat = (o.seat ?? 0) + 1
  const total = o.vSum ?? 0

  if (o.status === OrderStatus.IN_PROGRESS)
    return !seatMode
      ? LL0().srm.messages.reproducingUnpaidBill({ total })
      : LL0().srm.messages.reproducingUnpaidBillForSeat({
          seat,
          total,
        })

  return LL0().srm.messages.reproducingBill({ id: o.id ?? 0 })
}

/**
 * Check if the order should print reproduce.
 *
 * The order should print reproduce if:
 * - The last temporary bill exists.
 * - The last temporary bill is printed on paper.
 * - The last temporary bill is printed in bill mode.
 * - The order is unchanged.
 *
 * Note: Must move this out of `srmTransactionLogic` so we can call it in decorators
 *
 * @param order The order to check.
 * @returns `true` if the order should print reproduce, `false` otherwise.
 */
async function shouldPrintReproduce(order: Order): Promise<boolean> {
  const lastBill = await getLatestOrderTransaction(order._id)
  // If the last temporary bill is not found, do not print reproduce
  if (!lastBill) return false
  // If the last temporary bill is not printed on paper, do not print reproduce
  if (lastBill.data.formImpr !== PrintOptions.paper) return false
  // If the last temporary bill is not printed in bill mode, do not print reproduce
  if (lastBill.data.modImpr !== PrintModes.bill) return false
  // If the order is not unchanged, do not print reproduce
  return await isOrderUnchanged(order)
}

/**
 * Check if the order is unchanged by comparing the last temporary bill with the current order.
 *
 * This is used to check if the order needs to be resent to the SRM.
 *
 * @param order The order to check.
 * @returns `true` if the order is unchanged, `false` otherwise.
 */

async function isOrderUnchanged(order: Order): Promise<boolean> {
  const lastBill = await getLatestOrderTransaction(order._id)
  // If the last temporary bill is not found, consider the order changed
  if (!lastBill) return false
  const nextBill = await order2Trans(order)

  return isTemporaryBillUnchanged(lastBill.data, nextBill)
}

/**
 * Get the changed items in the order compared to the last temporary bill.
 *
 * This is used to detect if any new items have been added/modified in the order.
 *
 * @param order The order to check.
 * @param parentOrder The parent order to compare with.
 * @returns An array of new items.
 */
async function getChangedItems(order: Order): Promise<OrderItem[]> {
  const lastTrans = await getLatestOrderTransaction(order._id)
  // If the last temporary bill is not found, consider all items is new
  if (!lastTrans) return order.items
  // Compare the last temporary bill with the current order
  return _.differenceWith(order.items, lastTrans.data.items, (a, b) => {
    if (ensureValidAsciiStandard(a.name ?? '') !== b.descr) return false
    if (formatQuantity(a.quantity) !== b.qte) return false
    if (formatMoney(a.price * a.quantity) !== b.prix) return false
    // If all checks pass, consider the item is the same
    return true
  })
}

async function getTransRefs(order: Order, opt: RecordTransactionOptions = {}) {
  const result = [...(opt.extraRefs ?? [])]
  const isSeatOrder = typeof order.seat !== 'undefined'

  if (isSeatOrder) {
    const lastTrans = await getLatestOrderTransaction(order._id, order.splitId)
    if (lastTrans) result.push(lastTrans.data)
    else {
      // If current order does not have any transaction,
      // check if there are any transactions in parent order and reference them
      // (Will use in the case grouped bill)
      const lastParentTrans = await getLatestOrderTransaction(opt.parentOrder?._id)
      if (lastParentTrans) result.push(lastParentTrans.data)
    }
    if (opt.parentOrder) {
      // TODO: double check this logic
      const changedItems = await getChangedItems(order)
      const refTransactionIds = changedItems.map(a => a.originalInfo?.srm_originalTransactionId).filter(ensureHasValue)
      const seatIds = (opt.parentOrder.seatMap ?? [])
        .filter(a => a.seat !== order.seat) // Take all the seat except the current one
        .map(a => a._id) // Take all the transactions
      const transactionsFromOtherSeats = await SrmTransactionLog.find({ selector: { ref: { $in: seatIds } } })
        .exec()
        .then(a => a.map(a => a.toMutableJSON()))

      const refTrans = transactionsFromOtherSeats.filter(a => refTransactionIds.includes(a._id)) // Filter by the original transaction number
      result.push(...refTrans.map(a => a.data))
    }
  } else {
    const lastTrans = await getLatestOrderTransaction(order._id)
    if (lastTrans) result.push(lastTrans.data)
    else {
      // If current order does not have any transaction,
      // check if there are any seat transactions and reference them
      // (Will use in the case grouped bill)
      for (const seat of order.seatMap ?? []) {
        const lastSeatTrans = await await getLatestOrderTransaction(seat._id)
        if (lastSeatTrans) result.push(lastSeatTrans.data)
      }
    }
  }
  if (result.length) return transToRefs(...result)
}

// DEV Only
Object.assign(window, {
  async dev_toggleOfflineMode(mode: 'error' | 'timeout' | 'off') {
    await rnHost.switchOfflineMode(mode)
  },
})
