import debug from 'debug'
import _ from 'lodash'
import { toast } from 'react-toastify'
import type { RxDocument } from 'rxdb'

import { Modifier } from '@/data/Modifier'
import { Product } from '@/data/Product'
import { TaxType, type TaxCategory } from '@/data/TaxCategory'
import { busyIndicator, requireConfirmation } from '@/shared/decorators'

import { LL0 } from '../core/I18nService'
import { signal } from '../core/reactive'
import { Icons } from '../SystemService/msgBox'

const log = debug('dev:payment-setting')

const [applyingTaxToAllProduct, setApplyingTaxToAllProduct] = signal(false)

export class PaymentSettingViewLogic {
  @requireConfirmation(t => ({
    title: LL0().ui.confirmation(),
    content: LL0().tax.applyTaxToAllConfirm({ name: t.name ?? '_' }),
    icons: Icons.Warning,
  }))
  @busyIndicator(setApplyingTaxToAllProduct)
  async applyTaxToAllProduct(taxCategory: TaxCategory) {
    const taxValue = taxCategory.value ?? _.sumBy(taxCategory.components, 'value')
    const taxComponents = taxCategory.components ? _.cloneDeep(taxCategory.components) : undefined

    // Counter
    const count = { products: 0, modifiers: 0 }
    const updatedProducts: Product[] = []

    if ([TaxType.COMBO, TaxType.HST].includes(taxCategory.type)) {
      log(`Applying ${taxCategory.type} tax with value ${taxValue} to all products...`)
      const products = (await Product.find({ selector: { taxCategory: taxCategory.name } }).exec()).map(p => p.toMutableJSON())
      for (const product of products) {
        product.tax = taxValue
        product.taxComponents = taxComponents
        product.tax2 = taxValue
        product.taxComponents2 = taxComponents
        if (product.menuTaxes && product.menuTaxes?.length > 0) {
          product.menuTaxes?.forEach(p => p.value = taxValue)
        }
        if (product.menuTaxes2 && product.menuTaxes2?.length > 0) {
          product.menuTaxes2?.forEach(p => p.value = taxValue)
        }
        if (product.menuTaxes && !Array.isArray(product.menuTaxes)) {
          product.menuTaxes = undefined
        }
        if (product.menuTaxes2 && !Array.isArray(product.menuTaxes2)) {
          product.menuTaxes2 = undefined;
        }
      }
      await Product.bulkUpsert(products)
      updatedProducts.push(...products)
    }

    if ([TaxType.DRINK_DINE_IN, TaxType.FOOD_DINE_IN].includes(taxCategory.type)) {
      log(`Applying dine-in tax to all products...`)
      const products = (await Product.find({ selector: { taxCategory: taxCategory.name } }).exec()).map(p => p.toMutableJSON())
      for (const product of products) {
        product.tax = taxValue
        product.taxComponents = undefined
        if (product.menuTaxes && product.menuTaxes?.length > 0) {
          product.menuTaxes?.forEach(p => p.value = taxValue)
        }
        if (product.menuTaxes && !Array.isArray(product.menuTaxes)) {
          product.menuTaxes = undefined
        }
      }

      await Product.bulkUpsert(products)

      updatedProducts.push(...products)

      // updatedProducts.push(
      //   ...(await Product.find({ selector: { taxCategory: taxCategory.name } }).update({
      //     $set: { tax: taxValue, ...(taxComponents ? { taxComponents } : {}) },
      //   })),
      //   ...(await Product.find({ selector: { menuTaxes: { $exists: true, $elemMatch: { ref: taxCategory.name } } } }).update({
      //     $set: { 'menuTaxes.$[].value': taxValue },
      //   }))
      // )
    }
    if ([TaxType.DRINK_TAKE_AWAY, TaxType.FOOD_TAKE_AWAY].includes(taxCategory.type)) {
      log(`Applying take away tax to all products...`)
      const products = (await Product.find({ selector: { taxCategory: taxCategory.name } }).exec()).map(p => p.toMutableJSON())
      for (const product of products) {
        product.tax2 = taxValue
        product.taxComponents2 = undefined
        if (product.menuTaxes2 && product.menuTaxes2?.length > 0) {
          product.menuTaxes2?.forEach(p => p.value = taxValue)
        }
        if (product.menuTaxes2 && !Array.isArray(product.menuTaxes2)) {
          product.menuTaxes2 = undefined
        }
      }
      await Product.bulkUpsert(products)
      // updatedProducts.push(
      //   ...(await Product.find({ selector: { taxCategory2: taxCategory.name } }).update({
      //     $set: { tax2: taxValue, ...(taxComponents ? { taxComponents2: taxComponents } : {}) },
      //   })),
      //   ...(await Product.find({ selector: { menuTaxes2: { $exists: true, $elemMatch: { ref: taxCategory.name } } } }).update({ $set: { 'menuTaxes2.$[].value': taxValue } }))
      // )
    }

    log(`Applying tax to all modifiers: ${taxCategory.name}`)
    // const updatedModifier = await Modifier.find({ selector: { items: { $elemMatch: { tax: { $exists: true, $ne: taxValue } } } } }).update({
    //   $set: { 'items.$[].tax': taxValue },
    // })

    const modifiers = (await Modifier.find({ selector: { items: { $elemMatch: { tax: { $exists: true, $ne: taxValue } } } } }).exec()).map(m => m.toMutableJSON())
    for (const modifier of modifiers) {
      modifier.items?.forEach(item => {
        if (item.tax != null) {
          item.tax = taxValue
        }
      })
    }
    await Modifier.bulkUpsert(modifiers)


    count.products += _.uniqBy(updatedProducts, a => a._id).length
    count.modifiers += modifiers.length

    log(`Tax applied to ${count.products} product(s) and ${count.modifiers} modifier(s)`)
    toast.success(`Tax applied to ${count.products} product(s) and ${count.modifiers} modifier(s)`)
  }
}

export const paymentSettingViewLogic = new PaymentSettingViewLogic()
export { applyingTaxToAllProduct }
