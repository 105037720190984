import type Printer from "@/shared/printer/pure-image-printer-parallel";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import type EscPrinterType from "@/shared/printer/node-thermal-printer";
import type { getNatsClient as getNatsClientType } from "@/lib/nats-client.ts";
import type { StringCodec as StringCodecType } from "nats.ws";

export let ImagePrinter: typeof Printer;
export const ImagePrinterLock = new MultiAwaitLock();
export async function importImagePrinter() {
  ImagePrinter = (await import('@/shared/printer/pure-image-printer-parallel')).default;
  ImagePrinterLock.release().then();
}

export let EscPrinter: typeof EscPrinterType;
export const EscPrinterLock = new MultiAwaitLock();
export async function importEscPrinter() {
  EscPrinter = (await import('@/shared/printer/node-thermal-printer')).default
  EscPrinterLock.release().then();
}

export let getNatsClient: typeof getNatsClientType;
export const getNatsClientLock = new MultiAwaitLock();
export async function importNatsClient() {
  getNatsClient = (await import('@/lib/nats-client.ts')).getNatsClient
  getNatsClientLock.release().then();
}

export let StringCodec: typeof StringCodecType;
export const StringCodecLock = new MultiAwaitLock();
export async function importStringCodec() {
  StringCodec = (await import('nats.ws')).StringCodec
  StringCodecLock.release().then();
}