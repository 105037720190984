export type SaleToPOIRequest = Record<string, any> & {
  MessageHeader: {
    MessageClass: MessageClassType,
    MessageCategory: MessageCategoryType,
    MessageType: MessageType.Request,
    ProtocolVersion: string,
    ServiceID: string,
    SaleID: string,
    POIID: string,
  }
}

export enum MessageCategoryType {
  Abort = 'Abort',
  Admin = 'Admin',
  BalanceInquiry = 'BalanceInquiry',
  Batch = 'Batch',
  CardAcquisition = 'CardAcquisition',
  CardReaderApdu = 'CardReaderApdu',
  CardReaderInit = 'CardReaderInit',
  CardReaderPowerOff = 'CardReaderPowerOff',
  Diagnosis = 'Diagnosis',
  Display = 'Display',
  EnableService = 'EnableService',
  Event = 'Event',
  GetTotals = 'GetTotals',
  Input = 'Input',
  InputUpdate = 'InputUpdate',
  Login = 'Login',
  Logout = 'Logout',
  Loyalty = 'Loyalty',
  Pin = 'Pin',
  Payment = 'Payment',
  Print = 'Print',
  Reconciliation = 'Reconciliation',
  Reversal = 'Reversal',
  Sound = 'Sound',
  StoredValue = 'StoredValue',
  TransactionStatus = 'TransactionStatus',
  Transmit = 'Transmit',
}

export enum MessageClassType {
  Device= "Device",
  Event = "Event",
  Service = "Service",
}

export enum MessageType {
  Notification= "Notification",
  Request = "Request",
  Response = "Response",
}

export enum ResultType {
  Failure = "Failure",
  Partial = "Partial",
  Success = "Success",
}