import AwaitLock from 'await-lock'

export const syncStateLockMap: Record<string, AwaitLock> = {}

export function getSyncLock(collectionName: string) {
  if (!syncStateLockMap[collectionName]) {
    syncStateLockMap[collectionName] = new AwaitLock()
  }
  return syncStateLockMap[collectionName]
}

export function releaseSyncLock(collectionName: string) {
  if (!syncStateLockMap[collectionName]) {
    return
  }
  setTimeout(() => {
    if (syncStateLockMap[collectionName].acquired) {
      syncStateLockMap[collectionName].release()
    }
  }, 0)
}
