import type { Order } from "@/data/Order.ts";
import { TseTransaction } from "@/data/TseTransaction.ts";
import { createOrder } from "@/pos/logic/order-reactive.ts";
import { debugTse, type TransactionContent } from "@/tse/tse-init.ts";
import { makeKassenBelegFinish, makeQr } from "@/tse/makeProcessDataLib.ts";
import uuid from "time-uuid";
import { TseClient, TseClientMock } from "@/tse/tse-client";
import { filterApplyItems, filterPassthroughItem, ServerTypeGroup, TseType } from "@/tse/tse-utils.ts";
import { setTseConfigV, tseConfig0, tseConfigLock } from "@/data/TseConfigHub.ts";
import { addCashPayment, clearPayment } from "@/pos/logic/order-utils.ts";
import SrTseClient from "@atc-group/tse-client";
import { connectToServer, pingTseServer, tseServerSignup } from "@/tse/tse-server-signup.ts";
import dayjs from "dayjs";
import _ from "lodash";
import { TseCertificate } from "@/data/TseCertificate.ts";
import { toast } from "react-toastify";
import { deviceSettingLock } from "@/data/DeviceSettingHub.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { registerTseHook } from "@/tse/tse-init-hook.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import delay from "delay";
import { TSE_NOT_REACHABLE } from "@/tse/tse-variable.ts";
import { captureException } from "@sentry/react";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { isMaster } from "@/lib/master-signal.ts";
import { registerTseClient } from "@/tse/tse-client-provider.ts";
import { TseClientUse } from "@/tse/tse-client-use.ts";

const posId = uuid();
const clientId = 'SWISSBIT'

type TseClientMix = SrTseClient & TseClient;

export let tse: TseClientMix;

export async function initializeFirstTimeTse() {
  //check tseConfig0()?.type is server or not
  toast('sign up now ...');
  if (ServerTypeGroup.includes(tseConfig0()?.type!)) {
    try {
      await tseServerSignup(tseConfig0()?.username, tseConfig0()?.password);
    } catch (e) {
    }
  }
  tseConfig0().tseEnable = true;
  await initializeRuntimeTse(true);
  await getTseInfoAtFirstTime();
  tseConfig0().initialized = true;
  await delay(1000);
  location.reload();
}

async function getTseInfoAtFirstTime() {
  try {
    await tseConfigLock.acquireAsync();
    // if (tseConfig0().inited || !tseConfig0().tseEnable) return;
    toast('init tse now...');

    // tseConfig0().inited = true;
    tseConfig0().tsePublicKey = await tse.getTsePublicKey();
    toast('init tse publicKey finished');
    tseConfig0().signatureAlgorithm = await tse.getTseSignatureAlgorithm();
    tseConfig0().serialNumber = await tse.getTseSerialNumber();
    tseConfig0().certificateExpirationDate = dayjs(await tse.getTseCertificateExpDate()).toISOString();
    tseConfig0().tseTimeFormat = 'unixTime';
    tseConfig0().pdEncoding = 'ASCII';
    // tseConfig.delayAssignQr = 10 * 60 * 1000;
    toast('init tse step 1 finished');

    let _certificates = await tse.getLogMessageCertificate();
    _certificates = _certificates.replace(/-----END CERTIFICATE-----\n/g, '');
    _certificates = _certificates.replace(/-----END CERTIFICATE-----/g, '');
    let certificates = _certificates.split('-----BEGIN CERTIFICATE-----\n');
    certificates = certificates.filter(c => !_.isEmpty(c));
    //certificate

    toast('init tse step 3 finished');
    // await TseCertificate.remove();
    await TseCertificate.upsert({ _id: uuid(), certificates: certificates });
    toast('init tse finished');
  } catch (e: any) {
    toast('init tse error: ' + (e.message || e));
    throw e;
  }
}

export const tseLock = new MultiAwaitLock(true);
export const tseFailLock = new MultiAwaitLock(true);

export async function initLocalTse() {
  await tse.fastInit({
    credentialSeed: 'SwissbitSwissbit',
    adminPuk: '123456',
    adminPin: '12345',
    timeAdminPin: '12345',
    clientId: clientId
  });
  // notificationToast('Tse init successful !!!', { autoClose: 1000 * 60 });
}

export async function initializeRuntimeTse(force = false) {
  debugTse('initializeRuntimeTse');
  setTseConfigV(1)
  await tseConfigLock.acquireAsync();
  debugTse('deviceSettingLock acquireAsync');
  await deviceSettingLock.acquireAsync();
  debugTse('initializeRuntimeTse release lock');

  if (!force && !tseConfig0()?.initialized && !tseConfig0()?.tseEnable) return;

  debugTse('initializeRuntimeTse start');
  try {
    if (tseConfig0()?.type === TseType.TseLocalMock) {
      debugTse('initializeRuntimeTse local mock');
      // if (!isMaster()) return;
      if (isMaster()) {
        tse = new TseClientMock('') as TseClientMix;
        await initLocalTse();
      } else {
        tse = new TseClientUse() as any;
      }
    } else if (tseConfig0()?.type === TseType.TseLocal) {
      debugTse('initializeRuntimeTse local');
      if (isMaster()) {
        tse = new TseClient('http://localhost:5005') as TseClientMix;
        await initLocalTse();
      } else {
        tse = new TseClientUse() as any;
      }
    } else if ([TseType.TseServer, TseType.TseServerTest, TseType.TseServerTestGermany, TseType.TseServerMock].includes(tseConfig0()?.type!)) {
      debugTse('initializeRuntimeTse server');
      tse = new SrTseClient() as TseClientMix;
      try {
        await pingTseServer();
      } catch (e) {
        setTimeout(() => {
          initializeRuntimeTse().then();
        }, 30 * 1000);
        throw e;
      }
      await connectToServer(tse);
      const status = await tse.getServerStatus();
      if (status.lastTimePing) {
      }
    }
    if (isMaster()) {
      await registerTseClient(tse);
    }

    notificationToast('Tse init successful !!!', { autoClose: 1000 * 60 * 60 * 240 });
    debugTse('Tse init successful !!!');
    tseLock.release().then();
  } catch (e) {
    tseFailLock.release().then();
    notificationToast('Tse init fail', { autoClose: 1000 * 60 * 60 * 240 });
    debugTse('Tse init fail');
    captureException(e);
  } finally {
    debugTse('initializeRuntimeTse finally');
    registerTseHook();
  }
}

export async function deregisterTse() {
  try {
    toast('Tse deregister start...');
    await tse.deregisterClient?.();
    tseConfig0().initialized = false;
    tseConfig0().tseEnable = false;
    toast('Tse deregister success');
  } catch (e: Error) {
    toast('Tse deregister fail' + (e.message || e));
  }
}

export async function sendContentToTsePayApi(order: Order, content?: TransactionContent, autoHandleTseMethod?: boolean) {
  let assertHandlerObj: { TSE_TA_VORGANGSDATEN2?: string } = {};
  if (autoHandleTseMethod && filterPassthroughItem(order).items.length > 0) {
    //cache old value before handle
    assertHandlerObj.TSE_TA_VORGANGSDATEN2 = makeKassenBelegFinish(order).transactionData;
    order = filterApplyItems(order)
    order = createOrder(order);
    clearPayment(order);
    addCashPayment(order);
  }

  const orderContent = makeKassenBelegFinish(order);
  let transactions = [{
    clientId,
    transactionData: orderContent.transactionData,
    processType: orderContent.processType
  }];
  if (content) {
    transactions.unshift({
      clientId,
      transactionData: content.transactionData,
      processType: content.processType
    })
  }
  let mResults: any[] | undefined;
  const begin = performance.now();
  try {
    mResults = await tse.bulkStartAndFinishTransaction(transactions as any);
  } catch (e) {
    captureException(e);
  }
  const end = performance.now();
  console.log('mResults: ', mResults);
  if (content) {
    // make transaction here
    try {
      if (!mResults) throw new Error();
      const result = mResults[0];
      notificationToast(`register: ${content.transactionData}`, { autoClose: 1000 * 10 });
      debugTse('register: ', content.transactionData);
      await TseTransaction.insert({
        _id: uuid(),
        TSE_TANR: result.transactionFinishInfo.transactionNumber,
        TSE_TA_START: result.transactionStartInfo.logTime,
        TSE_TA_ENDE: result.transactionFinishInfo.logTime,
        TSE_TA_VORGANGSART: content.processType,
        TSE_TA_SIGZ: result.transactionStartInfo.signatureCounter,
        TSE_TA_SIG: result.transactionFinishInfo.signature,
        TSE_TA_VORGANGSDATEN: content.transactionData,
        order: order._id,
        z: order.z
      });
    } catch (e) {
      await TseTransaction.insert({
        _id: uuid(),
        TSE_TA_START: order.date,
        TSE_TA_ENDE: order.date,
        TSE_TA_VORGANGSART: content.processType,
        TSE_TA_VORGANGSDATEN: content.transactionData,
        order: order._id,
        TSE_TA_FEHLER: TSE_NOT_REACHABLE,
        z: order.z
      });
    }
  }
  // make beleg:
  try {
    if (!mResults) throw new Error();
    const finishResult = content ? mResults[1] : mResults[0];
    debugTse('register: ', `${orderContent.transactionData}`);
    notificationToast(`register: ${orderContent.transactionData}  (${_.round((end - begin) / 1000, 1)} s)`, { autoClose: 1000 * 10 });

    const transactionNumber = finishResult.transactionFinishInfo.transactionNumber;
    const startTime = finishResult.transactionStartInfo.logTime;
    const logTime = finishResult.transactionFinishInfo.logTime;
    const signature = finishResult.transactionFinishInfo.signature;

    await TseTransaction.insert({
      _id: uuid(),
      TSE_TANR: finishResult.transactionFinishInfo.transactionNumber,
      TSE_TA_START: finishResult.transactionStartInfo.logTime,
      TSE_TA_ENDE: finishResult.transactionFinishInfo.logTime,
      TSE_TA_VORGANGSART: orderContent.processType,
      TSE_TA_SIGZ: finishResult.transactionStartInfo.signatureCounter,
      TSE_TA_SIG: finishResult.transactionFinishInfo.signature,
      TSE_TA_VORGANGSDATEN: orderContent.transactionData,
      order: order._id,
      z: order.z,
      ...assertHandlerObj
    });
    //todo: pattern for transactionNumber

    //todo: gen qr code
    const qrCode = makeQr({
      kassenSeriennummer: tseConfig0()?.username || getDeviceId(),
      processData: orderContent.transactionData,
      transactionNumber,
      signatureCounter: finishResult.transactionFinishInfo.signatureCounter,
      startTime: startTime,
      logTime,
      signature,
      sigAlg: tseConfig0().signatureAlgorithm!,
      publicKey: tseConfig0().tsePublicKey!
    });

    debugTse(`qrCode: ${qrCode}`, {orderId: order._id});
    return qrCode;
  } catch (e) {
    notificationToast(`can not register: ${orderContent.transactionData}`, { autoClose: 1000 * 10 });

    await TseTransaction.insert({
      _id: uuid(),
      TSE_TA_START: order.date,
      TSE_TA_ENDE: order.date,
      TSE_TA_VORGANGSART: orderContent.processType,
      TSE_TA_VORGANGSDATEN: orderContent.transactionData,
      order: order._id,
      TSE_TA_FEHLER: TSE_NOT_REACHABLE,
      z: order.z
    });
    return '';
  }
}

export async function sendContentToTse(content: TransactionContent, order: Order, z: number) {
  if (content.transactionData === '') return;
  try {
    const begin = performance.now();
    const result = await tse.startAndFinishTransaction({
      clientId,
      transactionData: content.transactionData,
      processType: content.processType
    });
    // const result = await tse.startAndFinishTransaction(content.transactionData, content.processType);
    const end = performance.now();
    await TseTransaction.insert({
      _id: uuid(),
      TSE_TANR: result.transactionFinishInfo.transactionNumber,
      TSE_TA_START: result.transactionStartInfo.logTime,
      TSE_TA_ENDE: result.transactionFinishInfo.logTime,
      TSE_TA_VORGANGSART: content.processType,
      TSE_TA_SIGZ: result.transactionStartInfo.signatureCounter,
      TSE_TA_SIG: result.transactionFinishInfo.signature,
      TSE_TA_VORGANGSDATEN: content.transactionData,
      order: order._id,
      z: z
    });
    debugTse(`register: `, content.transactionData);
    notificationToast(`register: ${content.transactionData} (${_.round((end - begin) / 1000, 1)} s)`, { autoClose: 1000 * 10 });
  } catch (e) {
    captureException(e);
    await TseTransaction.insert({
      _id: uuid(),
      TSE_TA_START: order.date,
      TSE_TA_ENDE: order.date,
      TSE_TA_VORGANGSART: content.processType,
      TSE_TA_VORGANGSDATEN: content.transactionData,
      order: order._id,
      TSE_TA_FEHLER: TSE_NOT_REACHABLE,
      z: order.z,
    });
    notificationToast(`not register: ${content.transactionData}`, { autoClose: 1000 * 10, type: 'error' });
  }
}