import uuid from 'time-uuid'

import type { Order } from '@/data/Order'
import { createOrder, resetOrder } from '@/pos/logic/order-reactive'
import { CommitAction, OrderStatus } from '@/pos/OrderType'
import { signalSyncedWithLocalStorage } from '@/react/core/reactive'

import { completeOrder } from '../order-mutations'
import { srmTransactionLogic } from '../transaction.logic'
import { execTestcase, getCaTaxInfo } from './libs'
import { getLatestOrderTransaction } from '../transaction-history.service'

type O = Readonly<Order>

const [order01, setOrder01] = signalSyncedWithLocalStorage<O | null>('TESTCASE_021_ORDER_01', null)
const [order02, setOrder02] = signalSyncedWithLocalStorage<O | null>('TESTCASE_021_ORDER_02', null)

export const testRunner021: Record<string, () => Promise<boolean>> = {
  '021.001': () =>
    execTestcase({
      num: 21,
      step: 1,
      device: 'A',
      run: async () => {
        const taxInfo = getCaTaxInfo()
        const order: O = createOrder({
          _id: uuid(),
          table: '2',
          status: OrderStatus.IN_PROGRESS,
          payments: [],
          commits: [
            { action: CommitAction.CHANGE_SEAT_MODE, seatMode: true },
            { action: CommitAction.CHANGE_SEAT_QUANTITY, delta: 2, ids: [uuid(), uuid()] },
            // Main Course
            { action: CommitAction.ADD_PRODUCT, quantity: 1, seat: 0, productRef: { id: '1', name: 'Bouillabaisse', price: 22, ...taxInfo } },
            { action: CommitAction.ADD_PRODUCT, quantity: 1, seat: 0, productRef: { id: '2', name: 'Poutine', price: 33, ...taxInfo } },
            // Drinks
            { action: CommitAction.ADD_PRODUCT, quantity: 1, seat: 1, productRef: { id: '3', name: 'Bordeaux', price: 18, ...taxInfo } },
            { action: CommitAction.PRINT },
          ],
          items: [],
        })
        const seat1 = order.seatMap?.[0]
        if (!seat1) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordTemporaryBill(seat1, { print: true, parentOrder: order })
        setOrder01(order)
      },
    }),
  '021.002': () =>
    execTestcase({
      num: 21,
      step: 2,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = createOrder(o)
        const seat2 = order.seatMap?.[1]
        if (!seat2) throw new Error('Seat 2 not found')
        await srmTransactionLogic.recordTemporaryBill(seat2, { print: true, parentOrder: order })
      },
    }),
  '021.003': () =>
    execTestcase({
      num: 21,
      step: 3,
      device: 'A',
      run: async () => {
        const o = order01()
        if (!o) throw new Error('Please run step 1 first')
        const order = createOrder(resetOrder(o))
        const fromSeat = 0
        const originalItem = order.items.find(i => i.id === '1')
        if (!originalItem) throw new Error('Target item not found')

        const priceAndSeats = [
          { price: originalItem.price, quantity: originalItem.quantity / 2, seat: 0 },
          { price: originalItem.price, quantity: originalItem.quantity / 2, seat: 1 },
        ]
        for (const item of priceAndSeats) {
          order.commits?.push({
            action: CommitAction.SPLIT_ITEM,
            commitId: originalItem._id ?? '',
            ref: originalItem._id,
            seat: item.seat,
            productRef: {
              id: originalItem?.id,
              name: originalItem?.name!,
              price: item.price,
              taxes: [originalItem?.tax!, originalItem?.tax2!],
              ingredients: originalItem?.ingredients,
              taxComponents: originalItem?.taxComponents,
              taxComponents2: originalItem?.taxComponents2,
              ...(originalItem?.categories && originalItem?.categories!.length > 0 && { categories: originalItem?.categories }),
            },
            groupPrinter: originalItem?.groupPrinter,
            groupPrinter2: originalItem?.groupPrinter2,
            quantity: item.quantity,
            printed: originalItem.printed,
            printedRound: originalItem.printedRound,
            oldId: originalItem?.splitId ? originalItem?.originalInfo?.oldId : originalItem._id,
            oldPrice: originalItem?.splitId ? originalItem?.originalInfo?.oldPrice : originalItem.price,
            oldQuantity: originalItem?.splitId ? originalItem?.originalInfo?.oldQuantity : originalItem.quantity,
            originalSeat: fromSeat,
            originalQuantity: originalItem.quantity,
            srm_originalTransactionId: await getLatestOrderTransaction(order.seatMap?.[fromSeat]?._id).then(a => a?._id),
          })
        }
        const seat0 = order.seatMap?.[0]
        if (!seat0) throw new Error('Seat 0 not found')
        await srmTransactionLogic.recordTemporaryBill(seat0, { print: true, parentOrder: order })
        setOrder02(order)
      },
    }),
  '021.004': () =>
    execTestcase({
      num: 21,
      step: 4,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const seat2 = o.seatMap?.[1]
        if (!seat2) throw new Error('Seat 2 not found')
        await srmTransactionLogic.recordTemporaryBill(seat2, { print: true, parentOrder: o })
      },
    }),
  '021.005': () =>
    execTestcase({
      num: 21,
      step: 5,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const seat1 = o.seatMap?.[0]
        if (!seat1) throw new Error('Seat 1 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(seat1), { print: true, parentOrder: o })
      },
    }),
  '021.006': () =>
    execTestcase({
      num: 21,
      step: 6,
      device: 'A',
      run: async () => {
        const o = order02()
        if (!o) throw new Error('Please run step 1 first')
        const seat2 = o.seatMap?.[1]
        if (!seat2) throw new Error('Seat 2 not found')
        await srmTransactionLogic.recordClosingReceipt(completeOrder(seat2), { print: true, parentOrder: o })
      },
    }),
}
