import { captureException } from '@sentry/react'
import AwaitLock from 'await-lock'
import debug from 'debug'
import _ from 'lodash'
import pTimeout, { TimeoutError } from 'p-timeout'
import { toast, type ToastContent, type ToastOptions } from 'react-toastify'
import { TimeoutError as RxJsTimeoutError } from 'rxjs'

import { isRunningTestcase } from '@/data/PosSettingsSignal.ts'
import dialogService from '@/react/SystemService/dialogService'
import msgBox, { Buttons, Icons, Results } from '@/react/SystemService/msgBox'

import { markFinished, markStarted, printDuration } from './performance-utils'

const log = debug('data:decorators')

/**
 * Writing Well-Typed Decorators
 *
 * https://devblogs.microsoft.com/typescript/announcing-typescript-5-0/#decorators
 */
export type MethodDecorator<This, Args extends unknown[], Return> = (
  target: (this: This, ...args: Args) => Return,
  context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
) => (...args: Args) => Return

/**
 * Writing Well-Typed Decorators
 *
 * https://devblogs.microsoft.com/typescript/announcing-typescript-5-0/#decorators
 */
export type ClassDecorator<T extends abstract new (...args: unknown[]) => unknown> = (target: T, context: ClassDecoratorContext<T>) => T | void

type BaseClass = abstract new (...arg: unknown[]) => unknown
type BaseMethod<T> = (this: T, ...args: unknown[]) => unknown

/**
 * Writing Well-Typed Decorators
 *
 * https://devblogs.microsoft.com/typescript/announcing-typescript-5-0/#decorators
 */
export type ClassOrMethodDecorator<T, O> = T extends BaseClass ? ClassDecorator<T> : T extends (this: O, ...args: infer A) => infer R ? MethodDecorator<O, A, R> : never

/**
 * Writing Well-Typed Decorators
 *
 * https://devblogs.microsoft.com/typescript/announcing-typescript-5-0/#decorators
 */
export type ClassOrMethodDecoratorContext<T, O> = T extends BaseClass ? ClassDecoratorContext<T> : T extends BaseMethod<O> ? ClassMethodDecoratorContext<O, T> : never

/** Decorator for async method - will show loading Toast */
export function progressToast<C, T, A extends unknown[], R>(
  content: NoInfer<(...args: A) => ToastContent<C> | Promise<ToastContent<C>>>,
  options?: ToastOptions,
  onLoading?: (isLoading: boolean) => void
): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      const msg = await content(...args)
      log(msg)
      const id = toast.loading(msg, options)
      onLoading?.(true)
      try {
        return await originMethod.apply(this, args)
      } finally {
        toast.dismiss(id)
        onLoading?.(false)
      }
    }
}

/** Decorator for async method - will show loading Dialog */
export function progressDialog<T, A extends unknown[], R>(getOptions: (args: A) => Parameters<typeof dialogService.progress>[0]): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      const options = getOptions(args)
      log(options.title, options)
      const closeProgress = dialogService.progress(options)
      try {
        return await originMethod.apply(this, args)
      } finally {
        closeProgress()
      }
    }
}

/**
 * Decorator to make method fail if x milliseconds have passed, by using `p-timeout` lib
 *
 * @default 30s (3000ms)
 */
export function timeout<T, A extends unknown[], R>(ms = 30_000): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      // Ignore timeout when in dev mode
      if (import.meta.env.DEV) return originMethod.apply(this, args)
      return await pTimeout(originMethod.apply(this, args), { milliseconds: ms })
    }
}

let shouldBypassConfirm: 'once' | 'always' | false
/**
 * Helper function to skip the next confirmation popup.
 *
 * Used for automation
 */
export const bypassConfirmation = (mode: typeof shouldBypassConfirm = 'once') => (shouldBypassConfirm = mode)

interface ConfirmationConfig {
  title: string
  content: string
  buttons?: Buttons.YesNo | Buttons.OKCancel
  icons?: Icons
}

/**
 * Decorator for async method - Show confirmation dialog.
 *
 * Must applied to function with return type is `Promise<void>`
 */
export function requireConfirmation<T, A extends unknown[], R>(getContent: NoInfer<(...args: A) => undefined | string | ConfirmationConfig>): MethodDecorator<T, A, Promise<R | undefined>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      const data = getContent(...args)
      if (!shouldBypassConfirm && data) {
        const { title, content, buttons = Buttons.YesNo, icons } = typeof data === 'string' ? { title: 'Confirmation', content: data } : data
        const answer = await msgBox.show(title, content, buttons, icons)
        if (answer !== Results.ok && answer !== Results.yes) return
      }
      if (shouldBypassConfirm === 'once') shouldBypassConfirm = false

      return await originMethod.apply(this, args)
    }
}

/**
 * Decorator for async method - will catch errors and show toast message.
 *
 * Must applied to function with return type include `undefined` (`Promise<T | undefined>`)
 */
export function handleError<T, A extends unknown[], R>(onError?: (e: unknown) => void): MethodDecorator<T, A, Promise<R | undefined>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      try {
        return await originMethod.apply(this, args)
      } catch (e) {
        if (onError) {
          onError(e)
          return
        }
        if (e instanceof TimeoutError || e instanceof RxJsTimeoutError) {
          toast.error('Process timeout!')
          console.warn('🛑 Timeout:', e.message)
          captureException(e)
        } else if (e instanceof Error || (e as Error).message) {
          toast.error((e as Error).message)
          console.warn('🛑 Error', e)
          captureException(e)
          if ((e as Error).message === 'Network error') return
        } else {
          toast.error(`Unknown Error: ${JSON.stringify(e, null, 2)}`)
          console.error('🛑 Unknown Error', e)
          captureException(e)
        }

        if (isRunningTestcase()) throw e // Rethrow if running testcase
      }
    }
}

/**
 * Decorator for async method - will catch errors and show toast message.
 *
 * Must applied to async function
 */
export function showErrorToast<T, A extends unknown[], R>(getMsg?: NoInfer<(...args: A) => string | Promise<string>>): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A): Promise<R> {
      try {
        return await originMethod.apply(this, args)
      } catch (e) {
        const errorMsg = e instanceof Error || (e as Error).message ? (e as Error).message : JSON.stringify(e, null, 2)
        const toastMsg = getMsg ? await getMsg(...args) : `${errorMsg}`
        toast.error(toastMsg)
        throw e // Rethrow
      }
    }
}

/**
 * Decorator for async method - will ensure ony one method can be execute at a time, using lock
 */
export function ensureExclusive<T, A extends unknown[], R>(): MethodDecorator<T, A, Promise<R>> {
  const lock = new AwaitLock()
  return originMethod =>
    async function (this: T, ...args: A) {
      await lock.acquireAsync()
      try {
        return await originMethod.apply(this, args)
      } finally {
        lock.release()
      }
    }
}

/**
 * Decorator for async method - will queue the method execution with a delay interval
 */
export function queue<T, A extends unknown[], R>(ms: number, initialDelay: number = 0): MethodDecorator<T, A, Promise<R>> {
  const queue: Array<() => Promise<void>> = []
  let isProcessing = false

  const processQueue = () => {
    const next = queue.shift()
    if (next) next()
    else isProcessing = false // No more task, reset flag
  }

  return originMethod =>
    function (this: T, ...args: A) {
      return new Promise<R>((resolve, reject) => {
        // Add task to the queue
        queue.push(async () => {
          try {
            resolve(await originMethod.apply(this, args))
          } catch (e) {
            reject(e)
          } finally {
            // Process next task after delay
            setTimeout(processQueue, ms)
          }
        })
        // Start processing after delay if not started
        if (!isProcessing) {
          isProcessing = true
          setTimeout(processQueue, initialDelay || ms)
        }
      })
    }
}

/**
 * Decorator for method or class instance - make the method or all method in class bounded
 */
export function bound<T, O>() {
  return ((target: T, ctx: ClassOrMethodDecoratorContext<T, O>) => {
    if (ctx.kind === 'class') {
      ctx.addInitializer(function () {
        for (const key of Object.getOwnPropertyNames(this)) {
          if (key === 'constructor') continue
          const m = this[key as keyof T]
          if (typeof m === 'function') this[key as keyof T] = m.bind(this)
        }
      })
    } else {
      if (ctx.private) throw new TypeError('Not supported on private methods.')
      if (ctx.name === 'constructor') throw new TypeError('Not supported on constructor.')

      ctx.addInitializer(function () {
        const self = this as Record<string | symbol, unknown>
        const m = self[ctx.name]
        if (typeof m === 'function') self[ctx.name] = m.bind(this)
      })
    }
    return target
  }) as ClassOrMethodDecorator<T, O>
}

/**
 * Decorator for async method - Early exit if the condition is true
 *
 * Must applied to function with return type is `Promise<void>`
 */
export function skipIf<T, A extends unknown[], R>(predicate: NoInfer<(args: A) => boolean | Promise<boolean>>): MethodDecorator<T, A, Promise<R | undefined>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      if (await predicate(args)) return
      return await originMethod.apply(this, args)
    }
}

/**
 * Decorator for async method - Throw if the condition IS true
 *
 * Must applied to function with return type is `Promise<void>`
 */
export function throwIf<T, A extends unknown[], R>(predicate: (args: A) => boolean | Promise<boolean>, msg?: string): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      if (await predicate(args)) throw new Error(msg ?? 'Invalid params')
      return await originMethod.apply(this, args)
    }
}

export function consoleGroup<T, A extends unknown[], R>(content: (args: A) => string | Promise<string>): MethodDecorator<T, A, Promise<R>> {
  return (originMethod, ctx) =>
    async function (this: T, ...args: A) {
      console.groupCollapsed(ctx.name, await content(args))
      try {
        return await originMethod.apply(this, args)
      } finally {
        console.groupEnd()
      }
    }
}

/**
 * Decorator for async method - Add performance marks at the start and end of the method.
 */
export function performanceMark<T, A extends unknown[], R>(key?: string): MethodDecorator<T, A, Promise<R>> {
  return (originMethod, ctx) =>
    async function (this: T, ...args: A) {
      const name = typeof ctx.name === 'string' ? ctx.name : originMethod.name
      markStarted(key ?? name)
      try {
        return await originMethod.apply(this, args)
      } finally {
        markFinished(key ?? name)
        printDuration(key ?? name)
      }
    }
}

/**
 * Decorator for async method - Set a flag when the method is running
 */
export function busyIndicator<T, A extends unknown[], R>(set: (isWorking: boolean) => void): MethodDecorator<T, A, Promise<R>> {
  return originMethod =>
    async function (this: T, ...args: A) {
      set(true)
      try {
        return await originMethod.apply(this, args)
      } finally {
        set(false)
      }
    }
}

/**
 * Clone the arguments before passing to the method. Useful to prevent side effect.
 */
export function clonedArgs<T, A extends unknown[], R>(): MethodDecorator<T, A, R> {
  return originMethod =>
    function (this: T, ...args: A) {
      const clonedArgs = args.map(a => _.cloneDeep(a)) as A
      return originMethod.apply(this, clonedArgs)
    }
}

// class TestDecorators {
//   @queue(1000, 5000) // Decorator to make the function a queue with 1000ms interval
//   async test(n: number) {
//     log('Test decorator', n)
//   }
// }

// const testDecorators = new TestDecorators()

// Object.assign(window, {
//   testDecorators,
// })
