import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { printIntegrate } from "@/shared/printer/intergrateManager.ts";

import { Buffer } from "buffer";

import { createPrinter } from "@/react/Printer/create-printer.ts";
import { convertToBase64Png } from "@/shared/utils3.ts";

export const testIntegratePrint = async () => {
  const printer = await createPrinter();
  await printer.println("TEST");
  const raster: Raster = (await printer.print())!;
  const base64 = await convertToBase64Png(raster, false);
  printIntegrate({ data: Buffer.from(base64, 'base64') });
}