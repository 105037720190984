import { type FunctionComponent, memo } from "react";
import ExtraItem from "@payment/ExtraItem";
import type { OrderItem as TOrderItem, TOrder } from "@/pos/OrderType.ts";
import { useSwipeable } from "react-swipeable";
import { paymentContext0 } from "@/react/PaymentView/PaymentView.tsx";
import { getLL, LL0 } from "@/react/core/I18nService.tsx";
import clsx from "clsx";
import { twVisible } from "@/react/core/tw.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type OrderItemType = {
  item: TOrderItem,
  order: TOrder,
  itemQuantity: number,
  movedQuantity?: number,
  seat: number,
  seatEnable: boolean
  itemName?: string;
  oldPrice?: string;
  price?: string;
  courseNumber?: string;
  menuItemName?: string;
  quantityNumber?: string;
  dishImageId2?: string;
  showAddButton?: boolean;
  showCourse?: boolean;
  showTakeAway?: boolean;
  showSeperatedLine?: boolean;
  showModifiers?: boolean;
  showOldPrice?: boolean;
  showQuantity?: boolean;
  showQty?: boolean;
  showVectorIcon?: boolean;
  showExtraItem?: boolean;
  showPrice?: boolean;
};

const OrderItem: FunctionComponent<OrderItemType> = memo(
  ({
     item,
     order,
     itemQuantity,
     movedQuantity,
     seat,
     seatEnable,
     itemName = "5. Miso Soup",
     oldPrice = "€ 50",
     price = "€ 35",
     courseNumber = "Course: 1",
     menuItemName,
     quantityNumber = "1",
     dishImageId2,
     showCourse = false,
     showTakeAway = false,
     showSeperatedLine = false,
     showModifiers = false,
     showOldPrice = false,
     showQuantity = true,
     showQty = true,
     showAddButton = false,
     showVectorIcon = false,
     showExtraItem,
     showPrice,
   }) => {
    if (!item) return;
    const LL = getLL();

    const {splitEnable, onItemClick, onMinus, onPlus, order0} = paymentContext0;
    const quantity = splitEnable() ? (item.quantity - (item.movedQuantity || 0)) : item.quantity;
    const handlers = useSwipeable({
      onSwipedLeft: () => {
        if (item.printed) return;
        console.log('swipe left');

        if (item.separate) return;
        if (item.course! > 0) {
          item.course!--;
        } else if (item.course === 0) {
          item.course = -1;
        }
      },
      onSwipedRight: () => {
        if (item.printed) return;
        if (item.separate) {
          item.course = 0;
        } else {
          item.course!++;
        }
      },
      onTap: () => {
        if (item?.isVoucher) return;
        onItemClick(item, order);
      },
      trackMouse: true,
      trackTouch: true,
    });
    if (item.course === 0) showTakeAway = true;
    if (item.course! > 1) showCourse = true;
    if (item.quantity > 0 && item.separate) showSeperatedLine = true;
    if (item.modifiers.length > 0) showModifiers = true;

    return (
      <div
        className={clsx(`${twVisible(quantity > 0 && item.name !== "AAA")}`,
          item.printed ? 'opacity-70' : 'opacity-100',
          'text-black-solid-black-900-1e1e23 rounded-sm bg-white flex flex-col items-start justify-start p-2.5 box-border gap-[8px] text-left text-sm text-gray-400 font-mulish self-stretch')}
        {...handlers}
      >
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="shrink-0 flex flex-col items-start justify-start gap-[4px]">
            <b className="relative leading-[15px] sm:text-smi Tablet_768:text-smi Tablet_600:text-smi height_414:text-smi">{item.id}. {item.name}</b>
            <div className="shrink-0 flex flex-row items-center justify-start gap-[8px] text-xs text-red-solid-red-200-ff5252">
              {!!item.discount && (
                <div className="relative text-3xs [text-decoration:line-through]">
                  {LL3().format.currency(item.price)}
                </div>
              )}
              <div className="relative text-smi leading-[15px] font-semibold text-black-solid-black-900-1e1e23 sm:text-xs Tablet_768:text-xs Tablet_600:text-xs height_414:text-xs">
                {LL3().format.currency(item.vPrice || 0)}
              </div>
              {showCourse && (
                <i className="relative font-bold sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs">{LL0().virtualPrinter.course()}: {item.course}</i>
              )}
              {showTakeAway && (
                <i className="relative font-bold text-green-solid-green-450-64dd17 sm:text-2xs Tablet_768:text-2xs Tablet_600:text-2xs height_414:text-2xs">
                  {LL().order.takeAway()}
                </i>
              )}
            </div>
          </div>
          {showQuantity && (
          <div className="shrink-0 flex flex-row items-center justify-start gap-[3px] text-center text-base">
              {showAddButton && (
              <img
                className="object-cover w-[22px] relative h-[22px] sm:w-[18px] sm:h-[18px]"
                alt=""
                src="/payment-minusvector3.svg"
              />
              )}
              {showQty && (
                <b className="relative inline-block w-6 shrink-0 Tablet_768:text-sm Tablet_768:w-[22px] Tablet_600:text-sm Tablet_600:w-[22px] height_414:text-sm height_414:w-[22px]">
                  {quantity}
                </b>
              )}
            {showAddButton && (
              <img
                className="object-cover w-[22px] relative h-[22px] Tablet_768:w-[18px] Tablet_768:h-[18px]"
                alt=""
                src="/plusvector3.svg"
              />
              )}
            </div>
          )}
        </div>
        {showModifiers && (
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-x-2 gap-y-2">
            {item.modifiers.map((modifier, index) => (
              <ExtraItem key={index} modifierSName={`${modifier.name} | `} modifierSPrice={modifier.vPrice}/>
            ))}
          </div>
        )}
        {showSeperatedLine && (
          <div className="self-stretch relative bg-[#ff5252] h-0.5"/>
        )}
      </div>
    );
  }
);

export default OrderItem;
