import type { Eod } from "@/data/Eod.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

// location.csv
export const Stamm_Orte = {
  Z_KASSE_ID: {
      type: "string",
      maxLength: 50,
      hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
      type: "datetime",
      hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
      type: "number",
      places: 0,
      hint: "Nr. des Kassenabschlusses"
  },
  LOC_NAME: {
      type: "string",
      maxLength: 60,
      hint: "Name des Standortes"
  },
  LOC_STRASSE: {
      type: "string",
      maxLength: 60,
      hint: "Straße"
  },
  LOC_PLZ: {
      type: "string",
      maxLength: 10,
      hint: "Postleitzahl"
  },
  LOC_ORT: {
      type: "string",
      maxLength: 62,
      hint: "Ort"
  },
  LOC_LAND: {
      type: "string",
      maxLength: 3,
      hint: "Land"
  },
  LOC_USTID: {
      type: "string",
      maxLength: 15,
      hint: "USTID"
  }
}

export function Stamm_OrteFactory(eod: Eod) {
  return build()
  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      LOC_NAME: LOC_NAME(),
      LOC_STRASSE: LOC_STRASSE(),
      LOC_PLZ: LOC_PLZ(),
      LOC_ORT: LOC_ORT(),
      LOC_LAND: LOC_LAND(),
      LOC_USTID: LOC_USTID()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse",
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses",
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses",
    return eod.z;
  }

  function LOC_NAME() {
    // hint: "Name des Standortes",
    return companyInfo0().name;
  }

  function LOC_STRASSE() {
    // hint: "Straße",
    return companyInfo0().address;
  }

  function LOC_PLZ() {
    // hint: "Postleitzahl",
    return companyInfo0().zipCode;
  }

  function LOC_ORT() {
    // hint: "Ort",
    return companyInfo0().city;
  }

  function LOC_LAND() {
    // hint: "Land",
    return 'DEU';
  }

  function LOC_USTID() {
    // hint: "USTID",
    return companyInfo0().ustId;
  }
}
