import { computed } from "@/react/core/reactive.ts";

/** Get value from the param in URL `?key=val` */
export function getUrlParam(key: string) {
  return typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get(key) : undefined
}

/** Get values from the param in URL `?key=val1,key=val2` */
export const getUrlParams = (key: string) => (typeof window !== 'undefined' ? new URLSearchParams(window.location.search).getAll(key) : undefined)
export const VALID_OS = ['ios', 'android', 'win32', 'darwin'] as const
export type VALID_OS = (typeof VALID_OS)[number]
/** Get the `?os=` param in the url. Default to `'android'`  */
export const getOsFromParam = (): VALID_OS => {
  const val = getUrlParam('os')
  if (VALID_OS.includes(val as VALID_OS)) return val as VALID_OS
  return 'android'
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export const stringifyObj = (obj: object) =>
  Object.entries(obj)
    .map(a => a.join('='))
    .join(' ')


export const connectCodeFromParam = computed(() => {
  const val = getUrlParam('connect-code')
  if (val && val?.length > 4) return val
});