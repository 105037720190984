import { type FunctionComponent, useCallback, useState } from 'react';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import TaxPaymentPaymentMethodRow from '@tax-payment/TaxPaymentPaymentMethodRow.tsx';
import { payments0 } from '@/data/PaymentHub.ts';
import {
  createPaymentMethod,
  initPaymentMethod,
  payment0,
  setPayment0
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import { deepSignal } from '@/react/core/reactive.ts';
import { clone } from '@atc-group/json-fn';
import { LL0 } from '@/react/core/I18nService.tsx';
import NewEditPaymentMethodPopu from '@tax-payment/Popups/NewEditPaymentMethodPopup/NewEditPaymentMethodPopu.tsx';

const PaymentMethodsSection: FunctionComponent = () => {
  const onAddPaymentMethod = async () => {
    const _payment = payment0();
    if (!_payment) return;
    await createPaymentMethod(_payment);
    closeNewEditPaymentMethodPopu5()
  }

  const [isNewEditPaymentMethodPopu5Open, setNewEditPaymentMethodPopu5Open] =
    useState(false);

  const openNewEditPaymentMethodPopu5 = useCallback(() => {
    setPayment0(deepSignal(clone(initPaymentMethod)))
    setNewEditPaymentMethodPopu5Open(true);
  }, []);

  const closeNewEditPaymentMethodPopu5 = useCallback(() => {
    setNewEditPaymentMethodPopu5Open(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-end justify-start gap-[14px_0px] text-left text-sm text-gray-300 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
          <b className="self-stretch relative sm:text-smi Tablet_768:text-smi">
            {LL0().payment.paymentMethods()}:
          </b>
          <div className="self-stretch rounded overflow-hidden flex flex-col items-start justify-start text-mini text-white-solid-white-100-ffffff border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
            <div className="self-stretch bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-between py-[13px] px-[11px] sm:pt-2 sm:pb-2 sm:box-border Tablet_768:pt-1.5 Tablet_768:pb-1.5 Tablet_768:box-border">
              <div className="w-5 h-5 overflow-hidden shrink-0" />
              <div className="flex-1 flex flex-row items-center justify-start py-0 px-1 text-left text-sm">
                <div className="relative font-semibold Tablet_768:text-smi">{LL0().article.name()}</div>
              </div>
              <div className="flex-[0.7778] flex flex-row items-center justify-center py-0 px-2 box-border max-w-[44px] pr-10">
                <div className="relative font-semibold Tablet_768:text-smi">{LL0().settings.generalSetting.auto()}</div>
              </div>
              <div className="ml-3 flex-[0.7778] flex flex-row items-center justify-center py-0 px-2 box-border max-w-[44px] pr-10">
                <div className="relative font-semibold Tablet_768:text-smi">{LL0().payment.tip()}</div>
              </div>
              <div className="flex-[0.875] h-5 overflow-hidden max-w-[72px]" />
            </div>
            {payments0().map((payment) => <TaxPaymentPaymentMethodRow
              paymentData={payment}
              key={payment._id}
            />)}
          </div>
        </div>
        <div
          className="rounded-xl bg-yellowgreen shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-1 px-2 gap-[0px_5px] cursor-pointer text-smi text-black1"
          onClick={openNewEditPaymentMethodPopu5}
        >
          <img
            className="relative w-6 h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"
            alt=""
            src="/iconplus-icon@2x.png"
          />
          <div className="relative font-semibold Tablet_768:text-xs">
            {LL0().taxPayment.newPayment()}
          </div>
        </div>
      </div>
      {isNewEditPaymentMethodPopu5Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewEditPaymentMethodPopu5}
        >
          <NewEditPaymentMethodPopu onClose={closeNewEditPaymentMethodPopu5} onComplete={onAddPaymentMethod} />
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentMethodsSection;