import _ from "lodash";
import dayjs from "dayjs";
import { formatCurrencyDe } from "@/shared/utils";
import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos, Bonpos_USt, getUST_SCHLUESSEL } from "@/tse/dsfinv/dsfinvModel2.ts";
import { calItemNet, calNet, calTax } from "@/pos/orderUtils.ts";
import { getItemNet, getItemTax } from "@/pos/logic/order-reactive.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";


const Bonpos_Preisfindung = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  POS_ZEILE: {
    type: "string",
    maxLength: 50,
    hint: "Zeilennummer"
  },
  TYP: {
    type: "string",
    maxLength: 20,
    hint: "Basispreis, Rabatt oder Zuschlag",
    //todo: check TYP here
    regex: "^(base_amount|discount|extra_amount)$"
  },
  UST_SCHLUESSEL: {
    type: "number",
    places: 0,
    hint: "ID des USt-Satzes"
  },
  PF_BRUTTO: {
    type: "number",
    places: 5,
    hint: "Bruttoumsatz"
    //todo: check why is equals, maybe different by rabatt
  },
  PF_NETTO: {
    type: "number",
    places: 5,
    hint: "Nettoumsatz"
  },
  PF_UST: {
    type: "number",
    places: 5,
    hint: "USt"
  }
}

export enum PreisfindungMode {
  base_amount = 'base_amount',
  discount = 'discount',
  extra_amount = 'extra_amount'
}

export function Bonpos_PreisfindungFactory(order: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  let mode: PreisfindungMode;

  return build();
  function build() {
    mode =PreisfindungMode.base_amount;
    const item1 = buildSingle();
    mode =PreisfindungMode.discount;
    const item2 = buildSingle();
    return [item1, item2];
  }

  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      TYP: TYP(),
      UST_SCHLUESSEL: UST_SCHLUESSEL(),
      PF_BRUTTO: PF_BRUTTO(),
      PF_NETTO: PF_NETTO(),
      PF_UST: PF_UST()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    if (isCancelled) {
      return order.items.indexOf(item) + 1;
    } else {
      return order.cancellationItems!.indexOf(item) + 1;
    }
  }

  function TYP() {
    //Basispreis, Rabatt oder Zuschlag
    //regex: "^(base_amount|discount|extra_amount)$",
    return mode;
  }

  function UST_SCHLUESSEL() {
    return getUST_SCHLUESSEL(item?.tax!);
  }

  function PF_BRUTTO() {
    if (mode === PreisfindungMode.base_amount) {
      return formatCurrencyDe(item?.vSum || 0)
    } else {
      return formatCurrencyDe(item?.vDiscount || 0)
    }
  }

  function PF_NETTO() {
    if (mode === PreisfindungMode.base_amount) {
      return formatCurrencyDe(getItemNet(item) || 0);
    }
    return formatCurrencyDe(calNet(item?.vDiscount!, item?.tax || 0));
  }

  function PF_UST() {
    if (mode === PreisfindungMode.base_amount) {
      return formatCurrencyDe(getItemTax(item) || 0);
    }
    return formatCurrencyDe(calTax(item?.vDiscount!, item?.tax || 0));
  }
}

/**
 * Nếu giảm giá không liên quan trực tiếp đến từng mặt hàng mà liên quan đến toàn bộ hóa đơn,
 * những chiết khấu này phải được thể hiện dưới dạng các dòng mục riêng biệt có dấu trừ trong
 * tệp Bonpos. Việc phân chia giảm giá trị thanh toán được thực hiện trong tệp Bonpos_USt.
 */
