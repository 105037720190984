import { memo, useEffect } from "react";
import VoucherPlugin from "@voucher/VoucherPlugin.tsx";
import { signal } from "@/react/core/reactive.ts";
import { Voucher, type VoucherInfo, VoucherStatus, VoucherType } from "@/data/Voucher.ts";
import { makeVouchersAvailable, setVoucherV, vouchers0 } from "@/data/VoucherHub.ts";
import uuid from "time-uuid";
import dayjs from "dayjs";
import { loginUsers } from '@/data/UserSignal.computed'
import _ from "lodash";
import { CommitAction, type TOrder } from "@/pos/OrderType.ts";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import { itemContext } from "@/react/OrderView/OrderView.tsx";
import { Order } from "@/data/Order.ts";
import { toast } from "react-toastify";
import { LL0 } from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { PosScreen, router } from "@/pos/PosRouter";
import { roundNumber } from "@/shared/order/order-config.ts";
import { paymentContext0, setValue0, value0, order0 as orderPayment0 } from "@/react/PaymentView/PaymentView.tsx";
import { log } from "debug";


export const [selectedVoucherId, setSelectedVoucherId] = signal<string | undefined>()
export const selectedVoucher = () => vouchers0().find(u => u._id === selectedVoucherId())
export const [searchText, setSearchText] = signal<string | undefined>('')

export function genVoucherCode(length = 8) {
  const characters = '0123456789';
  let voucherCode = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    voucherCode += characters.charAt(randomIndex);
  }
  userFLow(`generate voucher code ${voucherCode}`, {
    username: loginUser()?.name
  })
  return voucherCode;
}

export const checkExpireDate = (date?: number) => {
  if (!date) return false
  const expiredAt = dayjs.unix(date)
  return expiredAt.isBefore(dayjs())
}

export const createVoucher = async (
  { code, price, amount, isEqualPrice = true, customerFirstName, customerLastName }: {
    customerFirstName?: string,
    customerLastName?: string,
    code?: string,
    price?: number,
    amount?: number,
    isEqualPrice?: boolean
  }
) => {
  const newVoucher = await Voucher.insert({
    _id: uuid(),
    code: code || genVoucherCode(),
    createdAt: dayjs().unix(),
    price: price || 0,
    createdBy: loginUsers()?.[0],
    status: VoucherStatus.CREATED,
    activated: true,
    expiredAt: undefined,
    name: 'Voucher',
    type: VoucherType.AMOUNT,
    totalValue: amount || 0,
    usedValue: 0,
    customerLastName: customerLastName || '',
    customerFirstName: customerFirstName || '',
    isEqualPrice: isEqualPrice,
  })
  userFLow(`Create voucher in voucher ${newVoucher._id} ${newVoucher.code}`, {
    username: loginUser()?.name
  })
  setVoucherV(v => v + 1)
  setSelectedVoucherId(newVoucher._id)
  return newVoucher
}

export const deleteVoucher = async () => {
  userFLow(`Delete voucher in voucher ${selectedVoucher()?._id} ${selectedVoucher()?.code}`, {
    username: loginUser()?.name
  })
  const v = _.clone(selectedVoucher())
  if (v) {
    await Voucher.find({ selector: { _id: v._id } }).remove()
    setVoucherV(v => v + 1)
    setSelectedVoucherId(undefined)
  }
}

export const addVoucher = ({ code, price, voucher, _id, seat }: {
  code: string,
  price: number,
  voucher?: VoucherInfo,
  _id?: string,
  seat?: number
}, order: TOrder) => {
  const voucherItem = {
    name: 'Voucher',
    price: +price,
    isVoucher: true,
    voucher: voucher || {
      amount: 0,
      firstName: '',
      lastName: '',
    },
    tax: 0,
    taxes: [0, 0],
    code: code,
    _id: _id || uuid(),
  }

  const isPaymentView = router.screen === PosScreen.PAYMENT;
  const targetSeat = isPaymentView ? paymentContext0.addSeat() : itemContext.addSeat()
  order.commits?.push({
    action: CommitAction.ADD_PRODUCT,
    ref: voucherItem!._id,
    _id: uuid(),
    productRef: voucherItem,
    ...targetSeat,
    // ...seat != null ? {seat: seat} : itemContext.addSeat(),
  });

  //setValue0 after add voucher in payment view
  if (!isPaymentView) return;
  updateValueAfterAddVoucher(order, targetSeat);
}

function updateValueAfterAddVoucher(order: TOrder, targetSeat?: any) {
  //add value
  const debouncedSetValue = _.debounce((value) => {
    setValue0(roundNumber(value, 2).toString());
    log(`change payment to ${value} after add voucher`)
  }, 50);

  const targetOrder = order.seatMode && !_.isEmpty(targetSeat) && targetSeat != null ? order?.seatMap?.[targetSeat?.seat] : order;
  if (targetOrder?.payments?.length === 0) return

  const isSinglePayment = (targetOrder?.payments?.length || 1) < 2
  const total = targetOrder?.vTotal || 0;
  const paymentSum = _.round(_.sumBy(targetOrder?.payments, 'value'), 2);

  if (isSinglePayment || total <= 0) {
    //single payment
    debouncedSetValue(total);
    return
  }

  //multi payment
  if ((total || 0) > 0) {
    const addValue = _.round(total - paymentSum, 2)
    if (!addValue) return;
    const oldValue0 = Number(value0());
    debouncedSetValue(_.round(addValue + oldValue0, 2))
  }
}

export const checkVoucherStatus = (voucherCode: string) => {
  const today = dayjs().unix();
  const voucher = vouchers0().find(v => v.code === voucherCode);

  if (!voucher) return { valid: false, message: LL0().voucher.voucherNotCreated() };
  if (voucher.status === VoucherStatus.REDEEMED) return { valid: false, message: LL0().voucher.voucherRedeemed() };
  if (voucher.expiredAt && voucher.expiredAt <= today) return { valid: false, message: LL0().voucher.voucherExpired() };
  if (!voucher.activated) return { valid: false, message: LL0().voucher.voucherInactive() };

  const remainingValue = _.round(voucher.totalValue - (voucher.usedValue || 0), 2);
  return { valid: true, remainingValue, voucher };
};

export const redeemVoucher = async (code: string, amount: number, order: TOrder, voucherInfo?: VoucherInfo, seat?: number) => {
  const { valid, message, voucher, remainingValue } = checkVoucherStatus(code);
  userFLow(`add create voucher in voucher ${code}`, {
    username: loginUser()?.name
  })
  if (valid && voucher) {
    const orders = await Order.find().exec();
    const existedVoucher = orders.some(order => order.items?.some(item => item.code === code && item.quantity > 0 && item.isVoucher));
    let exitedVoucherInOrder
    if (paymentContext0.splitEnable()){
      const checkItems = (orderPayment0()?.seatMap || [])?.flatMap(seatOrder => seatOrder.items)
      exitedVoucherInOrder = checkItems.some(item => item.code === code && item.quantity > 0 && item.isVoucher)
    } else {
      exitedVoucherInOrder = order0().items.some(item => item.code === code && item.quantity > 0 && item.isVoucher)
    }
    if (!existedVoucher && !exitedVoucherInOrder) {
      addVoucher({
        code: code,
        price: -(amount > remainingValue ? remainingValue : amount || 0),
        _id: voucher._id,
        voucher: voucherInfo || {
          firstName: '',
          lastName: '',
        },
        ...seat != null ? {seat: seat} : itemContext.addSeat()
      }, order);
    } else {
      toast.error(LL0().voucher.voucherHasAlready());
    }
  } else {
    toast.error(message || LL0().voucher.voucherInvalid());
  }
};

const VoucherView = () => {

  makeVouchersAvailable()
  useEffect(() => {
    setSelectedVoucherId(vouchers0()?.[0]?._id)
  }, [])

  return (
    <VoucherPlugin/>
  )
}

export default memo(VoucherView)