import {type FunctionComponent, memo} from 'react';
import HistoryLeftPath from '@order-history/HistoryLeftPath';
import HistoryRightPath from '@order-history/HistoryRightPath';
import { makeOrdersPageAvailable, setIsFirstRender, setOrderV1 } from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { makePaymentsAvailable } from '@/data/PaymentHub.ts';
import { setOrderV } from "@/tse/ZOrderHub.ts";
import { onEnter, PosScreen } from "@/pos/PosRouter.ts";
import { rnHost } from '@/shared/webview/rnwebview';
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { makeTerminalsAvailable } from "@/data/TerminalHub.ts";


const OrderHistoryPlugin: FunctionComponent = () => {
  //init data
  makePaymentsAvailable();
  makeOrdersPageAvailable();
  makeTerminalsAvailable();
  //renew data
  onEnter(PosScreen.ORDER_HISTORY, () => {
    setIsFirstRender(true);
    setOrderV1(v => v + 1)

    if(isQuebecSrmEnabled()) rnHost.loadCert()
  })

  return (
    <div className="order-history-screen w-full h-full max-w-full overflow-hidden flex flex-row items-start justify-start relative">
      <HistoryLeftPath />
      <HistoryRightPath />
    </div>
  );
};

export default memo(OrderHistoryPlugin);
