import { type FunctionComponent, useCallback, useState} from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';
import EditMenuNewGroupPopup from './EditMenuNewGroupPopup.tsx';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {addModifierFactory, dineInModifiers} from '@/data/ModifierHub.ts';
import {
  createNewModifier,
  removeModifierItem,
  setSelectedItem,
  setSelectedModifier
} from '@/react/EditMenuView/EditMenuView.tsx';
import {LL0} from '@/react/core/I18nService.tsx';
import {clone} from '@atc-group/json-fn';
import uuid from 'time-uuid'
import { type Modifier, type ModifierItem, ModifierType } from '@/data/Modifier.ts'
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { product0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

type EditMenuPopupModifiersType = {
  onClose?: () => void;
};

const EditMenuPopupModifiers: FunctionComponent<EditMenuPopupModifiersType> = ({
                                                                                 onClose,
                                                                               }) => {
  const [isEditMenuNewGroupPopupOpen, setEditMenuNewGroupPopupOpen] =
    useState(false);

  const openEditMenuNewGroupPopup = useCallback(() => {
    userFLow(`open edit menu new group popup`, {
      username: loginUser()?.name
    });
    setEditMenuNewGroupPopupOpen(true);
  }, []);

  const closeEditMenuNewGroupPopup = useCallback(() => {
    userFLow(`close edit menu new group popup`, {
      username: loginUser()?.name
    });
    setEditMenuNewGroupPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className="relative rounded bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-start p-6 box-border gap-[24px] min-w-[520px] max-w-full text-left text-smi text-black-solid-black-1000-000000 font-mulish">
        <div className="h-10 self-stretch flex-1 flex flex-col items-start justify-start gap-[16px]">
          <div
            className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-1 text-base text-blue-solid-blue-500-0051c1">
            <b className="flex-1 relative">{LL0().productViewSetting.modifierDialog.availabilityGroups()}:</b>
            <div
              className="rounded-10xs bg-green-solid-green-600-00c37c h-8 flex flex-row items-center justify-center py-[7px] px-2 box-border cursor-pointer text-smi text-white-solid-white-100-ffffff"
              onClick={() => createNewModifier(ModifierType.MODIFIER)}
            >
              <b className="relative">{LL0().productViewSetting.modifierDialog.addNewGroup()}</b>
            </div>
          </div>
          <div className="flex flex-col overflow-y-auto no-scrollbar self-stretch gap-[16px]">
            {dineInModifiers().map((modifier) =>
              <div className="self-stretch flex flex-row items-center justify-between"
                   key={modifier._id}
              >
                <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                  <div className="!w-fit w-[121px] grid flex-row items-center justify-start gap-[12px] grid-flow-col">
                    <div className="self-stretch relative w-5">
                      <FormControlLabel
                        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                        label=""
                        control={
                          <Checkbox color="primary"
                                    checked={!!product0()?.activePopupModifierGroup?.includes(modifier._id!)}
                                    onChange={e => addModifierFactory(() => product0(), modifier)(e.target.checked)}
                          />
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[2px]">
                      <div
                        className="!bg-[#c2dbff] rounded bg-cyan-solid-cyan-150-c2dbff flex flex-row items-center justify-start py-0 pr-0 pl-1.5 gap-[8px]">
                        <div className="relative">{modifier?.name}</div>
                        <div
                          className="box-border w-6 h-6 flex flex-row items-center justify-center border-l-[1px] border-solid border-white-solid-white-100-ffffff">
                          <img
                            className="relative w-[18px] h-[18px] object-cover cursor-pointer"
                            alt=""
                            src="/iconpencil-icon@2x.png"
                            onClick={() => {
                              setSelectedModifier(modifier);
                              setSelectedItem(modifier.items?.[0]!);
                              openEditMenuNewGroupPopup();
                            }}
                          />
                        </div>
                      </div>
                      <img
                        className="relative w-6 h-6 object-cover"
                        alt=""
                        src="/iconaccordion-arow-right@2x.png"
                      />
                    </div>
                  </div>
                  <div
                    className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[8px] text-blue-solid-blue-420-2979ff">
                    {modifier.items?.map((item, index) => (
                      <div
                        className="rounded bg-gray-solid-gray-150-eeeeee flex flex-row items-center justify-start py-0 pr-0 pl-1.5 gap-[8px]"
                        key={index}
                      >
                        <div className="relative">{item?.name}</div>
                        <div
                          className="box-border w-6 h-6 flex flex-row items-center justify-center border-l-[1px] border-solid border-white-solid-white-100-ffffff"
                          onClick={() => removeModifierItem(modifier, index)}
                        >
                          <img
                            className="relative w-3.5 h-3.5 object-cover"
                            alt=""
                            src="/icondelete-fillter-icon@2x.png"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 object-cover"
                  alt=""
                  src="/iconduplicate-icon@2x.png"
                  onClick={() => {
                    const items = modifier.items?.map((item) => ({ ...item, _id: uuid() })) as ModifierItem[];
                    createNewModifier(ModifierType.MODIFIER, clone({ ...modifier, _id: uuid(), items } as Modifier))
                  }}
                />
              </div>)}
          </div>
        </div>
        <div
          className="h-[50px] self-stretch flex flex-row items-center justify-end pt-3 px-0 pb-0 gap-[12px] text-center text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={onClose}
          >
            <b className="relative">{LL0().ui.cancel()}</b>
          </div>
          <div
            className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8 text-white-solid-white-100-ffffff"
            onClick={onClose}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
      {isEditMenuNewGroupPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuNewGroupPopup}
        >
          <EditMenuNewGroupPopup onClose={closeEditMenuNewGroupPopup} type={ModifierType.MODIFIER}/>
        </PortalPopup>
      )}
    </>
  );
};

export default EditMenuPopupModifiers;
