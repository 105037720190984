import axios from 'axios'
import delay from 'delay'
import _ from 'lodash'
import uuid from 'time-uuid'

import { getDemoPosSetting } from '@/assets/data/demoPosSetting'
import { CashbookTransactions } from '@/data/Cashbook'
import { Category } from '@/data/Category'
import { Customer } from '@/data/Customer.ts'
import { deleteMany } from '@/data/data-utils.ts'
// import { DeviceSetting, DeviceSettingCollection } from '@/data/DeviceSetting'
import { Discount } from '@/data/Discount.ts'
import { GroupPrinter } from '@/data/GroupPrinter'
import { Inventory } from '@/data/Inventory.ts'
import { InventoryAction } from '@/data/InventoryAction.ts'
import { Menu } from '@/data/Menu'
import { Modifier } from '@/data/Modifier'
import { OnlineProvider } from '@/data/OnlineProvider'
import { Order, PaidOrder } from '@/data/Order'
import { OrderLayout } from '@/data/OrderLayout'
import { Payment } from '@/data/Payment'
import { PosSetting } from '@/data/PosSetting'
import { Product } from '@/data/Product'
import { ProductLayout } from '@/data/ProductLayout'
import { Room, RoomObject, type RoomObjectType } from '@/data/Room'
import { TaxCategory } from '@/data/TaxCategory'
import { User, UserRole } from '@/data/User'
import { ASIA_SERVER_1, CANADA_SERVER_1, GERMANY_SERVER_1, GERMANY_SERVER_2 } from '@/lib/servers.ts'
import type { OrderStrip } from '@/pos/OrderType'
import { isGermany } from '@/react/CompanyInformationView/companyInfomation.logic.ts'
import { signal } from '@/react/core/reactive.ts'
import { getOsFromParam, getUrlParam } from '@/shared/utils2.ts'
import { Section } from "@/data/Section.ts";
import { applyDefaultTaxByCountry } from './utils/applyDefaultTaxByCountry'
import { deleteCreatedMenuUser } from "@/data/utils/data-utils-3.ts";

export const [subscribePause, setSubscribePause] = signal(false)

export async function importLayout(orderLayout: OrderLayout) {
  const productLayouts: Array<ProductLayout> = []
  for (const category of orderLayout.categories) {
    productLayouts.push(...(category.products?.map(p => ({ ...p, category: category._id })) ?? []))
    delete category.products
  }

  await OrderLayout.insert(orderLayout)
  const result = await ProductLayout.bulkInsert(productLayouts)
  console.log('✅ Imported', result.success.length, 'productLayouts.')
}

async function importPayment(payments: Array<Payment>) {
  const result = await Payment.bulkInsert(payments)
  console.log('✅ Imported', result.success.length, 'payments.')
}

async function importSection(section: Array<Section>) {
  const result = await Section.bulkInsert(section)
  console.log('✅ Imported', result.success.length, 'sections.')
}

export async function importProduct(products: Array<Product>) {
  const countryCode = getUrlParam('cn') || import.meta.env.VITE_COUNTRY
  const finalProducts: Array<Product> = products.map(applyDefaultTaxByCountry(countryCode, true))
  const result = await Product.bulkInsert(finalProducts)
  console.log('✅ Imported', result.success.length, 'products.')
}

async function importCategory(data: Array<Category>) {
  const result = await Category.bulkInsert(data)
  console.log('✅ Imported', result.success.length, 'categories.')
}
async function importMenu(menus: Array<Menu>) {
  const result = await Menu.bulkInsert(menus)
  console.log('✅ Imported', result.success.length, 'menus.')
}
async function importOnlineProvider(onlineProviders: Array<OnlineProvider>) {
  const result = await OnlineProvider.bulkInsert(onlineProviders)
  console.log('✅ Imported', result.success.length, 'onlineProviders.')
}

async function importInventory(inventories: Array<Inventory>) {
  const result = await Inventory.bulkInsert(inventories)
  console.log('✅ Imported', result.success.length, 'inventories.')
}

async function importInventoryAction(inventoryActions: Array<InventoryAction>) {
  const result = await InventoryAction.bulkInsert(inventoryActions)
  console.log('✅ Imported', result.success.length, 'inventoryActions.')
}

async function importCustomer(customers: Array<Customer>) {
  const result = await Customer.bulkInsert(customers)
  console.log('✅ Imported', result.success.length, 'customers.')
}

async function importUser(users: Array<User>) {
  const result = await User.bulkInsert(users)
  console.log('✅ Imported', result.success.length, 'users.')
}

async function importGroupPrinter(groupPrinters: Array<GroupPrinter>) {
  // groupPrinters = groupPrinters.map((p, index) => ({ ...p, _id: index.toString() }));
  const result = await GroupPrinter.bulkInsert(groupPrinters)
  console.log('✅ Imported', result.success.length, 'groupPrinters.')
}

async function importModifier(modifiers: Array<Modifier>) {
  const result = await Modifier.bulkInsert(modifiers)
  console.log('✅ Imported', result.success.length, 'modifier.')
}

async function importPosSetting(posSettings: Array<PosSetting | undefined>) {
  const result = await PosSetting.bulkInsert(posSettings.filter(Boolean) as PosSetting[])
  console.log('✅ Imported', result.success.length, 'posSettings.')
}

// async function importDeviceSetting(deviceSettings: Array<DeviceSetting>) {
//   const result = await DeviceSettingCollection.bulkInsert(deviceSettings)
//   console.log('✅ Imported', result.success.length, 'deviceSettings.')
// }

async function importCashbookTransactions(data: Array<CashbookTransactions>) {
  const result = await CashbookTransactions.bulkInsert(data)
  console.log('✅ Imported', result.success.length, 'cashbookTransactions.')
}

// async function clear() {
//   // @ts-ignore
//   await window.clear()
// }

async function importRoom(rooms: Array<Room>) {
  const roomObjects: Array<RoomObject> = []
  for (const room of rooms) {
    roomObjects.push(...(room.roomObjects?.map(p => ({ ...p, room: room._id, type: p.type as RoomObjectType })) ?? []))
    delete room.roomObjects
  }

  const result = await Room.bulkInsert(rooms)
  const result2 = await RoomObject.bulkInsert(roomObjects)
  console.log('✅ Imported', result.success.length, 'rooms.')
  console.log('✅ Imported', result2.success.length, 'roomObjects.')
}

async function importTaxCategory(taxCategories: Array<TaxCategory>) {
  const result = await TaxCategory.bulkInsert(taxCategories)
  console.log('✅ Imported', result.success.length, 'taxCategories.')
}

export async function importDemoOrders() {
  // return await changeDemoOrders();
  const demoOrder = (await import('@/assets/data/demo-order3')).default as unknown as OrderStrip
  const arr = []
  setSubscribePause(true)
  for (let i = 0; i < 100; i++) {
    for (let j = 0; j < 100; j++) {
      arr.push({ ...demoOrder, _id: uuid() })
    }
    await PaidOrder.bulkInsert(arr)
    console.log('processed: ', i)
    arr.splice(0, arr.length)
  }
}

async function importDiscountLabel(discountLabels: Array<Discount>) {
  const result = await Discount.bulkInsert(discountLabels)
  console.log('✅ Imported', result.success.length, 'discountLabels.')
}

export async function changeDemoOrders() {
  const demoOrder = (await import('@/assets/data/demo-order3')).default as unknown as OrderStrip
  // const arr = []
  setSubscribePause(true)
  await Order.insert(demoOrder)
  const doc = await Order.findOne({}).exec()
  // await doc?.incrementalRemove();
  // log('removed');
  for (let i = 0; i < 100; i++) {
    await doc?.incrementalPatch({ id: i })
    console.log('processed: ', i)
  }
}

async function getServerFromApi() {
  try {
    console.log('getServerFromApi')
    const apiUrl = 'https://api.ipgeolocation.io/ipgeo?apiKey=4ab115b6473d42848825ccc041185dbb&fields=continent_name'
    const { data } = await axios.get(apiUrl)
    const { continent_name } = data
    switch (continent_name) {
      case 'Asia':
        return ASIA_SERVER_1
      case 'Europe':
        return GERMANY_SERVER_1
      default:
        return CANADA_SERVER_1
    }
  } catch (e) {
    console.error('getServerFromApi', e)
    return CANADA_SERVER_1
  }
}

export async function getDefaultPosSetting() {
  const enrich = { server: '' }
  if (import.meta.env.MODE === 'development') {
    enrich.server = ASIA_SERVER_1
  } else if (isGermany()) {
    enrich.server = GERMANY_SERVER_1
  } else if (getUrlParam('cn')) {
    const countryCode = getUrlParam('cn')
    switch (countryCode) {
      case 'ca':
        enrich.server = CANADA_SERVER_1
        break
      case 'de':
        enrich.server = GERMANY_SERVER_1
        break
      case 'de1':
        enrich.server = GERMANY_SERVER_1
        break
      case 'de2':
        enrich.server = GERMANY_SERVER_2
        break
      case 'as':
        enrich.server = ASIA_SERVER_1
        break
      default:
        return
    }
  } else {
    enrich.server = await getServerFromApi()
  }
  const demoPosSetting = getDemoPosSetting(getOsFromParam())
  const finalPosSetting = Object.assign(demoPosSetting, enrich)
  const countryFromUrl = getUrlParam('cn')
  const finalCountry = countryFromUrl || import.meta.env.VITE_COUNTRY
  // config posSettings for different countries
  if (finalCountry === 'de') {
    //clear all existing taxes
    _.assign(finalPosSetting.generalSetting, {
      isProductPriceIncludingTax: true,
      taxType: 'two',
    })
    _.assign(finalPosSetting.printerGeneralSetting, {
      autoPrintInvoiceBeforePaying: true,
    })
  } else if (finalCountry === 'ca') {
    _.assign(finalPosSetting.generalSetting, {
      isProductPriceIncludingTax: false,
      taxType: 'one',
    })
    _.assign(finalPosSetting.printerGeneralSetting, {
      autoPrintInvoiceBeforePaying: true,
    })
  }
  _.assign(finalPosSetting.companyInfo, { country: finalCountry })

  return finalPosSetting
}

export const demoDataImportMap = async (country: string) => {
  switch (country) {
    case 'de':
      return (await import('@/assets/data/demoData/de.ts')).default
    case 'ca':
      return (await import('@/assets/data/demoData/ca.ts')).default
    case 'vn':
      return (await import('@/assets/data/demoData/vn.ts')).default
    case 'fr':
      return (await import('@/assets/data/demoData/fr.ts')).default
    default:
      return (await import('@/assets/data/demoData/de.ts')).default
  }
}

export const emptyPosSettingImportMap = async (country: string) => {
  switch (country) {
    case 'de':
      return (await import('@/assets/data/emptyDemoData/emptyPosSetting-de.ts')).default
    case 'ca':
      return (await import('@/assets/data/emptyDemoData/emptyPosSetting-ca.ts')).default
    case 'vn':
      return (await import('@/assets/data/emptyDemoData/emptyPosSetting-ca.ts')).default
    case 'fr':
      return (await import('@/assets/data/emptyDemoData/emptyPosSetting-ca.ts')).default
    default:
      return (await import('@/assets/data/emptyDemoData/emptyPosSetting-ca.ts')).default
  }
}

const countryCode = getUrlParam('cn')

export async function initNewDatabase() {
  const { demoUsers } = await import('@/assets/data/demoUsers')
  const demoData = await demoDataImportMap(countryCode || import.meta.env.VITE_COUNTRY)
  const adminOnly = getUrlParam('skipImportUsers') === '1'

  // await clear()
  await importLayout(demoData.OrderLayout[0])
  await importProduct(demoData.Product)
  await importUser(demoUsers.filter(a => (adminOnly ? a.role === UserRole.ADMIN : true)))
  await importRoom(demoData.Room)
  await importPayment(demoData.Payment)
  await importSection(demoData.Section)
  await importGroupPrinter(demoData.GroupPrinter)
  await importModifier(demoData.Modifier)
  await importTaxCategory(demoData.TaxCategory)
  await importPosSetting([await getDefaultPosSetting()])
  await importCategory(demoData.Category)
  await importMenu(demoData.Menu)
  await importOnlineProvider(demoData.OnlineProvider)
  await importInventory(demoData.Inventory)
  await importInventoryAction(demoData.InventoryAction)
  await importCustomer(demoData.Customer)
  await importDiscountLabel(demoData.Discount)
  // await importDeviceSetting([{
  // 	_id: getDeviceId(),
  // 	isMaster: false
  // }])
}

export async function emptyDatabase() {
  const emptyDemoData = (await import('@/assets/data/emptyDemoData')).default
  const emptyDemoUser = (await import('@/assets/data/emptyDemoUsers')).default

  await deleteCreatedMenuUser()
  await importLayout(emptyDemoData.OrderLayout[0])
  await importProduct(emptyDemoData.Product)
  await importUser(emptyDemoUser)
  await importRoom(emptyDemoData.Room)
  await importModifier(emptyDemoData.Modifier)
  // await importPayment(emptyDemoData.Payment)
  // await importGroupPrinter(emptyDemoData.GroupPrinter)
  // await importTaxCategory(emptyDemoData.TaxCategory)
  await importPosSetting([await emptyPosSettingImportMap(countryCode || import.meta.env.VITE_COUNTRY)])
  await importCategory(emptyDemoData.Category)
  await importMenu(emptyDemoData.Menu)
  await importOnlineProvider(emptyDemoData.OnlineProvider)
  await importInventory(emptyDemoData.Inventory)
  await importInventoryAction(emptyDemoData.InventoryAction)
  await importCustomer(emptyDemoData.Customer)
  await importDiscountLabel(emptyDemoData.Discount)
  await importCashbookTransactions(emptyDemoData.CashbookTransactions)

  // localStorage.removeItem(DATA_INITED_2);
  await delay(1000)
  location.reload()
  // await importDeviceSetting([{
  // 	_id: getDeviceId(),
  // 	isMaster: false
  // }])
}

export async function emptyPosSetting() {
  await deleteMany(PosSetting, {}, false)
  const posSetting = await emptyPosSettingImportMap(countryCode || import.meta.env.VITE_COUNTRY)
  await importPosSetting([posSetting])
}

Object.assign(window, { emptyPosSetting }) // make it global
