import _ from "lodash";
import dayjs from "dayjs";
import type { Order } from "@/data/Order.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos } from "@/tse/dsfinv/dsfinvModel2.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bonkopf_AbrKreis = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  ABRECHNUNGSKREIS: {
    type: "string",
    maxLength: 50,
    hint: "z. B. Tischnummer"
  }
}
export function Bonkopf_AbrKreisFactory(order: Order, eod: Eod){
  return build();
  function build(){
    return{
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      ABRECHNUNGSKREIS: ABRECHNUNGSKREIS(),
    }
  }
  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function ABRECHNUNGSKREIS() {
    // hint: "z. B. Tischnummer"
    // Kasse for fast checkout
    return order.table || 'Kasse';
  }
}