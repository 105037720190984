import type { Order } from "@/data/Order.ts";
import type { OrderItem } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { getDeviceId } from '@/shared/getDeviceId'
import { formatCurrencyDe } from "@/shared/utils.ts";
import { getZERSTELLUNG } from "./dsfinv-convert";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bonpos = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  POS_ZEILE: {
    type: "string",
    maxLength: 50,
    hint: "Zeilennummer"
  },
  GUTSCHEIN_NR: {
    type: "string",
    maxLength: 50,
    hint: "Gutschein-Nr."
  },
  ARTIKELTEXT: {
    type: "string",
    maxLength: 255,
    hint: "Artikeltext"
  },
  POS_TERMINAL_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID des Positions-Terminals"
  },
  GV_TYP: {
    type: "string",
    maxLength: 30,
    hint: "Geschäftsvorfall-Art"
  },
  GV_NAME: {
    type: "string",
    maxLength: 40,
    hint: "Zusatz zu der Geschäftsvorfall-Art"
  },
  INHAUS: {
    type: "boolean",
    hint: "Verzehr an Ort und Stelle"
  },
  P_STORNO: {
    type: "boolean",
    hint: "Positionsstorno-Kennzeichnung"
  },
  AGENTUR_ID: {
    type: "number",
    places: 0,
    hint: "ID der Agentur"
  },
  ART_NR: {
    type: "string",
    maxLength: 50,
    hint: "Artikelnummer"
  },
  GTIN: {
    type: "string",
    maxLength: 50,
    hint: "GTIN"
  },
  WARENGR_ID: {
    type: "string",
    maxLength: 40,
    hint: "Warengruppen-ID"
  },
  WARENGR: {
    type: "string",
    maxLength: 50,
    hint: "Bezeichnung Warengruppe"
  },
  MENGE: {
    type: "number",
    places: 3,
    hint: "Menge"
  },
  FAKTOR: {
    type: "number",
    places: 3,
    hint: "Faktor, z. B. Gebindegrößen"
  },
  EINHEIT: {
    type: "string",
    maxLength: 50,
    hint: "Maßeinheit, z. B. kg, Liter oder Stück"
  },
  STK_BR: {
    type: "number",
    places: 5,
    hint: "Preis pro Einheit inkl. USt"
  }
}

export function BonPosFactory(order: Order, item: OrderItem, isCancelled: boolean, eod: Eod) {
  return build();

  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      POS_ZEILE: POS_ZEILE(),
      GUTSCHEIN_NR: GUTSCHEIN_NR(),
      ARTIKELTEXT: ARTIKELTEXT(),
      POS_TERMINAL_ID: POS_TERMINAL_ID(),
      GV_TYP: GV_TYP(),
      GV_NAME: GV_NAME(),
      INHAUS: INHAUS(),
      P_STORNO: P_STORNO(),
      AGENTUR_ID: AGENTUR_ID(),
      ART_NR: ART_NR(),
      GTIN: GTIN(),
      WARENGR_ID: WARENGR_ID(),
      WARENGR: WARENGR(),
      MENGE: MENGE(),
      FAKTOR: FAKTOR(),
      EINHEIT: EINHEIT(),
      STK_BR: STK_BR()
    }
  }

  function Z_KASSE_ID() {
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // if (!eod?.date) return '';
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function POS_ZEILE() {
    if (!isCancelled) {
      return order.items.filter(i => i.quantity !== 0).indexOf(item) + 1;
    } else {
      return order.cancellationItems!.filter(i => i.quantity !== 0).indexOf(item) + 1;
    }
  }

  function GUTSCHEIN_NR() {
    //fixme: implement
    return '';
  }

  function ARTIKELTEXT() {
    return item.name;
  }

  function POS_TERMINAL_ID() {
    return getDeviceId();
  }

  function GV_TYP() {
    //fixme: check again
    return 'Umsatz';
  }

  function GV_NAME() {
    //todo: research GV_NAME
    return '';
  }

  function INHAUS() {
    return !item.vTakeAway ? '1' : '0';
  }

  function P_STORNO() {
    return isCancelled ? '1': '0';
  }

  function AGENTUR_ID() {
    return 0;
  }

  function ART_NR() {
    return item.id || item._id;
  }

  function GTIN() {
    return ''
  }

  function WARENGR_ID() {
    //Warengruppen-ID
    //fixme: get name of category
    return item.categories?.[0];
  }

  function WARENGR() {
    //Bezeichnung Warengruppe
    //fixme: check
    return item.categories?.[0];
  }

  function MENGE() {
    return item.quantity;
  }

  function FAKTOR() {
    //Faktor, z. B. Gebindegrößen
    return '';
  }

  function EINHEIT() {
    //Maßeinheit, z. B. kg, Liter oder Stück
    return '';
  }

  function STK_BR() {
    //Preis pro Einheit inkl. USt
    return formatCurrencyDe(item.price);
  }
}