import { computed, selector, signal } from "@/react/core/reactive.ts";
import { groupPrinters0, labelPrinters0 } from "@/data/GroupPrinterHub.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { GroupPrinter } from "@/data/GroupPrinter.ts";

export const additionalSetting0 = computed(() => posSetting0()?.printerGeneralSetting)

export enum PrinterMode {
  NORMAL,
  LABEL_PRINTER,
  RECHECK_PRINTER,
  CLIENT_RECEIPT,
  ADDITIONAL_SETTING,
}

export const printerSettingModeSignal = signal<PrinterMode>(PrinterMode.NORMAL)
export const printerSettingMode = printerSettingModeSignal[0]
export const setPrinterSettingMode = printerSettingModeSignal[1]
export const currentPrinterIndexSignal = signal<number>(0);
export const currentPrinterIndex = currentPrinterIndexSignal[0];
export const setCurrentPrinterIndex = currentPrinterIndexSignal[1];
export const selectedGroupPrinterIdSignal = signal<string | undefined>();
export const selectedGroupPrinterId = selectedGroupPrinterIdSignal[0];
export const setSelectedGroupPrinterId = selectedGroupPrinterIdSignal[1];
export const selectedGroupPrinter = computed(() => groupPrinters0().find(groupPrinter => groupPrinter._id === selectedGroupPrinterId()))
export const isSelectedGroupPrinter = selector(selectedGroupPrinter, (p1: string, p2: GroupPrinter) => p1 === p2?._id);
export const currentPrinter = computed(() => {
  if (selectedGroupPrinter()) return selectedGroupPrinter()!.printers[currentPrinterIndex()]
})
export const isLabelPrinterEnable = computed<boolean>(() => {
  return !!labelPrinters0()?.printers[0]?.printerType;
})