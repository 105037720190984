import type {FunctionComponent} from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl, Box, Chip,
} from "@mui/material";
import {LL0} from "@/react/core/I18nService.tsx";
import Input, {inputController0} from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import {createNewInventory, inventory0, updateInventoryStock} from "@/react/InventoryView/InventoryView.tsx";
import _ from "lodash";
import {materialCategories} from "@/data/CategoryHub.ts";
import {useSignal} from "@/react/core/reactive.ts";
import {convertDocument} from "@/data/data-utils.ts";

import { loginUser } from "@/data/UserSignal.ts";
import { product0 } from "@/react/EditMenuView/EditMenuView.signal.ts";

type EditMenuAddIngredientPType = {
  onClose?: () => void;
};

const EditMenuAddIngredientP: FunctionComponent<EditMenuAddIngredientPType> = ({
  onClose,
}) => {
  const [stock, setStock] = useSignal<number>(0)
  const handleEditItemSave = async () => {
    onClose?.();
    const _inventory = inventory0();
    if (!_inventory) return;
    const newInventory = await createNewInventory(_inventory);
    _.assign(product0(), {ingredients: [...(product0().ingredients || []), {
        inventoryId: _inventory._id,
        amount: '0'
      }]})
    updateInventoryStock(convertDocument(newInventory, true, ["product", "lastUpdated"]),
      stock(), `${LL0().inventory.initStockBy()} ${loginUser()?.name}`)

  }

  return (
    <div className="relative bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-col items-start justify-between p-6 box-border min-w-[520px] max-w-full max-h-full text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
      <div className="mb-2 self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[16px]">
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[8px]">
            <div className="relative font-medium md:text-xs">{LL0().article.name()}:</div>
            <Input
              className="self-stretch h-[37px] font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
              value={inventory0()?.product?.name?.trim() || ""}
              onChange={(v) => _.assign(inventory0()?.product, {name: v})}
            />
          </div>
          <div className="flex-1 flex flex-row items-start justify-start gap-[8px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[8px]">
              <div className="relative font-medium md:text-xs">{LL0().inventory.stock()}:</div>
              <Input
                className="self-stretch font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
                value={inventory0()?.stock.toString() || '0'}
                onChange={(v)=> {
                  setStock(parseFloat(v.replace(",", ".") || "0"))
                  // _.assign(inventory0(), {stock: parseFloat(v.replace(",", "."))})
                }}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[8px]">
              <div className="relative font-medium md:text-xs">{LL0().article.unit()}:</div>
              <FormControl
                className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
                variant="outlined"
                // sx={{
                //   borderRadius: "0px 0px 0px 0px",
                //   width: "175.5px",
                //   height: "37px",
                //   m: 0,
                //   p: 0,
                //   "& .MuiInputBase-root": {
                //     m: 0,
                //     p: 0,
                //     minHeight: "37px",
                //     justifyContent: "center",
                //     display: "inline-flex",
                //   },
                //   "& .MuiInputLabel-root": {
                //     m: 0,
                //     p: 0,
                //     minHeight: "37px",
                //     display: "inline-flex",
                //   },
                //   "& .MuiMenuItem-root": {
                //     m: 0,
                //     p: 0,
                //     height: "37px",
                //     display: "inline-flex",
                //   },
                //   "& .MuiSelect-select": {
                //     m: 0,
                //     p: 0,
                //     height: "37px",
                //     alignItems: "center",
                //     display: "inline-flex",
                //   },
                //   "& .MuiInput-input": { m: 0, p: 0 },
                //   "& .MuiInputBase-input": {
                //     textAlign: "left",
                //     p: "0 !important",
                //   },
                // }}
              >
                <InputLabel color="primary" />
                <Select color="primary" size="small"
                        value={inventory0()?.unit || "piece"}
                        onChange={e => _.assign(inventory0(), {unit: e.target.value})}
                >
                  <MenuItem value="piece">{LL0().inventory.units.piece()} (pc)</MenuItem>
                  <MenuItem value="g">{LL0().inventory.units.g()}</MenuItem>
                  <MenuItem value="kg">{LL0().inventory.units.kg()}</MenuItem>
                  <MenuItem value="ml">{LL0().inventory.units.ml()}</MenuItem>
                  <MenuItem value="l">{LL0().inventory.units.l()}</MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
          <div className="relative font-medium md:text-xs">
            <span>{LL0().article.category()}</span>
            <span className="text-red">*</span>
            <span>:</span>
          </div>
          <FormControl
            className="self-stretch font-mulish text-sm text-black-solid-black-600-424242"
            variant="outlined"
            sx={{
              borderRadius: "0px 0px 0px 0px",
              width: "726px",
              height: "37px",
              m: 0,
              p: 0,
              "& .MuiInputBase-root": {
                m: 0,
                p: 0,
                minHeight: "37px",
                justifyContent: "center",
                display: "inline-flex",
              },
              "& .MuiInputLabel-root": {
                m: 0,
                p: 0,
                minHeight: "37px",
                display: "inline-flex",
              },
              "& .MuiMenuItem-root": {
                m: 0,
                p: 0,
                height: "37px",
                display: "inline-flex",
              },
              "& .MuiSelect-select": {
                m: 0,
                p: 0,
                height: "37px",
                alignItems: "center",
                display: "inline-flex",
              },
              "& .MuiInput-input": { m: 0, p: 0 },
              "& .MuiInputBase-input": { textAlign: "left", p: "2 !important" },
            }}
          >
            <InputLabel color="primary" />
            <Select color="primary" size="small"
                    multiple
                    value={inventory0()?.product?.categories || []}
                    onChange={e => _.assign(inventory0()?.product, {categories: e.target.value})}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 , paddingLeft: 2}}>
                        {selected.map((value) => (
                          <Chip key={value} label={materialCategories().find(category => category._id === value)?.name} />
                        ))}
                      </Box>
                    )}
            >
              { materialCategories().map(({_id, name}) =>
                <MenuItem value={_id} key={_id}>{name}</MenuItem>
              ) }
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
      </div>
      {/*<div className="self-stretch flex flex-row items-center justify-end py-4 px-0 gap-[12px] text-center text-blue-solid-blue-420-2979ff">*/}
      {/*  <div*/}
      {/*    className="rounded-md bg-white-solid-white-100-ffffff box-border h-[37px] overflow-hidden flex flex-row items-center justify-center py-0 px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff height_414:h-[30px]"*/}
      {/*    onClick={onClose}*/}
      {/*  >*/}
      {/*    <b className="relative">{LL0().ui.cancel()}</b>*/}
      {/*  </div>*/}
      {/*  <div className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-0 px-8 box-border text-white-solid-white-100-ffffff height_414:h-[30px]">*/}
      {/*    <b className="relative">{LL0().ui.ok()}</b>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <TableManualKeyboard
        value=''
        className={'Tablet_600:min-w-[416px]'}
        inputController={inputController0}
        onEnter={handleEditItemSave}
      />
    </div>
  );
};

export default EditMenuAddIngredientP;
