//region init-tse
import dayjs from "dayjs";
import _ from "lodash";
import { setTseConfigV, tseConfig0, tseConfigLock } from "@/data/TseConfigHub.ts";
import { type Order, PaidOrder } from "@/data/Order";
import { CommitAction, type ItemCommit, type OrderItem, type OrderPayment, OrderStatus, TseMethod } from "@/pos/OrderType.ts";
import { TseTransaction, VorgangArt } from "@/data/TseTransaction.ts";
import { sendContentToTse, sendContentToTsePayApi, tse, tseFailLock, tseLock } from "@/tse/tse-communicate.ts";
import { makeKassenBestellung } from "@/tse/makeProcessDataLib.ts";
import debug from 'debug'
import {
  handleTsePassthroughInvoice,
  makeTempTransaction,
  mutateOrderEod,
  removeFakeEod,
  removeFakeTransaction,
  tseMakeApply
} from "@/tse/tseFakeLogic.ts";
import { getTseMethod, isFood } from "@/tse/tse-utils.ts";
// import '@/tse/ZOrderHub.ts'
import { percent0 } from "@/tse/ZOrderHub.ts";
import { orderTseTemp0 } from "@/data/OrderTseTempHub.ts";
import {
  addCashPayment,
  clearEodCache,
  clearPayment,
  getPayment,
  getRxPaidOrder,
  patchOrder} from "@/pos/logic/order-utils";
import { reCreateOrder, makeCancelOrder } from '@/pos/logic/order-factory'
import { deleteMany } from "@/data/data-utils.ts";
import { now } from "@/pos/logic/time-provider.ts";
import { createOrder, stripPaidOrder } from "@/pos/logic/order-reactive.ts";
import { getLastOldItems, getVDate } from "@/pos/orderUtils.ts";
import { maxId0 } from "@/data/MaxIdHub.ts";
import { Eod } from "@/data/Eod.ts";
import { toast } from "react-toastify";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { deviceSettingLock } from "@/data/DeviceSettingHub.ts";
import { registerTseHook } from "@/tse/tse-init-hook.ts";
import { eodMode0 } from "@/tse/tse-variable.ts";
import './tse-server-signup.ts';
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { beginDate, beginHour } from "@/data/PosSettingsSignal.ts";
import { addTseMethod } from "@/pos/logic/order-utils-2.ts";

const debugTemp = debug('OrderTseTemp');
export const debugTse = debug('Tse');

let inited = false;

const clientId = 'SWISSBIT'

export interface TransactionContent {
  transactionData: string;
  processType: VorgangArt;
}

export const onPrintTse = async (order: Order) => {
  if (!tseConfig0().tseEnable) return;
  //region init
  const items = order.getRecent!(true);
  const cancellationItems = order.getCancellationRecent!(true);
  const voucherItems = items.filter(i => i.isVoucher);

  const oldItems = getLastOldItems(order.items, 0, order);
  const oldCancellationItems = getLastOldItems(order.cancellationItems!, 0, order)
  const isNew = oldItems.length === 0 && oldCancellationItems.length === 0 && cancellationItems.length === 0;

  //endregion
  const updateOrder = async () => {
  }

  //todo: voucher case
  if (cancellationItems.length > 0 || voucherItems.length > 0) {
    //todo test here
    await tseMakeApply(order, oldItems, oldCancellationItems, items, cancellationItems);
    await registerItems();
    await updateOrder();
    return;
  }

  //todo: print from second time + test
  if (isNew) {
    const {
      passthroughTse,
      passthroughTseComplete
    } = await checkPassthroughTse(order);
    if (((order.tseMethod === TseMethod.passthrough && passthroughTse) || passthroughTseComplete || order.tseMethod === TseMethod.forcePassthrough) && voucherItems.length === 0) {
      debugTse('1.print : Make passthrough order');
      order.tseMethod = TseMethod.passthrough;
      items.forEach(i => addTseMethod(order, i, TseMethod.passthrough, i.seat));
      await makeTempTransaction(items, cancellationItems, order);
      await updateOrder();
    } else {
      if ([TseMethod.auto, TseMethod.applyPart].includes(order.tseMethod!)) {
        if (passthroughTse) {
          processApplyPart();
        }

        if (!passthroughTse) {
          order.tseMethod = TseMethod.apply;
        } else {
          order.tseMethod = getTseMethod(order) === TseMethod.apply ? TseMethod.applyPart : getTseMethod(order);
        }

        debugTse(`1.print : Make ${order.tseMethod} order`);
        await makeTempTransaction(items, cancellationItems, order)
      }
      await updateOrder();
      await registerItems();
    }
  } else if (order.tseMethod === TseMethod.passthrough || order.tseMethod === TseMethod.applyPart) {
    debugTse('2.print: process passthrough/apply:part')
    items.forEach(i => addTseMethod(order, i, TseMethod.passthrough, i.seat));
    await makeTempTransaction(items, cancellationItems, order)
    await updateOrder();
  } else if (order.tseMethod === TseMethod.apply) {
    debugTse('2.print: process apply')
    items.forEach(i => addTseMethod(order, i, TseMethod.apply, i.seat))
    await registerItems()
    await updateOrder();
  }

  //region fns
  async function registerItems() {
    const content = makeKassenBestellung(items, cancellationItems);
    await sendContentToTse(content, order, order.z!);
  }

  function processApplyPart() {
    let hasItemAtLastTime = oldItems.length > 0;
    let hasFood = !!items.find(i => isFood(i));

    if (hasItemAtLastTime) {
      items.forEach(i => addTseMethod(order, i, TseMethod.passthrough))
    } else if (hasFood) {
      let holdFood = false;
      items.forEach(i => {
        if (isFood(i) && !holdFood) {
          addTseMethod(order, i, TseMethod.applyPart, i.seat)
          holdFood = true;
        } else if (isFood(i)) {
          addTseMethod(order, i, TseMethod.passthrough, i.seat)
        }
        if (!isFood(i)) addTseMethod(order, i, TseMethod.passthrough, i.seat);
      })
    } else {
      items.forEach((i, k) => {
        if (k === 0) addTseMethod(order, i, TseMethod.applyPart, i.seat)
        if (k > 0) addTseMethod(order, i, TseMethod.passthrough, i.seat);
      })
    }
  }

  //endregion
}

export async function initTse() {
  setTseConfigV(1)
  await tseConfigLock.acquireAsync();
  await deviceSettingLock.acquireAsync();

  let tseConfig = tseConfig0();
  if (tseConfig && tseConfig.tseEnable && !inited) {
    inited = true;

    try {
      await tse.fastInit({
        credentialSeed: 'SwissbitSwissbit',
        adminPuk: '123456',
        adminPin: '12345',
        timeAdminPin: '12345',
        clientId: deviceSetting0()!._id!
      });
      notificationToast('Tse init successful !!!', { autoClose: 1000 * 60 });
      tseLock.release().then();
      registerTseHook();
    } catch (e) {
      tseFailLock.release().then();
      toast('Tse init fail !!!');
      console.warn(e);
      //todo: show error message
    }
  }
}

export const handlePSMap = {
  1: [true, false],
  2: [true, true],
  3: [true, false],
  4: [true, false]
}

async function checkPassthroughTse(order: Order, immediatePay?: boolean) {
  let passthroughTse = false;
  let passthroughTseComplete = false;

  if (!tseConfig0().passthroughEnable) {
    return { passthroughTse, passthroughTseComplete };
  }

  let percent = percent0();

  if (percent < tseConfig0().percent! && tseConfig0().passthroughEnable!) {
    passthroughTse = true;

    if (orderTseTemp0().onlyFoodHoldNumber >= 1) {
      orderTseTemp0().onlyFoodHoldNumber = 0;
      passthroughTseComplete = true;
      //console.log('passthrough complete !!!');
    } else {
      orderTseTemp0().onlyFoodHoldNumber += 1;
    }

  }

  //fixme: remove code
  function set(_passthroughTse: boolean, _passthroughTseComplete: boolean) {
    passthroughTse = _passthroughTse;
    passthroughTseComplete = _passthroughTseComplete;
  }

  switch (maxId0().orderId) {
    case 1:
      set(handlePSMap["1"][0], handlePSMap["1"][1]);
      break;
    case 2:
      set(handlePSMap["2"][0], handlePSMap["2"][1]);
      break;
    case 3:
      set(handlePSMap["3"][0], handlePSMap["3"][1]);
      break;
    case 4:
      set(handlePSMap["4"][0], handlePSMap["4"][1]);
      break;
    default:
  }

  return { passthroughTse, passthroughTseComplete };
}

//endregion

//region pay-order
//splitProcess:false -> no-split, = pay -> split step 1, = complete -> split step2
//todo: neu an vao back -> call complete
// const tsePayOrder =  async (order: Order, printInvoice = false, splitProcess) => {
//   const hasSplitOrder = await Order.count({splitId: order._id})
//   if (splitProcess === 'pay' && !printInvoice) {
//     return await splitProcessHandler(order, recent);
//   }
//   if (hasSplitOrder) {
//     await splitProcessHandler(order, recent);
//     await payOrderHandler(order, recent, printInvoice);
//     return;
//   }
//   if (splitProcess === 'pay' && printInvoice) {
//     await splitProcessHandler(order, recent);
//     await payOrderHandler(order, recent, printInvoice);
//     return;
//   }
//   await payOrderHandler(order, recent, printInvoice);
// }

function getDistanceUntilNow(date: number) {
  return dayjs.unix(date).subtract(beginHour(), 'hour').diff(dayjs.unix(beginDate()), 'hour');
}

//region reassignQr
async function reassignQr(order: Order, needReload = true) {
  if (needReload) {
    const rawOrder = await PaidOrder.findOne({ selector: { _id: order._id } }).exec();
    order = rawOrder!.toMutableJSON() as Order;
  }
  if (!order.needReassignQr || order.qrCode) return;

  const duration = getDistanceUntilNow(order.date!);

  if (duration <= 24) {
    await deleteMany(TseTransaction, {
      selector: {
        TSE_TA_VORGANGSART: VorgangArt.KassenbelegV1,
        TSE_TA_FEHLER: { $exists: true },
        order: order._id
      }
    });
    //todo: use commit
    const qrCode = order.qrCode = await sendContentToTsePayApi(order, undefined, true);
    await patchOrder(order, { qrCode, date: dayjs(now()).unix(), needReassignQr: undefined })
  } else {
    await patchOrder(order, { needReassignQr: undefined });
  }
  delete order.needReassignQr;
}

//endregion
// cms.on('tse:app:restart', async function () {
//   const orders = await Order.find({needReassignQr: true});
//   for (const order of orders) {
//     await reassignQr(order, tseConfig);
//   }
// })
// if (!process.env.NODE_ENV === 'test') cms.emit('tse:app:restart');


async function cancelReassignQr(order: Order, shouldDeleteTseTransaction = false) {
  if (!order.needReassignQr) return;
  order.needReassignQr = undefined;
  if (shouldDeleteTseTransaction) {
    await deleteMany(TseTransaction, {
      selector: {
        TSE_TA_VORGANGSART: VorgangArt.KassenbelegV1,
        TSE_TA_FEHLER: { $exists: true },
        order: order._id
      }
    })
  }
}

async function makeTimeoutReassignQr(order: Order) {
  // await cms.emit('tse:passthrough:invoice', order, true);
  await handleTsePassthroughInvoice(order, true);
  //todo: register by build-form
  let timeout: ReturnType<typeof setTimeout>;
  // const { off } = cms.once(`cancel-reassign-qr:${order._id}`, async (_order, shouldDeleteTseTransaction = true) => {
  //   clearTimeout(timeout);
  //   //todo: check here
  //   if (shouldDeleteTseTransaction) {
  //     delete _order.needReassignQr;
  //     await TseTransaction.deleteOne({ TSE_TA_VORGANGSART: 'Kassenbeleg-V1', TSE_TA_FEHLER: { $exists: true }, order: order._id });
  //   }
  // });
  debugTse(`setTimeout:assignQr: ${tseConfig0().delayAssignQr} min`);
  timeout = setTimeout(async () => {
    debugTse('reassignQr: ');
    await reassignQr(order)
    const _order = await getRxPaidOrder(order);
  }, (tseConfig0().delayAssignQr || 30 * 60) * 1000);
}

function getLastPrinterRound(order: Order) {
  if (order.immediatelyPay) return 1;
  return (order.status === OrderStatus.IN_PROGRESS) ? 0 : 100;
}

//region main-handler
export async function payOrderHandler(order: Order) {
  if (!tseConfig0().tseEnable) return;
  //region init
  // let isNew = !filterTseCommits(order.commits!).find(c => c.action === CommitAction.PRINT);
  let isNew = !!order.immediatelyPay;
  // const hasSplitOrder = async () => await Order.count({splitId: order._id}) > 0
  let split = !!order.splitId;
  let content;
  const lastPrintedRound = getLastPrinterRound(order);
  const oldItems = getLastOldItems(order.items, lastPrintedRound, order);
  const oldCancellationItems = getLastOldItems(order.cancellationItems!, lastPrintedRound, order)
  const printed = true;
  const items = order.getRecent!(printed, lastPrintedRound);
  const cancellationItems = order.getCancellationRecent!(printed, lastPrintedRound);
  if (items.length > 0 || cancellationItems.length > 0) {
    content = makeKassenBestellung(items, cancellationItems);
  }
  const printInvoice = order.printInvoice;
  //endregion
  // 4 case :
  // pay without print, pay with print , rePrint
  // pay immediately with print , pay immediately without print

  let canPassthroughTse = tseConfig0().passthroughEnable && !printInvoice && getPayment(order) === 'cash' && order.tseMethod !== TseMethod.apply;
  if (!isNew || split) {
    //already print or split
    //if payment = cash
    // if (!order.splitId) await handleDiscount(order);
    if (canPassthroughTse) {
      //pay without print -> register order without items which has tseMethod = passthrough

      order.tseMethod = getTseMethod(order, true);
      if (order.tseMethod !== TseMethod.passthrough) {
        debugTse('Pay: sentOrder/split: can passthrough: apply/apply:part', {orderId: order._id});
        //todo: create fake first, reassign qrcode later
        //problems: manage timeout (in database), c1: remove if reprint, eod -> force reprint, restart -> check order with needReassignQr
        //fake: true/false
        order.needReassignQr = true;
        await makeTimeoutReassignQr(order);
        //order.qrCode = await sendContentToTsePayApi(filterApplyItems(order), '', tseConfig);
      } else {
        debugTse('Pay: sentOrder/split: can passthrough: passthough', {orderId: order._id})
        await handleTsePassthroughInvoice(order, true);
      }
      //todo: fake tse logic
    } else {
      //pay with print -> register items which has tseMethod = passthrough + register order with full items
      debugTse('Pay: sentOrder/split: printInvoice', {orderId: order._id})
      await tseMakeApply(order, oldItems, oldCancellationItems, items, cancellationItems, true);
      order.tseMethod = TseMethod.apply;
    }
  } else if (canPassthroughTse) {
    //immediate pay without print -> check passthrough and if yes register order with full items
    const {
      passthroughTse,
      passthroughTseComplete,
    } = await checkPassthroughTse(order, true);
    if (!passthroughTse) {
      debugTse('Pay: immediate pay: no-invoice: apply', {orderId: order._id});
      order.qrCode = await sendContentToTsePayApi(order, content);
      order.tseMethod = TseMethod.apply;
    } else {
      debugTse('Pay: immediate pay: no-invoice: passthrough', {orderId: order._id});
      order.tseMethod = TseMethod.passthrough;
      items.forEach(i => addTseMethod(order, i, TseMethod.passthrough, i.seat));
      await makeTempTransaction(items, cancellationItems, order);
      await handleTsePassthroughInvoice(order, true);
    }
  } else {
    //immediate pay with print -> register order with full items
    debugTse('Pay: immediate pay: invoice', {orderId: order._id});
    order.qrCode = await sendContentToTsePayApi(order, content);
    order.tseMethod = TseMethod.apply;
  }
}

//endregion

export async function preSplitHandler(order: Order, tseMethod: TseMethod) {
  if (!tseConfig0().tseEnable) return;
  if (!tseConfig0().passthroughEnable) return;
  if (tseMethod === TseMethod.apply) {
    await applyCaseSplitHandler(order);
    return;
  }
}

export async function applyCaseSplitHandler(order: Order) {
  const lastPrintedRound = order.status === OrderStatus.IN_PROGRESS ? 0 : 100;
  const oldItems = getLastOldItems(order.items, lastPrintedRound, order);
  const oldCancellationItems = getLastOldItems(order.cancellationItems!, lastPrintedRound, order)
  const items = order.getRecent!(true, lastPrintedRound);
  const cancellationItems = order.getCancellationRecent!(true, lastPrintedRound);
  await tseMakeApply(order, oldItems, oldCancellationItems, items, cancellationItems, false);
}

export async function paySplitHandler(seatOrder: Order) {
  await payOrderHandler(seatOrder);
}

//region split

export function getItemsFromTransaction(transaction: TseTransaction) {
  const items: OrderItem[] = [];
  const content = transaction['TSE_TA_VORGANGSDATEN2'] || transaction['TSE_TA_VORGANGSDATEN'];
  const _items = content!.split('\r');
  for (let _item of _items) {
    const _itemArr = _item.split(';');
    items.push({
      quantity: parseInt(_itemArr[0]),
      name: _itemArr[1],
      price: parseFloat(_itemArr[2].replace(',', '.'))
    } as OrderItem)
  }
  return items;
}

//endregion

async function handleDiscount(order: Order) {
  if (!order.discount) return;
  const transactions = await TseTransaction.find({ selector: { order: order._id } }).exec();
  for (const transaction of transactions) {
    let items: string[] = [];
    const _items = transaction.TSE_TA_VORGANGSDATEN!.split('\r');
    for (let _item of _items) {
      const [quantity, name] = _item.split(';');
      //search price;
      const item = order.items.find(i => i.name === name);
      const price = _.round(item!.price + _.sumBy(item!.modifiers, m => m.price * m.quantity), 2);
      items.push(`${quantity};${name};${price}`)
    }
    transaction.TSE_TA_VORGANGSDATEN2 = items.join('\r');
    await transaction.incrementalPatch({ TSE_TA_VORGANGSDATEN2: transaction.TSE_TA_VORGANGSDATEN2 });
  }
}

//region cancel-order
//refOrder: order
//case 1 before eod -> remove fake, passthrough items
//case 2 after eod : do nothing??
export async function cancelOrder(order: Order, refOrder: Order) {
  if (refOrder.needReassignQr) {
    await reassignQr(refOrder);
    refOrder = await PaidOrder.findOne({ selector: { _id: refOrder._id } }).exec() as Order;
  }

  if (refOrder.qrCode) {
    let _order = _.cloneDeep(order);
    let content = makeKassenBestellung(_order.items, [])
    const qrCode = await sendContentToTsePayApi(_order, content, true);
    await patchOrder(order, { qrCode });

    //passthrough
    _order = createOrder(_.cloneDeep(order));
    [_order.items, _order.cancellationItems] = getPassthroughItems(_order)
    await makeTempTransaction(_order.items, _order.cancellationItems, _order);
    await handleTsePassthroughInvoice(order, false);
    await patchOrder(refOrder, { status: OrderStatus.CANCELLED });
  } else {
    //register 100%
    //cancel + passthrough
    await makeTempTransaction(order.items, order.cancellationItems!, order);
    await handleTsePassthroughInvoice(order, false);
  }
  //todo: write to db
  //3 case: 1 case fake , 1 case real
  //real -> easy
  //fake some items , like real (clear items in end-of-day)

  //todo: check stornoReference logic again
  //fake complete -> make reference , ko write to tse
  //create transaction
}

function getPassthroughItems(order: Order, passthroughCb = (i: OrderItem) => null) {
  let items: OrderItem[] = [], cancellationItems: OrderItem[] = [];
  order.items.forEach(i => {
    if (i.tseMethod === TseMethod.passthrough) {
      items.push(_.cloneDeep(i));
      passthroughCb(i);
    }
    return i.tseMethod !== TseMethod.passthrough;
  });
  order.items.forEach(i => {
    if (i.tseMethod === TseMethod.applyPart && i.quantity > 1) {
      const _item = _.cloneDeep(i);
      _item.quantity--;
      _item.tseMethod = TseMethod.passthrough
      items.push(_item);
    } else if (i.tseMethod === TseMethod.applyPart && i.quantity <= -1) {
      const _item = _.cloneDeep(i);
      _item.quantity++;
      _item.tseMethod = TseMethod.passthrough
      items.push(_item);
    }
  })

  for (const item of (order.cancellationItems || [])) {
    if (item.tseMethod === TseMethod.passthrough) {
      cancellationItems.push(item);
      passthroughCb(item);
    }
  }
  return [items, cancellationItems];
}

function getApplyItems(order: Order) {
  let items = _.cloneDeep(order.items).filter(i => i.tseMethod !== TseMethod.passthrough);
  items.forEach(i => {
    if (i.tseMethod === TseMethod.applyPart && i.quantity > 1) {
      i.quantity = 1;
    }
  })
  let cancellationItems = _.cloneDeep(order.cancellationItems || []).filter(i => i.tseMethod === TseMethod.passthrough);
  return [
    items,
    cancellationItems
  ]
}

//endregion

//region re-print
export async function rePrintInvoiceTse(order: Order, splitHandled = false) {
  if (!tseConfig0()?.tseEnable) return;
  const updateOrder = async () => {
    await patchOrder(order, stripPaidOrder(order));
  }
  if (order.splitId && !splitHandled) {
    const orders = await PaidOrder.find({ selector: { splitId: order.splitId } }).exec();
    for (const order0 of orders) {
      await rePrintInvoiceTse(createOrder(order0.toMutableJSON()), true);
    }
    return;
  }
  //case 1: apply -> easy : do nothing
  if (order.tseMethod === TseMethod.apply) {
  }

  //case 2.1: apply:part (before eod) -> cancel , create again
  const eod = await Eod.findOne({ selector: { z: order.z! } }).exec();
  if (order.tseMethod === TseMethod.applyPart && !eod?.complete) {
    if (order.needReassignQr) {
      await cancelReassignQr(order);
      //don't need to cancel & recreate, need to make apply
      await tseMakeApply(order, order.items, order.cancellationItems!, [], []);
      await updateOrder();
      return;
    }
    //todo: userByCancel build-form
    await doCancelOrder(order);
    await recreate(order);
  }
  //case 2.2: apply:part (after eod) -> ko support
  if (order.tseMethod === TseMethod.applyPart && order.z) {
    //keep: cancel + recreate
    //not-keep : -> manual recreate or secret mode ??
  }
  //case 3.1: passthrough (before eod) -> assign qrCode , new date, remove Fake ... (= make apply)
  if (order.tseMethod === TseMethod.passthrough && !eod?.complete) {
    await tseMakeApply(order, order.items, order.cancellationItems!, [], []);
    await updateOrder();
  }
  //case 3.2: passthrough (after eod) -> ko support
  if (order.tseMethod === TseMethod.passthrough && order.z) {
    //do no-thing
    //not keep:
    //keep: -> in lại đơn
    //todo: show error for tse
  }
}

export async function doCancelOrder(order: Order) {
  const _order = await makeCancelOrder(order);
  await cancelOrder(_order, order);
  await clearEodCache({ date: order.vDate! });
  await patchOrder(order, { status: OrderStatus.CANCELLED, masterHandlers: order.masterHandlers });
  return _order
  //clear eod cache
}

async function recreate(order: Order, isPaidOrder?: boolean, mutateFn?: Function) {
  const api = reCreateOrder(order);
  await api.internalCreateOrder();
  const _order2 = api.order;

  mutateFn?.(_order2)
  await api.insertOrder(isPaidOrder);
  if (isPaidOrder) {
    // await api.recordCommits();
  }

  const content = makeKassenBestellung(_order2.items, _order2.cancellationItems!);
  if (!isPaidOrder) {
    await sendContentToTse(content, _order2, _order2.z!);
  } else {
    _order2.qrCode = await sendContentToTsePayApi(_order2, content);
    await patchOrder(_order2, { qrCode: _order2.qrCode })
  }

  return _order2;

  function filterNoPayCommits(commits: ItemCommit[]) {
    return commits.filter(c => {
      if (c.action === CommitAction.PAY) {
        return false;
      }
      return true;
    })
  }
}

async function check() {
  const orders = await PaidOrder.find().exec();
  let transactions = await TseTransaction.find().exec();
  let groups = _.groupBy(transactions, t => t.order!);
  return { orders, groups };
}

//endregion

//region reactivate


export async function reactivateCaseTse(order: Order) {
  if (order.needReassignQr) {
    //todo: create test
    await cancelReassignQr(order, true);
    //change status
    //todo: check tse again
    await clearEodCache({ date: order.vDate! });
    await patchOrder(order, {
      reactive: true,
      status: OrderStatus.IN_PROGRESS,
      payments: [],
      date: dayjs(now()).unix(),
      vDate: getVDate(dayjs(now()).unix()),
      needReassignQr: undefined,
      splitId: undefined,
    });
    return;
  }
  await doCancelOrder(order);
  await recreate(order, false, (_order: Order) => {
    [_order.reactive, _order.status, _order.payments] = [true, OrderStatus.IN_PROGRESS, []];
  })
}

//endregion

//region changePayment
export async function tseChangePayment(order: Order, newPayment: OrderPayment) {
  if (order.needReassignQr) {
    debugTse('needReassignQr');
    //change status
    const newOrder = createOrder(order)
    clearPayment(newOrder)
    newOrder.payments.splice(0, newOrder.payments.length, newPayment);
    newOrder.payments = [newPayment];
    await patchOrder(order, newOrder);
    return;
  }
  debugTse('no needReassignQr');
  await doCancelOrder(order);
  await recreate(order, true, (_order: Order) => {
    _order.payments.splice(0, _order.payments.length, newPayment);
  })
}

//endregion

//endregion

//region end-of-day
type EodMode = 'keep' | 'reduce';

export async function handleTseEod(report: {
  from: number, to: number, z: number, refresh: () => Promise<void>
}) {
  if (tseConfig0()?.tseEnable && tseConfig0()?.passthroughEnable) {
    let { z, from, to } = report;
    let mode = eodMode0();
    let startId: number = 0;
    let _orders = await PaidOrder.find({
      selector: { date: { $gte: from, $lte: to } } ,
      sort: [{ date: 'asc' }]
    }).exec();
    const orders = _orders.map(o => o.toMutableJSON() as Order);
    const orders3 = orders.filter(e => e.qrCode || e.needReassignQr);

    for (let order of orders3) {
      await reassignQr(order, false);
    }

    if (mode === 'reduce') {
      await removeFakeEod(orders);
      await deleteMany(PaidOrder, {
        selector: {
          date: { $gte: from, $lte: to },
          qrCode: { $exists: false },
          tseMethod: TseMethod.passthrough
        }
      });
    }

    startId = orders[0] ? orders[0].id! : 0;

    for (let order of orders3) {
      const snapshot = _.cloneDeep(order);
      let mutate = false;
      let items: OrderItem[] = [];
      order.items = order.items!.filter(i => {
        if (i.tseMethod === TseMethod.passthrough) {
          mutate = true;
          items.push(i);
        }
        return i.tseMethod !== TseMethod.passthrough;
      });
      order.items.forEach(i => {
        if (i.tseMethod === TseMethod.applyPart && i.quantity > 1) {
          const _item = _.cloneDeep(i);
          _item.quantity--;
          _item.tseMethod = TseMethod.passthrough
          items.push(_item);
          mutate = true;
          i.quantity = 1;
        } else if (i.tseMethod === TseMethod.applyPart && i.quantity <= -1) {
          const _item = _.cloneDeep(i);
          _item.quantity++;
          _item.tseMethod = TseMethod.passthrough
          items.push(_item);
          mutate = true;
          i.quantity = -1;
        }
      })
      order.cancellationItems = (order.cancellationItems || []).filter(i => i.tseMethod === TseMethod.passthrough);
      if (order.id !== startId) {
        mutate = true;
        order.id = startId;
      }
      startId++;
      if (mutate && order.tseMethod !== TseMethod.apply) {
        if (mode === 'keep') {
          await mutateOrderEod(order, items);
        } else {
          await removeFakeTransaction(order);
        }

        order = createOrder(order);
        if (order.payments.length === 1) {
          clearPayment(order);
          addCashPayment(order);
        }

        //todo: update order
        await patchOrder(order, stripPaidOrder(order));
      } else if (mutate) {
        await patchOrder(order, {id: order.id});
      }
    }
    //clear eod cache for this one
    await clearEodCache({ date: orders[0].vDate });
    await report.refresh?.();
  }
}

//endregion
