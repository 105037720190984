import { rnHost } from "@/shared/webview/rnwebview.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { createPrinter } from "@/react/Printer/create-printer.ts";
import { currentPrinter } from "@/react/Printer/PrinterSettingView.signal.ts";
import { EscPrinter, EscPrinterLock } from "@/lib/entries";

export async function printUsb(data: {payload:string}){
  try {
    await rnHost.printUsb(data as any);
  }catch (e) {
    notificationToast("Connection failed", {
      type: "error",
      autoClose: 1000 * 60
    })
  }
}

export const testUSBPrinter = async () => {
  await EscPrinterLock.acquireAsync();
  const printer = await createPrinter();
  await printer.println("TEST USB PRINTER");
  const raster: Raster = (await printer.print())!;
  const printer2 = new EscPrinter(currentPrinter());
  await printer2.printRaster(raster);
  await printer2.print();
}