import React, { type FunctionComponent, useEffect } from 'react'
import PaymentScreenFee from '@payment/PaymentScreenFee'
import {
  addServiceFee,
  blockMutateOrderButton,
  changeTip, currentPayment0,
  currentPaymentName0,
  getActiveOrder, handleChangeServiceFee,
  handleChangeTip,
  multiPayment0, order0,
  serviceFee0,
  tip0
} from '@/react/PaymentView/PaymentView.tsx'
import _ from 'lodash'
import { LL0 } from '@/react/core/I18nService.tsx'
import { clsx } from 'clsx'
import { signal, useSignal } from '@/react/core/reactive.ts'
import InputPopup from '@/react/core/InputPopup.tsx'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { roundNumber, useServiceFeeBeforeTax } from '@/shared/order/order-config'
import { toast } from "react-toastify";
import ChoosePaymentPopUp from "@payment/ChoosePaymentPopUp.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { generalSetting0, isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";


export const [tip, setTip] = signal<string>()

const PaymentTipFee: FunctionComponent = () => {

  const [isPopupCashTip, setIsPopupCashTip] = useSignal<boolean>(false)

  const [isPopUpChoosePayment, setIsPopUpChoosePayment] = useSignal<boolean>(false)


  return (
    <>
      <div
        className={clsx(
          'self-stretch rounded-10xs bg-gray-opacity-gray-24-eeeeee h-[214px] overflow-hidden shrink-0 flex flex-row items-center justify-center p-2.5 box-border gap-[8px] height_414:p-1 height_414:box-border height_414:max-h-[130px]',
          {
            'opacity-70': blockMutateOrderButton()
          }
        )}
      >
        {(!currentPayment0()?.hasOwnProperty('enableTip') || currentPayment0()?.enableTip) && (<div
          className=" no-scrollbar gap-2 self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start">

          {!generalSetting0()?.cashTipEnable && (multiPayment0() || currentPaymentName0()) && (
            <PaymentScreenFee
              tip={LL0().payment.tip() + `: ${getActiveOrder()?.tip || '__'} ${LL3().common.currency()}`}
              showTip={true}
              showShowAll={false}
              price={getActiveOrder()?.tip || 0}
              showFees={false}
              tipCash={true}
              key={'tipManual'}
              isSelected={getActiveOrder()?.tip === roundNumber(getActiveOrder().vItemTotal! * parseFloat(tip()) / 100, 2)}
              onClick={() => {
                userFLow(`Open popup add tips in Payment, ${roundNumber(getActiveOrder().vItemTotal! * parseFloat(tip()) / 100, 2)}`, {
                  username: loginUser()?.name,
                  orderId: order0?.()._id
                })
                if (blockMutateOrderButton()) return toast("You have already printed the invoice!", {
                  type: 'info',
                  autoClose: 500
                });
                if (getActiveOrder()?.payments?.length === 0) {
                  setIsPopUpChoosePayment(true);
                } else {
                  setIsPopupCashTip(true)
                }
              }}
            />
          )}
          {generalSetting0()?.defaultTips?.map((tip, index) => {
            const amount = roundNumber(getActiveOrder().vItemTotal! * tip / 100, 2)
            return <PaymentScreenFee
              tip={`${LL0().payment.tip()}: ${tip}%`}
              price={amount}
              showTip
              showShowAll={false}
              showFees={false}
              key={`tip${index}`}
              isSelected={tip0() == amount && tip0() !== 0}
              onClick={() => {
                userFLow(`add "${tip}%" tips in Payment`, {
                  username: loginUser()?.name,
                  orderId: order0?.()._id
                })
                if (getActiveOrder()?.payments?.length === 0) {
                  setIsPopUpChoosePayment(true);
                } else {
                  console.log(`» Adding "${tip}%" tip`, amount, tip0())
                  if (tip0() === amount) changeTip(0)
                  else changeTip(amount)
                }
              }}
            />
          }
          )}
        </div>)}
        <div
          className={clsx("no-scrollbar gap-2 self-stretch flex-1 overflow-y-auto flex justify-start", currentPayment0()?.enableTip === false ? 'flex-row justify-center items-start' : 'flex-col items-center')}>
          {generalSetting0()?.serviceCharges?.map((serviceCharge, index) =>{
            const amount = useServiceFeeBeforeTax() ? 
              roundNumber((getActiveOrder().getNet?.() ?? 0) * serviceCharge / 100, 2) :
              roundNumber(((getActiveOrder().vItemTotal ?? 0) - (getActiveOrder().serviceFee ?? 0)) * serviceCharge / 100, 2)
            return <PaymentScreenFee
              feeName="SF:"
              feeValue={`${serviceCharge} %`}
              price={amount}
              showTip={false}
              showShowAll={false}
              key={`sc${index}`}
              // isSelected = {isQuebecSrmEnabled() ? serviceFee0() == roundNumber(getActiveOrder().vSubTotal! * serviceCharge / 100, 2) : serviceFee0() == roundNumber(getActiveOrder().vItemTotal! * serviceCharge / 100, 2)}
              isSelected = { serviceFee0() == amount  && serviceFee0() !== 0}
              onClick={() => {
                userFLow(`add ${amount} serviceFee0 in Payment`, {
                  username: loginUser()?.name,
                  orderId: order0?.()._id
                })
                if (getActiveOrder()?.payments?.length === 0) {
                  setIsPopUpChoosePayment(true);
                } else {
                  console.log('⚡️ Toggle ServiceFee', serviceCharge, amount)
                  handleChangeServiceFee(amount, serviceCharge)
                }
              }}
            />
            }
          )}
        </div>
      </div>
      {isPopupCashTip() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsPopupCashTip(false)}>
          <InputPopup
            label={'Tip'}
            value={tip()}
            modeKeyboard={1}
            onEnter={() => {
              userFLow(`cash tip in Payment ${tip()}`, {
                username: loginUser()?.name,
                orderId: order0?.()._id
              })
              const isValid =
                tip().length > 0 &&
                !tip().includes('..') &&
                !tip().startsWith('.') &&
                !tip().endsWith('.') &&
                /^[0-9.]*$/.test(tip());
              if (!isValid) {
                toast.warning("Please enter a number!")
              } else {
                handleChangeTip(tip())
                setIsPopupCashTip(false)
                setTip('')
              }
            }}
            onChange={value => setTip(value)} />
        </PortalPopup>
      )}
      {isPopUpChoosePayment() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsPopUpChoosePayment(false)}>
          <ChoosePaymentPopUp
            onClose = {() => setIsPopUpChoosePayment(false)} />
        </PortalPopup>
      )}
    </>
  )
}

export default PaymentTipFee
