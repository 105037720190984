import { type FunctionComponent, useCallback, useState } from 'react';
import { LL0 } from '@/react/core/I18nService.tsx';
import { router } from '@/pos/PosRouter.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {
  customer0,
  CustomerInfoScreenMode,
  deleteCurrentCustomer, initCustomer,
  setCustomer0,
  setCustomerInfoScreenMode
} from '@/react/CustomerInfoView/CustomerInfoView.tsx';
import { setSortBy, sortBy } from '@/data/CustomerHub.ts';
import _ from 'lodash';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import { deepSignal } from '@/react/core/reactive';
import { clone } from '@atc-group/json-fn';
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const BottomBar: FunctionComponent = () => {
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true)
  }, [])

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  const changeSort = useCallback(({value}: {value: 'name' | 'address' | ''}) => {
    setSortBy({ sortBy: value, sort: sortBy()?.sort === 'asc' ? 'desc' : 'asc' })
  }, [])

  return (
    <>
      <div
        className="self-stretch rounded-12xs bg-gray-solid-gray-570-5a6370 flex flex-row items-start justify-between py-2 px-4 z-[1] text-center text-sm text-white-solid-white-100-ffffff font-mulish">
        <div className="flex flex-row items-center justify-start gap-[8px]">
          <div
            className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px]"
            onClick={() => router.screen = mainScreen()}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="w-[34px] relative leading-[17px] hidden">{LL0().ui.back()}</b>
          </div>
          <div
            className="rounded-81xl bg-green-solid-green-420-a9e15d shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-black-solid-black-900-1e1e23 Tablet_600:pl-2.5 Tablet_600:box-border"
            ref={makeRipple}
            onClick={() => {
              changeSort({ value: 'name' })
            }}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconsort-icon@2x.png"
            />
            <b className="flex-1 relative leading-[17px] Tablet_600:hidden">
              {LL0().ui.sortBy()} {_.get(LL0().customer, sortBy()?.sortBy)?.()}
            </b>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[8px] text-black-solid-black-900-1e1e23">
          <div
            className="disabled:opacity-60 rounded-81xl bg-cyan-solid-cyan-250-8aeaff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px]"
            ref={makeRipple}
            onPointerDown={() => {
              setCustomer0(deepSignal(clone(initCustomer())))
              setCustomerInfoScreenMode(CustomerInfoScreenMode.EDIT_CUSTOMER)
            }}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/icongeneralplus-icon@2x.png"
            />
            <b className="flex-1 relative leading-[17px] Tablet_600:hidden">
              {LL0().pendingOrder.newCustomer()}
            </b>
          </div>
          <button className="disabled:opacity-60 rounded-81xl bg-orange-solid-orange-400-ffbe56 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 pr-3 pl-2.5 box-border gap-[3px] min-w-[72px]"
                  disabled={!customer0()}
                  ref={makeRipple}
                  onPointerDown={() => {
                    setCustomerInfoScreenMode(CustomerInfoScreenMode.EDIT_CUSTOMER)
                  }}
          >
            <img
              className="w-7 relative h-7 object-cover"
              alt=""
              src="/iconedit-icon@2x.png"
            />
            <b className="flex-1 relative leading-[17px] Tablet_600:hidden">
              {LL0().ui.edit()}
            </b>
          </button>
          <button
            className="disabled:opacity-60 rounded-81xl bg-red-solid-red-150-ff5e71 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 pr-2.5 pl-1.5 box-border gap-[2px] min-w-[72px] cursor-pointer"
            onClick={openDeleteConfirmationPopup}
            disabled={!customer0()}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconbin-icon--white@2x.png"
            />
            <b className="flex-1 relative leading-[17px] Tablet_600:hidden">
              {LL0().ui.delete()}
            </b>
          </button>
        </div>
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup}
                                   onComplete={() => {
                                     closeDeleteConfirmationPopup()
                                     deleteCurrentCustomer()
                                     setCustomer0()
                                   }}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default BottomBar
