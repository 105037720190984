import { context } from '@opentelemetry/api'
import debug from 'debug'
import { md5 } from 'js-md5'
import uuid from 'time-uuid'

import { SyncMode } from '@/data/data-enum.ts'
import { masterSocketLock, setMasterAvailable } from '@/data/data-utils.ts'
import { syncMode0 } from '@/data/DeviceSettingHub.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { getNatsClient, getNatsClientLock, StringCodec, StringCodecLock } from '@/lib/entries.ts'
import { createSpan, runWithCurrentContext } from '@/lib/open-telemetry.ts'
import { getRxdbClient } from '@/lib/rxdb-client.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'

const log = debug('app:client-caller')

export interface CallOptions {
  onlyOffline?: boolean
  onlyOnline?: boolean
  retry?: number
  retryTime?: number
  needUnique?: boolean
  uuid?: string | null
}

enum IntervalTime {
  Online = import.meta.env.MODE === 'production' ? 30000 : 10000,
  Offline = 5000,
}

export default class ClientCaller {
  static clientPingInterval: ReturnType<typeof setInterval> | undefined

  static async callMasterCommand(method: string, args: any[], _options: CallOptions = {}) {
    const options = {
      retry: 0,
      needUnique: true,
      ..._options,
    }
    await getNatsClientLock.acquireAsync()
    if (!posSync0()?.id!) return
    if (!method.includes('ping')) {
      log(`client-caller ${method} needUnique ${options.needUnique} md5: ${md5(JSON.stringify(args))}`)
    }
    const { onlyOnline = false, onlyOffline = false } = options
    const span = createSpan('Calling master command', undefined, context.active())
    span?.setAttribute('storeId', `${posSync0()?.id!}`)
    span?.setAttribute('deviceId', getDeviceId())
    span?.setAttribute('onlyOnline', onlyOnline)
    const commandId = options.needUnique ? (options.uuid ? options.uuid : uuid()) : null
    const handleRetry = () => {
      if (options.retry && options.retry > 0) {
        setTimeout(() => {
          if (method === 'print') log('retry calling print')
          ClientCaller.callMasterCommand(method, args, {
            ...options,
            retry: (options.retry || 1) - 1,
            uuid: commandId,
          }).then()
        }, options.retryTime || 30000)
      } else {
        throw new Error('Can not connect to master')
      }
    }
    return runWithCurrentContext(span, async () => {
      if (!posSync0()?.id!) {
        const err = new Error('No post id')
        span?.recordException(err)
        span?.end()
        return
      }
      if (!onlyOnline) {
        const rxdbClient = await getRxdbClient(null, '')
        if (!!rxdbClient) {
          try {
            const res = await rxdbClient.callMasterCommand({
              method: method,
              params: args,
              isMasterCall: true,
              commandId: commandId,
            })
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          } catch (err: any) {
            span?.recordException(err)
            if (onlyOffline) handleRetry()
          }
        }
      }
      if (!onlyOffline) {
        try {
          const subject = `${posSync0()?.id}-master`
          const natsClient = getNatsClient()
          await StringCodecLock.acquireAsync()
          const response = await natsClient.getClient()?.request(
            subject,
            StringCodec().encode(
              JSON.stringify({
                method,
                params: args,
                commandId: commandId,
              })
            ),
            { timeout: 4000 }
          )
          if (response) {
            if (StringCodec().decode(response!.data) === '') return undefined
            const res = JSON.parse(StringCodec().decode(response!.data))
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          }
          throw new Error('No response')
        } catch (err: any) {
          span?.recordException(err)
          span?.end()
          handleRetry()
        }
      }

      const err = new Error('No response')
      span?.recordException(err)
      span?.end()
      throw err
    })
  }

  static currentIntervalTime = IntervalTime.Offline

  static startClientPingInterval() {
    if (ClientCaller.clientPingInterval) {
      ClientCaller.stopClientPingInterval()
    }
    log(`startClientPingInterval ${ClientCaller.currentIntervalTime}`)
    const pingOffline = async () => {
      if (syncMode0() === SyncMode.online) return
      try {
        log('ping master offline')
        await ClientCaller.callMasterCommand('pingMaster', [], { onlyOffline: true, needUnique: false })
        setMasterAvailable(true)
        if (ClientCaller.currentIntervalTime !== IntervalTime.Online) {
          log('change interval to 30s')
          ClientCaller.currentIntervalTime = IntervalTime.Online
          ClientCaller.startClientPingInterval()
        }
      } catch (e) {
        setMasterAvailable(false)
        if (ClientCaller.currentIntervalTime !== IntervalTime.Offline) {
          log('change interval to 5s')
          ClientCaller.currentIntervalTime = IntervalTime.Offline
          ClientCaller.startClientPingInterval()
        }
      }
    }
    if (masterSocketLock._acquired) masterSocketLock.acquireAsync().then(() => pingOffline().then())
    ClientCaller.clientPingInterval = setInterval(pingOffline, ClientCaller.currentIntervalTime)
  }

  static stopClientPingInterval() {
    console.log('stopClientPingInterval')
    if (!ClientCaller.clientPingInterval) return
    clearInterval(ClientCaller.clientPingInterval)
    ClientCaller.clientPingInterval = undefined
  }
}
