import { Buffer } from 'buffer/'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import pako from 'pako'
import type { MangoQuerySelector, RxDocument } from 'rxdb'
import uuid from 'time-uuid'

import { dataLock } from '@/data/DataUtils.ts'
import { EodCache } from '@/data/EodCache.ts'
import { type Order, PaidOrder } from '@/data/Order.ts'
import { TimeClock } from '@/data/TimeClock.ts'
import { toISOString } from '@/lib/utils.ts'
import { renderPivotTable } from '@/pos/logic/pivot.js'
import { fromReducer, toReducer } from '@/pos/logic/report-shared.ts'
import { countReportTotal, getCommonReportData, getStaffReport } from '@/pos/logic/report-utils.ts'
import type { EodReport, MonthlyReport, TimeBreaksByDate, TimeClocksByDate, TotalBreakTimeByDate } from '@/pos/logic/ReportType.ts'
import { now } from '@/pos/logic/time-provider.ts'
import type { TOrder } from '@/pos/OrderType'
import { getVDate } from '@/pos/orderUtils.ts'

import { showTrainingMode } from '../trainingMode'

function replaceDotWithComma(obj) {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (key.includes('.')) {
        const newKey = key.replace('.', ',');
        obj[newKey] = obj[key];
        delete obj[key];
      }
    });
  }
}

const defaultSalesReport = {
  groupItemsByCategory: {},
  sumByCategory: {},
  totalTip: 0,
  totalCashback: 0,
  sumByPayment: {},
  paymentReport: {},
  totalDiscount: 0,
  report: {},
  dineInReport: {},
  takeAwayReport: {},
  discountedOrders: [],
  itemsQuantity: 0,
  dineInQuantity: 0,
  takeAwayQuantity: 0,
  vSum: 0,
  totalCashSalesExcludingCashback: 0,
  totalCashlessSales: 0,
  totalCashlessSalesExcludingTip: 0,
  voucherReport: {},
  staffReport: {},
}
const defaultStaffReport = {
  groupByPayment: {},
  userSales: {},
  cashSalesByUserExcludingCashBack: {},
  cashlessSalesByUser: {},
  cashlessSalesByUserExcludingTip: {},
  groupByStatus: {},
  totalTipByUser: {},
  totalCashbackByUser: {},
  totalCancelled: {},
  cancellationItems: [],
  cancellationItemsGroups: {},
  voucherReportByUser: {},
  soldItemsCnt: {},
  totalDiscountByUser: {},
}

async function getEodReportPeriod(from: number, to: number): Promise<EodReport> {
  let eodCaches: RxDocument<EodCache>[] = []
  await getCache(from, to)
  const query: MangoQuerySelector<TOrder> = {
    $and: [
      {
        vDate: {
          ...(from && { $gte: from }),
          ...(to && { $lte: to }),
        },
        trainingMode: showTrainingMode() ? true : { $ne: true },
        // status: { $in: ['paid', 'cancelled', 'completed'] }
      },
      ...genQueryUseCache(eodCaches),
    ],
  }

  let orders = (await PaidOrder.find({ selector: query }).exec()).map(o => o.toMutableJSON())
  console.log(`EOD TRIGGER, order size: ${orders.length}`)
  let concatReducer = {
    label: 'orders',
    fn(_orders: Order[], order: Order) {
      return _.concat(_orders, order)
    },
    initValue: [],
  }
  const ordersByDate = renderPivotTable(
    {
      columns: ['@date:vDate', {
        fn: (order: TOrder) => {
          return order.z || -1
        },
        label: 'z',
      }],
      reducers: [concatReducer],
    },
    orders
  )
  let rs = _.cloneDeep(ordersByDate)
  for (let date of Object.keys(ordersByDate)) {
    for (let z of Object.keys(ordersByDate[date])) {
      const {
        groupItemsByCategory,
        sumByCategory,
        totalTip,
        totalCashback,
        sumByPayment,
        paymentReport,
        totalCashSalesExcludingCashback,
        totalCashlessSales,
        totalCashlessSalesExcludingTip,
        totalDiscount,
        totalServiceFee,
        report,
        voucherReport,
        dineInReport,
        takeAwayReport,
        discountedOrders,
        itemsQuantity,
        dineInQuantity,
        takeAwayQuantity,
        totalDiscountByLabel,
        cancelledReport,
        reportByHours,
        cancelledItemReport,
        avgItemPrice,
        avgItemInOrder,
        avgOrderPrice,
        avgOrderNetPrice,
        avgDineInSale,
        avgTakeAwaySale,
        cancelledItems,
        totalRefund,
        taxGroup,
        totalDebitorAmount,
        refundItems,
        refundItemReport,
        totalShipping,
        totalDonation,
        totalExtraTax
      } = await getCommonReportData(ordersByDate[date][z])
      const staffReport = getStaffReport(ordersByDate[date][z])
      const { from, to } = renderPivotTable({ reducers: [fromReducer, toReducer] }, ordersByDate[date][z])
      rs[date][z] = {
        groupItemsByCategory,
        sumByCategory,
        totalTip,
        totalCashback,
        sumByPayment,
        paymentReport,
        totalDiscount,
        totalServiceFee,
        report,
        dineInReport,
        takeAwayReport,
        discountedOrders,
        itemsQuantity,
        dineInQuantity,
        takeAwayQuantity,
        vSum: report.vSum,
        totalCashSalesExcludingCashback,
        totalCashlessSales,
        totalCashlessSalesExcludingTip,
        voucherReport,
        from,
        to,
        staffReport,
        totalDiscountByLabel,
        cancelledReport,
        reportByHours,
        cancelledItemReport,
        avgItemPrice,
        avgItemInOrder,
        avgOrderPrice,
        avgOrderNetPrice,
        avgDineInSale,
        avgTakeAwaySale,
        cancelledItems,
        totalRefund,
        taxGroup,
        totalDebitorAmount,
        refundItems,
        refundItemReport,
        totalShippingFee: (totalShipping?.fee || 0) + (totalShipping?.serviceFee || 0) + (totalExtraTax || 0),
        totalShippingTip: totalShipping?.tip || 0,
        totalDonation
      }
    }
  }

  const rsFull = {
    ...rs,
  }
  mergeCacheToRs(rsFull)
  addToCacheIfNotExists(_.cloneDeep(rs)).then()

  //region utils
  function mergeCacheToRs(rsFull: any) {
    for (const eodCache of eodCaches) {
      if (typeof eodCache.toMutableJSON().data === 'string') {
        const dataBase64 = Buffer.from(eodCache.toMutableJSON().data as string, 'base64')
        rsFull[toISOString(eodCache.date!)] = JSON.parse(pako.inflate(dataBase64, { to: 'string' }))
      } else {
        rsFull[toISOString(eodCache.date!)] = eodCache.toMutableJSON().data
      }
    }
  }

  //cache handle
  //add to cache:
  //handle today independence

  async function addToCacheIfNotExists(rs: any) {
    const _eodCaches: EodCache[] = []
    for (const _date of Object.keys(rs)) {
      //prevent today
      if (_date === toISOString(getVDate(dayjs(now()).unix()))) {
        continue
      }
      const r = rs[_date]
      const date = dayjs(_date.split('T')[0], 'YYYY-MM-DD').unix()
      //todo: make index for date
      const data = Buffer.from(pako.deflate(JSON.stringify(r))).toString('base64')
      _eodCaches.push({
        _id: uuid(),
        date,
        data: data,
      })
    }
    for (const eodCache of _eodCaches) {
      await EodCache.insert(eodCache)
    }
  }

  async function getCache(from: number, to: number) {
    eodCaches = await EodCache.find({
      selector: {
        $and: [
          {
            date: {
              ...(from && { $gte: from }),
              ...(to && { $lte: to }),
            },
          },
          {
            date: { $ne: dayjs('02.10.2021', 'DD.MM.YYYY').startOf('d').unix() },
          },
        ],
      },
    }).exec()
  }

  function genQueryUseCache(eodCaches: EodCache[]) {
    if (eodCaches.length === 0) return []
    return [
      {
        vDate: {
          $nin: eodCaches.map(e => e.date),
        },
      },
    ]
  }

  //endregion

  return rsFull
}

async function getEodReportPeriodAggregate(from: number, to: number) {
  const rsFull = await getEodReportPeriod(from, to)
  const zItem = getZItems(rsFull)

  // zItem[0].discountedOrders = [{id: 1}, {id: 2}]
  // zItem[1].discountedOrders = [{id: 3}, {id: 4}]

  const staffReportReducer = {
    label: 'staffReport',
    initValue: {},
    fn(rs: any, { staffReport }: { staffReport: any }) {
      const tmp = _.mergeWith(rs, staffReport, (objValue, srcValue) => {
        if (_.isNumber(objValue) && _.isNumber(srcValue)) return objValue + srcValue
        if (_.isArray(objValue) && _.isArray(srcValue)) {
          return [...objValue, ...srcValue]
        }
      })

      return tmp
    },
  }
  const voucherReportReducer = {
    label: 'voucherReport',
    initValue: {},
    fn(rs: any, { voucherReport }: { voucherReport: any }) {
      const tmp = _.mergeWith(rs, voucherReport, (objValue, srcValue) => {
        if (_.isNumber(objValue) && _.isNumber(srcValue)) return objValue + srcValue
        if (_.isArray(objValue) && _.isArray(srcValue)) {
          return [...objValue, ...srcValue]
        }
      })

      return tmp
    },
  }

  zItem.forEach(item => {
    replaceDotWithComma(item.report?.vTaxSum?.vTaxSum);
  });

  const aggregateData = zItem.length
    ? renderPivotTable(
        {
          reducers: [
            '@sum[2]:vSum',
            '@objSum[2]:sumByPayment',
            '@objSum[2]:sumByCategory',
            '@objSum[2]:paymentReport',
            '@objSum[2](groupItemsNodeProcess):groupItemsByCategory',
            '@sum[2]:totalCashSalesExcludingCashback',
            '@sum[2]:totalCashlessSales',
            '@sum[2]:totalCashback',
            '@sum[2]:totalCashlessSalesExcludingTip',
            '@sum[2]:totalServiceFee',
            '@objSum[2]:report',
            voucherReportReducer,
            '@objSum[2]:dineInReport',
            '@objSum[2]:takeAwayReport',
            '@sum[2]:totalTip',
            '@sum[2]:itemsQuantity',
            '@sum[2]:dineInQuantity',
            '@sum[2]:takeAwayQuantity',
            '@mergeArr:discountedOrders',
            '@objSum[2]:totalDiscountByLabel',
            staffReportReducer,
            '@sum[2]:avgItemPrice',
            '@sum[2]:avgItemInOrder',
            '@sum[2]:avgOrderPrice',
            '@sum[2]:avgOrderNetPrice',
            '@sum[2]:avgDineInSale',
            '@sum[2]:avgTakeAwaySale',
            '@sum[2]:avgOrderNetPrice',
            '@sum[2]:totalRefund',
            '@sum[2]:cancelledItemReport',
            '@objSum[2]:cancelledReport',
            '@mergeArr:cancelledItems',
            '@sum[2]:totalDebitorAmount',
            '@mergeArr:refundItems',
            '@sum[2]:refundItemReport',
            '@sum[2]:totalShippingFee',
            '@sum[2]:totalDonation',
            '@sum[2]:totalShippingTip',
          ],
        },
        zItem
      )
    : {
        ...defaultSalesReport,
        staffReport: defaultStaffReport,
      }

  _.assign(aggregateData.report, { from, to })

  //fix report.from/to

  const _zNumbers = getZNumbers(rsFull)
  aggregateData.zNumbers = _zNumbers

  // const timeClocks = await TimeClock.find({
  //   selector: {
  //     vDate: {
  //       $gte: from, $lt: to
  //     },
  //     // status: ShiftStatus.CLOCK_OUT
  //   }
  // }).exec()

  await dataLock.acquireAsync()

  const timeClocks = await TimeClock.find({
    selector: {
      clockInTime: {
        $gte: from,
        $lt: to,
      },
    },
    sort: [{ clockInTime: 'asc' }],
  }).exec()

  //choose clock in staff view => find by clockIn

  const timeClocksByUser = renderPivotTable(
    {
      columns: ['username'],
      reducers: [
        {
          fn: (timeClocksByDate: TimeClocksByDate, i: TimeClock) => {
            // const timeClockDate = dayjs.unix(i.vDate!).format('DD-MM-YYYY')
            const timeClockDate = dayjs.unix(i.clockInTime!).format('DD-MM-YYYY')
            if (!timeClocksByDate[timeClockDate]) {
              timeClocksByDate[timeClockDate] = []
            }
            timeClocksByDate[timeClockDate].push(i)
            return timeClocksByDate
          },
          label: 'timeClocksByDate',
          initValue: {},
        },
        {
          fn: (totalHours: number, i: TimeClock) => totalHours + (i.clockOutTime ? _.round(dayjs.unix(i.clockOutTime).diff(dayjs.unix(i.clockInTime!), 'hour', true), 2) : 0),
          label: 'totalHours',
          initValue: 0,
        },
      ],
    },
    timeClocks
  )

  const totalBreakTimeByUser = renderPivotTable(
    {
      columns: ['username'],
      reducers: [
        {
          fn: (totalBreakTime: number, i: TimeClock, index: number, timeClocks: TimeClock[]) => {
            if (index > 0) {
              const previousClock = timeClocks[index - 1]
              const breakTime = i?.clockInTime! - previousClock?.clockOutTime!
              totalBreakTime = totalBreakTime + (breakTime ?? 0)
            }
            return totalBreakTime
          },
          label: 'totalBreakTime',
          initValue: 0,
        },
        {
          fn: (totalBreakTimeByDate: TotalBreakTimeByDate, i: TimeClock, index: number, timeClocks: TimeClock[]) => {
            const timeClockDate = dayjs.unix(i.clockInTime!).format('DD-MM-YYYY')
            if (index > 0) {
              const previousClock = timeClocks[index - 1]
              if (dayjs.unix(previousClock.clockInTime!).format('DD-MM-YYYY') === timeClockDate) {
                const breakTime = i?.clockInTime! - previousClock?.clockOutTime!
                if (!totalBreakTimeByDate[timeClockDate]) {
                  totalBreakTimeByDate[timeClockDate] = []
                }
                totalBreakTimeByDate[timeClockDate].push(breakTime)
              }
            }
            return totalBreakTimeByDate
          },
          label: 'totalBreakTimeByDate',
          initValue: {},
        },
        {
          fn: (timeBreaksByDate: TimeBreaksByDate, i: TimeClock, index: number, timeClocks: TimeClock[]) => {
            const timeClockDate = dayjs.unix(i.clockInTime!).format('DD-MM-YYYY')
            if (index > 0) {
              const previousClock = timeClocks[index - 1]
              if (dayjs.unix(previousClock.clockInTime!).format('DD-MM-YYYY') === timeClockDate) {
                if (!timeBreaksByDate[timeClockDate]) {
                  timeBreaksByDate[timeClockDate] = []
                }
                timeBreaksByDate[timeClockDate].push({ out: previousClock?.clockOutTime!, in: i?.clockInTime! })
              }
            }
            return timeBreaksByDate
          },
          label: 'timeBreaksByDate',
          initValue: {},
        },
      ],
    },
    timeClocks
  )

  aggregateData.timeClocksByUser = timeClocksByUser
  aggregateData.totalBreakTimeByUser = totalBreakTimeByUser

  //region utils
  function getZNumbers(rsFull: any) {
    const result: any = {}
    for (const date of Object.keys(rsFull)) {
      const _date = moment.utc(date).format('DD.MM.YYYY')
      result[_date] = {}
      const data = rsFull[date]
      for (const z of Object.keys(data)) {
        if (!data[z]) continue
        const {gross} = countReportTotal(data[z].report);
        result[_date][z] = _.round(gross, 2)
      }
    }
    return result
  }

  function getZItems(rsFull: any) {
    const items = []
    for (const date of Object.keys(rsFull)) {
      const data = rsFull[date]
      for (const z of Object.keys(data)) {
        items.push(data[z])
      }
    }
    return _.compact(items)
  }

  //endregion

  return aggregateData as MonthlyReport
}

export { getEodReportPeriod, getEodReportPeriodAggregate }
