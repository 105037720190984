import { posSetting0, posSettingLock } from "@/data/PosSettingsSignal.ts";
import { migrateEodV2 } from "@/react/EndOfDayView/eod-utils.ts";
import { clearEodCache } from "@/pos/logic/order-utils.ts";
import { toast } from "react-toastify";
import { batch } from "@/react/core/reactive";
import { isMaster } from "@/lib/master-signal.ts";
import { cTaxProduct } from "@/data/fix/changeTax142.ts";
import dayjs from "dayjs";
import { log } from "debug";
import { storeId } from "@/data/PosSyncState.ts";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

const getMigrateVersion = () => posSetting0()?.migrateVersion || 0

const getFixDate16_02_2025 = () => posSetting0()?.fixDate16_02_2025 || 0

export async function startMigrate() {
  await posSettingLock.acquireAsync();
  const migrateVersion = getMigrateVersion();
  if (migrateVersion === 0 && isMaster()) {
    const id = toast('Migrating data...', { autoClose: false });
    try {
      batch(() => {
        posSetting0()!.migrateVersion = 1;
        if (posSetting0()?.companyInfo?.country === 'ca') {
          posSetting0()!.oneEodPerDay = true;
        }
      })
      await migrateEodV2();
      await clearEodCache();
      toast.update(id, { render: 'Migrate data successfully', type: 'success', autoClose: 3000 });
    } catch (e: any) {
      console.error(e);
      toast.update(id, { render: `Migrate data error ${e.message}`, type: 'error', autoClose: 3000 });
    }
  }
  //run updateTax at master
  //test ở máy master
  if (isMaster()) {
    if (!getFixDate16_02_2025() && dayjs('2025-02-16').isSameOrBefore(dayjs().startOf('d'))) {
      const t = toast("Updating product tax..., please wait !!!", { autoClose: false, progress: 100, });
      try {
        batch(() => {
          posSetting0()!.fixDate16_02_2025 = true
        })
        await handleUpdateTax()
        log(`update tax and product tax successful, date: ${dayjs().format('DD-MM-YYYY HH:mm')}`)
        toast.update(t, { render: "Tax update successful!", type: "success", progress: 100, autoClose: 3000, });
      } catch (e: any) {
        const store = storeId() || localStorage.getItem('storeId')
        log(`failed to update tax in Canada storeId: ${store}, date: ${dayjs().format('DD-MM-YYYY HH:mm')}`, {alert: true, storeId: store})
        toast.update(t, { render: "An error occurred while updating the tax.", type: "error", progress: 100, autoClose: 3000,
        });
      } finally {
        setTimeout(() => {
          toast.dismiss(t);
        }, 3000);
      }
    }
  }
}

export async function handleUpdateTax() {
  if (posSetting0()?.companyInfo?.country === 'ca') {
    const province = posSetting0()?.companyInfo?.province
    const tax = province === 'Ontario' ? 13 : (province === 'New Bruswick' ? 15 : undefined)
    await cTaxProduct(tax)
  }
}

setTimeout(() => {
  // @ts-ignore
  window.startMigrate = startMigrate;
}, 1);

window.handleUpdateTax = handleUpdateTax
