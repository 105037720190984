import type { Order } from '@/data/Order';
import { createOrder, stripOrder } from '@/pos/logic/order-reactive';
import { CommitAction, type OrderItem, type OrderStrip, type TOrder } from '@/pos/OrderType';
import { computed, selector, signal } from '@/react/core/reactive';
import _ from 'lodash';
import { createContext } from 'react';
import { clone } from 'rxdb';
import type { Accessor, Setter } from 'solid-js';
import { generateIds } from "@/pos/logic/order-utils.ts";
import { PosScreen, router } from '@/pos/PosRouter'
import { toast } from 'react-toastify'

import { recordUserActionHistory, UserAction } from '@/data/UserActionHistory';
import uuid from "time-uuid";
import { addItemFactory } from "@/pos/logic/add-item-utils.ts";

export type ItemFactoryReturn = ReturnType<typeof useItemFactory>

function handleAddProductAfterPrint(order: TOrder, item: OrderItem, seat: number | undefined) {
  const cloneItem = _.cloneDeep(item)
  const modifiers = cloneItem.modifiers!;
  const newId = uuid()
  const {addItem, addModifier, addItemDiscount, addItemCourse} = addItemFactory()
  addItem(order, cloneItem, seat, newId)
  addModifier(order, modifiers, seat, newId)
  addItemDiscount(order, newId, cloneItem)
  addItemCourse(order, cloneItem, newId)
}

export const ItemFactoryContext = createContext<Partial<ItemFactoryReturn>
  & {
  isPayment?: boolean;
  containerClassName?: string;
}
>({});

let snapshot: OrderStrip | undefined;
export const useItemFactory =
  (order0: Accessor<TOrder>,
    setOrder0: Setter<TOrder>,
    onItemTap: (item: OrderItem, order: Order) => void) => {
    const [splitEnable, setSplitEnable] = signal<boolean>(false);
    const [moveEnable, setMoveEnable] = signal<boolean>(false);
    const [currentSeat, setCurrentSeat] = signal<number>(0);
    const isCurrentSeat = selector<number>(currentSeat);
    const [seat, setSeat] = signal<number>(0);
    const [groupEnable, setGroupEnable] = signal<boolean>(false);


    //todo : use callback

    const saveSnapshot = () => {
      console.log('Saving snapshot...')
      snapshot = clone(stripOrder(order0()))
    }
    const restoreSnapshot = async () => {
      console.log("restore snapshot ", snapshot);
      const order = createOrder(snapshot);
      // @ts-ignore
      order.snapshot = true;
      setOrder0(order);
    };

    function moveRestItemsToSeat() {
      for (const item of order0().items) {
        if (item.quantity > (item.movedQuantity || 0)) {
          order0().commits!.push({
            action: CommitAction.MOVE,
            commitId: item.commitRefs![0],
            quantity: item.quantity - (item.movedQuantity || 0),
            seat: order0().seatMap!.length - 1,
            printed: item.printed
          });
        }
      }
      setSplitEnable(false);
    }

    function _onItemClick(item: OrderItem, order: TOrder) {
      if (splitEnable()) {
        const quantity = "seat" in order
          ? (Number.isInteger(item.quantity) ? -1 : -item.quantity)
          : (Number.isInteger(item.quantity) ? 1 : item.quantity);
        //fixme: move modifiers
        order0().commits?.push({
          action: CommitAction.MOVE,
          commitId: item.commitRefs![0],
          // quantity: "seat" in order ? -1 : 1,
          quantity: quantity,
          seat: currentSeat(),
          ...!("seat" in order) && {
            printed: item.printed
          }
        });
        const seatOrder = splitEnable() ? order0()?.seatMap?.[currentSeat()] : order0()
        const data = {
          productRef: { name: item.name, price: item.price },
          seat: currentSeat()
        }
        if ("seat" in order) {
          recordUserActionHistory(seatOrder, UserAction.DELETE_ITEM, data).then()
          recordUserActionHistory(order0(), UserAction.CANCEL_MOVE_TO_SEAT, data).then()
        } else {
          recordUserActionHistory(order0(), UserAction.MOVE_TO_SEAT, data).then()
          recordUserActionHistory(seatOrder, UserAction.ADD_ITEM, data).then()
        }
      } else if (moveEnable()) {
        const quantity = order.isMoveOrder
          ? (Number.isInteger(item.quantity) ? -1 : -item.quantity)
          : (Number.isInteger(item.quantity) ? 1 : item.quantity);
        order0().commits?.push({
          action: CommitAction.MOVE_TO_ORDER,
          commitId: item.commitRefs![0],
          // quantity: order.isMoveOrder ? -1 : 1,
          quantity: quantity,
          printed: item.printed
        });
      }
      onItemTap(item, order);
    }

    const onItemClick = _.debounce(_onItemClick, 100, { leading: true, trailing: false });

    function onNext() {
      const remaining = _.sumBy(order0().items, i => i.quantity - (i.movedQuantity || 0));
      if (remaining > 0) {
        if (currentSeat() === order0().seatMap!.length - 1) {
          const oldQuantity = order0().seatMap!.length
          order0().commits!.push({ action: CommitAction.CHANGE_SEAT_QUANTITY, delta: 1, ids: [...generateIds(1)] });
          recordUserActionHistory(order0(), UserAction.CHANGE_SEAT_QUANTITY, { quantity: order0().seatMap!.length, oldQuantity }).then()
        }
        setCurrentSeat(s => s + 1);
        setSeat(s => s + 1)
      } else {
        setSplitEnable(false);
      }
    }

    function onSplitCancel(needRestore = true) {
      setSplitEnable(false);
      recordUserActionHistory(order0(), UserAction.CHANGE_SEAT_MODE, { seatMode: 'normal' }).then()
      if (needRestore) restoreSnapshot();
    }

    function onMoveCancel() {
      setMoveEnable(false);
      restoreSnapshot();
    }

    function onMoveComplete() {
      setMoveEnable(false);
      const moveOrder = order0().getMoveOrder!();
      //todo: merge moveOrder.commits to current order at table X (concat) or create if not exist
    }

    const lastSeat = computed<number>(() => {
      if (seat() >= 0) {
        return seat();
      }
      //fixme: check
      return 0;
    });

    const addSeat: () => ({ seat: number } | {}) = computed(() => {
      if (order0().seatMode) {
        return { seat: lastSeat() };
      }
      return {};
    });

    function onMinus(item: OrderItem, seatEnable: boolean, seat: number) {
      const isRemoveItem = !(Number.isInteger(item.quantity) && item.quantity !== 1);
      const extra = seatEnable ? { seat } : {};
      order0().commits!.push({
        action: CommitAction.CHANGE_QUANTITY, commitId: item.commitRefs![0], quantityDelta: !Number.isInteger(item.quantity) ? -item.quantity : -1, newCommitId: uuid(), ...extra
      });
      const data = {
        productRef: {
          productId: item.commitRefs![0],
          name: item?.name,
        },
        ...extra,
        quantity: 1,
      }
      console.log('data decrease', data)
      const targetOrder = order0()?.seatMode ? order0()?.seatMap?.[data?.seat!]: order0();
      if (isRemoveItem) {
        recordUserActionHistory(targetOrder, UserAction.DELETE_ITEM, data).then();
      } else {
        recordUserActionHistory(targetOrder, UserAction.DECREASE_QUANTITY, data).then();
      }
    }

    function onPlus(item: OrderItem, seatEnable: boolean, seat: number) {
      const extra = seatEnable ? { seat } : {};
      console.log(extra);
      if (!item.printed) {
        order0().commits!.push({
          action: CommitAction.CHANGE_QUANTITY, commitId: item.commitRefs![0], quantityDelta: 1, newCommitId: uuid(), ...extra
        });
      } else {
        handleAddProductAfterPrint(order0(), item, extra?.seat)
      }

      const data = {
        productRef: {
          productId: item.commitRefs?.[0],
          name: item?.name,
        },
        ...extra,
        quantity: 1,
      }
      const targetOrder = order0()?.seatMode ? order0()?.seatMap?.[data?.seat!]: order0();
      recordUserActionHistory(targetOrder, UserAction.INCREASE_QUANTITY, data).then();
    }

    function onSeatToggle() {
      saveSnapshot();
      setCurrentSeat(0);
      if (!order0().seatMode) {
        order0().commits!.push({ action: CommitAction.CHANGE_SEAT_MODE, seatMode: !order0().seatMode });
        recordUserActionHistory(order0(), UserAction.CHANGE_SEAT_MODE, { seatMode: 'split' }).then()
        if (order0().seatMap!.length === 0) {
          order0().commits!.push({ action: CommitAction.CHANGE_SEAT_QUANTITY, delta: 2, ids: [...generateIds(2)] });
          recordUserActionHistory(order0(), UserAction.CHANGE_SEAT_QUANTITY, { quantity: 2, oldQuantity: 0 }).then()
        }
        setSplitEnable(true);
      } else {
        setSplitEnable(true);
        // order0().commits.push({action: CommitAction.CHANGE_SEAT_MODE, seatMode: !order0().seatMode})
      }
    }

    function onMoveToggle() {
      saveSnapshot();
      if (order0()?.seatMode) {
        order0().commits?.push({action: CommitAction.MERGE_SEAT});
      }
      order0().commits!.push({ action: CommitAction.CREATE_MOVE_ORDER });
      setMoveEnable(true);
    }

    function onGroupToggle() {
      saveSnapshot();
      if (order0()?.items?.length === 0) return toast.error('Cannot group empty order ')
      order0().commits!.push({ action: CommitAction.CREATE_MOVE_ORDER });
      order0().commits!.push({ action: CommitAction.GROUP_TABLE, seatMode: order0()?.seatMode });
      // order0().commits!.push({ action: CommitAction.CREATE_MOVE_ORDER });
      setGroupEnable(true)
      router.screen = PosScreen.MOVE_ITEM_TABLE;
      console.log("Order: ", order0(), order0().getMoveOrder!())
    }

    function onGroupCancel() {
      setGroupEnable(false);
      restoreSnapshot();
    }



    return {
      splitEnable,
      setSplitEnable,
      currentSeat,
      setCurrentSeat,
      isCurrentSeat,
      seat,
      setSeat,
      snapshot,
      restoreSnapshot,
      onItemClick,
      onMinus,
      onPlus,
      order0,
      onNext,
      onSeatToggle,
      onSplitCancel,
      addSeat,
      /** Indicated that the table is ready to be moved. The seat is merged and the target table is selected. */
      moveEnable,
      setMoveEnable,
      /** Mark to move only part of the table. If the table is in seat mode, will merge all seat before continue */
      onMoveToggle,
      onMoveCancel,
      onMoveComplete,
      /** Mark to move all items in the table. Merge if necessary */
      onGroupToggle,
      onGroupCancel,
      /** Allow merging table if the target table is occupied */
      groupEnable,
      saveSnapshot,
      moveRestItemsToSeat
    };
  };