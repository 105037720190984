import { openobserveLogs } from '@openobserve/browser-logs'
import dayjs from 'dayjs'
import { filter, Subject } from 'rxjs'
import Surreal from 'surrealdb'

import { posSettingLock } from '@/data/PosSettingsSignal.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { updateConnectionStatus } from '@/lib/open-telemetry.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import { getUrlSurrealDbCloud } from '@/shared/utils.ts'

export const surrealLock = new MultiAwaitLock(true)

type ScopeOption = {
  username: string
  password: string
}

export type SurrealConnectionState = {
  state?: 'CONNECTED' | 'DISCONNECTED' | 'ERROR'
  dbName?: string
  mode?: 'ws' | 'http'
}

interface Notification {
  updatedAt: Date
  action: any
  doc: any
}

export default class SurrealClient {
  static surrealClient: Record<string, Promise<Surreal>> = {}
  static message$: Subject<SurrealConnectionState> = new Subject<SurrealConnectionState>()
  static mode: 'http' | 'ws' = 'ws'

  static async getSurrealClient(dbName: string) {
    if (!!SurrealClient.surrealClient[dbName]) {
      return SurrealClient.surrealClient[dbName]
    }

    SurrealClient.surrealClient[dbName] = new Promise(async (resolve, reject) => {
      await posSettingLock.acquireAsync()
      await surrealLock.acquireAsync()
      try {
        console.log('create surreal connection', dbName)
        const db = new Surreal({})

        const url = getUrlSurrealDbCloud()
        const token = posSync0()?.replicateServer?.[dbName]

        if (!token) {
          throw Error(`Token of ${dbName} not found`)
        }

        async function connect(firstConnect = false) {
          while (true) {
            try {
              if (db.connection?.connection) {
                //@ts-ignore
                db.connection!.connection = undefined
              }
              await db.connect(SurrealClient.mode === 'ws' ? url.replace('https', 'wss') : url.replace('wss', 'https'), {
                // Set the authentication details for the connection
                auth: token,
              })
              openobserveLogs.logger.info('surrealConnected')
              updateConnectionStatus('surreal-connection', true)
              // Connected
              return
            } catch (e) {
              await db.close()
              if (firstConnect) {
                console.error('Failed to connect, retrying', e)
                await new Promise(resolve => {
                  setTimeout(resolve, 3000)
                })
              } else {
                // Use retry
                return
              }
            }
          }
        }

        const proxyDb = new Proxy(db, {
          get(target: Surreal, p: string | symbol, receiver: any): any {
            if (p === 'live') {
              return async (table: string, callback: (action: string, result: any) => void) => {
                if (target.connection?.connection.url?.protocol.includes('wss')) {
                  SurrealClient.message$.pipe(filter(message => message.dbName === dbName)).subscribe({
                    next: async connectionState => {
                      if (connectionState.state === 'CONNECTED' && SurrealClient.mode === 'ws' && connectionState.dbName === dbName) {
                        await target.live(table, callback)
                      }
                    },
                  })

                  return await target.live(table, callback)
                }
                if (table !== 'master') return
                let since = dayjs().toDate()
                setInterval(async () => {
                  const [result] = await target.query<[Notification[]]>(`SELECT * FROM ${table}_change WHERE updatedAt > $since`, {
                    since,
                  })
                  if (result.length === 0) return
                  for (const notification of result) {
                    callback(notification.action, notification.doc)
                    since = notification.updatedAt
                  }
                }, 5000)
              }
            }
            return Reflect.get(target, p, receiver)
          },
        })

        await connect(true)

        db.emitter.subscribe('connected', () => {
          openobserveLogs.logger.info('surrealConnected')
          updateConnectionStatus('surreal-connection', true)
          console.log('Surreal connected', dbName)
          SurrealClient.message$.next({
            state: 'CONNECTED',
            dbName: dbName,
          })
        })
        db.emitter.subscribe('disconnected', () => {
          openobserveLogs.logger.info('surrealDisconnected')
          updateConnectionStatus('surreal-connection', false)
          console.log('Surreal disconnected', dbName)
          SurrealClient.message$.next({
            state: 'DISCONNECTED',
            dbName: dbName,
          })
          if (SurrealClient.mode === 'ws') {
            setTimeout(() => connect().then(), 3000)
          }
        })
        db.emitter.subscribe('error', () => {
          console.log('Surreal error', dbName)
          SurrealClient.message$.next({
            state: 'ERROR',
            dbName: dbName,
          })
        })
        resolve(proxyDb)
      } catch (err) {
        delete SurrealClient.surrealClient[dbName]
        reject(err)
      }
    })

    return SurrealClient.surrealClient[dbName]
  }

  static async closeAll() {
    const dbNames = Object.keys(SurrealClient.surrealClient)
    for (const dbName of dbNames) {
      const db = await SurrealClient.surrealClient[dbName]
      await db.close()
    }
  }

  static async switchToHttp() {
    if (SurrealClient.mode === 'http') return
    SurrealClient.mode = 'http'
    await SurrealClient.closeAll()
    SurrealClient.message$.next({
      mode: 'http',
    })
  }

  static async switchToWs() {
    if (SurrealClient.mode === 'ws') return
    SurrealClient.mode = 'ws'
    await SurrealClient.closeAll()
    SurrealClient.message$.next({
      mode: 'ws',
    })
  }
}

//@ts-ignore
window.SurrealClient = SurrealClient
