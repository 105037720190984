import { TaxCategory } from "@/data/TaxCategory.ts";
import {PaymentSettingViewLogic} from '@/react/PaymentSettingView/PaymentSettingView.logic.ts'
import { convertDocuments } from "../data-utils";
import { log } from "debug";
import { posSync0 } from "@/data/PosSyncState.ts";
import { Product } from "@/data/Product.ts";
import type { RxDocument } from "rxdb";
import { batch } from "@/react/core/reactive.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";



async function changeTax(_taxCategories: RxDocument<TaxCategory>[], tax?: number) {
  if (!_taxCategories) return
  const taxCategories = convertDocuments(_taxCategories, true)
  for (const taxCategory of taxCategories) {
    if (taxCategory.type === 'combo') {
      const tpsIndex = taxCategory.components?.findIndex(c => c.name === 'TPS');
      taxCategory.components![tpsIndex!].value = 5;
      taxCategory.value = 14.975
      log(`update Quebec tax, value 14.975`)
    } else if (tax) {
      taxCategory.value = tax
      log(`update non Quebec, value ${tax}, tax type: ${taxCategory.type}, province ${posSetting0()?.companyInfo?.province}`)
    }
  }
  console.log('taxCategories', taxCategories)
  return taxCategories;
}

async function changeProductTax(taxCategories: TaxCategory[]) {
  // const taxCategories = await TaxCategory.find().exec();
  for (const taxCategory of taxCategories) {
    const applyTax = new PaymentSettingViewLogic()
    await applyTax.applyTaxToAllProduct(taxCategory)
  }
}

async function checkTax() {
  const _taxCategories = await TaxCategory.find().exec();
  if (!_taxCategories) {
    log(`taxCategories not found, storeId ${posSync0()?.id!}`, {alert: true, storeId: posSync0()?.id!})
    return
  }
  const taxCategories = convertDocuments(_taxCategories, false)
  for (const taxCategory of taxCategories) {
    if (taxCategory.type === 'combo') {
      //taxCategory chưa đổi
      if (taxCategory.value === 9.975) {
        log(`check fail, continue handleUpdateTax`)
        return _taxCategories;
      }
      //tps chưa đổi
      const tpsValue = taxCategory.components?.find(c => c.name === 'TPS')?.value;
      if(!tpsValue) {
        log(`check fail, continue handleUpdateTax`)
        return _taxCategories;
      }
    } else {
      //thuế ngoài quebec vẫn là 0
      if (!taxCategory.value) {
        log(`check fail, continue handleUpdateTax`)
        return _taxCategories;
      }
    }
    // tax category đã đổi nhưng thuế product chưa đổi
    const _products = await Product.find({selector: {taxCategory: taxCategory.name}}).exec();
    const products = convertDocuments(_products, true)
    const taxType = taxCategory.type

    batch(() => {
      //xử lý nếu menuTaxes là object
      for (const product of products) {
        if (typeof product.menuTaxes === 'object') {
          product.menuTaxes = undefined
        }

        if (typeof product.menuTaxes2 === 'object') {
          product.menuTaxes2 = undefined
        }
      }
    })

    const unChangeProduct = products.filter(t =>
      taxType !== 'combo'
        ? (t.tax === 0 || t.tax2 === 0)
        : (t.taxComponents?.[0].value === 0 || t.tax === 9.975 || t.tax2 === 9.975 || t.taxComponents2?.[0].value === 0)
    );

    if (unChangeProduct.length > 0) {
      log(`check fail, continue handleUpdateTax`)
      return _taxCategories;
    }
  }
  log(`product and tax has been changed already, stop handleUpdateTax`)
  return
}

//use this function

export async function cTaxProduct(tax?: number) {
  const _taxCategories = await checkTax();
  if (_taxCategories) {
    const taxCategories = await changeTax(_taxCategories, tax);
    // @ts-ignore
    await changeProductTax(taxCategories)
  }
}

window.changeTax = changeTax
window.cTaxProduct = cTaxProduct
window.checkTax = checkTax
