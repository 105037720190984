import KitchenMonitorPlugin from "@kitchen-monitor/KitchenMonitorPlugin.tsx";
import { computed, effectOn, signal } from "@/react/core/reactive.ts";
import { memo } from "react";
import { Order, PaidOrder } from "@/data/Order.ts";
import { CommitAction, OrderKitchenStatus, OrderStatus, type TOrder } from "@/pos/OrderType.ts";
import { subscribePause } from "@/data/ImportData.ts";
import _ from "lodash";
import { OnlineOrder } from "@/data/OnlineOrder.ts";
import { kitchenOrderStatus } from "@/react/PendingOrder/PendingOrderLogic.tsx";
import { createOrder, resetOrder, stripOrder } from "@/pos/logic/order-reactive.ts";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import debug from 'debug';
import { generalSetting0, posSettingLock } from "@/data/PosSettingsSignal.ts";
import bellMp3 from "@/assets/sounds/bell.mp3";
import { posSettingV } from "@/data/PosSettingHub.ts";

const log = debug("view:kitchenMonitor");

const repeat = () => true
const playSound = () => true

let isPlaying = false
let shouldStopAtNextTurn = false
const bell = new Audio(bellMp3)
let bellInitialized = false
let totalNewItems: number = 0
let playCount = 0;


export const [kitchenOrders, setKitchenOrders] = signal<Order[]>([])
export const [fastCheckoutOrdersId, setFastCheckoutOrdersId] = signal<string[]>([])
export const [pendingOrdersId, setPendingOrderId] = signal<string[]>([])
export const [currentFilter, setFilterOrders] = signal<'all' | 'prepare' | 'done' | 'new'>('all')
export const [filterPrinter, setFilterPrinter] = signal<string | 'all'>("all")
export const kitchenOrders1 = computed(() => {
  let order: Order[]
  switch (currentFilter()) {
    case OrderKitchenStatus.PREPARE:
      order = kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.PREPARE)
      break;
    case OrderKitchenStatus.DONE:
      order = kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.DONE)
      break;
    case OrderKitchenStatus.NEW:
      order = kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.NEW && !o.items.every(i => i.isVoucher))
      break;
    default:
      order = kitchenOrders().filter(o => o.kitchenStatus !== OrderKitchenStatus.ARCHIVED)
      break;
  }
  return sortOrders(order.filter(o => !o.items.every(i => i.isVoucher)));
})

function initKitchenBell() {
  if (bellInitialized) return
  bellInitialized = true
  isPlaying = false
  shouldStopAtNextTurn = false
  bell.addEventListener('play', () => (isPlaying = true))
  bell.addEventListener('ended', () => {
    isPlaying = false
    const kitchenOrdersNotEmpty = sumAllItems(kitchenOrders()) > 0
    if (repeat() && !shouldStopAtNextTurn && kitchenOrdersNotEmpty && playCount < 2) {
      if (generalSetting0()?.limitBellOnly3Times) playCount += 1
      playKitchenBell()
    }
  })
}
export function stopKitchenBell() {
  shouldStopAtNextTurn = true
}

export function playKitchenBell() {
  if (import.meta.env.MODE === 'development') return
  if (!playSound()) {
    console.log('play sound is disabled. skip')
    return
  }

  if (isPlaying) {
    console.log('bell is playing. skip')
    return
  }
  shouldStopAtNextTurn = false
  bell.play().then()
}

function sumAllItems(orders: Order[]): number {
  return orders.reduce((total, order) => {
    if (order.isStarted) {
      return total;
    }
    const validItemsQuantity = order.items
      .filter(item =>
        item.quantity > 0 &&
        !item.isKitchenDone &&
        !item.isVoucher &&
        (filterPrinter() === 'all' ||
          item.groupPrinter === filterPrinter() ||
          item.groupPrinter2 === filterPrinter() ||
          item.labelPrinter === filterPrinter())
      )
      .reduce((itemTotal, item) => itemTotal + item.quantity, 0);
    return total + validItemsQuantity;
  }, 0);
}

const fetchOrdersKitchen = _.debounce(async function fetch() {
  const orders = (await Order.find({
    selector: {
      $and: [
        { kitchenStatus: { $exists: true } },
      ]
    }
  }).exec()).map(o => o.toMutableJSON())?.filter((order) => !!order.table || !!order.provider)
  const pendingOrders = (await OnlineOrder.find({
    selector: {
      status: { $in: [...kitchenOrderStatus] },
    },
  }).exec()).map(o => o.toMutableJSON())
  const fastCheckoutOrders = (await PaidOrder.find({
    selector: {
      provider: { $exists: false },
      table: { $exists: false },
      status: OrderStatus.PAID,
      $and: [
        { kitchenStatus: { $exists: true } },
        { kitchenStatus: { $ne: OrderKitchenStatus.ARCHIVED } }
      ]
    }
  }).exec()).map(o => o.toMutableJSON())
  setPendingOrderId(pendingOrders.map(p => p._id))
  setFastCheckoutOrdersId(fastCheckoutOrders.map(p => p._id))
  const mergeOrders = [...orders, ...pendingOrders, ...fastCheckoutOrders] as Order[];
  const finalOrders = mergeOrders.filter(o => !!o.kitchenStatus)
  // Fixme: if archived -> check item -> prepare
  for (const order of finalOrders) {
    if (
      order.kitchenStatus !== OrderKitchenStatus.NEW &&
      !order.items.every(i => i.isKitchenDone === true || i.isVoucher)
    ) {
      order.kitchenStatus = OrderKitchenStatus.PREPARE;
    }
  }
  const newItems = sumAllItems(finalOrders)
  const shouldPlayBell = newItems > totalNewItems
  if (shouldPlayBell && generalSetting0()?.enableKitchenBell) {
    playCount = 0
    playKitchenBell()
  }
  totalNewItems = newItems
  setKitchenOrders(finalOrders.filter(o => o.kitchenStatus !== OrderKitchenStatus.ARCHIVED))
}, 150);

export const startOrder = async (orderId: string) => {
  const order = kitchenOrders().find(order => order._id === orderId);
  if (!order || order?.isStarted) return
  log(`startOrder ${orderId}`, { orderId })
  const isPendingOrder = pendingOrdersId().includes(orderId)
  const isFastCheckoutOrder = fastCheckoutOrdersId().includes(orderId)
  _.assign(order, { isStarted: true })
  if (isPendingOrder) {
    await OnlineOrder.upsert(order)
  } else if (isFastCheckoutOrder) {
    await PaidOrder.upsert(order)
  } else {
    await Order.upsert(order)
  }
}

export const startAllOrder = async () => {
  for (const order of kitchenOrders()) {
    if (order.isStarted) continue
    await startOrder(order._id)
  }
}

export async function setOrderStatus(orderId: string, status: OrderKitchenStatus) {
  const order = kitchenOrders().find(o => o._id === orderId)
  log(`setOrderStatus ${orderId}: ${status} isPendingOrder ${pendingOrdersId().includes(orderId || '')}`, { orderId })
  if (!order) {
    log(`setOrderStatus not found order ${orderId}`)
    return
  }
  const isPendingOrder = pendingOrdersId().includes(orderId)
  const isFastCheckoutOrder = fastCheckoutOrdersId().includes(orderId)
  if (isFastCheckoutOrder || isPendingOrder) {
    order.kitchenStatus = status;
    order.isStarted = true
    if (status === OrderKitchenStatus.DONE) {
      for (const item of order.items) {
        if (!item.isKitchenDone) {
          item.isKitchenDone = true
        }
      }
    }
    if (isFastCheckoutOrder) {
      await PaidOrder.upsert(order);
    } else {
      await OnlineOrder.upsert(order);
    }
    return
  }
  const _order = createOrder(resetOrder(order as Order))
  _order.kitchenStatus = status;
  _order.isStarted = true
  if (status === OrderKitchenStatus.DONE) {
    for (const item of _order.items) {
      if (!item.isKitchenDone) {
        _order.commits!.push({ action: CommitAction.SET_ITEM_STATUS, commitId: item._id!, isKitchenDone: true })
      }
    }
  }
  await Order.upsert(stripOrder(_order));
}

//@ts-ignore
window.setOrderStatus = setOrderStatus

export async function setItemStatus(orderId: string, itemId: string, isKitchenDone: boolean) {
  log(`setItemStatus ${itemId}. status is ${isKitchenDone}`, { orderId })
  const order = kitchenOrders().find(o => o._id === orderId)
  if (!order) return
  const isPendingOrder = pendingOrdersId().includes(orderId)
  const isFastCheckoutOrder = fastCheckoutOrdersId().includes(orderId)
  if (isFastCheckoutOrder || isPendingOrder) {
    _.assign(order, { isStarted: true })
    const item = order.items.find(i => i._id === itemId)
    _.assign(item, { isKitchenDone })
    if (order.items.every(i => i.isKitchenDone === true || i.isVoucher)) {
      _.assign(order, { kitchenStatus: OrderKitchenStatus.DONE })
    } else {
      _.assign(order, { kitchenStatus: OrderKitchenStatus.PREPARE })
    }
    if (isFastCheckoutOrder) {
      await PaidOrder.upsert(order);
    } else {
      await OnlineOrder.upsert(order);
    }
    return
  }
  const _order = createOrder(resetOrder(order as Order))
  _order.isStarted = true
  _order.commits!.push({ action: CommitAction.SET_ITEM_STATUS, commitId: itemId, isKitchenDone: isKitchenDone })
  if (_order.items.every(i => i.isKitchenDone === true || i.isVoucher)) {
    _order.kitchenStatus = OrderKitchenStatus.DONE
  } else {
    _order.kitchenStatus = OrderKitchenStatus.PREPARE
  }
  await Order.upsert(stripOrder(_order));
}


function sortOrders(orders: Order[]): Order[] {
  const statusOrder = {
    [OrderKitchenStatus.NEW]: 0,
    [OrderKitchenStatus.PREPARE]: 1,
    [OrderKitchenStatus.DONE]: 2,
    [OrderKitchenStatus.ARCHIVED]: 3,
  };

  return orders.sort((a, b) => {
    if (generalSetting0()?.onlySortByDate) {
      return (b.startAt ?? 0) - (a.startAt ?? 0);
    }
    const statusDiff = statusOrder[a.kitchenStatus ?? OrderKitchenStatus.NEW] - statusOrder[b.kitchenStatus ?? OrderKitchenStatus.NEW];
    if (statusDiff !== 0) return statusDiff;
    return (b.startAt ?? 0) - (a.startAt ?? 0);
  });
}


export const totalNewOrders = computed(() => {
  return kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.NEW && !o.items.every(i => i.isVoucher))?.length
})
export const totalPrepareOrders = computed(() => {
  return kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.PREPARE)?.length
})
export const totalDoneOrders = computed(() => {
  return kitchenOrders().filter(o => o.kitchenStatus === OrderKitchenStatus.DONE)?.length
})

export const getNameKitchenPrinter = (id: string) => {
  const name = groupPrinters0().find(p => p._id === id)?.name
  return name || 'Cannot find printer'
}

export const groupItemsPrinter = (order: TOrder) => {

  const items = order?.items.filter(item => item.quantity > 0 && !item.isVoucher) || [];

  // Tạo groupItems
  const groupItems = items.reduce((result, item) => {
    const groupKey = new Set([item.groupPrinter || "undefined", item.groupPrinter2 || "undefined", item.labelPrinter || "undefined"]);
    const key = Array.from(groupKey).sort().join(","); // Đảm bảo key là duy nhất và có thứ tự
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {} as Record<string, typeof items>);

  // Lấy danh sách printers
  const printers = Object.keys(groupItems).map(key => key.split(",").map(id => id));

  // Lọc printers
  const selectedPrinterKeys = printers
    .map((ids, index) => (ids.includes(filterPrinter()) ? Object.keys(groupItems)[index] : null))
    .filter((key): key is string => key !== null); // Chỉ giữ những key không phải null

  // Tạo filterGroupItems dưới dạng Record
  const filterGroupItems = selectedPrinterKeys.reduce((result, key) => {
    result[key] = groupItems[key] || [];
    return result;
  }, {} as Record<string, typeof items>);

  return filterPrinter() === "all" ? groupItems : filterGroupItems;
};



//@ts-ignore
window.kitchenOrders1 = kitchenOrders1

effectOn([posSettingV], async () => {
  await posSettingLock.acquireAsync()
  if (!generalSetting0()?.displayKitchenEnable || bellInitialized) return
  initKitchenBell()
  await fetchOrdersKitchen()
  Order.$.subscribe(async _e => {
    if (subscribePause()) return
    await fetchOrdersKitchen()
  })
  OnlineOrder.$.subscribe(async _e => {
    if (subscribePause()) return
    await fetchOrdersKitchen()
  })
  PaidOrder.$.subscribe(async _e => {
    if (subscribePause()) return
    await fetchOrdersKitchen()
  })
})

const KitchenMonitorView = () => {
  return (
    <KitchenMonitorPlugin />
  )
}

export default memo(KitchenMonitorView)