import uuid from 'time-uuid'

import { posSync0 } from '@/data/PosSyncState.ts'
import type { CallOptions } from '@/lib/master-caller/client-caller.ts'
import { broadcastCommand } from '@/lib/rxdb-master.ts'
import { getNatsClient, getNatsClientLock, StringCodec } from "@/lib/entries.ts";

export default class MasterCaller {
  static async broadcastClientCommand(method: string, args: any[], options: CallOptions) {
    if (!posSync0()?.id!) return
    const { onlyOnline = false, onlyOffline = false } = options
    let sent = false
    const commandId = uuid()
    if (!onlyOnline) {
      try {
        broadcastCommand({
          method,
          params: args,
          commandId: commandId,
        })
        sent = true
      } catch (err) {
        console.log('Can not broadcast message offline', err)
      }
    }
    if (!onlyOffline) {
      const subject = `${posSync0()?.id}-client`
      await getNatsClientLock.acquireAsync()
      const natsClient = getNatsClient()
      try {
        natsClient.getClient()?.publish(
          subject,
          StringCodec().encode(
            JSON.stringify({
              method,
              params: args,
              commandId: commandId,
            })
          )
        )
        sent = true
      } catch (err) {
        console.log('Can not publish message using nats', err)
      }
    }
    if (!sent) {
      throw new Error('Failed to send command to client')
    }
  }
}
