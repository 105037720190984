import { type FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import NewDeliveryMissedCallPopup from './Popups/NewDeliveryMissedCallPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { onEnterNewDeliveryWithCustomer } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import {LL0} from "@/react/core/I18nService.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { onToggleTakeOut } from "@/react/OrderView/OrderView.tsx";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import { CommitAction } from "@/pos/OrderType.ts";

const NewDeliveryBottomBar: FunctionComponent = () => {
  const { order0 } = useContext(ItemFactoryContext)
  const [isNewDeliveryMissedCallPopupOpen, setNewDeliveryMissedCallPopupOpen] =
    useState(false);

  const openNewDeliveryMissedCallPopup = useCallback(() => {
    userFLow(`Open popup missing call in delivery`, {
      username: loginUser()?.name
    })
    setNewDeliveryMissedCallPopupOpen(true);
  }, []);

  const closeNewDeliveryMissedCallPopup = useCallback(() => {
    setNewDeliveryMissedCallPopupOpen(false);
  }, []);

  useEffect(() => {
    order0?.().commits?.push({
      action: CommitAction.CHANGE_ORDER_TAKE_AWAY,
      takeAway: true,
    })
  }, []);

  return (
    <>
      <div className="self-stretch [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] [backdrop-filter:blur(100px)] flex flex-row items-center justify-between py-2 px-4 text-center text-sm text-black-solid-black-900-1e1e23 font-mulish sm:flex height_414:hidden">
        <div className="flex flex-row items-start justify-start gap-[0px_12px] sm:gap-[0px_12px] Tablet_600:gap-[0px_12px]">
          <div
            className="rounded-81xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px] cursor-pointer"
            onClick={openNewDeliveryMissedCallPopup}
          >
            <img
              className="w-[26px] relative h-[26px] object-cover sm:flex Tablet_768:hidden"
              alt=""
              src="/iconadd-item-icon@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-xs">
              {LL0().delivery.toolbar.missedCalls()}
            </b>
          </div>
          {!generalSetting0()?.addCustomerAfterOrder ? <div
            className="rounded-81xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px]"
            onClick={() => onEnterNewDeliveryWithCustomer()}
          >
            <img
              className="w-[27px] relative h-[27px] object-cover sm:flex Tablet_768:hidden"
              alt=""
              src="/iconnew-deliveryadd-customer-icon@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-xs">
              {LL0().onlineOrder.addCustomer()}
            </b>
          </div> : null}
        </div>
        <div className='flex'>
          <div
            className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2 box-border relative gap-[3px]"
            ref={makeRipple}
            onClick={onToggleTakeOut}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover z-[1] sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
              alt=""
              src="/ordertake-out-icon@2x.png"
            />
            {order0?.().takeAway ? <>
                <div
                  className="!block absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl bg-blue-solid-blue-350-2196f3 hidden z-[0]" />
                <b
                  className="!w-fit !block relative leading-[17px] hidden text-white-solid-white-100-ffffff items-center w-16 h-[17px] shrink-0 z-[3] Tablet_768:text-xs">
                  {LL0().order.takeAway()}
                </b>
              </> :
              <b className="!w-fit relative leading-[17px] flex items-center w-16 h-[17px] shrink-0 z-[2]">
                {LL0().order.takeAway()}
              </b>
            }
          </div>
        </div>
      </div>
      {isNewDeliveryMissedCallPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryMissedCallPopup}
        >
          <NewDeliveryMissedCallPopup
            onClose={closeNewDeliveryMissedCallPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default NewDeliveryBottomBar;
