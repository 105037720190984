import _ from "lodash";
import dayjs from "dayjs";
import { formatCurrencyDe } from "@/shared/utils";
import type { Order } from "@/data/Order.ts";
import { type OrderItem, OrderStatus } from "@/pos/OrderType.ts";
import type { Eod } from "@/data/Eod.ts";
import { Bonpos } from "@/tse/dsfinv/dsfinvModel2.ts";
import type { User } from "@/data/User.ts";
import { getZERSTELLUNG } from "@/tse/dsfinv/dsfinv-convert.ts";
import { deviceSetting0, deviceSettings0 } from "@/data/DeviceSettingSignal.ts";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Bonkopf = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  BON_ID: {
    type: "string",
    maxLength: 40,
    hint: "Vorgangs-ID"
  },
  BON_NR: {
    type: "number",
    places: 0,
    hint: "Bonnummer"
  },
  BON_TYP: {
    type: "string",
    hint: "Bontyp"
  },
  BON_NAME: {
    type: "string",
    maxLength: 60,
    hint: "Zusatz-Beschreibung zum Bontyp"
  },
  TERMINAL_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID des Erfassungsterminals"
  },
  BON_STORNO: {
    type: "boolean",
    hint: "Storno-Kennzeichen"
  },
  BON_START: {
    type: "datetime",
    hint: "Zeitpunkt des Vorgangsstarts"
  },
  BON_ENDE: {
    type: "datetime",
    hint: "Zeitpunkt der Vorgangsbeendigung"
  },
  BEDIENER_ID: {
    type: "string",
    maxLength: 50,
    hint: "Bediener-ID"
  },
  BEDIENER_NAME: {
    type: "string",
    maxLength: 50,
    hint: "Bediener-Name"
  },
  UMS_BRUTTO: {
    type: "number",
    places: 2,
    hint: "Brutto-Gesamtumsatz"
  },
  KUNDE_NAME: {
    type: "string",
    maxLength: 50,
    hint: "Name des Leistungsempfängers"
  },
  KUNDE_ID: {
    type: "string",
    maxLength: 50,
    hint: "Kundennummer des Leistungsempfängers"
  },
  KUNDE_TYP: {
    type: "string",
    maxLength: 50,
    hint: "Art des Leistungsempfängers (z. B. Mitarbeiter)"
  },
  KUNDE_STRASSE: {
    type: "string",
    maxLength: 60,
    hint: "Straße und Hausnummer des Leistungsempfängers"
  },
  KUNDE_PLZ: {
    type: "string",
    maxLength: 10,
    hint: "PLZ des Leistungsempfängers"
  },
  KUNDE_ORT: {
    type: "string",
    maxLength: 62,
    hint: "Ort des Leistungsempfängers"
  },
  KUNDE_LAND: {
    type: "string",
    maxLength: 3,
    hint: "Land des Leistungsempfängers"
  },
  KUNDE_USTID: {
    type: "string",
    maxLength: 15,
    hint: "UStID des Leistungsempfängers"
  },
  BON_NOTIZ: {
    type: "string",
    maxLength: 255,
    hint: "Zusätzliche Informationen zum Bonkopf"
  }
}

enum BON_TYPE_ENUM {
  Beleg = 'Beleg',
  Storno = 'Storno'
}

enum BON_NAME_ENUM {
  VERKAUF = 'VERKAUF',
  STORNO = 'STORNO'
}

const LAUF_KUNDE = 'Laufkunde';

export function BonkopfFactory(order: Order, eod: Eod, user: User) {
  return build();

  function build() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      BON_ID: BON_ID(),
      BON_NR: BON_NR(),
      BON_TYP: BON_TYP(),
      BON_NAME: BON_NAME(),
      TERMINAL_ID: TERMINAL_ID(),
      BON_STORNO: BON_STORNO(),
      BON_START: BON_START(),
      BON_ENDE: BON_ENDE(),
      BEDIENER_ID: BEDIENER_ID(),
      BEDIENER_NAME: BEDIENER_NAME(),
      UMS_BRUTTO: UMS_BRUTTO(),
      KUNDE_NAME: KUNDE_NAME(),
      KUNDE_ID: KUNDE_ID(),
      KUNDE_TYP: KUNDE_TYP(),
      KUNDE_STRASSE: KUNDE_STRASSE(),
      KUNDE_PLZ: KUNDE_PLZ(),
      KUNDE_ORT: KUNDE_ORT(),
      KUNDE_LAND: KUNDE_LAND(),
      KUNDE_USTID: KUNDE_USTID(),
      BON_NOTIZ: BON_NOTIZ()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    return order.z;
  }

  function BON_ID() {
    return order.id;
  }

  function BON_NR() {
    // hint: "Bonnummer"
    return order.id;
  }

  function BON_TYP() {
    // hint: "Bontyp"
    if (order.status === 'cancelled') return BON_TYPE_ENUM.Storno
    return BON_TYPE_ENUM.Beleg
  }

  function BON_NAME() {
    // hint: "Zusatz-Beschreibung zum Bontyp"
    return BON_NAME_ENUM.VERKAUF;
  }

  function TERMINAL_ID() {
    // hint: "ID des Erfassungsterminals"
    return deviceSetting0()?._id || '';
  }

  function BON_STORNO() {
    // hint: "Storno-Kennzeichen"
    return order.status === OrderStatus.CANCELLED ? '1': '0';
  }

  function BON_START() {
    //todo: make order.endDate for better trace, log
    // hint: "Zeitpunkt des Vorgangsstarts"

    const items = _.orderBy(order.items, ['date']);
    if (!items[0] || !dayjs.unix(items[0]?.date!).isValid()) return order.date;
    return dayjs.unix(items[0]?.date!).toISOString();
  }

  function BON_ENDE() {
    // hint: "Zeitpunkt der Vorgangsbeendigung"
    return dayjs.unix(order.date!).toISOString();
  }

  function BEDIENER_ID() {
    // hint: "Bediener-ID"
    return user?._id;
  }

  function BEDIENER_NAME() {
    // hint: "Bediener-Name"
    return user?.name;
  }

  function UMS_BRUTTO() {
    // hint: "Brutto-Gesamtumsatz"
    return formatCurrencyDe(order.vSum!);
  }

  function KUNDE_NAME() {
    // hint: "Name des Leistungsempfängers"
    return order.customerRaw?.name || LAUF_KUNDE;
  }

  function KUNDE_ID() {
    // hint: "Kundennummer des Leistungsempfängers"
    return order.customer;
  }

  function KUNDE_TYP() {
    // hint: "Art des Leistungsempfängers (z. B. Mitarbeiter)"
    return order.customerRaw?.type || 'Standardkunde';
  }

  function KUNDE_STRASSE() {
    // hint: "Straße und Hausnummer des Leistungsempfängers"
    return order.customerRaw?.address;
  }

  function KUNDE_PLZ() {
    // hint: "PLZ des Leistungsempfängers"
    return order.customerRaw?.zipCode;
  }

  function KUNDE_ORT() {
    // hint: "Ort des Leistungsempfängers"
    return order.customerRaw?.city || '';
  }

  function KUNDE_LAND() {
    // hint: "Land des Leistungsempfängers"
    return "DEU";
  }

  function KUNDE_USTID() {
    // hint: "UStID des Leistungsempfängers"
    return order.customerRaw?.ustId;
  }

  function BON_NOTIZ() {
    // hint: "Zusätzliche Informationen zum Bonkopf"
    return order.customerRaw?.note || '';
  }
}
