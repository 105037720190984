import _ from "lodash";
import dayjs from "dayjs";
import type { Eod } from "@/data/Eod.ts";
import { TseCertificate } from "@/data/TseCertificate.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { getZERSTELLUNG } from "./dsfinv-convert";
import { masterDeviceSetting } from "@/data/utils/MasterDeviceSetting.ts";

export const Stamm_TSE = {
  Z_KASSE_ID: {
    type: "string",
    maxLength: 50,
    hint: "ID der (Abschluss-) Kasse"
  },
  Z_ERSTELLUNG: {
    type: "datetime",
    hint: "Zeitpunkt des Kassenabschlusses"
  },
  Z_NR: {
    type: "number",
    places: 0,
    hint: "Nr. des Kassenabschlusses"
  },
  TSE_ID: {
    type: "number",
    places: 0,
    hint: "ID der TSE - wird nur zur Referenzierung innerhalb eines Kassenabschlusses verwendet"
  },
  TSE_SERIAL: {
    type: "string",
    maxLength: 68,
    hint: "Seriennummer der TSE (Entspricht laut TR-03153 Abschnitt 7.5. dem Hashwert des im Zertifikat enthaltenen Schlüssels in Octet-String-Darstellung)"
  },
  TSE_SIG_ALGO: {
    type: "string",
    maxLength: 30,
    hint: "Der von der TSE verwendete Signaturalgorithmus"
  },
  TSE_ZEITFORMAT: {
    type: "string",
    hint: "Das von der TSE verwendete Format für die Log-Time - 'utcTime' = YYMMDDhhmmZ, 'utcTimeWithSeconds' = YYMMDDhhmmssZ, 'generalizedTime' = YYYYMMDDhhmmssZ, 'generalizedTimeWithMilliseconds' = YYYYMMDDhhmmss.fffZ, 'unixTime'"
  },
  TSE_PD_ENCODING: {
    type: "string",
    maxLength: 5,
    hint: "Text-Encoding der ProcessData (UTF-8 oder ASCII)",
    regex: "^(UTF-8|ASCII)$"
  },
  TSE_PUBLIC_KEY: {
    type: "string",
    maxLength: 512,
    hint: "Öffentlicher Schlüssel – ggf. extrahiert aus dem Zertifikat der TSE – in base64-Codierung"
  },
  TSE_ZERTIFIKAT_I: {
    type: "string",
    maxLength: 1000,
    hint: "Erste 1.000 Zeichen des Zertifikats der TSE (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_II: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_III: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_IV: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_V: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_VI: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  },
  TSE_ZERTIFIKAT_VII: {
    type: "string",
    maxLength: 1000,
    hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
  }
}

export function Stamm_TSEFactory(eod: Eod) {
  let certificates: string[] = [];
  return build();
  async function build() {
    if (!eod) return []
    certificates = eod.tseData?.certificates || [];
    const rows: ReturnType<typeof buildSingle>[] = [];
    rows.push(buildSingle());
    return rows;
  }
  function buildSingle() {
    return {
      Z_KASSE_ID: Z_KASSE_ID(),
      Z_ERSTELLUNG: Z_ERSTELLUNG(),
      Z_NR: Z_NR(),
      TSE_ID: TSE_ID(),
      TSE_SERIAL: TSE_SERIAL(),
      TSE_SIG_ALGO: TSE_SIG_ALGO(),
      TSE_ZEITFORMAT: TSE_ZEITFORMAT(),
      TSE_PD_ENCODING: TSE_PD_ENCODING(),
      TSE_PUBLIC_KEY: TSE_PUBLIC_KEY(),
      TSE_ZERTIFIKAT_I: TSE_ZERTIFIKAT_I(),
      TSE_ZERTIFIKAT_II: TSE_ZERTIFIKAT_II(),
      TSE_ZERTIFIKAT_III: TSE_ZERTIFIKAT_III(),
      TSE_ZERTIFIKAT_IV: TSE_ZERTIFIKAT_IV(),
      TSE_ZERTIFIKAT_V: TSE_ZERTIFIKAT_V(),
      TSE_ZERTIFIKAT_VI: TSE_ZERTIFIKAT_VI(),
      TSE_ZERTIFIKAT_VII: TSE_ZERTIFIKAT_VII()
    }
  }

  function Z_KASSE_ID() {
    // hint: "ID der (Abschluss-) Kasse"
    return masterDeviceSetting()?._id;
  }

  function Z_ERSTELLUNG() {
    // hint: "Zeitpunkt des Kassenabschlusses"
    return getZERSTELLUNG(eod)
  }

  function Z_NR() {
    // hint: "Nr. des Kassenabschlusses"
    return eod.z;
  }

  function TSE_ID() {
    // hint: "ID der TSE - wird nur zur Referenzierung innerhalb eines Kassenabschlusses verwendet"
    // return eod.tseData?.username;
    return 1;
  }

  function TSE_SERIAL() {
    // hint: "Seriennummer der TSE (Entspricht laut TR-03153 Abschnitt 7.5. dem Hashwert des im Zertifikat enthaltenen Schlüssels in Octet-String-Darstellung)"
    return eod.tseData?.serialNumber;
  }

  function TSE_SIG_ALGO() {
    // hint: "Der von der TSE verwendete Signaturalgorithmus"
    return eod.tseData?.signatureAlgorithm;
  }

  function TSE_ZEITFORMAT() {
    // hint: "Das von der TSE verwendete Format für die Log-Time - 'utcTime' = YYMMDDhhmmZ, 'utcTimeWithSeconds' = YYMMDDhhmmssZ, 'generalizedTime' = YYYYMMDDhhmmssZ, 'generalizedTimeWithMilliseconds' = YYYYMMDDhhmmss.fffZ, 'unixTime'"
    return eod.tseData?.tseTimeFormat;
  }

  function TSE_PD_ENCODING() {
    // hint: "Text-Encoding der ProcessData (UTF-8 oder ASCII)"
    return eod.tseData?.pdEncoding;
  }

  function TSE_PUBLIC_KEY() {
    // hint: "Öffentlicher Schlüssel – ggf. extrahiert aus dem Zertifikat der TSE – in base64-Codierung"
    return eod.tseData?.tsePublicKey;
  }

  function TSE_ZERTIFIKAT_I() {
    // hint: "Erste 1.000 Zeichen des Zertifikats der TSE (in base64-Codierung)"
    return certificates[0] || '';
  }

  function TSE_ZERTIFIKAT_II() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[1] || '';
  }

  function TSE_ZERTIFIKAT_III() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[2] || '';
  }

  function TSE_ZERTIFIKAT_IV() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[3] || '';
  }

  function TSE_ZERTIFIKAT_V() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[4] || '';
  }

  function TSE_ZERTIFIKAT_VI() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[5] || '';
  }

  function TSE_ZERTIFIKAT_VII() {
    // hint: "Ggf. Rest des Zertifikats (in base64-Codierung)"
    return certificates[6] || '';
  }
}
