export type Server = {
  manageServer: {
    url: string
  }
  replicateServer: {
    couchdb: string
    password: string
    management: string
  }
  surrealDbCloud: {
    url: string
    username?: string
    password?: string
  }
  surrealDbLog: {
    url: string
    username?: string
    password: string
  }
  s3Log: {
    region: string
    accessKeyId: string
    secretAccessKey: string
    bucketName: string
  }
  o2: {
    url: string
    metrics: string
    authToken: string
    logToken: string
    logSite: string
  }
  nats: {
    url: string
  }
  appStore: {
    appId: string
  }
  getTimeAPI: {
    url: string
  }
}

export const CANADA_SERVER_1 = 'canada1'
export const CANADA_SANDBOX_SERVER = 'canada_sandbox'
export const GERMANY_SERVER_1 = 'germany1'
export const GERMANY_SERVER_2 = 'germany2'
export const ASIA_SERVER_1 = 'asia1'

export const servers: Record<string, Server> = {
  [CANADA_SERVER_1]: {
    manageServer: {
      url: 'https://manage.vegapos.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika.gigasource.io',
      password: 'mBkrkOm11j9fR5rQcJwK',
      management: 'https://couchdb-pika.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-pika.gigasource.io',
    },
    surrealDbLog: {
      url: 'https://surreal-log-ca.gigasource.io',
      username: 'logUser',
      password: 'logUserRandom202334234!',
    },
    s3Log: {
      region: 'us-east-2', // Replace with your region
      accessKeyId: 'AKIAYTPBKCTRYRR77NTT',
      secretAccessKey: '6gTWF1phCnUYXqf8U6B7X2sa2LdqVSucD0LVCHiY',
      bucketName: 'pos-logs-rn',
    },
    o2: {
      url: 'https://o2-pika-1.gigasource.io/api/default/traces',
      metrics: 'https://o2-pika-1.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206T1NVWEdEd2I1U2Mza25mWg==',
      logToken: 'rumXcIaZtY1eVCMBG0X',
      logSite: 'o2-pika-1.gigasource.io',
    },
    nats: {
      url: 'wss://nats-pika.gigasource.io',
    },
    appStore: {
      appId: 'id6473614358'
    },
    getTimeAPI: {
      url: 'https://pos-fn-dev-ca.gigasource.io/time'
    }
  },
  [CANADA_SANDBOX_SERVER]: {
    manageServer: {
      url: 'https://sandbox.manage.vegapos.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika.gigasource.io',
      password: 'mBkrkOm11j9fR5rQcJwK',
      management: 'https://couchdb-pika.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-pika.gigasource.io',
    },
    surrealDbLog: {
      url: 'https://surreal-log-ca.gigasource.io',
      username: 'logUser',
      password: 'logUserRandom202334234!',
    },
    s3Log: {
      region: 'us-east-2', // Replace with your region
      accessKeyId: 'AKIAYTPBKCTRYRR77NTT',
      secretAccessKey: '6gTWF1phCnUYXqf8U6B7X2sa2LdqVSucD0LVCHiY',
      bucketName: 'pos-logs-rn',
    },
    o2: {
      url: 'https://o2-pika-1.gigasource.io/api/default/traces',
      metrics: 'https://o2-pika-1.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206T1NVWEdEd2I1U2Mza25mWg==',
      logToken: 'rumXcIaZtY1eVCMBG0X',
      logSite: 'o2-pika-1.gigasource.io',
    },
    nats: {
      url: 'wss://nats-pika.gigasource.io',
    },
    appStore: {
      appId: 'id6473614358'
    },
    getTimeAPI: {
      url: 'https://pos-fn-dev-ca.gigasource.io/time'
    }
  },
  [GERMANY_SERVER_1]: {
    manageServer: {
      url: 'https://manage.wave-pos.de',
    },
    replicateServer: {
      couchdb: 'https://couchdb-moritz-de.gigasource.io/',
      password: 'hvDJ3wNNaqouvw9z000V',
      management: 'https://couchdb-moritz-de.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal.wave-pos.de',
    },
    surrealDbLog: {
      url: 'https://surreal-log-de.gigasource.io',
      username: 'logUser',
      password: 'logUserRandom202334234!',
    },
    o2: {
      url: 'https://o2-de-1.gigasource.io/api/default/traces',
      metrics: 'https://o2-de-1.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206SmRhbjdqREQyOVRPYU1HWg==',
      logToken: 'rum5bXjz46Vjy1vTRBT',
      logSite: 'o2-de-1.gigasource.io',
    },
    s3Log: {
      region: 'eu-central-1', // Replace with your region
      accessKeyId: 'AKIAZQ3DPCXIZNTCN4MF',
      secretAccessKey: 'UoDe0bT9ChOe3vT5w8tFr84FQUK9rXgVQAU/bI7y',
      bucketName: 'pos-logs-rn-de',
    },
    nats: {
      url: 'wss://nats-ger-1.gigasource.io',
    },
    appStore: {
      appId: 'id6476484529'
    },
    getTimeAPI: {
      url: 'https://pos-fn-dev-de.gigasource.io/time'
    }
  },
  [GERMANY_SERVER_2]: {
    manageServer: {
      url: 'https://manage-de.gigasource.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb.gigasource.io',
      password: 'd8kpvWAhfQyJMDq65kpn',
      management: 'https://couchdb.gigasource.io/_utils/',
    },
    surrealDbCloud: {
      url: 'wss://surreal-de.gigasource.io/',
      password: 'po0xuuGj08OY65z1',
    },
    surrealDbLog: {
      url: 'https://surreal-log-de.gigasource.io',
      username: 'logUser',
      password: 'logUserRandom202334234!',
    },
    s3Log: {
      region: 'eu-central-1', // Replace with your region
      accessKeyId: 'AKIAZQ3DPCXIZNTCN4MF',
      secretAccessKey: 'UoDe0bT9ChOe3vT5w8tFr84FQUK9rXgVQAU/bI7y',
      bucketName: 'pos-logs-rn-de',
    },
    o2: {
      url: 'https://o2-de-2.gigasource.io/api/default/traces',
      metrics: 'https://o2-de-2.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206c1BsblltT1U3R0w2NFh5eg==',
      logToken: 'rumj5UJv7lYRbsQ9Y0w',
      logSite: 'o2-de-2.gigasource.io',
    },
    nats: {
      url: 'wss://nats-ger-1.gigasource.io',
    },
    appStore: {
      appId: 'id6473614358'
    },
    getTimeAPI: {
      url: 'https://pos-fn-dev-de.gigasource.io/time'
    }
  },
  [ASIA_SERVER_1]: {
    manageServer: {
      url: 'https://pos-manage.gigasource.io',
    },
    replicateServer: {
      couchdb: 'https://couchdb-pika-sg.gigasource.io',
      password: 'ZXufGvfKmnsWqKkUy10R',
      management: 'https://couchdb-pika-sg.gigasource.io/_utils',
    },
    surrealDbCloud: {
      url: 'wss://ovh-db.gigasource.io',
    },
    surrealDbLog: {
      url: 'https://ovh-db.gigasource.io',
      username: 'logUser',
      password: 'logUserRandom202334234!',
    },
    s3Log: {
      region: 'us-east-2', // Replace with your region
      accessKeyId: 'AKIAYTPBKCTRYRR77NTT',
      secretAccessKey: '6gTWF1phCnUYXqf8U6B7X2sa2LdqVSucD0LVCHiY',
      bucketName: 'pos-logs-rn',
    },
    o2: {
      url: 'https://o2-sg.gigasource.io/api/default/traces',
      metrics: 'https://o2-sg.gigasource.io/api/default/v1/metrics',
      authToken: 'Basic YWRtaW5AZXhhbXBsZS5jb206NnR4ZmhmY1cwdTd3Y0J6TQ==',
      logToken: 'rumMgT6JZWgxCwp6LIW',
      logSite: 'o2-sg.gigasource.io',
    },
    nats: {
      url: 'wss://nats-sg.gigasource.io',
    },
    appStore: {
      appId: 'id6473614358'
    },
    getTimeAPI: {
      url: 'https://pos-fn-sg.gigasource.io/time'
    }
  },
}
